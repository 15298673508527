.disclosure,
.footer-text-block__subtitle {
  color: #fff;
  font-stretch: initial;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: normal;
  text-transform: none;
  font-family: var(--fmc-font--proxima-nova);

  a {
    color: #fff !important;
  }
}
.footer-text-block__subtitle {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 300;
}

.footer-nav {
  &__list {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 580px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__column {
    &__link-ul {
      span {
        font-family: var(--fmc-font--proxima-nova);
        font-weight: 400;
        font-size: 17px;
        margin-bottom: 21px;
        letter-spacing: 2px;
        a {
         /* font-size: 16px;*/
         color: #a8a8a8 !important;
          font-weight: 300;
          letter-spacing: 0.5px;
          text-decoration: none;

          &:hover {
            color: #f26148 !important;
            font-weight: 300; 
          }
        }
      }
    }
  }
}

.footer-legal {
  &__nav {
    min-height: 120px;
    border-top: none !important;

    @media (max-width: 1040px) {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
    }

    &-list-item,
    button {
      font-size: 14px;
      font-weight: 400;
      margin-left: 30px;
      white-space: nowrap;
      text-decoration: none;
      letter-spacing: normal;
      font-family: var(--fmc-font--proxima-nova);
      min-width: auto;
      background-color: transparent;
      
      @media (max-width: 1040px) {
        width: 100%;
        margin-left: 0;
        margin-top: 12px;
        #ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
          width:initial;
        }
        
      }
    }

    button {
      border: none;
      margin-left: 0;
      padding: 0;
      color: var(--fds-color--primary);
      height: auto;
      word-wrap: break-word;
      line-height: 1.2;
      cursor: pointer;
      transition: 0.1s ease;
      font-stretch: condensed;

      &:hover {
        color: var(--fds-color--secondary);
      }

      @media (max-width: 1040px) {
        text-align: left;
        margin-top: 0px;
      }
    }

    &__logo {
      margin-left: auto;
      @media (max-width: 1040px) {
        margin-left: 0;
        margin-top: 20px;
        align-self: flex-start;
        margin-bottom: 15px;
        svg {
          margin-top:23px;
          width: 71px;
        }
      }
    }
  }
}

p.footer-legal__nav-list-item {
  margin-left: 0;
}

.footerbtn {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 300;
  letter-spacing: normal;
  height: 51px;
  padding-left: 35px;
  padding-right: 35px; 
  color: #fff;
  margin: 2px;
  border-radius: 1px solid #fff;
  border: 1px solid #fff;
  border-radius: 0;
  margin-top:5px;
  &:hover {
    background: #324047 !important;
  }
  @media (max-width: 600px){ 
    font-size:12px !important;
    padding: 0px 8px 0px 15px !important;
  }
}

@media (max-width: 600px){
  .cookiebtn{
    justify-content: left !important;
  }
  .disclosure{
    color: #fff;
  font-stretch: initial;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: none;
  }
  
  .footer-nav__list-item {
    margin-top: 20px !important;
    color: #a8a8a8;
    padding-top: 0px; 
    padding-bottom: 0px;
    .item-txt {
      margin-top:0px;
      margin-bottom:0px;
      a {
        color: #a8a8a8;
        font-weight:400;
      }
    }
  } 
  footer {
    background: inherit !important;
  }
}

#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
  color: #324047 !important;
  border: 1px solid #68b631;
  height: auto;
  white-space: normal;
  word-wrap: break-word;
  padding: 0.8em 2em;
  font-size: 0.8em;
  line-height: 1.2;
  cursor: pointer;
  -moz-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  -webkit-transition: 1s ease;
  transition: 0.1s ease;
}
#ot-sdk-btn.ot-sdk-show-settings:hover, #ot-sdk-btn.optanon-show-settings:hover {
  color: #fff !important;
  background-color: #68b631;
}

.opt-out_container {
  height: 35px;
  margin-left: 20px;
   img  {
    height: 100%;
    width: auto;
  }
}

@media screen and (max-width: 1200px) {
  .opt-out_container {
    margin-top: 1rem;
    margin-left: 6px;
    align-self: flex-start;
  }
  }