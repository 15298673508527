$fds-lincoln-font-directory: "~@ford/ford-design-system/dist/assets/fonts/lincoln/";
$theme-color: #324047;
$white-color: #ffffff;
$theme-hover-color: #f26148;

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  //font-family: var(--fmc-font--proxima-nova);
}

/* Set core body defaults */
html {
  font-family: var(--fmc-font--proxima-nova); 
}
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: var(--fmc-font--proxima-nova);
  overflow-x: hidden; 
}

/* Remove list styles on ul, ol elements with a class attribute */
ul {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  color: inherit;
}
a:focus {
  outline: none;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/*
    responsive-property mixin
    Adds automatic small-desktop & tablet breakpoints based on the desktop + mobile dimensions
    - Parameters: 1 SCSS list containing multiple SCSS lists
    - Usage:
        @include responsive-property((
        ($property, $desktop-dimension, $mobile-dimension),
        (margin-top, 60px, 30px),
        (height, 100px, 50px)
        ))
    - Important: When only adding in one responsive property, add a comma to the end of the list to avoid SCSS errors
*/
/*
    responsive-property-liquid mixin

    Adds automatic small-desktop & tablet breakpoints based on the desktop + mobile dimensions
    - 3 Parameters: property, schema settings id containing desktop dimension value, schema settings id containing mobile dimension value
    - Usage:
        @include responsive-property-liquid(font-size, font_size_title_xl, font_size_title_xl_mobile)
*/
/* ------------------------------------------
    Project specific
------------------------------------------ */
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.flex > *.grow {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.f-inline {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.f-inline > *.grow {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.f-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

.f-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.f-flex-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
}

.f-flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}

.f-vertical-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
}

.f-vertical-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.f-vertical-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}

.f-dir-column {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.f-dir-reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.f-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.f-equal-width > * {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.visually-hidden {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

.opacity-hidden {
  opacity: 0 !important;
  visibility: hidden !important;
}

/* ---------------------------------------------
    Helpers
--------------------------------------------- */
/* ---------------------------------------------
    General
--------------------------------------------- */
body {
  font-family: var(--fmc-font--miller) !important;
  color: #4d4d4d;
  line-height: normal;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 16px;
}
@media (max-width: 1024px) {
  body {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  body {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  body {
    font-size: 14px;
  }
}

a[class] {
  text-decoration: none;
}

.fnt-pri {
  font-family: var(--fmc-font--proxima-nova);
}

.fnt-sec {
  font-family: var(--fmc-font--miller);
}

/* ---------------------------------------------
    Titles
--------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 300;
  text-transform: uppercase;
}

[class*="title--"] {
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.title--xl {
  font-size: 96px;
}
@media (max-width: 1024px) {
  .title--xl {
    font-size: 82px;
  }
}
@media (max-width: 768px) {
  .title--xl {
    font-size: 68px;
  }
}
@media (max-width: 600px) {
  .title--xl {
    font-size: 40px;
  }
}

.title--lg {
  font-size: 60px;
}
@media (max-width: 1024px) {
  .title--lg {
    font-size: 57px;
  }
}
@media (max-width: 768px) {
  .title--lg {
    font-size: 54px;
  }
}
@media (max-width: 600px) {
  .title--lg {
    font-size: 48px;
  }
}
.title--lg.bold {
  line-height: 70px;
  letter-spacing: 2px;
}
@media (max-width: 1024px) {
  .title--lg.bold {
    line-height: 67px;
    letter-spacing: 1.9px;
  }
}
@media (max-width: 768px) {
  .title--lg.bold {
    line-height: 64px;
    letter-spacing: 1.8px;
  }
}
@media (max-width: 600px) {
  .title--lg.bold {
    line-height: 58px;
    letter-spacing: 1.6px;
  }
}

.title--md {
  font-size: 48px;
  line-height: 58px;
}
@media (max-width: 1024px) {
  .title--md {
    font-size: 44px;
  }
}
@media (max-width: 768px) {
  .title--md {
    font-size: 41px;
  }
}
@media (max-width: 600px) {
  .title--md {
    font-size: 34px;
  }
}
@media (max-width: 1024px) {
  .title--md {
    line-height: 54.5px;
  }
}
@media (max-width: 768px) {
  .title--md {
    line-height: 51px;
  }
}
@media (max-width: 600px) {
  .title--md {
    line-height: 44px;
  }
}

.title--sm,
h2 {
  font-size: 34px;
}
@media (max-width: 1024px) {
  .title--sm,
  h2 {
    font-size: 31px;
  }
}
@media (max-width: 768px) {
  .title--sm,
  h2 {
    font-size: 29px;
  }
}
@media (max-width: 600px) {
  .title--sm,
  h2 {
    font-size: 24px;
  }
}

.title--xs {
  font-size: 24px;
  line-height: 34px;
}
@media (max-width: 1024px) {
  .title--xs {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .title--xs {
    font-size: 22px;
  }
}
@media (max-width: 600px) {
  .title--xs {
    font-size: 20px;
  }
}
@media (max-width: 1024px) {
  .title--xs {
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .title--xs {
    line-height: 30px;
  }
}
@media (max-width: 600px) {
  .title--xs {
    line-height: 26px;
  }
}

.title--xxs {
  font-size: 20px;
}
@media (max-width: 1024px) {
  .title--xxs {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .title--xxs {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .title--xxs {
    font-size: 20px;
  }
}

.subtitle {
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 300;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 26px;
  font-family: var(--fmc-font--miller);
  font-weight: 400;
  text-transform: none;
}
@media (max-width: 1024px) {
  .subtitle {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .subtitle {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .subtitle {
    font-size: 16px;
  }
}

/* ---------------------------------------------
    Font weights
--------------------------------------------- */
.bolder {
  font-weight: 900;
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

/* ---------------------------------------------
    Font sizes
--------------------------------------------- */
.error {
  font-size: 12px;
  letter-spacing: 1px;
  color: #b00020;
  line-height: 21px;
}
@media (max-width: 1024px) {
  .error {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .error {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .error {
    font-size: 12px;
  }
}

.caption {
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 21px;
  font-family: var(--fmc-font--miller);
  text-transform: none;
}
@media (max-width: 1024px) {
  .caption {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .caption {
    font-size: 11px;
  }
}
@media (max-width: 600px) {
  .caption {
    font-size: 11px;
  }
}

.sm {
  font-size: 14px;
}
@media (max-width: 1024px) {
  .sm {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .sm {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .sm {
    font-size: 10px;
  }
}

.md {
  font-size: 16px;
}
@media (max-width: 1024px) {
  .md {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .md {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  .md {
    font-size: 14px;
  }
}

.lg {
  font-size: 18px;
}
@media (max-width: 1024px) {
  .lg {
    font-size: 17px;
  }
}
@media (max-width: 768px) {
  .lg {
    font-size: 17px;
  }
}
@media (max-width: 600px) {
  .lg {
    font-size: 16px;
  }
}

/* ---------------------------------------------
    Text transform
--------------------------------------------- */
.lc {
  text-transform: lowercase;
}

.uc {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.strike {
  text-decoration: line-through;
}

/* ---------------------------------------------
    Text alignment
--------------------------------------------- */
.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

/* ---------------------------------------------
    RichTextEditor
--------------------------------------------- */
.rte {
  line-height: 26px;
}
@media (max-width: 600px) {
  .rte {
    line-height: 24px;
    letter-spacing: 0.88px;
  }
}
.rte li {
  line-height: 20px;
  margin-bottom: 11px;
}
.rte a:not([class]),
.color-white .rte a:not([class]),
.bg-primary .rte a:not([class]),
.bg-secondary .rte a:not([class]),
.bg-tertiary .rte a:not([class]),
.bg-quaternary .rte a:not([class]),
.bg-quinary .rte a:not([class]),
.bg-black .rte a:not([class]) {
  color: #ecedee;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
@media (min-width: 769px) {
  .rte a:not([class]):hover,
  .color-white .rte a:not([class]):hover,
  .bg-primary .rte a:not([class]):hover,
  .bg-secondary .rte a:not([class]):hover,
  .bg-tertiary .rte a:not([class]):hover,
  .bg-quaternary .rte a:not([class]):hover,
  .bg-quinary .rte a:not([class]):hover,
  .bg-black .rte a:not([class]):hover {
    color: $white-color;
  }
}
@media (min-width: 769px) {
  .rte a:not([class]):hover {
    color: $theme-color;
  }
}
.rte img {
  display: inline-block;
}
.rte iframe {
  display: block;
  width: 100%;
  margin: 30px 0;
}

.rte-icon-and-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 20px;
}
.rte-icon-and-heading img,
.rte-icon-and-heading svg {
  width: 15px;
  height: auto;
  margin-right: 10px;
  order: 0;
}

sup {
  font-size: 30%;
  position: relative;
  top: 1rem;
  line-height: 0;
  vertical-align: text-top;
}
@media (max-width: 480px) {
  sup {
    top: 1.1em;
  }
}

img {
  display: block;
  max-width: 100%;
}

.object-cover {
  display: block;
  overflow: hidden;
}
.object-cover > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.object-cover.object-cover__top img {
  object-position: top;
}

.object-contain {
  display: block;
}
.object-contain > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.object-contain .object-contain__top img {
  object-position: top;
}

.alternative-object-fit {
  background-size: cover;
  background-position: center center;
}
.alternative-object-fit.contain {
  background-size: contain;
  background-repeat: no-repeat;
}
.alternative-object-fit img {
  opacity: 0;
}

/* ------------------------------------------
    Text Colour
------------------------------------------ */
.color-primary {
  color: $theme-color;
}
.color-primary a:not([class]),
.color-primary button,
.color-primary em,
.color-primary h1,
.color-primary h2,
.color-primary h3,
.color-primary h4,
.color-primary h5,
.color-primary h6,
.color-primary input,
.color-primary label,
.color-primary li,
.color-primary p,
.color-primary s,
.color-primary span,
.color-primary strong {
  color: $theme-color;
}
.color-primary use {
  fill: $theme-color;
}

.color-secondary {
  color: $theme-color;
}
.color-secondary a:not([class]),
.color-secondary button,
.color-secondary em,
.color-secondary h1,
.color-secondary h2,
.color-secondary h3,
.color-secondary h4,
.color-secondary h5,
.color-secondary h6,
.color-secondary input,
.color-secondary label,
.color-secondary li,
.color-secondary p,
.color-secondary s,
.color-secondary span,
.color-secondary strong {
  color: $theme-color;
}
.color-secondary use {
  fill: $theme-color;
}

.color-tertiary {
  color: $theme-hover-color;
}
.color-tertiary a:not([class]),
.color-tertiary button,
.color-tertiary em,
.color-tertiary h1,
.color-tertiary h2,
.color-tertiary h3,
.color-tertiary h4,
.color-tertiary h5,
.color-tertiary h6,
.color-tertiary input,
.color-tertiary label,
.color-tertiary li,
.color-tertiary p,
.color-tertiary s,
.color-tertiary span,
.color-tertiary strong {
  color: $theme-hover-color;
}
.color-tertiary use {
  fill: $theme-hover-color;
}

.color-quaternary {
  color: #233338;
}
.color-quaternary a:not([class]),
.color-quaternary button,
.color-quaternary em,
.color-quaternary h1,
.color-quaternary h2,
.color-quaternary h3,
.color-quaternary h4,
.color-quaternary h5,
.color-quaternary h6,
.color-quaternary input,
.color-quaternary label,
.color-quaternary li,
.color-quaternary p,
.color-quaternary s,
.color-quaternary span,
.color-quaternary strong {
  color: #233338;
}
.color-quaternary use {
  fill: #233338;
}

.color-white,
.bg-primary,
.bg-secondary,
.bg-tertiary,
.bg-quaternary,
.bg-quinary,
.bg-black {
  color: $white-color;
}
.color-white a:not([class]),
.bg-primary a:not([class]),
.bg-secondary a:not([class]),
.bg-tertiary a:not([class]),
.bg-quaternary a:not([class]),
.bg-quinary a:not([class]),
.bg-black a:not([class]),
.color-white button,
.bg-primary button,
.bg-secondary button,
.bg-tertiary button,
.bg-quaternary button,
.bg-quinary button,
.bg-black button,
.color-white em,
.bg-primary em,
.bg-secondary em,
.bg-tertiary em,
.bg-quaternary em,
.bg-quinary em,
.bg-black em,
.color-white h1,
.bg-primary h1,
.bg-secondary h1,
.bg-tertiary h1,
.bg-quaternary h1,
.bg-quinary h1,
.bg-black h1,
.color-white h2,
.bg-primary h2,
.bg-secondary h2,
.bg-tertiary h2,
.bg-quaternary h2,
.bg-quinary h2,
.bg-black h2,
.color-white h3,
.bg-primary h3,
.bg-secondary h3,
.bg-tertiary h3,
.bg-quaternary h3,
.bg-quinary h3,
.bg-black h3,
.color-white h4,
.bg-primary h4,
.bg-secondary h4,
.bg-tertiary h4,
.bg-quaternary h4,
.bg-quinary h4,
.bg-black h4,
.color-white h5,
.bg-primary h5,
.bg-secondary h5,
.bg-tertiary h5,
.bg-quaternary h5,
.bg-quinary h5,
.bg-black h5,
.color-white h6,
.bg-primary h6,
.bg-secondary h6,
.bg-tertiary h6,
.bg-quaternary h6,
.bg-quinary h6,
.bg-black h6,
.color-white input,
.bg-primary input,
.bg-secondary input,
.bg-tertiary input,
.bg-quaternary input,
.bg-quinary input,
.bg-black input,
.color-white label,
.bg-primary label,
.bg-secondary label,
.bg-tertiary label,
.bg-quaternary label,
.bg-quinary label,
.bg-black label,
.color-white li,
.bg-primary li,
.bg-secondary li,
.bg-tertiary li,
.bg-quaternary li,
.bg-quinary li,
.bg-black li,
.color-white p,
.bg-primary p,
.bg-secondary p,
.bg-tertiary p,
.bg-quaternary p,
.bg-quinary p,
.bg-black p,
.color-white s,
.bg-primary s,
.bg-secondary s,
.bg-tertiary s,
.bg-quaternary s,
.bg-quinary s,
.bg-black s,
.color-white span,
.bg-primary span,
.bg-secondary span,
.bg-tertiary span,
.bg-quaternary span,
.bg-quinary span,
.bg-black span,
.color-white strong,
.bg-primary strong,
.bg-secondary strong,
.bg-tertiary strong,
.bg-quaternary strong,
.bg-quinary strong,
.bg-black strong {
  color: $white-color;
}
.color-white use,
.bg-primary use,
.bg-secondary use,
.bg-tertiary use,
.bg-quaternary use,
.bg-quinary use,
.bg-black use {
  fill: $white-color;
}

.color-black {
  color: #4d4d4d;
}
.color-black a:not([class]),
.color-black button,
.color-black em,
.color-black h1,
.color-black h2,
.color-black h3,
.color-black h4,
.color-black h5,
.color-black h6,
.color-black input,
.color-black label,
.color-black li,
.color-black p,
.color-black s,
.color-black span,
.color-black strong {
  color: #4d4d4d;
}
.color-black use {
  fill: #4d4d4d;
}

/* ------------------------------------------
    Background colour
------------------------------------------ */
.bg-primary {
  background: $theme-color;
}

.bg-secondary {
  background: $theme-color;
}

.bg-tertiary {
  background: $theme-hover-color;
}

.bg-quaternary {
  background: #233338;
}

.bg-quinary {
  background: #ecedee;
}

.bg-black {
  background: #4d4d4d;
}

@media (min-width: 321px) {
  .mobile-small-only {
    display: none !important;
  }
}

@media (min-width: 481px) {
  .mobile-only {
    display: none !important;
  }
}

@media (min-width: 601px) {
  .mobile-large-only {
    display: none !important;
  }
  .MuiAlert-message {
    font-size: 16px;
  }
}

@media (min-width: 769px) {
  .tablet-only {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .small-desktop-only {
    display: none !important;
  }
}

@media (min-width: 1201px) {
  .medium-desktop-only {
    display: none !important;
  }
}

@media (min-width: 1441px) {
  .large-desktop-only {
    display: none !important;
  }
}

@media (max-width: 1440px) {
  .above-large-desktop {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .above-medium-desktop {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .above-small-desktop {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .above-tablet {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .above-mobile-large {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .above-mobile {
    display: none !important;
  }
}

@media (max-width: 320px) {
  .above-mobile-small {
    display: none !important;
  }
}

/* ------------------------------------------
    body
------------------------------------------ */
body.no-scroll {
  overflow: hidden;
}

/* ------------------------------------------
    UPDATE ONCE SAFARI FIXES THEIR SH....
------------------------------------------ */
html.ios__no-scroll,
body.ios__no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

/* ------------------------------------------
    Container
------------------------------------------ */
.container,
.container--sm,
.container--no-padding,
.container--mb-2,
.kuContainer {
  width: 1299px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 1024px) {
  .container,
  .container--sm,
  .container--no-padding,
  .container--mb-2,
  .kuContainer {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 600px) {
  .container,
  .container--sm,
  .container--no-padding,
  .container--mb-2,
  .kuContainer {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.container--no-padding {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 600px) {
  .container--mb-2 {
    padding-left: 25px;
    padding-right: 25px;
  }
}

main:after {
  content: "";
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 4;
  pointer-events: none;
  background: #4d4d4d;
  opacity: 0;
  visibility: visible;
  -webkit-transition: all linear 0.35s;
  -moz-transition: all linear 0.35s;
  -ms-transition: all linear 0.35s;
  -o-transition: all linear 0.35s;
  transition: all linear 0.35s;
  transition-delay: 250ms;
}

main .ford-section:last-child section {
  margin-bottom: 0px;
}

main section {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media (max-width: 1024px) {
  main section {
    margin-top: 52.5px;
  }
}
@media (max-width: 768px) {
  main section {
    margin-top: 45px;
  }
}
@media (max-width: 600px) {
  main section {
    margin-top: 30px;
  }
}
@media (max-width: 1024px) {
  main section {
    margin-bottom: 52.5px;
  }
}
@media (max-width: 768px) {
  main section {
    margin-bottom: 45px;
  }
}
@media (max-width: 600px) {
  main section {
    margin-bottom: 30px;
  }
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.m-t-0 {
  margin-top: 0;
}

.p-t-0 {
  padding-top: 0;
}

.m-b-0 {
  margin-bottom: 0;
}

.p-b-0 {
  padding-bottom: 0;
}

/* --------------------------------
    Padding
-------------------------------- */
.p-m {
  padding-top: 30px;
  padding-bottom: 30px;
}

.p-l {
  padding-top: 40px;
  padding-bottom: 40px;
}

.p-s {
  padding-top: 25px;
  padding-bottom: 25px;
}

.p-t-s {
  padding-top: 25px;
}

.p-t-m {
  padding-top: 30px;
}

.p-t-l {
  padding-top: 40px;
}

.p-b-s {
  padding-bottom: 25px;
}

.p-b-m {
  padding-bottom: 30px;
}

.p-b-l {
  padding-bottom: 40px;
}

.p-l-s {
  padding-left: 25px;
}

.p-l-m {
  padding-left: 30px;
}

.p-l-l {
  padding-left: 40px;
}

.p-r-s {
  padding-right: 25px;
}

.p-r-m {
  padding-right: 30px;
}

.p-r-l {
  padding-right: 40px;
}

/* --------------------------------
    margin
-------------------------------- */
.m-s {
  margin-top: 25px;
  margin-bottom: 25px;
}

.m-m {
  margin-top: 30px;
  margin-bottom: 30px;
}

.m-l {
  margin-top: 40px;
  margin-bottom: 40px;
}

.m-t-s {
  margin-top: 25px;
}

.m-t-m {
  margin-top: 30px;
}

.m-t-l {
  margin-top: 40px;
}

.m-b-s {
  margin-bottom: 25px;
}

.m-b-m {
  margin-bottom: 30px;
}

.m-b-l {
  margin-bottom: 40px;
}

.m-l-s {
  margin-left: 25px;
}

.m-l-m {
  margin-left: 30px;
}

.m-l-l {
  margin-left: 40px;
}

.m-r-s {
  margin-right: 25px;
}

.m-r-m {
  margin-right: 30px;
}

.m-r-l {
  margin-right: 40px;
}

.col-2 {
  column-count: 2;
}

.col-3 {
  column-count: 3;
}

.col-4 {
  column-count: 4;
}

.grid,
.grid--1,
.grid--2,
.grid--3,
.grid--4,
.grid--5,
.grid--6 {
  display: grid;
  grid-gap: 25px;
}

.grid--1 {
  grid-template-columns: repeat(1, 1fr);
}

.grid--2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid--6 {
  grid-template-columns: repeat(6, 1fr);
}

.grid-item--full {
  grid-column: 1 / -1;
}

/* Hides preview bar */
html {
  padding-bottom: 0px !important;
}

@media (min-width: 769px) {
  .text--half {
    width: 607px;
    max-width: 50%;
  }
}

.vue-rendered {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

*:focus {
  outline: 1px solid #2861a4 !important;
  outline-offset: 10px;
}

.bg-primary *:focus,
.site-header-eyebrow *:focus,
.footer-text-block *:focus,
.footer-nav *:focus {
  outline: 1px solid $white-color !important;
}

*:focus:not(:focus-visible) {
  outline: none;
  box-shadow: 1px 1px 5px rgba(1, 1, 0, 0.7);
}

* [aria-hidden="true"]:not(.ColorPicker__active___2xhvC) {
  /*display: none;*/
}

.lp_header-buttons-container .lpc_maximized-header__minimize-button-asset {
  display: inherit;
}

.lp_header-buttons-container .lpc_maximized-header__close-button-asset {
  display: inherit;
}

.skip-to-content-link {
  display: block;
  position: absolute;
  left: 30px;
  top: -100px;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  color: $white-color;
  background: #4d4d4d;
  border-radius: 3px;
  -webkit-transition: all 0.35s linear;
  -moz-transition: all 0.35s linear;
  -ms-transition: all 0.35s linear;
  -o-transition: all 0.35s linear;
  transition: all 0.35s linear;
}
.skip-to-content-link:focus {
  top: 30px;
  z-index: 11;
}

.hidden-for-crawlers {
  display: none !important;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

/* ---------------------------------------------
    Custom overrides
--------------------------------------------- */
.title--case-normal {
  text-transform: initial;
  letter-spacing: 1px;
}

main {
  overflow-x: hidden;
}

section:first-of-type {
  margin-top: 0;
}

/* ---------------------------------------------
    Elements
--------------------------------------------- */
/* ---------------------------------------------
    Button
--------------------------------------------- */
[class*="btn"] {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  font-size: 18px;
  font-family: var(--fmc-font--proxima-nova);
  letter-spacing: 1px;
  min-width: 165px;
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  color: $white-color;
  background: $theme-color;
}
@media (min-width: 769px) {
  [class*="btn"]:hover:not(:disabled) {
    background: $white-color;
    color: $theme-color;
  }
}

/* ---------------------------------------------
    Button - Centered
--------------------------------------------- */
.btn--centered {
  margin: 0 auto;
}

/* ---------------------------------------------
    Button - Rounded
--------------------------------------------- */
.btn--rounded {
  border-radius: 20px;
}

/* ---------------------------------------------
    Button - Chevron Mixin
--------------------------------------------- */
/* ---------------------------------------------
    Button - Chevron
--------------------------------------------- */
.btn--chevron {
  position: relative;
  padding-right: 40px;
}
.btn--chevron:before,
.btn--chevron:after {
  content: "";
  /* background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-white.svg?v=32409767268468141941664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  width: 10px;
  height: 16px;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn--chevron:before {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-primary.svg?v=156992684832692582051664033349);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
}
@media (min-width: 769px) {
  .btn--chevron:hover:not(:disabled):not(.btn--plain):after {
    opacity: 0;
  }
  .btn--chevron:hover:not(:disabled):not(.btn--plain):before {
    opacity: 1;
  }
}
.btn--chevron.btn--white:not(.btn--border):not(.btn--plain):after,
.btn--chevron:not(.btn--white).btn--border:after {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-primary.svg?v=156992684832692582051664033349);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.btn--chevron.btn--white:not(.btn--border):not(.btn--plain):before,
.btn--chevron:not(.btn--white).btn--border:before {
  /* background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-white.svg?v=32409767268468141941664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.btn--chevron.btn--plain {
  padding-right: 50px;
  visibility: visible;
}
@media (min-width: 769px) {
  .btn--chevron.btn--plain:hover:not(:disabled):after,
  .btn--chevron.btn--plain:hover:not(:disabled):before {
    right: 25px;
  }
}

/* ---------------------------------------------
    Button - Chevron Secondary
--------------------------------------------- */
.btn--chevron-secondary {
  position: relative;
  padding-right: 46px;
}
.btn--chevron-secondary:before,
.btn--chevron-secondary:after {
  content: "";
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-rounded-chevron-right-white.svg?v=124415340203201549581664033352);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn--chevron-secondary:before {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-rounded-chevron-right-primary.svg?v=79085750878079925731664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
}
@media (min-width: 769px) {
  .btn--chevron-secondary:hover:not(:disabled):not(.btn--plain):after {
    opacity: 0;
  }
  .btn--chevron-secondary:hover:not(:disabled):not(.btn--plain):before {
    opacity: 1;
  }
}
.btn--chevron-secondary.btn--white:not(.btn--border):not(.btn--plain):after,
.btn--chevron-secondary:not(.btn--white).btn--border:after {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-rounded-chevron-right-primary.svg?v=79085750878079925731664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.btn--chevron-secondary.btn--white:not(.btn--border):not(.btn--plain):before,
.btn--chevron-secondary:not(.btn--white).btn--border:before {
  /* background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-rounded-chevron-right-white.svg?v=124415340203201549581664033352);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.btn--chevron-secondary.btn--plain {
  padding-right: 56px;
  visibility: visible;
}
@media (min-width: 769px) {
  .btn--chevron-secondary.btn--plain:hover:not(:disabled):after,
  .btn--chevron-secondary.btn--plain:hover:not(:disabled):before {
    right: 25px;
  }
}

/* ---------------------------------------------
    Button - Border
--------------------------------------------- */
.btn--border {
  border: 1px solid $theme-color;
  background: none;
  color: $theme-color;
}
@media (min-width: 769px) {
  .btn--border:hover:not(:disabled) {
    background: rgba(40, 97, 164, 0.06);
  }
}
.btn--border.btn--white {
  color: $white-color;
  border-color: $white-color;
  background: none;
  visibility: visible;
}
@media (min-width: 769px) {
  .btn--border.btn--white:hover:not(:disabled) {
    background: $white-color;
    color: $theme-color;
  }
}

/* ---------------------------------------------
    Button - White
--------------------------------------------- */
.btn--white:not(.btn--plain) {
  background: $white-color;
  color: $theme-color;
}

.btn--white.btn--plain {
  color: $white-color;
}

@media (min-width: 769px) {
  .btn--white:hover:not(:disabled):not(.btn--plain) {
    color: $white-color;
    background: $theme-color;
  }
}

.btn--plain {
  background: none;
  border: none;
  padding-left: 0;
  min-width: 0px;
  height: auto;
  color: $theme-color;
}
@media (min-width: 769px) {
  .btn--plain:hover:not(:disabled) {
    background: none;
  }
  .btn--plain:hover:not(:disabled).btn--white {
    color: $white-color;
  }
}

/* ---------------------------------------------
    Button - Blue Hover
--------------------------------------------- */
.btn--blue-hover:hover {
  background-color: $theme-hover-color !important;
  color: $white-color !important;
  border-color: $theme-hover-color !important;
}

/* ---------------------------------------------
    CTA
--------------------------------------------- */
.cta {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 26px;
  text-decoration: underline;
  color: $theme-color;
}

button,
input,
textarea {
  resize: none;
  border: none;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

button {
  cursor: pointer;
  margin: 0;
  width: auto;
  height: auto;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-field__label-message {
  color: #747474;
  font-weight: 400;
  font-size: inherit;
  line-height: inherit;
}

.input-field__error {
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
  padding-left: 5px;
  color: #cc452a;
}

.input-field input[type="text"],
.input-field input[type="number"],
.input-field input[type="password"],
.input-field input[type="email"],
.input-field input[type="tel"],
.input-field textarea,
.input-field .choices {
  height: 50px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  color: $theme-color;
  border-radius: 3px;
  border: 1px solid #6e6e6e;
  width: 100%;
}
.input-field input[type="text"]::-webkit-input-placeholder,
.input-field input[type="number"]::-webkit-input-placeholder,
.input-field input[type="password"]::-webkit-input-placeholder,
.input-field input[type="email"]::-webkit-input-placeholder,
.input-field input[type="tel"]::-webkit-input-placeholder,
.input-field textarea::-webkit-input-placeholder,
.input-field .choices::-webkit-input-placeholder {
  color: #4d4d4d;
}
.input-field input[type="text"]:-moz-placeholder,
.input-field input[type="number"]:-moz-placeholder,
.input-field input[type="password"]:-moz-placeholder,
.input-field input[type="email"]:-moz-placeholder,
.input-field input[type="tel"]:-moz-placeholder,
.input-field textarea:-moz-placeholder,
.input-field .choices:-moz-placeholder {
  color: #4d4d4d;
}
.input-field input[type="text"]::-moz-placeholder,
.input-field input[type="number"]::-moz-placeholder,
.input-field input[type="password"]::-moz-placeholder,
.input-field input[type="email"]::-moz-placeholder,
.input-field input[type="tel"]::-moz-placeholder,
.input-field textarea::-moz-placeholder,
.input-field .choices::-moz-placeholder {
  color: #4d4d4d;
}
.input-field input[type="text"]:-ms-input-placeholder,
.input-field input[type="number"]:-ms-input-placeholder,
.input-field input[type="password"]:-ms-input-placeholder,
.input-field input[type="email"]:-ms-input-placeholder,
.input-field input[type="tel"]:-ms-input-placeholder,
.input-field textarea:-ms-input-placeholder,
.input-field .choices:-ms-input-placeholder {
  color: #4d4d4d;
}
.input-field input[type="text"]::placeholder,
.input-field input[type="number"]::placeholder,
.input-field input[type="password"]::placeholder,
.input-field input[type="email"]::placeholder,
.input-field input[type="tel"]::placeholder,
.input-field textarea::placeholder,
.input-field .choices::placeholder {
  color: #4d4d4d;
}
.input-field input[type="text"].input-error,
.input-field input[type="number"].input-error,
.input-field input[type="password"].input-error,
.input-field input[type="email"].input-error,
.input-field input[type="tel"].input-error,
.input-field textarea.input-error,
.input-field .choices.input-error {
  border-color: #b00020;
}

.choices__item {
  text-transform: capitalize;
}

[disabled] {
  cursor: default;
  opacity: 0.5 !important;
}

label {
  display: block;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 12px;
  margin-bottom: 13px;
}

/* ---------------------------------------------
    Radio, Checkbox
--------------------------------------------- */
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
  position: relative;
  color: #919191;
  height: 20px;
  width: 20px;
  border: 2px solid #6e6e6e;
  border-radius: 100%;
  margin-right: 7px;
  outline: none;
}
input[type="radio"]:checked::before,
input[type="checkbox"]:checked::before {
  content: "";
  background-image: url(https://storage.googleapis.com/hclcommerce2-b2c-prod-gcs-ext/B2C/lincoln/BackGround_Images/Linclon_scss/icon-tick.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 11px;
  height: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 26px;
}
.checkbox span {
  margin-left: 10px;
}

input[type="checkbox"] {
  border-radius: 3px;
}

.form-checkbox {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.form-checkbox .form-checkbox__label {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}
.form-checkbox .form-checkbox__label--disabled {
  opacity: 0.6;
  pointer-events: none;
}
.form-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.form-checkbox input:checked ~ .checkmark {
  background: $theme-color;
}
.form-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.form-checkbox .checkmark {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $white-color;
  border: 2px solid #6e6e6e;
  border-radius: 5px;
}
.form-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6.5px;
  top: 3.3px;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ---------------------------------------------
    Dropdown
--------------------------------------------- */
select,
.select,
.fake-dropdown__selected-option {
  padding-left: 15px;
  padding-right: 35px;
  text-overflow: ellipsis;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #6e6e6e;
  background-color: $white-color;
  color: #4d4d4d;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1.23px;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background: url("https://storage.googleapis.com/hclcommerce2-b2c-prod-gcs-ext/B2C/lincoln/BackGround_Images/Linclon_scss/chevron-down.svg")
    calc(100% - 15px) 50% no-repeat transparent;
}
select:focus,
.select:focus,
.fake-dropdown__selected-option:focus {
  outline: none;
}

.fake-dropdown {
  position: relative;
  width: 100%;
}
.fake-dropdown.open .fake-dropdown__selected-option {
  border-radius: 3px 3px 0px 0px;
}
.fake-dropdown.open .fake-dropdown__selected-option:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: -7.5px;
}
.fake-dropdown.open .fake-dropdown__option-list {
  opacity: 1;
  visibility: visible;
}

.fake-dropdown__selected-option {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
  background: none;
}
.fake-dropdown__selected-option:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}

.fake-dropdown__option-list {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 1;
  border-radius: 0px 0px 3px 3px;
  border: 1px solid #6e6e6e;
  border-top: none;
  background: $white-color;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
.fake-dropdown__option-list:hover .fake-dropdown__option--selected {
  background: $white-color;
}
.fake-dropdown__option-list:hover .fake-dropdown__option--selected:hover {
  background: #f2f2f2;
}

.fake-dropdown__option {
  font-size: 14px;
  text-transform: capitalize;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
.fake-dropdown__option a {
  text-decoration: none;
  display: block;
  padding: 10px;
}
.fake-dropdown__option.fake-dropdown__option--selected,
.fake-dropdown__option:hover {
  background: #f2f2f2;
}

/* ---------------------------------------------
    Errors
--------------------------------------------- */
.form-error {
  color: #b00020;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
}

/* ---------------------------------------------
    Tooltips
--------------------------------------------- */
.input-field--has-tooltip {
  position: relative;
}

.input-field__tooltip-trigger {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
}
.input-field__tooltip-trigger:before,
.input-field__tooltip-trigger:after {
  position: absolute;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  top: -15px;
  bottom: initial;
}
.input-field__tooltip-trigger:before {
  content: attr(data-tooltip);
  background-color: $white-color;
  width: max-content;
  padding: 5px;
  border: 2px solid #bdbdbd;
  border-radius: 5px;
  color: #4d4d4d;
  width: 250px;
  text-align: center;
  -webkit-transform: translate(-50%, -100%);
  -moz-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  -o-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}
@media (max-width: 1024px) {
  .input-field__tooltip-trigger:before {
    -webkit-transform: translate(-82%, -100%);
    -moz-transform: translate(-82%, -100%);
    -ms-transform: translate(-82%, -100%);
    -o-transform: translate(-82%, -100%);
    transform: translate(-82%, -100%);
  }
}
.input-field__tooltip-trigger:after {
  content: "";
  position: absolute;
  border-color: #4d4d4d transparent transparent transparent;
  border-width: 10px;
  border-style: solid;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.input-field__tooltip-trigger:hover:before,
.input-field__tooltip-trigger:hover:after {
  opacity: 1;
}

.responsive-table {
  width: calc(100% + 20px);
  position: relative;
  left: -10px;
  position: relative;
}
.responsive-table td,
.responsive-table th {
  padding: 5px 10px;
  text-align: left;
  vertical-align: top;
}
.responsive-table th {
  padding-bottom: 20px;
  padding-top: 0;
}
.responsive-table td {
  font-weight: 300;
}
.responsive-table tfoot {
  font-weight: bold;
  border-top: 20px solid white;
}
.responsive-table a {
  text-decoration: underline;
}

/* ---------------------------------------------
    Close cross
--------------------------------------------- */
.close-cross {
  width: 25px;
  height: 25px;
  position: absolute;
  display: block;
  padding: 5px;
  margin-right: -5px;
  box-sizing: content-box;
  background: none;
}
.close-cross::after,
.close-cross::before {
  content: "";
  width: 25px;
  height: 2px;
  background: $theme-color;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.close-cross::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
@media (min-width: 769px) {
  .close-cross:hover::before {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .close-cross:hover::after {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.burger {
  position: relative;
  height: 16px;
  width: 21px;
  min-width: 21px;
}
.mobile-menu-open .burger {
  z-index: 1;
}
.burger > div {
  width: 100%;
  height: 2px;
  background: $theme-color;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.burger > div:nth-of-type(2) {
  top: 7px;
}
.burger > div:nth-of-type(3) {
  top: 14px;
}
.mobile-menu-open .burger > div:nth-of-type(3) {
  transform: translate(-10px);
  opacity: 0;
}
.mobile-menu-open .burger > div:nth-of-type(1) {
  transform: rotate(45deg);
  top: 7px;
}
.mobile-menu-open .burger > div:nth-of-type(2) {
  transform: rotate(-45deg);
  top: 7px;
}
.burger .burger__text {
  color: $theme-color;
  display: block;
  position: absolute;
  bottom: -16px;
  left: -4px;
  font-size: 10px;
}

.chevron::after {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  content: "";
  display: inline-block;
  height: 0.65em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.65em;
}

.chevron.right:after {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:after {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:after {
  left: 0.25em;
  transform: rotate(-135deg);
}

.banner {
  position: relative;
  color: $white-color;
  -webkit-box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
}

.banner:not([class*="-banner"]) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 333px;
  height: 333px;
  text-align: center;
}
@media (max-width: 1024px) {
  .banner:not([class*="-banner"]) {
    height: 348.5px;
  }
}
@media (max-width: 768px) {
  .banner:not([class*="-banner"]) {
    height: 364px;
  }
}
@media (max-width: 600px) {
  .banner:not([class*="-banner"]) {
    height: 395px;
  }
}
@media (max-width: 600px) {
  .banner:not([class*="-banner"]) {
    padding-left: 7px;
    padding-right: 7px;
  }
}

.banner__background {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.banner__background > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.banner__background.object-cover__top img {
  object-position: top;
}

.banner__title {
  line-height: 70px;
}
@media (max-width: 1024px) {
  .banner__title {
    line-height: 67px;
  }
}
@media (max-width: 768px) {
  .banner__title {
    line-height: 64px;
  }
}
@media (max-width: 600px) {
  .banner__title {
    line-height: 58px;
  }
}

.banner__subtitle {
  margin-top: 40px;
  line-height: 21px;
  font-weight: 400;
}
@media (max-width: 1024px) {
  .banner__subtitle {
    margin-top: 35px;
  }
}
@media (max-width: 768px) {
  .banner__subtitle {
    margin-top: 30px;
  }
}
@media (max-width: 600px) {
  .banner__subtitle {
    margin-top: 20px;
  }
}

.banner__cta {
  margin-top: 15px;
}

.spinner-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background: white;
}
.is-loading .spinner-overlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

.spinner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  z-index: 1;
}

.spinner-sector {
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 8px solid transparent;
  mix-blend-mode: overlay;
}

.spinner-sector-color-one {
  animation: rotate 0.8s ease-in infinite;
  border-top: 8px solid $theme-color;
}

.spinner-sector-color-two {
  animation: rotate 1.2s ease-in-out infinite;
  border-top: 8px solid $theme-hover-color;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

.btn--chevron:before,
.btn--chevron:after {
  height: 12px;
}

[class*="btn"]:not(.boost-pfs-quickview-btn):not(.boost-pfs-quickview-qty-cartbtn-wrapper):not(.boost-pfs-quickview-qty-cartbtn) {
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  height: 50px;
  font-weight: 500;
  position: relative;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
[class*="btn"]:not(.boost-pfs-quickview-btn):not(.boost-pfs-quickview-qty-cartbtn-wrapper):not(.boost-pfs-quickview-qty-cartbtn):after {
  content: "";
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-left: 5px;
}
[class*="btn"]:not(.boost-pfs-quickview-btn):not(.boost-pfs-quickview-qty-cartbtn-wrapper):not(.boost-pfs-quickview-qty-cartbtn):before {
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  content: " ";
  display: block;
  width: 100%;
  position: absolute;
  border-bottom: 3px solid transparent;
  bottom: 0;
  left: 0;
  opacity: 0;
}
@media (min-width: 769px) {
  [class*="btn"]:not(.boost-pfs-quickview-btn):not(.boost-pfs-quickview-qty-cartbtn-wrapper):not(.boost-pfs-quickview-qty-cartbtn):hover:not(:disabled):not(.btn--plain):not(.esc-wishlist__add-to-wishlist__icon-group__icon) {
    background: $theme-color;
    color: $white-color;
  }
  [class*="btn"]:not(.boost-pfs-quickview-btn):not(.boost-pfs-quickview-qty-cartbtn-wrapper):not(.boost-pfs-quickview-qty-cartbtn):hover:before {
    -webkit-transition: 0.2s all ease-in-out;
    -moz-transition: 0.2s all ease-in-out;
    -ms-transition: 0.2s all ease-in-out;
    -o-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
    content: " ";
    display: block;
    width: 100%;
    position: absolute;
    border-bottom: 3px solid $theme-hover-color;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}

.btn:after {
  background-image: url(https://storage.googleapis.com/hclcommerce2-b2c-prod-gcs-ext/B2C/lincoln/BackGround_Images/Linclon_scss/icon-chevron-lincoln-white.svg);
  background-size: contain;
  position: relative;
  right: 25px;
  background-position: center center;
  background-repeat: no-repeat;
}

.btn--border {
  border-width: 2px;
}
.btn--border:after {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln.svg?v=132685267715316169101664033355);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (min-width: 769px) {
  .btn--border:hover:after {
    /* background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln-white.svg?v=95280333402642282991664033348);*/
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.btn--white:after {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln.svg?v=132685267715316169101664033355);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.btn--white:hover::after {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln-white.svg?v=95280333402642282991664033348);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.btn--plain.btn--white:after {
  background-image: url(https://storage.googleapis.com/hclcommerce2-b2c-prod-gcs-ext/B2C/lincoln/BackGround_Images/Linclon_scss/icon-chevron-lincoln-white.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.btn--plain:hover:before {
  width: calc(100% - 50px);
  bottom: 10px;
}

@media (min-width: 769px) {
  .btn--plain:hover:not(:disabled) {
    background: none;
  }
  .btn--plain:hover:not(:disabled).btn--white {
    color: $white-color;
  }
}

.btn--chevron-hover:hover {
  background-color: $theme-color !important;
  color: #fff !important;
  border-bottom: 0;
  position: relative;
  padding-right: 46px;
  padding-right: 40px;
}
.btn--chevron-hover:hover:before,
.btn--chevron-hover:hover:after {
  content: "";
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln-white.svg?v=95280333402642282991664033348);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn--chevron-hover:hover:before {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln-white.svg?v=95280333402642282991664033348);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
}
@media (min-width: 769px) {
  .btn--chevron-hover:hover:hover:not(:disabled):not(.btn--plain):after {
    opacity: 0;
  }
  .btn--chevron-hover:hover:hover:not(:disabled):not(.btn--plain):before {
    opacity: 1;
  }
}
.btn--chevron-hover:hover.btn--white:not(.btn--border):not(.btn--plain):after,
.btn--chevron-hover:hover:not(.btn--white).btn--border:after {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln-white.svg?v=95280333402642282991664033348);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.btn--chevron-hover:hover.btn--white:not(.btn--border):not(.btn--plain):before,
.btn--chevron-hover:hover:not(.btn--white).btn--border:before {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln-white.svg?v=95280333402642282991664033348);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.btn--chevron-hover:hover.btn--plain {
  padding-right: 56px;
  visibility: visible;
}
@media (min-width: 769px) {
  .btn--chevron-hover:hover.btn--plain:hover:not(:disabled):after,
  .btn--chevron-hover:hover.btn--plain:hover:not(:disabled):before {
    right: 25px;
  }
}
.btn--chevron-hover:hover:before {
  border-bottom: 0;
}
.btn--chevron-hover:hover:after {
  right: 70px;
  height: 12px;
}

.banner__title {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 2px;
}
@media (max-width: 1024px) {
  .banner__title {
    font-size: 28.5px;
  }
}
@media (max-width: 768px) {
  .banner__title {
    font-size: 27px;
  }
}
@media (max-width: 600px) {
  .banner__title {
    font-size: 24px;
  }
}
@media (max-width: 1024px) {
  .banner__title {
    line-height: 30.5px;
  }
}
@media (max-width: 768px) {
  .banner__title {
    line-height: 29px;
  }
}
@media (max-width: 600px) {
  .banner__title {
    line-height: 26px;
  }
}
@media (max-width: 1024px) {
  .banner__title {
    letter-spacing: 2.1675px;
  }
}
@media (max-width: 768px) {
  .banner__title {
    letter-spacing: 2.335px;
  }
}
@media (max-width: 600px) {
  .banner__title {
    letter-spacing: 2.67px;
  }
}

.banner__subtitle {
  margin-top: 10px;
  font-weight: 300;
  font-size: 17px;
  letter-spacing: 2px;
}
@media (max-width: 1024px) {
  .banner__subtitle {
    margin-top: 12.5px;
  }
}
@media (max-width: 768px) {
  .banner__subtitle {
    margin-top: 15px;
  }
}
@media (max-width: 600px) {
  .banner__subtitle {
    margin-top: 20px;
  }
}
@media (max-width: 1024px) {
  .banner__subtitle {
    font-size: 15.75px;
  }
}
@media (max-width: 768px) {
  .banner__subtitle {
    font-size: 14.5px;
  }
}
@media (max-width: 600px) {
  .banner__subtitle {
    font-size: 12px;
  }
}
@media (max-width: 1024px) {
  .banner__subtitle {
    letter-spacing: 1.875px;
  }
}
@media (max-width: 768px) {
  .banner__subtitle {
    letter-spacing: 1.75px;
  }
}
@media (max-width: 600px) {
  .banner__subtitle {
    letter-spacing: 1.5px;
  }
}

.banner:not([class*="-banner"]) {
  justify-content: flex-end;
  padding-bottom: 40px;
}
@media (max-width: 1024px) {
  .banner:not([class*="-banner"]) {
    justify-content: center;
    padding-bottom: 0;
    width: calc(100% + 25px + 25px);
    margin-left: -25px;
    margin-right: -25px;
  }
}

/* ---------------------------------------------
    Vendor
--------------------------------------------- */
/*===============================
=            Choices            =
===============================*/
.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eaeaea;
  cursor: not-allowed;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}
.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px;
}
.choices[data-type*="select-one"] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
  background-color: $white-color;
  margin: 0;
}
.choices[data-type*="select-one"] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.5;
}
.choices[data-type*="select-one"] .choices__button:hover,
.choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}
.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #00bcd4;
}
.choices[data-type*="select-one"]
  .choices__item[data-value=""]
  .choices__button {
  display: none;
}
.choices[data-type*="select-one"]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices[data-type*="select-one"].is-open:after {
  border-color: transparent transparent #333333 transparent;
  margin-top: -7.5px;
}
.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*="select-multiple"] .choices__button:hover,
.choices[data-type*="select-multiple"] .choices__button:focus,
.choices[data-type*="text"] .choices__button:hover,
.choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #dddddd;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}
.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: #b7b7b7;
}
.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}
.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
[dir="rtl"] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  color: $white-color;
  word-break: break-all;
  box-sizing: border-box;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir="rtl"] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: $white-color;
  border: 1px solid #dddddd;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}
.choices__list--dropdown.is-active {
  visibility: visible;
}
.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}
.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}
[dir="rtl"] .choices__list--dropdown .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}
.choices__input:focus {
  outline: 0;
}
[dir="rtl"] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

/*=====  End of Choices  ======*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: inline-block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.product__zoom {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.product-images__image-wrapper {
  position: relative;
  overflow: hidden;
}

.drift-zoom-pane {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background: rgba(0, 0, 0, 0.3);
  border: none;
  z-index: 9;
  position: absolute;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  pointer-events: none;
  overflow: hidden;
}
.drift-zoom-pane img {
  max-width: unset;
}

.drift-bounding-box {
  background: rgba(0, 0, 0, 0.3);
}
.drift-bounding-box.drift-quick-view-bounding-box {
  z-index: 999;
}

.drift-zoom-pane.drift-opening,
.drift-zoom-pane.drift-open,
.drift-bounding-box.drift-opening,
.drift-bounding-box.drift-open {
  animation: drift-fadeZoomIn 180ms ease-out;
  -webkit-animation: drift-fadeZoomIn 180ms ease-out;
}

.drift-zoom-pane.drift-closing,
.drift-bounding-box.drift-closing {
  animation: drift-fadeZoomOut 210ms ease-in;
  -webkit-animation: drift-fadeZoomOut 210ms ease-in;
}

@-webkit-keyframes drift-fadeZoomIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes drift-fadeZoomOut {
  0% {
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.boost-pfs-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.47);
}

.boost-pfs-quickview-content {
  padding: 60px;
}

.boost-pfs-quickview-wrapper {
  width: 990px;
}

.boost-pfs-quickview-left {
  width: 50%;
  max-width: 50%;
}

.boost-pfs-quickview-right {
  width: 50%;
  max-width: 50%;
}

.boost-pfs-quickview-right {
  margin-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  outline: none;
}
.boost-pfs-quickview-right:focus {
  outline: none;
}
.boost-pfs-quickview-right .boost-pfs-quickview-right-inner {
  outline: none;
}
.boost-pfs-quickview-right .boost-pfs-quickview-right-inner:focus {
  outline: none;
}

.boost-pfs-quickview-close {
  right: 20px !important;
  top: 20px;
}

.boost-pfs-quickview-slider {
  width: 100%;
  height: 100%;
}

.boost-pfs-quickview-featured-image img {
  height: 100%;
  object-fit: cover;
}

.boost-pfs-quickview-slider-prev,
.boost-pfs-quickview-slider-next {
  filter: brightness(0);
  border: none !important;
  box-shadow: none !important;
}
.boost-pfs-quickview-slider-prev:before,
.boost-pfs-quickview-slider-prev:after,
.boost-pfs-quickview-slider-next:before,
.boost-pfs-quickview-slider-next:after {
  border-color: #4d4d4d;
}

.boost-pfs-quickview-title {
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.boost-pfs-quickview-vendor {
  display: none;
}

.boost-pfs-quickview-description {
  display: none;
}

.boost-pfs-quickview-price-wrapper {
  margin-bottom: 30px;
}

.boost-pfs-quickview-price {
  color: #4d4d4d;
  font-family: var(--fmc-font--miller);
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0;
}

#boost-pfs-quickview-cart-form {
  margin-bottom: 0;
}

.boost-pfs-quickview-selector-wrapper {
  margin-right: 0;
  margin-bottom: 15px !important;
}

.boost-pfs-quickview-product-option-header {
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px !important;
}

.boost-pfs-quickview-selector-wrapper
  .boost-pfs-swatch-element:not(.boost-pfs-quickview-option-color)
  label {
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  padding: 0 20px;
}

.boost-pfs-quickview-selector-wrapper
  .boost-pfs-swatch-element:not(.boost-pfs-quickview-option-color)
  input:checked
  + label {
  background: $theme-color;
  border-color: $theme-color;
  color: $white-color;
}

.boost-pfs-quickview-selector-wrapper
  .boost-pfs-swatch-element.boost-pfs-quickview-option-color
  label {
  width: 30px;
  height: 30px;
  min-width: 30px;
}

.boost-pfs-quickview-selector-wrapper
  .boost-pfs-swatch-element.boost-pfs-quickview-option-color
  input:checked
  + label {
  background: $theme-color;
  border-color: $theme-color;
  color: $white-color;
}

.boost-pfs-quickview-qty-cartbtn-wrapper {
  margin-top: 0;
  padding-top: 15px;
  border-top: 1px solid;
}
.boost-pfs-quickview-qty-cartbtn-wrapper label {
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.boost-pfs-quickview-qty-cartbtn-wrapper,
.boost-pfs-quickview-qty-cartbtn {
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: auto;
}

.boost-pfs-quickview-qty-cartbtn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
}

.boost-pfs-quickview-quantity {
  width: 100px;
  max-width: 100%;
  margin-bottom: 0px;
}
.boost-pfs-quickview-quantity input {
  color: #4d4d4d !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  max-width: 80px;
  padding: 8px 20px !important;
  text-align: center;
}

.boost-pfs-quickview-cart {
  width: calc(100% - 100px);
}

#boost-pfs-quickview-cart-btn {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  font-size: 18px;
  font-family: var(--fmc-font--proxima-nova);
  letter-spacing: 1px;
  min-width: 165px;
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  color: $white-color;
  background: $theme-color;
  width: 100%;
}
@media (min-width: 769px) {
  #boost-pfs-quickview-cart-btn:hover:not(:disabled) {
    background: $white-color;
    color: $theme-color;
  }
}

#boost-pfs-addtocart-error-label {
  display: none;
}

.boost-pfs-quickview-view-full-wrapper {
  margin-top: 30px !important;
  margin-bottom: 0 !important;
  padding-right: 0 !important;
}
.boost-pfs-quickview-view-full-wrapper .boost-pfs-quickview-view-full {
  color: $theme-hover-color !important;
  font-size: 12px !important;
  line-height: 12px !important;
  font-family: var(--fmc-font--proxima-nova) !important;
  font-weight: 300 !important;
  text-decoration: underline !important;
}
.boost-pfs-quickview-view-full-wrapper .boost-pfs-quickview-view-full:before,
.boost-pfs-quickview-view-full-wrapper .boost-pfs-quickview-view-full:after {
  display: none;
}

/* ------------------------------------------
    Arrows
------------------------------------------ */
.slick--bottom-arrows {
  padding-bottom: 64px;
}
.slick--bottom-arrows .slick-arrow {
  font-size: 0px;
  width: 14px;
  height: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-primary.svg?v=156992684832692582051664033349);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.slick--bottom-arrows .slick-arrow.slick-prev {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.slick--bottom-arrows .slick-arrow.slick-next {
  left: 70px;
}
.slick--bottom-arrows .slick-arrow.slick-disabled {
  opacity: 0;
  cursor: default;
}

.slick--left-and-right-arrows .slick-arrow {
  font-size: 0px;
  width: 14px;
  height: 24px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  background-image: url(https://storage.googleapis.com/hclcommerce2-b2c-prod-gcs-ext/B2C/lincoln/BackGround_Images/Linclon_scss/icon-chevron-right-primary.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.slick--left-and-right-arrows .slick-arrow.slick-prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.slick--left-and-right-arrows .slick-arrow.slick-next {
  right: 0;
}
.slick--left-and-right-arrows .slick-arrow.slick-disabled {
  opacity: 0;
  cursor: default;
}

/* ------------------------------------------
    Dots
------------------------------------------ */
.slick-dots {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  margin-top: 19px;
  font-size: 0px;
}
.slick-dots > li {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  border: 1px solid $theme-color;
  background: $theme-color;
  margin: 0 10px;
}
.slick-dots > li.slick-active {
  background: none;
}

/* ------------------------------------------
    Arrows & Dots
------------------------------------------ */
.slick--centered-bottom-arrows-and-dots {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.slick--centered-bottom-arrows-and-dots .slick-list,
.slick--centered-bottom-arrows-and-dots .slick-track,
.slick--centered-bottom-arrows-and-dots .slick-slide,
.slick--centered-bottom-arrows-and-dots .slick-slide > div {
  height: 100%;
}
.slick--centered-bottom-arrows-and-dots .slick-list {
  flex-basis: 100%;
}
.slick--centered-bottom-arrows-and-dots .slick-arrow {
  font-size: 0px;
  width: 40px;
  height: 40px;
  background-color: #6e6e6e;
  background-size: 13px 24px !important;
  z-index: 1;
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-white.svg?v=32409767268468141941664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.slick--centered-bottom-arrows-and-dots .slick-arrow:hover {
  background-color: $theme-color;
}
.slick--centered-bottom-arrows-and-dots .slick-arrow.slick-prev {
  order: 1;
  -webkit-transform: rotate(180deg) translateY(calc(100% + 10px));
  -moz-transform: rotate(180deg) translateY(calc(100% + 10px));
  -ms-transform: rotate(180deg) translateY(calc(100% + 10px));
  -o-transform: rotate(180deg) translateY(calc(100% + 10px));
  transform: rotate(180deg) translateY(calc(100% + 10px));
}
.slick--centered-bottom-arrows-and-dots .slick-arrow.slick-next {
  order: 3;
  -webkit-transform: translateY(calc((100% + 10px) * -1));
  -moz-transform: translateY(calc((100% + 10px) * -1));
  -ms-transform: translateY(calc((100% + 10px) * -1));
  -o-transform: translateY(calc((100% + 10px) * -1));
  transform: translateY(calc((100% + 10px) * -1));
}
.slick--centered-bottom-arrows-and-dots .slick-arrow.slick-disabled {
  opacity: 0;
  cursor: default;
}
.slick--centered-bottom-arrows-and-dots .slick-dots {
  order: 2;
  margin-top: 0;
  height: 40px;
  -webkit-transform: translateY(calc((100% + 10px) * -1));
  -moz-transform: translateY(calc((100% + 10px) * -1));
  -ms-transform: translateY(calc((100% + 10px) * -1));
  -o-transform: translateY(calc((100% + 10px) * -1));
  transform: translateY(calc((100% + 10px) * -1));
  margin-left: 10px;
  margin-right: 10px;
}
.slick--centered-bottom-arrows-and-dots .slick-dots > li {
  width: 32px;
  height: 3px;
  border-radius: 2px;
  background: #8c8c8c;
  border-color: #8c8c8c;
}
.slick--centered-bottom-arrows-and-dots .slick-dots > li.slick-active {
  background: $theme-color;
  border-color: $theme-color;
}
@media (max-width: 500px) {
  .slick--centered-bottom-arrows-and-dots .slick-arrow {
    width: 25px;
    height: 25px;
    background-size: 8px 10px !important;
  }
  .slick--centered-bottom-arrows-and-dots .slick-dots {
    height: 25px;
  }
  .slick--centered-bottom-arrows-and-dots .slick-dots > li {
    width: 25px;
  }
}

.slick-dots > li {
  width: 50px;
  height: 0px;
  border-radius: 0;
  margin: 0 5px;
  border: 1px solid $theme-color;
}

.slick-dots .slick-active {
  border: 1px solid $theme-hover-color;
}

/* ---------------------------------------------
    Apps
--------------------------------------------- */
.boost-pfs-filter-collection-description {
  text-align: center;
}

/* ---------------------------------------------
    Overrides / Resets
--------------------------------------------- */
.boost-pfs-filter-refine-by-wrapper-v {
  display: none;
}

.boost-pfs-filter-option-item-list {
  padding-right: 0 !important;
}

.boost-pfs-filter-mobile-toolbar {
  display: none !important;
}
@media (max-width: 767px) {
  .boost-pfs-filter-mobile-toolbar {
    display: initial !important;
  }
  .boost-pfs-filter-mobile-toolbar .boost-pfs-filter-mobile-toolbar-top {
    display: none !important;
  }
  .boost-pfs-filter-mobile-toolbar .boost-pfs-filter-mobile-toolbar-items {
    padding: 0 !important;
  }
  .boost-pfs-filter-mobile-toolbar
    .boost-pfs-filter-mobile-toolbar-items
    .boost-pfs-filter-mobile-toolbar-left
    .boost-pfs-filter-back-btn {
    display: block;
    min-width: 0;
    width: 100%;
    height: 40px;
    background: transparent;
    font-size: 28px;
    padding: 10px;
    position: relative;
    margin-left: 16px !important;
  }
  .boost-pfs-filter-mobile-toolbar
    .boost-pfs-filter-mobile-toolbar-items
    .boost-pfs-filter-mobile-toolbar-left
    .boost-pfs-filter-back-btn:before {
    top: 50% !important;
    -webkit-transform: translateY(-50%) rotate(135deg);
    -moz-transform: translateY(-50%) rotate(135deg);
    -ms-transform: translateY(-50%) rotate(135deg);
    -o-transform: translateY(-50%) rotate(135deg);
    transform: translateY(-50%) rotate(135deg);
  }
  .boost-pfs-filter-mobile-toolbar
    .boost-pfs-filter-mobile-toolbar-items
    .boost-pfs-filter-mobile-toolbar-left
    .boost-pfs-filter-back-btn:after {
    content: "back";
    font-family: var(--fmc-font--proxima-nova);
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 1px;
    font-weight: 400;
    text-transform: uppercase;
    display: block;
    position: absolute;
    left: 18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .boost-pfs-filter-mobile-toolbar
    .boost-pfs-filter-mobile-toolbar-items
    .boost-pfs-filter-mobile-toolbar-middle,
  .boost-pfs-filter-mobile-toolbar
    .boost-pfs-filter-mobile-toolbar-items
    .boost-pfs-filter-mobile-toolbar-right {
    display: none !important;
  }
}

/* ---------------------------------------------
    Filter Menu
--------------------------------------------- */
.boost-collection-filter__outer-wrapper {
  /*width: 100vw;*/
  background: rgba(0, 0, 0, 0.1);
  -webkit-transition: 0s z-index, 0s visibility 0.1s;
  -moz-transition: 0s z-index, 0s visibility 0.1s;
  -ms-transition: 0s z-index, 0s visibility 0.1s;
  -o-transition: 0s z-index, 0s visibility 0.1s;
  transition: 0s z-index, 0s visibility 0.1s;
}
body:not(.collection-filter-open) .boost-collection-filter__outer-wrapper {
  /* visibility: hidden;
    z-index: -2;*/
  -webkit-transition: all 0.35s linear;
  -moz-transition: all 0.35s linear;
  -ms-transition: all 0.35s linear;
  -o-transition: all 0.35s linear;
  transition: all 0.35s linear;
}

.boost-collection-filter__wrapper {
  position: relative;
  margin-top: 1px;
  width: 498px;
  height: calc(100% - 1px);
  background: $white-color;
  padding: 71px 38px;
  overflow-y: overlay;
  -webkit-transition: 0.2s all ease-in-out 0.1s;
  -moz-transition: 0.2s all ease-in-out 0.1s;
  -ms-transition: 0.2s all ease-in-out 0.1s;
  -o-transition: 0.2s all ease-in-out 0.1s;
  transition: 0.2s all ease-in-out 0.1s;
}
@media (max-width: 600px) {
  .boost-collection-filter__wrapper {
    width: 100%;
    padding-left: 31px;
    padding-right: 31px;
  }
}
body:not(.collection-filter-open) .boost-collection-filter__wrapper {
  -webkit-transform: translateX(-498px);
  -moz-transform: translateX(-498px);
  -ms-transform: translateX(-498px);
  -o-transform: translateX(-498px);
  transform: translateX(-498px);
  -webkit-transition: all 0.35s linear;
  -moz-transition: all 0.35s linear;
  -ms-transition: all 0.35s linear;
  -o-transition: all 0.35s linear;
  transition: all 0.35s linear;
}

.boost-collection-filter__top {
  padding-bottom: 43px;
  margin-bottom: 40px;
  border-bottom: 1px solid #eaeaea;
}
@media (min-width: 601px) {
  .boost-collection-filter__top {
    padding-left: 62px;
  }
}
@media (max-width: 768px) {
  .boost-collection-filter__top {
    margin-bottom: 0;
    padding-bottom: 30px;
    padding-left: 16px !important;
  }
}

.boost-collection-filter__active-vehicle {
  font-size: 12px;
  line-height: 12px;
}

.boost-collection-filter__close {
  position: absolute;
  top: 75px;
  right: 38px;
  width: 17px;
  height: 17px;
}
@media (max-width: 768px) {
  .boost-collection-filter__close {
    right: 54px;
  }
}
@media (max-width: 601px) {
  .boost-collection-filter__close {
    right: 47px;
  }
}
.boost-collection-filter__close:before,
.boost-collection-filter__close:after {
  width: 17px;
  height: 1px;
}

@media (max-width: 768px) {
  .boost-pfs-filter-tree-content {
    padding-bottom: 0 !important;
  }
}

/* ---------------------------------------------
    Filter Group
--------------------------------------------- */
.boost-pfs-filter-tree-v .boost-pfs-filter-option {
  padding-top: 40px;
  padding-bottom: 30px;
}

/* ---------------------------------------------
    Filter Group - title
--------------------------------------------- */
.boost-pfs-filter-tree-v
  .boost-pfs-filter-option
  .boost-pfs-filter-option-title
  .boost-pfs-filter-option-title-heading {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  font-family: var(--fmc-font--proxima-nova);
  position: relative;
  display: block;
  width: 100%;
  line-height: 12px;
  padding-bottom: 0px;
}
@media (min-width: 601px) {
  .boost-pfs-filter-tree-v
    .boost-pfs-filter-option
    .boost-pfs-filter-option-title
    .boost-pfs-filter-option-title-heading {
    padding-left: 62px;
  }
}

@media (max-width: 768px) {
  .boost-pfs-filter-option-title-heading {
    padding-left: 0 !important;
  }
}

.boost-pfs-filter-tree-v .boost-pfs-filter-option-title-text {
  display: block;
  width: 100%;
}
.boost-pfs-filter-tree-v .boost-pfs-filter-option-title-text:before {
  content: none;
}
.boost-pfs-filter-tree-v .boost-pfs-filter-option-title-text:after {
  content: "";
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-down-primary.svg?v=44734096722713386291664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 17px;
  height: 8px;
  position: absolute;
  right: 0;
  top: 3px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.boost-pfs-filter-tree-v
  .boost-pfs-filter-option-collapsed
  .boost-pfs-filter-option-title-text:after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.boost-pfs-filter-option-title .boost-pfs-filter-clear {
  display: none !important;
}

/* ---------------------------------------------
    Filter Group - filters list
--------------------------------------------- */
.boost-pfs-filter-option-content {
  padding-top: 22px;
}
@media (min-width: 601px) {
  .boost-pfs-filter-option-content {
    padding-left: 62px;
  }
}

/* ---------------------------------------------
    Filter Group - list item
--------------------------------------------- */
#boost-collection-filter .boost-pfs-filter-option-item {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 26px;
  margin: 0;
}
#boost-collection-filter .boost-pfs-filter-option-item:nth-child(n + 2) {
  margin-top: 19px;
}
#boost-collection-filter .boost-pfs-filter-option-item button {
  color: #4d4d4d;
}

/* ---------------------------------------------
    Filter Group - list item selected
--------------------------------------------- */
#boost-collection-filter .boost-pfs-filter-option-item.selected {
  font-weight: 400;
}

/* ---------------------------------------------
    Filter Group - list item checkbox
--------------------------------------------- */
#boost-collection-filter .boost-pfs-check-box {
  border-color: #6e6e6e;
  border-width: 2px;
  border-radius: 3px;
  width: 16px;
  height: 16px;
}

#boost-collection-filter .selected .boost-pfs-check-box {
  border-color: $theme-color;
}
#boost-collection-filter .selected .boost-pfs-check-box:before {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-tick.svg?v=19605891093612727881664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  border: none;
  width: 10px;
  height: 10px;
  top: 1px;
  left: 1px;
}

/* ---------------------------------------------
    Filter menu buttons
--------------------------------------------- */
.boost-collection-filter__buttons {
  padding-top: 20px;
  border-top: 1px solid #e3e3e3;
}
@media (min-width: 601px) {
  .boost-collection-filter__buttons {
    padding-left: 62px;
  }
}
@media (max-width: 768px) {
  .boost-collection-filter__buttons {
    padding-left: 16px !important;
  }
}

[class*="boost-collection-filter__button"]:nth-child(2) {
  margin-left: 10px;
}

.boost-pfs-filter-mobile-footer {
  display: none !important;
}

/* ---------------------------------------------
    Reset / Overrides - Product
--------------------------------------------- */
.boost-pfs-action-list-enabled .boost-pfs-action-list-wrapper,
.boost-pfs-filter-product-item-image-link
  img.boost-pfs-filter-product-item-flip-image,
.boost-pfs-filter-product-item-image-link
  img.boost-pfs-filter-product-item-main-image {
  z-index: 0;
}

.boost-pfs-filter-product-item-image-link {
  padding-top: 0 !important;
}

.boost-pfs-filter-product-item-main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.boost-pfs-filter-product-item {
  width: auto !important;
  float: none;
  clear: none;
  margin: 0;
  padding: 0 !important;
}

/* ---------------------------------------------
    Product Grid
--------------------------------------------- */
#boost-collection-products {
  margin-bottom: 0;
}

.boost-pfs-filter-wrapper-page {
  margin-top: 40px;
}

.boost-pfs-filter-products {
  grid-row-gap: 36px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .boost-pfs-filter-products {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 600px) {
  .boost-pfs-filter-products {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px 26px;
  }
}
.boost-pfs-filter-products.boost-pfs-filter-products--no-products {
  padding-bottom: 0;
}

/* ---------------------------------------------
    Product
--------------------------------------------- */
.boost-pfs-filter-product-item-inner {
  height: 100%;
  padding-bottom: 0 !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.boost-pfs-filter-product-item-inner .product-price {
  margin-top: auto;
  padding-top: 20px;
}

.boost-pfs-filter-product-item-image {
  height: 290px;
}
@media (max-width: 1440px) {
  .boost-pfs-filter-product-item-image {
    height: 230px;
  }
}
@media (max-width: 1100px) {
  .boost-pfs-filter-product-item-image {
    height: 205px;
  }
}
@media (max-width: 800px) {
  .boost-pfs-filter-product-item-image {
    height: 160px;
  }
}

.boost-pfs-filter-label {
  border-radius: 0 !important;
  font-family: var(--fmc-font--miller);
  font-size: 12px !important;
  line-height: 12px !important;
  letter-spacing: 1px;
  font-weight: 400 !important;
  padding: 5px 20px !important;
}
.boost-pfs-filter-label.sale {
  background: #028301;
}

/* ---------------------------------------------
    Product - Quick view button
--------------------------------------------- */
#boost-collection-products .boost-pfs-action-list-wrapper {
  /* Temporary removal of feature */
  display: none !important;
  top: calc(50% + 20px) !important;
  left: 0;
  right: 0;
  margin: auto;
  width: 165px !important;
  height: 40px;
  border-radius: 20px;
  -webkit-transform: translate(0);
  -moz-transform: translate(0);
  -ms-transform: translate(0);
  -o-transform: translate(0);
  transform: translate(0);
}

#boost-collection-products .boost-pfs-quickview-btn {
  width: 165px;
  height: 40px;
  border-radius: 20px;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1),
    0 20px 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1),
    0 20px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
  margin: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
#boost-collection-products .boost-pfs-quickview-btn:after {
  content: "Quick shop";
  display: block;
  font-size: 18px;
  font-weight: 400;
}

/* ---------------------------------------------
    Toolbar
--------------------------------------------- */
.boost-pfs-filter-default-toolbar {
  border: none;
  margin-bottom: 0px;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 45px;
}
@media (max-width: 1024px) {
  .boost-pfs-filter-default-toolbar {
    margin-top: 37.5px;
  }
}
@media (max-width: 768px) {
  .boost-pfs-filter-default-toolbar {
    margin-top: 35px;
  }
}
@media (max-width: 600px) {
  .boost-pfs-filter-default-toolbar {
    margin-top: 30px;
  }
}

.boost-pfs-filter-default-toolbar-inner {
  display: grid;
  grid-template-columns: 1fr 163px 240px;
  grid-gap: 40px;
}
@media (max-width: 1024px) {
  .boost-pfs-filter-default-toolbar-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    grid-gap: 0;
  }
}
@media (max-width: 450px) {
  .boost-pfs-filter-default-toolbar-inner {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  .boost-pfs-filter-default-toolbar-inner
    .boost-collection-filter-triggers__row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  .boost-pfs-filter-default-toolbar-inner .boost-pfs-filter-toolbar-item {
    width: 100% !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
}

/* ---------------------------------------------
    Toolbar - Mobile
--------------------------------------------- */
.boost-pfs-filter-toolbar-top-mobile {
  border: none;
}

/* ---------------------------------------------
    Toolbar section / Item
--------------------------------------------- */
#boost-collection-toolbar .boost-pfs-filter-toolbar-item {
  width: auto;
}
@media (max-width: 1024px) {
  #boost-collection-toolbar .boost-pfs-filter-toolbar-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    padding: 0;
  }
}

/* ---------------------------------------------
    Title
--------------------------------------------- */
.boost-pfs-filter-default-toolbar__title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.39px;
  color: $theme-color;
  line-height: normal;
  text-transform: none;
}
@media (max-width: 1024px) {
  .boost-pfs-filter-default-toolbar__title {
    display: none;
  }
}
.boost-pfs-filter-default-toolbar__title.boost-pfs-filter-default-toolbar__title--hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

/* ---------------------------------------------
    Pagination count
--------------------------------------------- */
.boost-collection-pagination-count__options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.boost-collection-pagination-count__option {
  height: 50px;
  border: 1px solid #6e6e6e;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  letter-spacing: 1.23px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.boost-collection-pagination-count__option:first-child {
  border-radius: 3px 0 0 3px;
}
.boost-collection-pagination-count__option:nth-child(2) {
  border-left: none;
  border-right: none;
}
.boost-collection-pagination-count__option:last-child {
  border-radius: 0 3px 3px 0;
}
.boost-collection-pagination-count__option--active {
  background-color: $theme-color;
  color: $white-color;
}

/* ---------------------------------------------
    Sorting - Trigger
--------------------------------------------- */
#boost-collection-toolbar .boost-pfs-filter-top-sorting-wrapper {
  width: 240px;
  height: 50px;
  border-radius: 3px;
  border: 1px solid #6e6e6e;
  padding: 0 20px 0 16px;
  margin-left: auto;
  position: relative;
  text-align: left;
  font-family: var(--fmc-font--proxima-nova);
  letter-spacing: 1.23px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
#boost-collection-toolbar
  .boost-pfs-filter-top-sorting-wrapper.boost-pfs-filter-sort-active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
@media (max-width: 1024px) {
  #boost-collection-toolbar .boost-pfs-filter-top-sorting-wrapper {
    display: none;
  }
}
#boost-collection-toolbar .boost-pfs-filter-top-sorting-wrapper > span {
  width: 100%;
}
#boost-collection-toolbar .boost-pfs-filter-top-sorting-wrapper > span:after {
  /* background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-down-primary.svg?v=44734096722713386291664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 15px;
  height: 9px;
  border: none;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

/* ---------------------------------------------
    Sorting - Trigger (active)
--------------------------------------------- */
#boost-collection-toolbar
  .boost-pfs-filter-sort-active
  .boost-pfs-filter-top-sorting-wrapper {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#boost-collection-toolbar .boost-collection-sorting__mobile-trigger {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  color: $theme-color;
  padding: 0;
  font-family: var(--fmc-font--proxima-nova);
  padding-right: 30px;
}
#boost-collection-toolbar .boost-collection-sorting__mobile-trigger svg {
  margin-left: 12px;
}

/* ---------------------------------------------
    Sorting - Dropdown
--------------------------------------------- */
#boost-collection-toolbar .boost-pfs-filter-filter-dropdown {
  width: 240px;
  border: 1px solid #6e6e6e;
  top: calc(100% - 1px);
  z-index: 2;
}

.boost-collection__footer {
  background: #f4f4f4;
  height: 105px;
  font-family: var(--fmc-font--proxima-nova);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .boost-collection__footer {
    background: transparent;
  }
}

.boost-collection__footer-inner {
  height: 105px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

/* ---------------------------------------------
    Total results
--------------------------------------------- */
.boost-collection-results {
  position: absolute;
  left: 30px;
  top: 42px;
}

.boost-pfs-filter-total-product {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.39px;
  line-height: normal;
  color: $theme-color;
}

/* ---------------------------------------------
    Prev / Next arrows
--------------------------------------------- */
#boost-collection-pagination [class*="boost-collection-pagination__arrow"] {
  width: auto;
  height: auto;
  border: none;
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.39px;
}
@media (max-width: 1024px) {
  #boost-collection-pagination [class*="boost-collection-pagination__arrow"] {
    font-size: 0px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    background: $theme-color;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  #boost-collection-pagination
    [class*="boost-collection-pagination__arrow"]:not(.boost-pfs-filter-pagination-disabled) {
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1),
      0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  }
  #boost-collection-pagination
    [class*="boost-collection-pagination__arrow"]
    svg
    * {
    fill: $white-color;
  }
}
#boost-collection-pagination
  [class*="boost-collection-pagination__arrow"]:hover {
  background: none;
}
@media (max-width: 1024px) {
  #boost-collection-pagination
    [class*="boost-collection-pagination__arrow"]:hover:not(.boost-pfs-filter-pagination-disabled) {
    background: $theme-color;
  }
}
#boost-collection-pagination
  [class*="boost-collection-pagination__arrow"].boost-pfs-filter-pagination-disabled {
  opacity: 1;
}
@media (max-width: 1024px) {
  #boost-collection-pagination
    [class*="boost-collection-pagination__arrow"].boost-pfs-filter-pagination-disabled {
    background: #6e6e6e;
  }
}
#boost-collection-pagination
  [class*="boost-collection-pagination__arrow"]
  > span,
#boost-collection-pagination [class*="boost-collection-pagination__arrow"] > a {
  margin: 0;
  color: $theme-color;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
#boost-collection-pagination [class*="boost-collection-pagination__arrow"] svg {
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
@media (min-width: 769px) {
  #boost-collection-pagination
    [class*="boost-collection-pagination__arrow"]
    a:hover {
    text-shadow: none;
  }
}

#boost-collection-pagination .boost-collection-pagination__arrow--prev {
  margin-right: 32px;
}
@media (min-width: 1025px) {
  #boost-collection-pagination
    .boost-collection-pagination__arrow--prev:not(.boost-pfs-filter-pagination-disabled):hover
    svg {
    -webkit-transform: rotate(180deg) translateX(5px);
    -moz-transform: rotate(180deg) translateX(5px);
    -ms-transform: rotate(180deg) translateX(5px);
    -o-transform: rotate(180deg) translateX(5px);
    transform: rotate(180deg) translateX(5px);
  }
}
#boost-collection-pagination .boost-collection-pagination__arrow--prev svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media (min-width: 1025px) {
  #boost-collection-pagination .boost-collection-pagination__arrow--prev svg {
    margin-right: 14px;
  }
}

#boost-collection-pagination .boost-collection-pagination__arrow--next {
  margin-left: 32px;
}
@media (min-width: 1025px) {
  #boost-collection-pagination
    .boost-collection-pagination__arrow--next:not(.boost-pfs-filter-pagination-disabled):hover
    svg {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
  }
  #boost-collection-pagination .boost-collection-pagination__arrow--next svg {
    margin-left: 14px;
  }
}

/* ---------------------------------------------
    Number page buttons
--------------------------------------------- */
#boost-collection-pagination [class*="boost-collection-pagination__item"] {
  height: auto;
  line-height: 26px;
  width: 28px;
  font-size: 16px;
  font-weight: 400;
  margin-left: 14px;
  margin-right: 14px;
}
@media (max-width: 1024px) {
  #boost-collection-pagination [class*="boost-collection-pagination__item"] {
    width: auto;
    margin: 0;
  }
}
#boost-collection-pagination
  [class*="boost-collection-pagination__item"]
  > span,
#boost-collection-pagination [class*="boost-collection-pagination__item"] > a {
  display: block;
  padding: 0;
  margin: 0;
}
@media (max-width: 1024px) {
  #boost-collection-pagination
    [class*="boost-collection-pagination__item"]
    > span,
  #boost-collection-pagination
    [class*="boost-collection-pagination__item"]
    > a {
    padding: 0;
  }
}
#boost-collection-pagination [class*="boost-collection-pagination__item"] > a {
  color: #6e6e6e;
}
#boost-collection-pagination
  [class*="boost-collection-pagination__item"]
  > a:hover {
  text-shadow: none;
}
@media (max-width: 1024px) {
  #boost-collection-pagination
    [class*="boost-collection-pagination__item"]:not(.boost-collection-pagination__item--active):not(:nth-last-child(2)) {
    display: none;
  }
}

@media (max-width: 1024px) {
  #boost-collection-pagination
    [class*="boost-collection-pagination__item--active"]:nth-last-child(2)
    .boost-pfs-filter-pagination-active:after {
    display: none;
  }
}

@media (max-width: 1024px) {
  #boost-collection-pagination .boost-collection-pagination__elipsis {
    display: none;
  }
}

/* ---------------------------------------------
    Active page
--------------------------------------------- */
#boost-collection-pagination .boost-pfs-filter-pagination-active {
  text-shadow: none;
  position: relative;
}
#boost-collection-pagination .boost-pfs-filter-pagination-active:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: $theme-color;
  position: absolute;
  bottom: -10px;
  left: 0;
}
@media (min-width: 1025px) {
  #boost-collection-pagination .boost-pfs-filter-pagination-active {
    font-weight: 400;
    color: $theme-color;
  }
}
@media (max-width: 1024px) {
  #boost-collection-pagination .boost-pfs-filter-pagination-active {
    color: #6e6e6e;
  }
  #boost-collection-pagination .boost-pfs-filter-pagination-active:after {
    content: "/";
    display: initial;
    width: auto;
    height: auto;
    background: unset;
    position: initial;
  }
}

.boost-collection-collection-filter {
  margin-top: 37px;
}

.collection-filter-item {
  width: 95px;
  margin: 0 16px;
  margin-bottom: 15px;
}

.collection-filter-item__image {
  width: 100%;
  height: 87px;
  background-size: cover;
}

.collection-filter-item__title {
  margin-top: 13px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02px;
  line-height: normal;
  text-align: center;
  text-transform: none;
  color: $theme-color;
}

.boost-collection-collection-filter.slick-slider {
  width: 100%;
  overflow: hidden;
}
.boost-collection-collection-filter.slick-slider .slick-slide {
  width: 95px;
  margin: 0 16px;
}
.boost-collection-collection-filter.slick-slider .collection-filter-item {
  width: 100%;
  margin: 0;
  margin-bottom: 0;
}

.boost-pfs-filter-collection-header-wrapper {
  background: none;
  padding-top: 30px;
  padding-bottom: 20px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.boost-pfs-filter-collection-header-wrapper h1 {
  text-align: center;
  font-weight: 300;
  font-size: 24px;
  line-height: 34px;
}

.boost-pfs-filter-collection-description {
  width: 675px;
  max-width: 100%;
  font-family: var(--fmc-font--miller);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 21px;
  margin: auto;
  margin-top: 10px;
}
.boost-pfs-filter-collection-description * {
  font-family: var(--fmc-font--miller);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 21px;
}

.boost-collection-filter-triggers__row {
  width: calc(100% + 10px);
  position: relative;
  left: -5px;
  margin-top: 6px;
}
@media (max-width: 1024px) {
  .boost-collection-filter-triggers__row {
    margin-top: 0px;
  }
}
@media (max-width: 450px) {
  .boost-collection-filter-triggers__row {
    width: 100%;
    left: 0;
  }
}
.boost-collection-filter-triggers__row.boost-collection-filter-triggers__row--hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.boost-collection-filter-trigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.boost-collection-filter-trigger.boost-collection-filter-trigger--has-active-filters {
  position: relative;
}
.boost-collection-filter-trigger.boost-collection-filter-trigger--has-active-filters:after {
  content: attr(data-active-filters);
  width: 19px;
  height: 19px;
  border-radius: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  background: #a8ce38;
  color: $theme-color;
  position: absolute;
  right: 15px;
  top: -8px;
  font-size: 10px;
  font-weight: 500;
}
.boost-collection-filter-trigger svg {
  margin-left: 18px;
  width: 10px;
  height: 17px;
}

.boost-pfs-filter-message {
  width: 100%;
  grid-column: 1 / -1;
  text-align: center;
}

.klevu-fluid {
  display: none !important;
  /* ---------------------------------------------
        Filters
    --------------------------------------------- */
}
.klevu-fluid .klevu-addtocart,
.klevu-fluid .klevu-sorting-area,
.klevu-fluid #klevu-filter-box_Price-Range {
  display: none;
}
.klevu-fluid .klevu-searching-area {
  width: calc(100vw - 200px) !important;
  max-width: 1239px;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transform: translateY(48px);
  -moz-transform: translateY(48px);
  -ms-transform: translateY(48px);
  -o-transform: translateY(48px);
  transform: translateY(48px);
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
@media (max-width: 1024px) {
  .klevu-fluid .klevu-searching-area {
    width: calc(100vw - 60px) !important;
  }
}
.klevu-fluid .klevu-searching-area:before {
  content: "";
  background: $white-color;
  height: calc(100% + 135px);
  width: 100vw;
  top: -48px;
  left: calc((-100vw + 1080px) / 2);
  position: absolute;
  z-index: -1;
}
.klevu-fluid .klevu-filters {
  background: none;
  width: 232px;
  margin-right: 44px;
}
.klevu-fluid .klevu-filter-name ul > li:nth-of-type(n + 2) {
  margin-top: 28px;
}
.klevu-fluid .klevu-filter-name ul li a {
  margin: 0;
  width: 100%;
  padding: 0;
}
.klevu-fluid .klevu-filter-total {
  display: none;
}

/* ---------------------------------------------
        Products
    --------------------------------------------- */
.klevu-grid-view {
  max-height: unset;
}
.klevu-grid-view ul.klevu-search__product-list {
  /*display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;*/
}
@media (max-width: 768px) {
  .klevu-grid-view ul.klevu-search__product-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
.klevu-grid-view ul.klevu-search__product-list li {
  border: none !important;
  background: none !important;
  width: auto;
  min-height: 0px;
  text-align: left;
}
.klevu-grid-view ul.klevu-search__product-list li:nth-of-type(n + 5) {
  display: none;
}
.klevu-grid-view ul.klevu-search__product-list li a.klevu-result-box {
  padding: 0;
  min-height: 0px;
}
.klevu-grid-view ul.klevu-search__product-list li .klevu-name {
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.6px;
  line-height: normal;
  margin-top: 10px;
  padding: 0;
  text-align: left;
}
.klevu-grid-view ul.klevu-search__product-list li .klevu-price {
  margin-top: 5px;
  padding: 0;
}
.klevu-grid-view ul.klevu-search__product-list li .klevu-price,
.klevu-grid-view ul.klevu-search__product-list li .klevu-saleprice {
  font-family: var(--fmc-font--proxima-nova);
  color: $theme-color;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1.8px;
  line-height: normal;
  text-align: left;
}

body.template-blog .klevu-fluid,
body.template-blog #klevuLoader,
body.template-blog #klevuMinLtr {
  display: none !important;
}

.klevu-search__inner {
  /*display: grid;*/
  overflow: auto;
  height: 100%;
  padding-top: 25px;
}
@media (min-width: 769px) {
  .klevu-search__inner {
    /* grid-template-columns: 220px 1fr;
    grid-gap: 70px;*/
  }
}
@media (max-width: 768px) {
  .klevu-search__inner {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
.klevu-search__inner .klevu-grid-view {
  max-height: unset;
  overflow: unset;
}

.klevu-search__product-image {
  /*width: 100%;*/
  position: relative;
  /*padding-bottom: 100%;*/
  /*height: 0;*/
  display: inline-block;
  overflow: hidden;
}
.klevu-search__product-link {
  display: inline-block;
}
.klevu-search__product-image > img {
  width: 50px;
  height: 50px;
  /*object-fit: cover;*/
  object-position: center;
}
.klevu-search__product-image.object-cover__top img {
  object-position: top;
}
.klevu-search__product-image img {
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;*/
}
.klevu-search__product-list li {
  border-bottom: 1px solid #ccc;
  padding-bottom: 0px;
}
.klevu-search__product-list li:last-child {
  border-bottom: none;
  padding-bottom: 5px;
}
.klevu-search__no-results {
  padding: 10px 15px;
  text-transform: capitalize;
}
.klevu-search__title {
  font-family: var(--fmc-font--proxima-nova);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 20px;
  padding: 8px 15px;
  border: 0;
  color: #324047;
  background: none;
  margin-bottom: 0px;
  border-bottom: 1px solid #ccc;
  /*border-top: 1px solid #ccc;*/
}
.klevu-search__product-name:hover {
  text-decoration: underline;
}

.klevu-search__title--categories {
  /*margin-top: 10px;*/
}

.klevu-search__list-item,
.klevu-search__list-item p {
  font-family: var(--fmc-font--proxima-nova);
  color: $theme-color;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 18px;
  text-transform: capitalize;
}
.klevu-search__list-item p {
  padding-left: 5px;
  white-space: normal;
}
.klevu-search__list-item {
  padding: 8px 15px;
  /*margin-bottom: 5px !important;*/
  @media (max-width: 959px) {
    min-height: inherit;
  }
}

.klevu-search__list-item a:hover {
  text-decoration: underline;
  color: #324047;
}
.klevu-search__list-item:last-child {
  margin-bottom: 5px;
  /*margin-bottom: 10px;*/
}
.klevu-search__list-item:before {
  content: none;
}

.klevu-search__product-type {
  display: block;
  color: #3d3d3d;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1.1px;
  line-height: normal;
  margin-top: 17px;
}

.klevu-filter__list {
  max-height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 22px;
}
@media (min-width: 601px) {
  .klevu-filter__list {
    padding-left: 62px;
  }
}
.klevu-filter__list::-webkit-scrollbar {
  width: 4px;
}
.klevu-filter__list::-webkit-scrollbar-track {
  background: #ebebeb;
}
.klevu-filter__list::-webkit-scrollbar-thumb {
  background: #757575;
}

.klevu-filter__option {
  margin: 0;
  border-top: 1px solid #e3e3e3;
  position: static;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 30px;
}

.klevu-filter__title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 12px;
  position: relative;
  cursor: pointer;
}
@media (min-width: 601px) {
  .klevu-filter__title {
    padding-left: 62px;
  }
}
.klevu-filter__title.active:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.klevu-filter__title:after {
  content: "";
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-down-primary.svg?v=44734096722713386291664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 17px;
  height: 8px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}

.klevu-filter__option-item,
.klevu-filter__option-item label {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 26px;
  cursor: pointer;
}

.klevu-filter__option-item label {
  margin-bottom: 0;
}

.klevu-filter__option-item {
  margin-bottom: 13px;
}

.klevu-search-landing__price {
  position: relative;
  width: calc(50% - 15px);
  height: 36px;
  margin: 0 0 10px;
  padding: 1px 5px;
  line-height: 24px;
  border: 1px solid #e3e3e3;
  min-height: auto;
  min-width: auto;
  text-align: center;
}

.klevu-search-landing__price-split {
  font-size: 14px;
  line-height: 36px;
  width: 30px;
  text-align: center;
}

.klevu-search-landing__price-inner {
  margin-top: 22px;
}

button.klevu-filter__title {
  width: 100%;
  text-align: left; 
  font-family: var(--fmc-font--proxima-nova);
  text-transform: uppercase;
  color: #4d4d4d;
  padding: 30px 0;
  padding-left: 62px;
}

details.klevu-filter__option > summary::after {
  top: 4px;
}

details.klevu-filter__option > summary {
  list-style: none;
}

details.klevu-filter__option > summary::-webkit-details-marker {
  display: none;
}

.klevu-filter__option {
  padding: initial;
}

details.klevu-filter__option > summary {
  padding: 30px 0;
}

#price-slider {
  margin: 22px auto 35px;
  overflow: visible;
  width: calc(100% - 20px);
  background: #b9b9b9;
  box-shadow: none;
  border: none;
  height: 2px;
}

.DOM-slider-hidden {
  opacity: 0;
}

.search__filters {
  z-index: 10 !important;
}

.kuGridView ul li {
  border: 0;
}

.search__filters {
  width: 100vw;
  background: rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.35s linear;
  -moz-transition: all 0.35s linear;
  -ms-transition: all 0.35s linear;
  -o-transition: all 0.35s linear;
  transition: all 0.35s linear;
}
body:not(.collection-filter-open) .search__filters {
  visibility: hidden;
  z-index: -2;
  -webkit-transition: all 0.35s linear;
  -moz-transition: all 0.35s linear;
  -ms-transition: all 0.35s linear;
  -o-transition: all 0.35s linear;
  transition: all 0.35s linear;
}
.search__filters-wrapper {
  position: relative;
  margin-top: 1px;
  width: 498px;
  height: calc(100% - 1px);
  background: $white-color;
  padding: 71px 38px;
  -webkit-transition: 0.2s all ease-in-out 0.1s;
  -moz-transition: 0.2s all ease-in-out 0.1s;
  -ms-transition: 0.2s all ease-in-out 0.1s;
  -o-transition: 0.2s all ease-in-out 0.1s;
  transition: 0.2s all ease-in-out 0.1s;
}
@media (max-width: 600px) {
  .search__filters-wrapper {
    width: 100%;
    padding-left: 31px;
    padding-right: 31px;
  }
}
body:not(.collection-filter-open) .search__filters-wrapper {
  -webkit-transform: translateX(-498px);
  -moz-transform: translateX(-498px);
  -ms-transform: translateX(-498px);
  -o-transform: translateX(-498px);
  transform: translateX(-498px);
  -webkit-transition: all 0.35s linear;
  -moz-transition: all 0.35s linear;
  -ms-transition: all 0.35s linear;
  -o-transition: all 0.35s linear;
  transition: all 0.35s linear;
}
.search__filters-title {
  padding-bottom: 43px;
}
@media (min-width: 601px) {
  .search__filters-title {
    padding-left: 62px;
  }
}
.search__filters-trigger {
  width: 100%;
  padding-bottom: 10px;
}
@media only screen and (max-width: 640px) {
  .search__filters-trigger {
    width: calc(50% - 10px);
    text-align: left;
  }
}
@media (max-width: 500px) {
  .search__filters-trigger [class*="btn"] {
    width: 100%;
    min-width: 0;
  }
}

@media only screen and (max-width: 640px) {
  .kuSortby {
    width: calc(50% - 10px);
    margin-left: auto;
  }
}

/*============================================================
=================== MAIN CSS OF KLEVU SEARCH RESULTS ==============
=============================================================*/
/***************** WIDTH OF ALL MAIN DIVS FOR DIFFERENT LAYOUTS *****************/
/* klevu searching area box */
.klevu-searching-area {
  position: absolute;
  z-index: 20000000000;
  width: 750px;
  font-family: inherit;
  font-size: 12px;
  border: 1px solid #9a9a9a;
  background-color: #eee;
  -webkit-box-shadow: 0px 3px 5px rgba(50, 50, 50, 0.25);
  -moz-box-shadow: 0px 3px 5px rgba(50, 50, 50, 0.25);
  box-shadow: 0px 3px 5px rgba(50, 50, 50, 0.25);
}

/* show minimum letter message */
.klevu-min-ltr {
  display: none;
  position: absolute;
  z-index: 20000000000;
  width: 20%;
  font-family: inherit;
  font-size: 12px;
  color: #414042;
  padding: 3px;
  border: 1px solid #ddd;
  background-color: #fff;
  text-align: left;
}

.klevu-min-ltr img {
  width: auto;
}

/* width of klevu searching area if fluid layout */
.klevu-fluid .klevu-searching-area {
  width: 56%;
}

/* width of klevu searching area without filter */
.klevu-without-filter {
  width: 550px;
}

/* width of klevu searching area without filter if fluid layout */
.klevu-fluid .klevu-without-filter {
  width: 40%;
}

/* width of klevu searching area with popular terms */
.klevu-popular-terms {
  width: 750px;
}

/* width of klevu searching area with popular terms if fluid layout */
.klevu-fluid .klevu-popular-terms {
  width: 55%;
}

/* Show Popular searches and Recent searches popup on searchbox hover */
.klevu-pt-rs-hover {
  font-family: inherit;
  padding: 10px;
  font-size: 11px;
  position: absolute;
  z-index: 20000000000;
  width: 500px;
  border: 1px solid #c3c3c3;
  background-color: #fff;
  line-height: 20px;
  display: none !important;
}
@media (max-width: 959px) {
  .quick-search-open .klevu-pt-rs-hover {
    display: block !important;
  }
}

/* width popup on hover of searchbox if fluid layout */
.klevu-fluid .klevu-pt-rs-hover {
  width: 38%;
}

.klevu-pt-rs-hover a {
  color: #414042;
  text-decoration: none;
  display: inline-block;
}

.klevu-pt-rs-hover a:hover {
  text-decoration: underline;
}

/* popular searches and recent searches heading in popup*/
.klevu-pt-rs-hover strong {
  float: left;
  width: 25%;
  color: #666;
  display: block;
  text-align: left;
}

/* keywords in popup */
.klevu-pt-rs-hover .klevu-pt-rs-keywords {
  float: left;
  width: 73%;
  margin-left: 1%;
  color: #666;
  text-align: left;
}

/*=================== RESULT BOX STYLES WITH POPULAR TERMS ===================*/
.klevu-pt-rs {
  float: left;
  width: 31%;
  max-height: 434px;
  overflow: auto;
  background-color: #eee;
}

/* for Popular term heading */
.klevu-pt-head {
  padding: 11.5px;
  font-weight: bold;
  background-color: #c3c3c3;
  font-size: 12px;
  text-align: left;
  color: #000;
}

.klevu-pt-list ul {
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
}

/* list to show polular terms */
.klevu-pt-list ul li {
  list-style: none;
  text-align: left;
  width: 98.5%;
  display: inline-table;
  margin: 0px;
  padding: 4px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px dotted #ddd;
  padding-left: 0px;
}

/* remove border for last popular term */
.klevu-pt-list ul li:last-child {
  border-bottom: none;
}

.klevu-pt-list ul li a {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  line-height: 15px;
  text-decoration: none;
  cursor: pointer;
  -moz-border-radius: 26px 26px 26px 26px;
  -webkit-border-radius: 26px 26px 26px 26px;
  border-radius: 26px 26px 26px 26px;
  color: #000;
}

.klevu-pt-list ul li a:hover {
  background-color: #dadada;
}

/* set color for selected popular term */
.klevu-pt-list ul li.klevu-pt-selected a {
  background-color: #616161;
  color: #fff;
}

/* show popular term */
.klevu-pt-list ul li a span.klevu-pt-label {
  float: left;
  width: 86%;
}

/* show cancel button with popular term */
.klevu-pt-list ul li a span.klevu-pt-cancel {
  float: right;
  width: 13%;
  text-align: right;
  font-size: 11px;
}

/* width of sorting results with popular terms */
.klevu-popular-terms .klevu-sorting-results {
  width: 69%;
}

/* Set image border to none for images under klevu searching area */
.klevu-searching-area img {
  border: none;
  display: inline-block;
}

/*=================== STYLES FOR FILTERS ===================*/
.klevu-filters {
  float: left;
  width: 30.8%;
  max-height: 434px;
  overflow: auto;
  background-color: #eee;
}

/* heading of filters i.e brand, color */
.klevu-filter-head {
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: #000;
  border-bottom: 1px solid #ddd;
}

/* set image for collapse icon */
// .klevu-collapse {
//   background: url(https://js.klevu.com/klevu-js-v1/img-1-1/klevu-collapse-filter.png)
//     right no-repeat;
// }

/* set image for expand icon */
// .klevu-expand {
//   background: url(https://js.klevu.com/klevu-js-v1/img-1-1/klevu-expand-filter.png)
//     right no-repeat;
// }

/* set the height of each filter*/
.klevu-filter-box {
  height: 150px;
  overflow: hidden;
  color: #000;
}

.klevu-filter-name {
  padding-bottom: 7px;
}

/* set text decoration for anchor tag to none */
.klevu-filter-box a {
  text-decoration: none;
}

/* shor more icon */
.klevu-show-opt {
  text-align: center;
  padding: 3px;
  position: relative;
}

/* shor less icon */
.klevu-hide-opt {
  text-align: center;
  padding: 3px;
  position: relative;
}

/* set list for filters */
.klevu-filter-name ul {
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
}

/* style for each values in filter */
.klevu-filter-name ul li {
  list-style: none;
  text-align: left;
  width: 99%;
  display: inline-table;
  margin: 0px;
  padding-left: 0px;
}

.klevu-filter-name ul li a {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

/* set background color on hover of filter */
/*.klevu-filter-name ul li a:hover{
	background-color:#dadada;
}*/
/* remove border bottom for selected filter */
.klevu-filter-name ul li.klevu-filter-selected {
  border-bottom: none;
}

/* set background color for selected filter */
/*.klevu-filter-name ul li.klevu-filter-selected a{
	background-color:#616161;
	color:#fff;
}*/
/* label for the filter */
.klevu-filter-name ul li a span.klevu-filter-label {
  float: left;
  width: 86%;
}

/* total nos of results available for filter  */
.klevu-filter-name ul li a span.klevu-filter-total {
  float: right;
  width: 13%;
  text-align: right;
}

/* shows cancel button if filter is selected */
.klevu-filter-name ul li a span.klevu-filter-cancel {
  float: right;
  width: 13%;
  text-align: right;
  font-size: 11px;
}

.klevu-filter-label:before {
  content: "";
  border: 1px solid #eee;
  background: $white-color;
  border-radius: 50%;
  margin-right: 8px;
  height: 12px;
  width: 12px;
  display: inline-block;
  color: #777;
  margin-top: 0px;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: background 200ms;
  -webkit-transition: background 200ms;
  -moz-transition: background 200ms;
}

.klevu-filter-name ul li a:hover span.klevu-filter-label:before {
  border: 1px solid #414042;
}

.klevu-filter-name ul li.klevu-filter-selected span.klevu-filter-label:before {
  background: #414042;
  border: 1px solid #414042;
}

.klevu-multi-check .klevu-filter-label:before {
  content: "";
  border: 1px solid #eee;
  border-radius: 0px;
  margin-right: 8px;
  height: 15px;
  width: 15px;
  color: #777;
  margin-bottom: -1px;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: background 200ms;
  -webkit-transition: background 200ms;
  -moz-transition: background 200ms;
  transition: border-color 200ms;
  -webkit-transition: border-color 200ms;
  -moz-transition: border-color 200ms;
}

.klevu-multi-check
  .klevu-filter-name
  ul
  li
  a:hover
  span.klevu-filter-label:before {
  background: $white-color;
  border: 1px solid #414042;
}

.klevu-multi-check
  .klevu-filter-name
  ul
  li.klevu-filter-selected
  span.klevu-filter-label:before {
  background: $white-color;
  border: 1px solid #414042;
}

.klevu-multi-check
  .klevu-filter-name
  ul
  li
  a:hover
  span.klevu-filter-label:after {
  content: "";
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 3px;
  left: 8px;
  border: 2px solid #cccccc;
  border-top: none;
  border-right: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.klevu-multi-check
  .klevu-filter-name
  ul
  li.klevu-filter-selected
  span.klevu-filter-label:after {
  content: "";
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 3px;
  left: 8px;
  border: 2px solid #414042;
  border-top: none;
  border-right: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.klevu-multi-check
  .klevu-filter-name
  ul
  li.klevu-filter-selected:hover
  span.klevu-filter-label:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-color: #414042;
}

/*=================== set width for klevu results ===================*/
/* set width for klevu results */
.klevu-sorting-results {
  float: left;
  width: calc(100% - 232px - 44px);
  object-fit: initial;
}

/* set width for klevu results for without filters*/
.klevu-without-filter .klevu-sorting-results {
  width: 100%;
}

/* div for Sorting, pagination, change result view icons*/
.klevu-sorting-area {
  border-bottom: 1px solid #ddd;
  border-top: none;
  padding: 9px;
  background-color: #eee;
  text-align: center;
}

/* div to display icons to change the view of result (grid/view) */
.klevu-view {
  float: left;
  width: 20%;
  text-align: left;
}

/* set width and height of view icons box*/
.klevu-view a {
  height: 15px;
  width: 15px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

/* display GRID view icon */
// .klevu-grid-btn {
//   background: #ededed
//     url(https://js.klevu.com/klevu-js-v1/img-1-1/klevu-grid.png) no-repeat 0 0;
//   cursor: pointer;
// }

// /* display LIST view icon */
// .klevu-list-btn {
//   background: #ededed
//     url(https://js.klevu.com/klevu-js-v1/img-1-1/klevu-list.png) no-repeat 0 0;
//   cursor: pointer;
// }

/* changing background position on hover of GRID/LIST view icons */
.klevu-view a:hover {
  border-color: #bbb;
  background-position: 0 -17px;
}

/* change background position to set current view */
.klevu-view a.klevu-current-view {
  background-position: 0 -17px;
  border-color: #bbb;
}

/* div to display sorting dropdown */
.klevu-sorting {
  float: left;
  width: 20%;
  text-align: right;
}

.klevu-sorting a {
  height: 15px;
  width: 15px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

// /* display relevance icon */
// .klevu-rel-btn {
//   background: url(https://js.klevu.com/klevu-js-v1/img-1-1/klevu-relevance.png)
//     no-repeat 0 0;
//   cursor: pointer;
// }

// /* display price-low-to-high icon */
// .klevu-price-low-btn {
//   background: url(https://js.klevu.com/klevu-js-v1/img-1-1/klevu-p-low-to-high.png)
//     no-repeat 0 0;
//   cursor: pointer;
//   margin-right: -4px;
// }

// /* display price-high-to-low icon */
// .klevu-price-high-btn {
//   background: url(https://js.klevu.com/klevu-js-v1/img-1-1/klevu-p-high-to-low.png)
//     no-repeat 0 0;
//   cursor: pointer;
// }

/* change background position on hover of sorting icons */
.klevu-sorting a:hover {
  background-position: 0 -17px;
}

/* set background position for selected sorting option */
.klevu-sorting a.klevu-current-sort {
  background-position: 0 -17px;
}

/* show total number of results */
.klevu-total-results {
  float: left;
  width: 60%;
  text-align: center;
  font-size: 11px;
  color: #414042;
}

.klevu-clear-left {
  clear: left;
}

/*=================== Grid view ===================*/
/* styles for grid view results */
.klevu-grid-view {
  background-color: #fff;
  overflow: auto;
  max-height: 400px;
  text-align: left;
}

.klevu-grid-view ul {
  margin: 0px;
  padding: 0px;
  width: 100% !important;
}

/* In GRIDVIEW: list style for each result */
.klevu-grid-view ul li {
  display: block;
  /*min-height: 200px;
  padding: 0;
  vertical-align: top;*/
  border-right: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  text-align: center;
}

.klevu-grid-view ul li:hover {
  background-color: #f8f8f8;
}

.klevu-grid-view ul li:nth-child(1),
.klevu-grid-view ul li:nth-child(2),
.klevu-grid-view ul li:nth-child(3) {
  border-top: 0px;
}

.klevu-grid-view ul li:nth-last-child(1),
.klevu-grid-view ul li:nth-last-child(2),
.klevu-grid-view ul li:nth-last-child(3) {
  border-bottom: 1px solid #e2e2e2;
}

/* for each result box */
.klevu-grid-view ul li a.klevu-result-box {
  position: relative;
  text-decoration: none;
  color: #000;
  outline: none;
  display: block;
  padding-top: 15px;
  height: auto;
  min-height: 200px;
  padding-bottom: 10px;
}

/* set undeline on product name on hover of result box */
.klevu-grid-view ul li a.klevu-result-box:hover .klevu-name {
  color: #666;
}

/* In GRIDVIEW: remove border right oof every 3rd result */
.klevu-grid-view ul li:nth-child(3n + 3) {
  border-right: none;
}

/* In GRIDVIEW:  for wrapping the image in fixed size div */
.klevu-grid-view .klevu-img-wrap {
  float: none;
  overflow: hidden;
  width: 100px !important;
  height: 100px !important;
  text-align: center;
  margin: 0 auto;
}

.kuGridView .klevuImgWrap a {
  position: static;
}

/* In GRIDVIEW: thumbnail of the product */
.klevu-grid-view .klevu-img-wrap img {
  max-width: 100px !important;
  max-height: 100px !important;
  width: auto;
  height: auto;
  border: none;
  outline: none;
  opacity: 1;
}

/* In GRIDVIEW: remove float value for name and description div */
.klevu-grid-view .klevu-name-desc {
  float: none;
}

.klevu-grid-view ul li .klevu-name {
  padding: 5px;
  font-size: 12px;
  text-decoration: none;
  color: #000;
  line-height: 16px;
  min-height: 32px;
  margin-top: 5px;
}

/* In GRIDVIEW: discription is not displayed in grid layout*/
.klevu-grid-view .klevu-desc {
  display: none;
}

.klevu-grid-view ul li .klevu-price {
  float: none;
  margin-top: 5px;
  padding: 5px;
}

/* In GRIDVIEW: div to display saleprice */
.klevu-grid-view ul li .klevu-saleprice {
  display: inline-block;
  font-weight: bold;
  font-size: 13px;
  color: #000;
}

.klevu-grid-view ul li .klevu-special-price {
  color: #f60;
  margin-left: 5px;
}

/* In GRIDVIEW: div to display original price with line-through style */
.klevu-grid-view ul li .klevu-origprice {
  display: inline-block;
  font-size: 12px;
  text-decoration: line-through;
  color: #555;
}

/* In GRIDVIEW: span to show greyText like "Starts with" */
.klevu-grid-view ul li span.klevuPriceGreyText {
  font-weight: normal;
  font-size: 11px !important;
  margin-bottom: 5px;
  color: #393939 !important;
}

.klevu-price span.klevuPriceGreyText {
  display: none;
}

/* Discount badge*/
.klevu-grid-view ul li .klevu-discount-badge {
  background: #ffcc01;
  padding: 5px 10px;
  color: #000000;
  font-weight: bold;
  position: absolute;
  top: 6px;
  left: 2px;
}

// /* In GRIDVIEW: show ratings*/
// .klevu-grid-view .klevu-stars-small {
//   height: 13px;
//   display: inline-block;
//   vertical-align: bottom;
//   background: transparent
//     url(https://js.klevu.com/klevu-js-v1/img-1-1/star-gray.png) top left
//     repeat-x;
//   width: 65px;
// }

// .klevu-grid-view .klevu-stars-small .klevu-rating {
//   background: transparent
//     url(https://js.klevu.com/klevu-js-v1/img-1-1/star-yellow.png) top left
//     repeat-x;
//   height: 13px;
// }

/* In Gridview: Add to cart */
.klevu-grid-view ul li .klevu-addtocart {
  margin-bottom: 5px;
  text-align: center;
}

/* Qty input box */
.klevu-grid-view ul li input[type="text"] {
  display: inline-block;
  width: 25%;
  float: left;
  border: 1px solid #ddd;
  outline: none;
  text-align: right;
  font-size: 12px;
}

.klevu-grid-view ul li input[name="klevu-qty"] {
  display: none;
}

/* Add to cart button */
.klevu-grid-view ul li a.klevu-addtocart-btn {
  display: inline-block;
  background: #414042;
  float: none;
  color: $white-color;
  width: 72%;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  text-decoration: none;
}

/* Add to cart button on hover*/
.klevu-grid-view ul li a.klevu-addtocart-btn:hover,
.klevu-grid-view ul li a.klevu-addtocart-btn:focus {
  text-decoration: none;
  background: #444444;
  color: $white-color;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

/* when there is an image for add to cart */
.klevu-grid-view ul li .klevu-addtocart img {
  max-width: auto !important;
  max-height: 25px !important;
}

.powered-by-klevu {
  padding-right: 2px;
  background-color: #eee;
  text-align: right;
  border-top: 1px solid #ddd;
}

.powered-by-klevu a {
  line-height: 16px;
  color: #414042;
  text-decoration: none;
  font-size: 10px;
}

.powered-by-klevu a:hover {
  text-decoration: none;
}

.klevu-clear-both {
  clear: both;
}

#klevuLoader img {
  width: auto;
}

.klevu-loader-with-filter {
  margin-top: 50%;
  margin-bottom: 10%;
  margin-left: 50%;
}

.klevu-loader-without-filter {
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: 50%;
  text-align: center;
}

.klevu-no-records {
  font-size: 13px;
  margin-bottom: 10%;
  margin-top: 10%;
  text-align: center;
}

/* To show other variants text */
.klevu-variants {
  font-size: 9px;
  text-align: left;
  color: #777;
  display: none;
}

/* To show out of stock caption */
.klevu-out-of-stock {
  font-size: 9px;
  text-align: center;
  color: #777;
}

/* To show vat caption */
.klevu-vat-caption {
  font-size: 9px;
  text-align: center;
  color: #777;
}

.klevuwebrupee {
  font-family: "WebRupee";
}

.klevu-total-results-tab {
  display: none;
}

.klevu-searching-with-cms .klevu-sorting-area {
  padding-bottom: 0px;
  border-bottom: 1px solid #ddd;
}

.klevu-searching-with-cms .klevu-total-results {
  display: none;
}

.klevu-searching-with-cms .klevu-total-results-tab {
  display: block;
  float: left;
  width: 70%;
  text-align: center;
  font-size: 11px;
  color: #414042;
}

.klevu-searching-with-cms .klevu-view {
  width: 15%;
}

.klevu-searching-with-cms .klevu-sorting {
  width: 15%;
}

.klevuTabs {
  text-align: center;
  list-style: none;
  padding: 0;
  line-height: 24px;
  height: 26px;
  overflow: hidden;
  font-size: 12px;
  position: relative;
  margin-bottom: -1px;
}

.klevuTabs a {
  border: 1px solid #ddd;
  background: #d1d1d1;
  background: -o-linear-gradient(top, #ececec 50%, #d1d1d1 100%);
  background: -ms-linear-gradient(top, #ececec 50%, #d1d1d1 100%);
  background: -moz-linear-gradient(top, #ececec 50%, #d1d1d1 100%);
  background: -webkit-linear-gradient(top, #ececec 50%, #d1d1d1 100%);
  background: linear-gradient(top, #ececec 50%, #d1d1d1 100%);
  display: inline-block;
  position: relative;
  z-index: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4), inset 0 1px 0 #fff;
  text-shadow: 0 1px #fff;
  margin: 0px -5px;
  padding: 0 12px;
  color: #555;
  text-decoration: none;
  cursor: pointer;
}

.klevuTabs a.klevu-tab-selected {
  background: #fff;
  color: #414042;
  z-index: 2;
  border-bottom-color: #fff;
}

.klevuTabs:before {
  position: absolute;
  content: " ";
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.klevuTabs a:before,
.klevuTabs a:after {
  border: 1px solid #ddd;
  position: absolute;
  bottom: -1px;
  width: 5px;
  height: 5px;
  content: " ";
}

.klevuTabs a:before {
  left: -6px;
  border-bottom-right-radius: 6px;
  border-width: 0 1px 1px 0;
  box-shadow: 2px 2px 0 #d1d1d1;
}

.klevuTabs a:after {
  right: -6px;
  border-bottom-left-radius: 6px;
  border-width: 0 0 1px 1px;
  box-shadow: -2px 2px 0 #d1d1d1;
}

.klevuTabs a.klevu-tab-selected:before {
  box-shadow: 2px 2px 0 #fff;
}

.klevuTabs a.klevu-tab-selected:after {
  box-shadow: -2px 2px 0 #fff;
}

#klevu-other-content {
  display: none;
}

/* klevu other content view */
/* styles for list view results */
.klevu-other-content-view {
  background-color: #fff;
  overflow: auto;
  max-height: 400px;
}

.klevu-other-content-view ul {
  margin: 0px;
  padding: 0px;
}

/* In LISTVIEW: list style for each result */
.klevu-other-content-view ul li {
  display: block;
  width: 99.5%;
  height: auto;
  border-bottom: 1px solid #eee;
  text-align: left;
}

/* for each result box */
.klevu-other-content-view ul li a.klevu-result-box {
  text-decoration: none;
  color: #000;
  outline: none;
  display: block;
  padding: 10px;
  height: auto;
}

/* set undeline on product name on hover of result box */
.klevu-other-content-view ul li a.klevu-result-box:hover .klevu-name {
  text-decoration: underline;
}

/* In LISTVIEW: set background of result box */
.klevu-other-content-view ul li:hover {
  cursor: pointer;
  /*	background-color:#f9f9f9;	*/
}

/* In LISTVIEW: display product name and description */
.klevu-other-content-view ul li .klevu-name-desc {
  width: 100%;
}

.klevu-other-content-view ul li .klevu-name {
  padding: 5px;
  font-size: 13px;
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.klevu-other-content-view ul li .klevu-desc {
  line-height: 20px;
  padding: 5px;
  color: #000;
}

/* In LISTVIEW: set color to highlight search keyowrd in name and description */
.klevu-other-content-view ul li strong {
  color: #2980b9;
}

/* Klevu Price slider style */
.klevuPriceRangeSlider {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  min-height: 50px;
}

.klevuPS-target,
.klevuPS-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.klevuPS-target {
  position: relative;
  direction: ltr;
}

.klevuPS-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.klevuPS-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.klevuPS-handle {
  position: relative;
  z-index: 1;
}

.klevuPS-stacking .klevuPS-handle {
  z-index: 10;
}

.klevuPS-state-tap .klevuPS-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.klevuPS-base,
.klevuPS-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.klevuPS-horizontal {
  height: 4px;
}

.klevuPS-horizontal .klevuPS-handle {
  width: 18px;
  height: 18px;
  left: -9px;
  top: -7px;
}

.klevuPS-vertical {
  width: 18px;
}

.klevuPS-vertical .klevuPS-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

.klevuPS-background {
  background: #eee;
}

.klevuPS-connect {
  background: #414042;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.klevuPS-origin {
  border-radius: 2px;
}

.klevuPS-target {
  border-radius: 4px;
}

.klevuPS-draggable {
  cursor: w-resize;
}

.klevuPS-vertical .klevuPS-draggable {
  cursor: n-resize;
}

.klevuPS-handle {
  border: 1px solid #414042;
  border-radius: 50%;
  background: $white-color;
  cursor: pointer;
}

.klevuPS-tooltip {
  display: block;
  position: absolute;
  text-align: center;
  font-size: 13px;
  margin-left: -9px;
}

.klevuPS-horizontal .klevuPS-handle-lower .klevuPS-tooltip {
  bottom: -24px;
}

.klevuPS-horizontal .klevuPS-handle-upper .klevuPS-tooltip {
  bottom: -24px;
}

/* set opacity to filter */
.disableKlevuFilter {
  opacity: 0.3;
  pointer-events: none;
}

/*---------------iPad & Samsung Galaxy Tablets portrait---------------*/
/*___________________________________________________________________*/
@media only screen and (max-width: 1024px) {
  .klevu-fluid .klevu-searching-area {
    width: 70%;
  }
  .klevu-fluid .klevu-without-filter {
    width: 60%;
  }
  .klevu-fluid .klevu-pt-rs-hover {
    width: 50% !important;
    padding: 5px;
  }
}

/*---------------iPhone & Samsung Mobiles landscape---------------*/
/*___________________________________________________________________*/
@media only screen and (min-width: 641px) and (max-width: 800px) {
  .klevu-fluid .klevu-searching-area {
    left: 0 !important;
    margin-left: 10px;
    width: 88%;
  }
  .klevu-fluid .klevu-filters {
    width: 40%;
    float: left;
    border-right: none;
  }
  .klevu-fluid .klevu-sorting-results {
    margin-top: 30px;
    width: 100%;
    float: left;
  }
  .klevu-fluid .klevu-popular-terms .klevu-sorting-results {
    width: 68.5%;
  }
  .klevu-fluid .klevu-without-filter .klevu-sorting-results {
    width: 100%;
  }
  .klevu-fluid .klevu-sorting ul li {
    padding-left: 3px;
    padding-right: 3px;
    font-size: 10px;
  }
  .klevu-fluid .klevu-grid-view ul li {
    width: 100%;
  }
  .klevu-fluid .klevu-grid-view ul li:nth-child(2n + 2) {
    border-right: none;
  }
  .klevu-fluid .klevu-grid-view ul li:nth-child(odd) {
    border-right: 1px solid #e2e2e2;
  }
  .klevu-grid-view ul li:nth-child(3) {
    border-top: 1px solid #e2e2e2;
  }
  .klevu-grid-view ul li:nth-last-child(1) {
    border-top: none;
  }
  .klevu-fluid .klevu-list-view ul li .klevu-price {
    float: none;
    width: 100%;
    margin-top: 20px;
  }
  .klevu-fluid .klevu-list-view .klevu-price .klevu-saleprice {
    display: inline !important;
  }
  .klevu-fluid .klevu-list-view .klevu-price .klevu-origprice {
    display: inline !important;
  }
  .klevu-fluid .klevu-pt-rs-hover {
    width: 75% !important;
    padding: 5px;
  }
}

/*---------------iPhone & Samsung Mobiles portrait---------------*/
/*___________________________________________________________________*/
@media only screen and (max-width: 640px) {
  .klevu-fluid .klevu-searching-area {
    left: 0 !important;
    margin-left: 10px;
    width: 90%;
  }
  .klevu-fluid .klevu-sorting-results {
    width: 100%;
  }
  .klevu-fluid .klevu-popular-terms .klevu-sorting-results {
    width: 100%;
  }
  .klevu-fluid .klevu-pt-rs {
    display: none !important;
  }
  .klevu-fluid .klevu-filters {
    display: none !important;
  }
  .klevu-fluid .klevu-grid-view ul li {
    width: 100%;
  }
  .klevu-fluid .klevu-list-view ul li .klevu-price {
    float: none;
    width: 100%;
    margin-top: 20px;
  }
  .klevu-fluid .klevu-list-view .klevu-price .klevu-saleprice {
    display: inline !important;
  }
  .klevu-fluid .klevu-list-view .klevu-price .klevu-origprice {
    display: inline !important;
  }
  .klevu-fluid .klevu-pt-rs-hover {
    width: 90% !important;
    padding: 5px;
  }
  .klevu-fluid .klevu-pt-rs-hover strong {
    float: none;
    width: 100%;
  }
  .klevu-fluid .klevu-pt-rs-hover .klevu-pt-rs-keywords {
    float: none;
    width: 100%;
  }
  .klevu-fluid .klevu-rs-hover-box {
    margin-top: 10px;
  }
  .klevu-fluid .klevu-searching-with-cms .klevu-sorting {
    display: none;
  }
  .klevu-fluid .klevu-searching-with-cms .klevu-total-results-tab {
    width: 85%;
  }
  .klevu-fluid .klevu-total-results-tab {
    overflow: hidden;
    margin-bottom: -4px;
  }
  .klevu-fluid .klevu-searching-with-cms .klevu-sorting-area {
    padding: 3px;
    padding-top: 8px;
  }
  .klevu-fluid .klevuTabs {
    font-size: 11px;
  }
  .klevu-fluid .klevuTabs a {
    padding: 0px 8px;
    margin: 0px -4px;
  }
}

@media only screen and (max-width: 480px) {
  .klevu-grid-view ul li:nth-child(3) {
    border-top: 1px solid #e2e2e2;
  }
  .klevu-grid-view ul li:nth-last-child(1) {
    border-top: none;
  }
  .klevu-fluid .klevu-grid-view ul li:nth-child(2n + 2) {
    border-right: none;
  }
  .klevu-fluid .klevu-grid-view ul li:nth-child(odd) {
    border-right: 1px solid #e2e2e2;
  }
}

/*============================================================
========== CSS OF KLEVU SEARCH RESULT LANDING PAGE ===========
=============================================================*/
.kuContainer {
  width: 1299px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 1024px) {
  .kuContainer {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 600px) {
  .kuContainer {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 600px) {
  .kuContainer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/* klevu container for fluid layout*/
.kuProListing {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* klevu filters */
.kuFilters {
  float: left;
  width: 100%;
}

/* heading of filters i.e brand, color */
.kuFilterBox {
  margin-bottom: 10px;
}

.kuFilterHead {
  padding: 6px;
  display: flex;
  align-items: center;
  padding: 30px 0;
  font-family: var(--fmc-font--proxima-nova);
  border-top: 1px solid #e3e3e3;
  font-size: 16px;
  letter-spacing: 2px;
  height: 32px;
  line-height: 12px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  border-bottom: none;
  color: #414042;
}
@media (min-width: 601px) {
  .kuFilterHead {
    padding-left: 62px;
  }
}

/* down arrow div in filters */
.kuShowOpt {
  text-align: left;
  padding: 3px;
  padding-left: 35px;
  margin-bottom: 20px;
  margin-top: -15px;
  display: none !important;
}

/* up arrow div in filters */
.kuHideOpt {
  text-align: left;
  padding: 3px;
  padding-left: 35px;
  margin-bottom: 20px;
  margin-top: -15px;
}

.kuShowOpt img,
.kuHideOpt img {
  width: auto !important;
}

/* down & up arrow link color in filters */
.kuShowOpt a,
.kuHideOpt a {
  color: #4d7abf;
  text-decoration: none;
}

/* set list for filters */
@media (min-width: 601px) {
  .kuFilterNames {
    padding-left: 62px;
  }
}

#kuFilterNames-Price-Range {
  padding-left: 22px;
}

.kuFilterNames ul {
  margin-bottom: 30px;
  padding: 0px;
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
}

/* style for each values in filter */
.kuFilterNames ul li {
  list-style: none;
  text-align: left;
  width: 100%;
  display: inline-table;
  margin: 0px;
  padding-left: 0px;
  margin-left: 0px !important;
}

.kuFilterNames ul li a {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  color: #414042;
  border: none !important;
  line-height: 22px;
}

/* set background color on hover of filter */
.kuFilterNames ul li a:hover {
  cursor: pointer;
}

/* set background color for selected filter */
.kuFilterNames ul li.kuSelected a {
  color: #414042;
}

/* label for the filter */
.kuFilterNames ul li a span.kuFilterLabel {
  float: left;
  width: 86%;
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  font-size: 16px;
  font-family: var(--fmc-font--proxima-nova);
}

/* total nos of results available for filter  */
.kuFilterNames ul li a span.kuFilterTotal {
  float: right;
  width: 13%;
  text-align: right;
}

.kuFilterLabel:before {
  content: "";
  border: 1px solid #6e6e6e;
  border-width: 2px;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: inline-block;
  color: #777;
  margin-top: 0px;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: background 200ms;
  -webkit-transition: background 200ms;
  -moz-transition: background 200ms;
}

.kuFilterNames ul li.kuSelected span.kuFilterLabel:before {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-tick.svg?v=19605891093612727881664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.kuMulticheck .kuFilterLabel:before {
  content: "";
  border: 1px solid #eee;
  border-radius: 0px;
  margin-right: 8px;
  height: 15px;
  width: 15px;
  color: #777;
  margin-bottom: -1px;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: background 200ms;
  -webkit-transition: background 200ms;
  -moz-transition: background 200ms;
  transition: border-color 200ms;
  -webkit-transition: border-color 200ms;
  -moz-transition: border-color 200ms;
}

.kuMulticheck .kuFilterNames ul li a:hover span.kuFilterLabel:before {
  background: $white-color;
  border: 1px solid #414042;
}

.kuMulticheck .kuFilterNames ul li.kuSelected span.kuFilterLabel:before {
  background: $white-color;
  border: 1px solid #414042;
}

.kuMulticheck .kuHover .kuFilterNames ul li a:hover span.kuFilterLabel:after {
  content: "";
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 3px;
  left: 8px;
  border: 2px solid #cccccc;
  border-top: none;
  border-right: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.kuMulticheck .kuFilterNames ul li.kuSelected span.kuFilterLabel:after {
  content: "";
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 3px;
  left: 8px;
  border: 2px solid #414042;
  border-top: none;
  border-right: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.kuMulticheck .kuFilterNames ul li.kuSelected:hover span.kuFilterLabel:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-color: #414042;
}

/* shows cancel button if filter is selected */
.kuFilterNames ul li a span.kuFilterCancel {
  float: right;
  width: 13%;
  text-align: right;
}

/* klevu results box */
/* div for Sorting, pagination, change result view icons*/
.kuSortHeader {
  padding-top: 0px;
  margin-top: 0px;
}

.kuResultInfo {
  padding-top: 20px;
  width: 100%;
  text-align: center;
}

.kuTotResults {
  text-align: left;
  margin-bottom: 10px;
  line-height: 24px;
  font-weight: bold;
  font-size: 14px;
  float: left;
  color: #414042;
}

.kuTotalResultsTab {
  float: none;
  width: 100%;
  text-align: center;
}
@media (max-width: 640px) {
  .kuTotalResultsTab {
    text-align: center;
  }
}

.kuTotalResultsTab .kuTabs {
  line-height: 30px;
}

.kuTotalResultsTab .kuTabs a {
  padding: 8px;
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  margin-right: 5px;
  transition: background 200ms;
  -webkit-transition: background 300ms;
  -moz-transition: background 200ms;
  color: #414042;
  padding: 0px 8px;
  display: inline-block;
}

.kuTotalResultsTab .kuTabs a:hover {
  border-bottom: 3px solid #eee;
  text-decoration: none;
  text-decoration: none;
  background: #eee;
}

.kuTotalResultsTab a.kuTabSelected {
  border-bottom: 3px solid #eee;
  transition: all 0.3s ease;
}

.kuTotalResultsTab a.kuTabSelected:hover {
  background: none;
}

.kuSortingOpt {
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  margin-top: 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}
@media (max-width: 768px) {
  .kuSortingOpt {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start;
  }
}

/* div to display sorting dropdown */
.kuSortby {
  float: right;
  width: 240px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
@media (max-width: 768px) {
  .kuSortby {
    width: 50%;
  }
  .kuSortby label#klevuSortLbl {
    display: none;
  }
  .kuSortby select#kuSortby {
    border: none;
    height: 40px !important;
  }
}

/* label of sorting dropdown */
.kuSortby label {
  display: block;
  width: 100%;
  text-align: left;
  color: #414042;
  margin-bottom: 5px;
}

/* sorting dropdown */
.kuSortby select {
  width: 240px;
  height: 50px !important;
  border-radius: 3px;
  border: 1px solid #6e6e6e;
  padding: 0 35px 0 16px;
  margin-left: auto;
  position: relative;
  text-align: left;
  font-family: var(--fmc-font--proxima-nova);
  letter-spacing: 1.23px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media (max-width: 768px) {
  .kuSortby select {
    width: 100%;
  }
}

/* div to display icons to change the view of result (grid/view) */
.kuView {
  float: right;
  width: 15%;
  text-align: right;
  display: none;
}

/* display GRID view icon */
// .kuView .kuGridviewBtn {
//   background: url(https://js.klevu.com/klevu-js-v1/img-1-1/ku-gridview.png)
//     no-repeat 0 0;
//   cursor: pointer;
// }

// /* display LIST view icon */
// .kuView .kuListviewBtn {
//   background: url(https://js.klevu.com/klevu-js-v1/img-1-1/ku-listview.png)
//     no-repeat 0 0;
//   cursor: pointer;
// }

/* set width and height of view icons box*/
.kuView a {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-left: 4px;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  -webkit-transition: background-position 0.1s ease-in;
  -moz-transition: background-position 0.1s ease-in;
  -o-transition: background-position 0.1s ease-in;
  transition: background-position 0.1s ease-in;
}

/* changing background position on hover of GRID/LIST view icons */
.kuView a:hover {
  background-position: 0 -25px;
  text-decoration: none;
}

/* change background position to set current view */
.kuView a.kuCurrent {
  background-position: 0 -25px;
  text-decoration: none;
}

/* dropdown to select no of results per page*/
.kuPerPage {
  float: left;
  width: 32%;
  margin-left: 10px;
  text-align: center;
}

.kuPerPage label {
  display: inline;
  color: #414042;
}

.kuPerPage select {
  width: auto !important;
  height: auto;
  min-height: 22px;
  display: inline;
  margin-left: 5px;
}

/* div for pagination */
.kuPagination {
  width: 33%;
  float: right;
  margin-left: 10px;
  text-align: right;
  margin-bottom: 5px;
}

/* style to display page nos in line */
.kuPagination a {
  margin: 0px;
  position: relative;
  display: inline-block;
  padding-left: 4px;
  padding-right: 5px;
  color: #414042;
  cursor: pointer;
  text-decoration: none;
  border-right: 1px solid #eee;
  font-size: 13px;
}

/* style on hover of page links */
.kuPagination a:hover {
  color: #000;
}

/* style to show current page */
.kuPagination a.kuCurrent {
  background: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-weight: bold;
}

.kuPagination a:last-child {
  border-right: none;
}

.kuOtherContent .kuPagination {
  float: right;
}

.kuOtherContent .kuPerPage {
  margin-left: 0px;
  text-align: left;
}

.kuClearLeft {
  clear: both;
  line-height: 0px;
}

.klevu-clearboth-listview {
  clear: left;
}

.kuDiscountBadge {
  background: #414042;
  padding: 7px 0px 0px 0px;
  color: $white-color;
  width: 47px;
  height: 40px;
  border-radius: 50%;
  font-weight: bold;
  position: absolute;
  text-align: center;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.kuDiscountBadge span {
  display: block;
}

/* klevu results div */
.kuResults {
  margin-top: 10px;
}

.kuOtherContentView {
  margin-top: 10px;
}

.kuOtherContentView ul li .kuNameDesc {
  margin-top: 0px !important;
}

/* styles for grid view results */
.kuGridView {
  margin-top: 10px;
}

.kuGridView ul {
  margin: 0px;
  padding: 0px;
  margin-left: 0px !important;
  padding-left: 0px !important;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 768px) {
  .kuGridView ul {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 600px) {
  .kuGridView ul {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* In GRIDVIEW: list style for each result */
.kuGridView ul li {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  vertical-align: top;
  text-align: center;
  margin: 0 !important;
  font-style: normal;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0;
}

.kuGridView ul li:hover {
  border-color: transparent;
}

/* In GRIDVIEW:  for wrapping the image in fixed size div */
.kuGridView .klevuImgWrap {
  float: none;
  overflow: hidden;
  width: 100% !important;
  padding-top: 100%;
  /* 1:1 Aspect Ratio */
  position: relative;
}

/* In GRIDVIEW: thumbnail of the product */
.kuGridView .klevuImgWrap img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.kuGridView ul li .kuDiscountBadge {
  top: 10px;
  right: 10px;
}

.kuGridView ul li:hover .kuDiscountBadge {
  width: 57px;
  height: 47px;
  padding-top: 10px;
}

/* In GRIDVIEW: remove float value for name and description div */
.kuGridView .kuNameDesc {
  float: none;
  text-align: left;
  margin-top: 10px;
}

/* In GRIDVIEW: discription is not displayed in grid layout*/
.kuGridView .kuDesc {
  display: none;
}

.kuGridView ul li .kuName {
  margin-bottom: 5px;
}

/* CSS for add to cart button */
.kuAddtocart {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.kuAddtocart input[type="text"] {
  display: none;
  border: 1px solid #ddd;
  outline: none;
  text-align: right;
}

.kuAddtocart a.kuAddtocartBtn:hover {
  background: #777;
}

/* add to cart in grid view */
.kuGridView ul li .kuAddtocart {
  width: 95%;
  display: none;
  margin-bottom: 15px;
  text-align: left;
  padding-left: 10px;
  position: absolute;
  bottom: 0;
  /*visibility: hidden;*/
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  -moz-transition: visibility 0s, opacity 0.5s linear;
}

.kuGridView ul li input[type="text"] {
  width: 25%;
}

/* if image for Add to cart */
.kuGridView ul li .kuAddtocart img,
.kuListView ul li .kuAddtocart img {
  max-height: 22px !important;
  max-width: auto !important;
}

/* In GRIDVIEW: set product name color and font size */
.kuGridView ul li .kuName a {
  font-size: 18px;
  font-family: var(--fmc-font--proxima-nova);
  letter-spacing: 0.02px;
  text-decoration: none;
  color: #414042;
  border: none;
}

/* In GRIDVIEW: div to display saleprice and original price */
.kuGridView ul li .kuPrice {
  float: none;
  margin-top: auto;
  text-align: left;
  color: #414042;
}

/* In GRIDVIEW: div to display saleprice */
.kuGridView ul li .kuSalePrice {
  color: $theme-color;
  font-size: 24px;
  line-height: normal;
  font-weight: 300;
  letter-spacing: 2.4px;
  font-family: var(--fmc-font--proxima-nova);
}
@media (max-width: 768px) {
  .kuGridView ul li .kuSalePrice {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 2px;
  }
}

.priceGreyText {
  font-size: 11px;
  color: #636363;
}

/* In GRIDVIEW: div to display original price with line-through style */
.kuGridView ul li .kuOrigPrice {
  font-size: 12px;
  text-decoration: line-through;
  text-align: left;
  display: inline;
  margin-right: 5px;
}

.kuGridView ul li .kuVariants {
  text-align: left;
}

/* In GRIDVIEW: set color to highlight search keyowrd in name and description */
.kuGridView ul li strong {
  color: #2980b9;
}

/* pagination links at bottom of results */
.kuBottomPagi {
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.kuBottomPagi .kuPerPage {
  margin-left: 0px;
  text-align: left;
}

.kuBottomPagi .kuPagination {
  width: 50%;
  float: right;
  margin-top: 5px;
}

.kuPagination a {
  font-style: normal;
}

.kuClearBoth {
  clear: both;
}

/* div to display No records found message */
.kuNoRecordFound {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
  color: #414042;
}

/* show variants */
.kuVariants {
  font-size: 10px;
  margin-top: 2px;
  color: #414042;
}

/* height for loader div */
#loader {
  height: 400px;
}

#loader img {
  margin-top: 10%;
  display: inline-block;
  width: auto !important;
}

.kuOtherContent {
  display: none;
}

.disableKuFilter {
  opacity: 0.3;
  pointer-events: none;
}

/* Klevu Price slider style */
.kuPriceRangeSlider {
  width: 75%;
  margin: 0 auto;
  margin-top: 30px;
  min-height: 50px;
}

.kuPS-target,
.kuPS-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.kuPS-target {
  position: relative;
  direction: ltr;
}

.kuPS-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.kuPS-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.kuPS-handle {
  position: relative;
  z-index: 1;
}

.kuPS-stacking .kuPS-handle {
  z-index: 10;
}

.kuPS-state-tap .kuPS-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.kuPS-base,
.kuPS-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.kuPS-horizontal {
  height: 4px;
}

.kuPS-horizontal .kuPS-handle {
  width: 18px;
  height: 18px;
  left: -9px;
  top: -7px;
}

.kuPS-vertical {
  width: 18px;
}

.kuPS-vertical .kuPS-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

.kuPS-background {
  background: #eee;
}

.kuPS-connect {
  background: #414042;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.kuPS-origin {
  border-radius: 2px;
}

.kuPS-target {
  border-radius: 4px;
}

.kuPS-draggable {
  cursor: w-resize;
}

.kuPS-vertical .kuPS-draggable {
  cursor: n-resize;
}

.kuPS-handle {
  border: 1px solid #414042;
  border-radius: 50%;
  background: $white-color;
  cursor: pointer;
}

.kuPS-tooltip {
  display: block;
  position: absolute;
  text-align: center;
  font-size: 13px;
  margin-left: -6px;
  background-color: transparent;
}

.kuPS-horizontal .kuPS-handle-lower .kuPS-tooltip {
  bottom: -24px;
  color: #414042;
}

.kuPS-horizontal .kuPS-handle-upper .kuPS-tooltip {
  bottom: -24px;
  color: #414042;
}

/* set opacity to filter */
.disableKlevuFilter {
  opacity: 0.3;
  pointer-events: none;
}

/*----------------------------------------starts kuFiltersTop css----------------------------------------*/
.kuContainer.kuFiltersTop .kuFilters {
  border: 0px solid #e8e8e8;
  float: right;
  width: 87%;
}

.kuContainer.kuFiltersTop .kuResultList {
  float: left;
  width: 100%;
}

.kuContainer.kuFiltersTop .kuFilterBox {
  border: 1px solid #e8e8e8;
  display: inline-block;
  float: left;
  height: auto;
  margin-bottom: 5px;
  margin-left: -1px;
  min-width: 90px;
  position: relative;
  width: auto;
}

.kuContainer.kuFiltersTop .kuShowOpt {
  display: none !important;
}

.kuContainer.kuFiltersTop .kuFilterHead {
  border-bottom: 0 none;
  border-right: 0 solid #e8e8e8;
  color: #414042;
  border-top: 1px solid #e3e3e3;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.kuContainer.kuFiltersTop .kuFilterNames {
  display: none;
  left: 0;
  position: absolute;
  top: 32px;
  min-width: 250px;
  width: 99.8%;
  transition: all 0.5s ease;
  min-height: 80px;
  max-height: 180px;
  overflow: auto;
  background: #fff;
  border: 1px solid #e8e8e8;
  padding-top: 5px;
  padding-bottom: 10px;
  z-index: 100;
}

.kuContainer.kuFiltersTop .kuFilterBox:last-child .kuFilterNames {
  left: auto;
  right: 0px;
}

.kuContainer.kuFiltersTop .kuFilterHead:hover {
  transition: all 0.5s ease;
  cursor: pointer;
}

.kuContainer.kuFiltersTop .kuFilterHead:hover + .kuFilterNames,
.kuFilterNames:hover {
  display: block !important;
}

.kuContainer.kuFiltersTop .kuFilterHead::after {
  border-left: 1px solid #414042;
  border-top: 1px solid #414042;
  content: "";
  display: inline-block;
  height: 6px;
  margin-left: 8px;
  transform: rotate(225deg);
  width: 6px;
  margin-bottom: 2px;
}

.kuContainer.kuFiltersTop #ku-search-filter-tags {
  display: block;
  color: #888;
  float: right;
  margin-bottom: 25px;
  width: 87%;
  text-align: left;
  line-height: 20px;
}

.ku-search-filter-tag {
  background: #f2f3f6;
  border: 1px solid #f8f8f8;
  margin-right: 5px;
  border-radius: 0px;
  font-size: 11px;
  padding: 2px 3px;
}

.ku-search-filter-remove,
.ku-search-filter-remove-all {
  cursor: pointer;
  transition: all 0.3s ease;
}

.ku-search-filter-remove:hover,
.ku-search-filter-remove-all:hover {
  color: #000;
}

.kuContainer.kuFiltersTop .kuGridView ul li {
  width: 23%;
  margin-right: 1%;
}

.kuContainer.kuFiltersTop .kuFilterHead.kufilter-label {
  color: #414042 !important;
  display: inline-block;
  float: left;
  border: 0px solid #414042;
  font-size: 16px;
  height: 32px;
  line-height: 20px;
  padding: 0px;
  width: 12%;
  text-align: left;
  min-width: 60px;
}

.kuContainer.kuFiltersTop .kuFilterHead.kufilter-label::after {
  display: none;
}

.ku-search-block-full {
  margin: 15px 0 15px 0px;
  position: relative;
}

div#ku-search-form form {
  width: 100%;
  max-width: 100% !important;
}

.ku-searchfield {
  border: 1px solid #e8e8e8;
  padding: 0px 10px !important;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box !important;
  height: 38px !important;
  line-height: 38px !important;
  background: none !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
}

.ku-search-btn {
  background: rgba(0, 0, 0, 0)
    url(https://js.klevu.com/klevu-js-v1/img-1-1/ku-search-icon.png) no-repeat !important;
  border: 0 none !important;
  cursor: pointer;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  height: 38px !important;
  width: 38px !important;
  top: 0;
  background-size: 22px 22px !important;
  background-position: center center !important;
  box-sizing: border-box;
  line-height: 38px !important;
  box-shadow: none !important;
}

.ku-search-btn:hover {
  background: rgba(0, 0, 0, 0)
    url("https://js.klevu.com/klevu-js-v1/img-1-1/ku-search-icon.png") no-repeat
    scroll center center/22px 22px;
  border: medium none;
  cursor: pointer;
  transform: scale(1);
}

/*============================ends kuFiltersTop css================================*/
/*----------------------------------------starts kuFiltersLeft css----------------------------------------*/
.kuContainer.kuFiltersLeft #ku-search-filter-tags {
  display: none;
}

.kuContainer.kuFiltersLeft .kuFilterBox {
  overflow: hidden;
}

.kuContainer.kuFiltersLeft .kuFilterHead.kufilter-label {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: none;
  color: #414042 !important;
  display: block;
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: -60px;
  max-width: 245px;
  padding: 5px !important;
}

.kuFiltersLeft .ku-search-block-full {
  float: right;
  width: 75%;
  position: relative;
}

.kuFiltersLeft .ku-search-block-full form {
  margin: 0px;
  padding: 0px;
}

/*============================ends kuFiltersLeft css================================*/
/*-----------------------css for enabling filters in mobile  and filter tags--------------------------------------------*/
/* css for filter tags*/
.kuContainer #ku-search-filter-tags {
  display: block !important;
  color: #222222;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
  padding-bottom: 0px;
  padding-left: 0px;
  width: auto !important;
  z-index: 1;
  background-color: #fff;
  padding: 5px 15px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}

.ku-search-filter-tag {
  background: #eee;
  border: 0px solid #f8f8f8;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 11px;
  padding: 0px 5px;
  color: #222;
  margin-bottom: 4px;
  display: inline-block;
  line-height: 20px;
}

.ku-search-filter-remove,
.ku-search-filter-remove-all {
  cursor: pointer;
  font-size: 12px;
  color: #222222;
  text-decoration: none;
}

.ku-search-filter-remove-all a {
  color: #222222;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
}

.ku-search-filter-remove-all a:hover {
  color: #333;
}

/* ends css for filter tags*/
.kuFilterHead {
  cursor: pointer;
}

.kuExpand {
  position: relative !important;
  transition: all 0.3s ease;
}

.kuFilterHead.kuExpand::after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #000;
  border-image: none;
  border-style: solid;
  border-width: 0 1px 1px 0;
  content: "";
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 12px;
  top: 24px;
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.3s ease 0s;
}

.kuCollapse {
  position: relative !important;
  transition: all 0.3s ease;
}

.kuFilterHead.kuCollapse::after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #000;
  border-image: none;
  border-style: solid;
  border-width: 0 1px 1px 0;
  content: "";
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 12px;
  top: 30px;
  -ms-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  transition: all 0.3s ease;
}

/* to remove arrow border when filtrs on top*/
.kuContainer.kuFiltersTop .kuFilterHead.kuCollapse::after {
  border-width: 1px 0px 0px 1px;
  position: initial;
}

.kuContainer.kuFiltersTop .kuFilterHead.kuExpand::after {
  border-width: 0 1px 1px 0;
  position: initial;
}

.kuContainer.kuFiltersLeft #ku-search-filter-tags {
  display: block;
  width: 75%;
  float: right;
  margin-top: 0px;
}

/*-----------------------ends css for enabling filters in mobile and filter tags--------------------------------------------*/
/*-------------- RESPONSIVE CSS FOR SEARCH RESULTS PAGE ---------------*/
@media only screen and (max-width: 1200px) {
  .kuContainer.kuFiltersTop .kuFilters,
  .kuContainer.kuFiltersTop #ku-search-filter-tags {
    width: 85%;
  }
  .kuContainer.kuFiltersTop .kuFilters {
    border: 0px;
  }
  .kuContainer.kuFiltersTop .kuFilterBox {
    border: 1px solid #e8e8e8;
    margin-bottom: 3px;
    margin-right: -1px;
  }
  .kuContainer.kuFiltersTop .kuFilterNames {
    margin-top: -1px;
  }
}

@media only screen and (max-width: 1024px) {
  .kuView {
    width: 28%;
    text-align: right;
    display: none;
  }
  .kuPerPage {
    width: 35%;
    text-align: center;
    margin-left: 10px;
  }
  .kuPagination {
    width: 50%;
    margin-top: 10px;
  }
  .kuGridView ul li,
  .kuContainer.kuFiltersTop .kuGridView ul li {
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
  }
  .kuGridView ul li .kuAddtocart {
    visibility: visible;
    opacity: 1;
  }
  .kuContainer.kuFiltersTop .kuFilters,
  .kuContainer.kuFiltersTop #ku-search-filter-tags {
    width: 82%;
  }
  .kuContainer.kuFiltersTop .kuFilterHead.kufilter-label {
    width: 16%;
    font-size: 14px;
    line-height: 20px;
  }
  /* css for filtersTop*/
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .kuContainer.kuFiltersLeft #ku-search-filter-tags {
    width: 66%;
  }
  .kuGridView ul li,
  .kuContainer.kuFiltersTop .kuGridView ul li {
    margin-bottom: 30px;
    margin-right: 2%;
  }
  .kuFiltersLeft .ku-search-block-full {
    float: right;
    width: 66%;
  }
}

@media only screen and (max-width: 640px) {
  .kuFiltersLeft .ku-search-block-full {
    float: none;
    width: 100%;
  }
  #ku-search-filter-tags,
  .kufilter-label {
    display: none !important;
  }
  .kuResultInfo {
    padding-bottom: 12px;
  }
  .kuTotResults {
    float: none;
    width: 100%;
    text-align: center;
  }
  .kuResultList {
    float: none;
    width: 100%;
    margin-left: 0px;
  }
  .kuSortingOpt {
    padding-bottom: 5px;
  }
  .kuSortby {
    width: 50%;
  }
  .kuSortHeader {
    margin: 0px;
  }
  .kuView {
    width: 50%;
    text-align: right;
    height: 50px;
    display: none;
  }
  .kuPerPage {
    width: 48%;
    text-align: right;
    margin: 0 auto;
    margin-top: 0px;
  }
  .kuPagination {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
  }
  .kuBottomPagi .kuPerPage {
    margin-left: 0px;
    text-align: left;
    margin-bottom: 5px;
    float: left;
    width: 46%;
  }
  .kuBottomPagi .kuPagination {
    width: 52%;
    float: right;
    margin-top: 5px;
    text-align: right;
  }
  .kuGridView ul li .kuAddtocart {
    padding-left: 0px;
  }
  .kuAddtocart a.kuAddtocartBtn {
    font-size: 11px;
  }
}

@media only screen and (max-width: 480px) {
  .kuGridView ul li,
  .kuContainer.kuFiltersTop .kuGridView ul li {
    margin-bottom: 45px;
  }
  .kuSortby select {
    display: block;
    margin-left: 0px;
  }
  .kuPerPage label {
    display: block;
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 380px) {
  .kuGridView ul li,
  .kuContainer.kuFiltersTop .kuGridView ul li {
    width: 100%;
    min-height: 320px;
    margin-bottom: 20px;
  }
}

/*-----------------------CSS for enabling filters in mobile and filter tags----------------------------*/
@media only screen and (max-width: 640px) {
  .kuPagination {
    display: none !important;
  }
  .kuBottomPagi .kuPagination {
    display: block !important;
  }
  .kuBottomPagi .kuPerPage {
    display: block;
  }
  .kuSortby {
    width: 50%;
  }
  .kuSortHeader {
    text-align: right;
  }
  .kuPerPage {
    display: none;
  }
  .kuFilterRefineMobile {
    border: none;
    font-size: 13px;
    font-weight: 600;
    height: 32px;
    line-height: 32px;
    margin: 10px 0px;
    padding-right: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #383838;
    background-color: #eee;
    cursor: pointer;
    display: none !important;
  }
  .kuFilterRefineMobile.kuExpand::after {
    top: 10px;
  }
  .kuFilterRefineMobile.kuCollapse::after {
    top: 14px;
  }
  .kuFilterBox {
    margin-bottom: 0px;
  }
  .kuFilterRefineMobile .KuFilterLabelTxt {
    position: relative;
  }
  .kuFilterRefineMobile.kuExpand .KuFilterLabelTxt::after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #000;
    border-image: none;
    border-style: solid;
    border-width: 0 1px 1px 0;
    content: "";
    display: inline-block;
    padding: 3px;
    position: absolute;
    right: -18px;
    top: 3px;
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all 0.3s ease;
  }
  .kuFilterRefineMobile.kuCollapse .KuFilterLabelTxt::after {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #000;
    border-image: none;
    border-style: solid;
    border-width: 0 1px 1px 0;
    content: "";
    display: inline-block;
    padding: 3px;
    position: absolute;
    right: -18px;
    top: 8px;
    -ms-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    transition: all 0.3s ease;
  }
  /* css for filter tags*/
  .kuContainer #ku-search-filter-tags {
    display: block !important;
    color: #222222;
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
    padding-bottom: 8px;
    padding-left: 0px;
    position: relative;
  }
  .kuContainer.kuFiltersLeft #ku-search-filter-tags {
    display: block;
    width: 100%;
    float: none;
    margin-top: 10px;
  }
  .ku-search-filter-tag {
    background: #eee;
    border: 0px solid #f8f8f8;
    margin-right: 4px;
    border-radius: 0px;
    font-size: 11px;
    padding: 0px 5px;
    color: #222;
    margin-bottom: 4px;
    display: inline-block;
    line-height: 20px;
  }
  .ku-search-filter-remove,
  .ku-search-filter-remove-all {
    cursor: pointer;
    font-size: 12px;
    color: #222222;
    text-decoration: none;
  }
  .ku-search-filter-remove-all a {
    color: #222222;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
  }
  .ku-search-filter-remove-all a:hover {
    color: #333;
  }
}

/*-----------------------ends CSS for enabling filters in mobile and filter tags----------------------------*/
/*CSS for personalized search*/
.klevupsSlider {
  -moz-perspective: 600px;
  -ms-perspective: 600px;
  -webkit-perspective: 600px;
  perspective: 600px;
  display: inline-block;
  text-align: left;
  position: relative;
  margin-bottom: 22px;
}

.klevupsSlider > input {
  display: none;
}

.klevupsSlider > input:nth-of-type(10):checked ~ ul li:first-of-type {
  margin-left: -500%;
}

.klevupsSlider > input:nth-of-type(9):checked ~ ul li:first-of-type {
  margin-left: -400%;
}

.klevupsSlider > input:nth-of-type(8):checked ~ ul li:first-of-type {
  margin-left: -350%;
}

.klevupsSlider > input:nth-of-type(7):checked ~ ul li:first-of-type {
  margin-left: -300%;
}

.klevupsSlider > input:nth-of-type(6):checked ~ ul li:first-of-type {
  margin-left: -250%;
}

.klevupsSlider > input:nth-of-type(5):checked ~ ul li:first-of-type {
  margin-left: -200%;
}

.klevupsSlider > input:nth-of-type(4):checked ~ ul li:first-of-type {
  margin-left: -150%;
}

.klevupsSlider > input:nth-of-type(3):checked ~ ul li:first-of-type {
  margin-left: -100%;
}

.klevupsSlider > input:nth-of-type(2):checked ~ ul li:first-of-type {
  margin-left: -50%;
}

.klevupsSlider > input:nth-of-type(1):checked ~ ul li:first-of-type {
  margin-left: 0%;
}

.klevupsSlider > ul {
  position: relative;
  width: 100%;
  z-index: 1;
  font-size: 0;
  line-height: 0;
  background-color: transparent;
  border: none;
  margin: 0 auto;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
}

.klevupsSlider > ul > li {
  position: relative;
  display: inline-block;
  width: 50%;
  height: 100%;
  overflow: hidden;
  font-size: 15px;
  font-size: initial;
  line-height: normal;
  -moz-transition: all 0.7s cubic-bezier(0.4, 1.3, 0.65, 1);
  -o-transition: all 0.7s ease-out;
  -webkit-transition: all 0.7s cubic-bezier(0.4, 1.3, 0.65, 1);
  transition: all 0.7s cubic-bezier(0.4, 1.3, 0.65, 1);
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  vertical-align: top;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: normal;
  margin-bottom: 5px;
}

.klevupsSlider > .klevupsArrows {
  position: absolute;
  left: 0px;
  top: 45%;
  width: 100%;
  height: 24px;
  padding: 0px;
  z-index: 10;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.klevupsSlider > .klevupsArrows label {
  display: none;
  position: absolute;
  top: -50%;
  padding: 2px;
  cursor: pointer;
  -moz-transition: 0.15s;
  -o-transition: 0.15s;
  -webkit-transition: 0.15s;
  transition: 0.15s;
  height: 24px;
  width: 24px;
}

.klevupsSlider > .klevupsArrows label:before:hover {
  box-shadow: inset 1px -1px 0 0px #0097ef;
  margin: 0 0px;
}

.klevupsSlider > .klevupsArrows label:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 100%;
}

.klevupsSlider.infinity
  > input:first-of-type:checked
  ~ .klevupsArrows
  label:last-of-type,
.klevupsSlider
  > input:nth-of-type(1):checked
  ~ .klevupsArrows
  label:nth-of-type(0),
.klevupsSlider
  > input:nth-of-type(2):checked
  ~ .klevupsArrows
  label:nth-of-type(1),
.klevupsSlider
  > input:nth-of-type(3):checked
  ~ .klevupsArrows
  label:nth-of-type(2),
.klevupsSlider
  > input:nth-of-type(4):checked
  ~ .klevupsArrows
  label:nth-of-type(3),
.klevupsSlider
  > input:nth-of-type(5):checked
  ~ .klevupsArrows
  label:nth-of-type(4),
.klevupsSlider
  > input:nth-of-type(6):checked
  ~ .klevupsArrows
  label:nth-of-type(5),
.klevupsSlider
  > input:nth-of-type(7):checked
  ~ .klevupsArrows
  label:nth-of-type(6),
.klevupsSlider
  > input:nth-of-type(8):checked
  ~ .klevupsArrows
  label:nth-of-type(7),
.klevupsSlider
  > input:nth-of-type(9):checked
  ~ .klevupsArrows
  label:nth-of-type(8),
.klevupsSlider
  > input:nth-of-type(10):checked
  ~ .klevupsArrows
  label:nth-of-type(9),
.klevupsSlider
  > input:nth-of-type(11):checked
  ~ .klevupsArrows
  label:nth-of-type(12) {
  display: block;
  left: -2px;
  background-image: url(https://storage.googleapis.com/hclcommerce2-b2c-prod-gcs-ext/B2C/lincoln/BackGround_Images/Linclon_scss/icon-prev.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.klevupsSlider.infinity
  > input:last-of-type:checked
  ~ .klevupsArrows
  label:first-of-type,
.klevupsSlider
  > input:nth-of-type(1):checked
  ~ .klevupsArrows
  label:nth-of-type(2),
.klevupsSlider
  > input:nth-of-type(2):checked
  ~ .klevupsArrows
  label:nth-of-type(3),
.klevupsSlider
  > input:nth-of-type(3):checked
  ~ .klevupsArrows
  label:nth-of-type(4),
.klevupsSlider
  > input:nth-of-type(4):checked
  ~ .klevupsArrows
  label:nth-of-type(5),
.klevupsSlider
  > input:nth-of-type(5):checked
  ~ .klevupsArrows
  label:nth-of-type(6),
.klevupsSlider
  > input:nth-of-type(6):checked
  ~ .klevupsArrows
  label:nth-of-type(7),
.klevupsSlider
  > input:nth-of-type(7):checked
  ~ .klevupsArrows
  label:nth-of-type(8),
.klevupsSlider
  > input:nth-of-type(8):checked
  ~ .klevupsArrows
  label:nth-of-type(9),
.klevupsSlider
  > input:nth-of-type(9):checked
  ~ .klevupsArrows
  label:nth-of-type(10),
.klevupsSlider
  > input:nth-of-type(10):checked
  ~ .klevupsArrows
  label:nth-of-type(11),
.klevupsSlider
  > input:nth-of-type(11):checked
  ~ .klevupsArrows
  label:nth-of-type(12) {
  display: block;
  right: -2px;
  background-image: url(https://storage.googleapis.com/hclcommerce2-b2c-prod-gcs-ext/B2C/lincoln/BackGround_Images/Linclon_scss/icon-next.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.klevu-fluid .klevups-enabled.klevu-pt-rs-hover {
  width: 35%;
  font-family: inherit;
  padding: 10px 0;
}

.klevups-enabled .klevu-pt-hover-box,
.klevups-enabled .klevu-pt-rs-keywords {
  border-bottom: 0px solid #ddd;
}

.klevupsLeft {
  box-shadow: none;
  float: left;
  margin: 0 1% 0 0;
  width: 32%;
  padding: 0 0 0 1%;
}

.klevupsRight {
  height: auto;
  width: 62%;
  border: none;
  background: none;
  float: left;
  padding: 0px;
  margin: 0px;
  box-shadow: none;
  margin-left: 3%;
}

.klevu-pt-rs-hover.klevups-enabled.klevups-onlyRight {
  width: 28%;
}

.klevu-pt-rs-hover.klevups-enabled.klevups-onlyRight .klevupsRight {
  width: 94%;
  margin-left: 3%;
}

.klevu-pt-rs-hover.klevups-enabled.klevups-onlyLeft {
  width: 22%;
}

.klevu-pt-rs-hover.klevups-enabled.klevups-onlyLeft .klevupsLeft {
  width: 94%;
  margin-left: 3%;
}

.klevupsLeft strong {
  color: #333;
}

.klevupsLeft .klevu-pt-rs-keywords a {
  padding-left: 10px;
  color: #666;
}

.klevu-fluid .klevups-enabled.klevu-pt-rs-hover .klevups-heading {
  color: #333;
  display: block;
  padding: 0 4px;
  font-size: 12px;
  margin-right: 8px;
  font-weight: normal;
  padding-left: 8px;
  margin-top: 8px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.klevups-trendingProducts,
.klevups-youMaylikeProducts {
  margin: 5px auto;
  display: block;
  position: relative;
  width: 100%;
}

.klevups-Slideitem {
  width: 90%;
  max-width: 90%;
  margin: 5px 5%;
  box-sizing: border-box !important;
  padding: 0px;
  float: none;
  display: block;
  border: 0px solid #eee;
  text-decoration: none;
}

.klevups-Slideitem-name {
  font-size: 12px;
  text-decoration: none;
  color: #666;
  word-wrap: break-word;
  font-weight: 500;
  line-height: 18px;
  margin-top: 5px;
  margin-bottom: 0px;
  text-align: left;
}

a.klevups-Slideitem:hover,
.klevupsSlider a:hover {
  background: none;
  text-decoration: none;
  border: none;
}

.klevups-SlideitemImg {
  float: none;
  overflow: hidden;
  width: 100% !important;
  height: 165px !important;
  text-align: center;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  padding: 0px;
  border: 1px solid #eee;
  /*background: #efefef;*/
}

.klevups-SlideitemImg img {
  margin: 0px auto;
  max-width: 100% !important;
  max-height: 165px !important;
  width: auto;
  height: auto;
  border: 0;
  outline: 0;
  display: inline-block;
}

.klevupsSlider .navigation label {
  background: #b1b1b1;
}

/* css changes for grid view layout*/
.klevups-enabled.klevu-pt-rs-hover .klevu-pt-hover-box,
.klevups-enabled.klevu-pt-rs-hover .klevu-pt-rs-keywords {
  width: 100%;
}

.klevups-enabled.klevu-pt-rs-hover .klevu-pt-rs-keywords {
  margin-bottom: 15px;
}

.klevups-enabled.klevu-pt-rs-hover strong {
  width: 100%;
  color: #333;
  display: inline-block;
  padding: 4px;
  font-size: 12px;
  margin-right: 8px;
  font-weight: normal;
  padding-left: 8px;
  margin-top: 8px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.klevups-enabled.klevu-pt-rs-hover .klevu-pt-rs-keywords a {
  display: block;
  padding: 6px 10px 6px 10px;
  font-size: 13px;
  line-height: 14px;
  color: #666;
  text-decoration: none;
  width: 100%;
}

.klevups-enabled.klevu-pt-rs-hover a:hover {
  /*background-color: #f5f5f5;*/
}

.klevups-enabled.klevu-pt-rs-hover a:hover img {
  /*background-color: #f5f5f5;*/
}

/* ends changes for grid view layout*/
@media only screen and (max-width: 1280px) {
  .klevu-fluid .klevu-pt-rs-hover.klevups-enabled {
    width: 45% !important;
  }
  .klevups-SlideitemImg {
    height: 140px !important;
  }
  .klevups-SlideitemImg img {
    max-height: 140px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .klevu-fluid .klevu-pt-rs-hover.klevups-enabled {
    width: 62% !important;
  }
}

@media only screen and (max-width: 768px) {
  .klevu-fluid .klevu-pt-rs-hover.klevups-enabled {
    width: 72% !important;
  }
  .klevu-pt-rs-hover.klevups-enabled.klevups-onlyLeft {
    max-width: 300px !important;
  }
}

@media only screen and (max-width: 640px) {
  .klevu-fluid .klevu-pt-rs-hover.klevups-enabled {
    width: 55% !important;
  }
  .klevupsLeft {
    width: 100%;
    margin: 0px;
  }
  .klevupsRight {
    width: 100%;
    margin: 0px;
  }
  .klevu-pt-rs-hover.klevups-enabled .klevu-pt-hover-box {
    width: 100%;
  }
  .klevups-SlideitemImg {
    height: 120px !important;
  }
  .klevups-SlideitemImg img {
    max-height: 120px !important;
  }
}

@media only screen and (max-width: 480px) {
  .klevu-fluid .klevu-pt-rs-hover.klevups-enabled {
    width: 85% !important;
  }
  .klevups-SlideitemImg {
    height: 110px !important;
  }
  .klevups-SlideitemImg img {
    max-height: 110px !important;
  }
  .klevupsSlider > .klevupsArrows {
    top: 36%;
  }
}

/*ends CSS for personalized search*/
/*code to hide cms tab in klevu category page*/
.kuTabs a#kuOtherTab {
  display: none !important;
}

.kuCategoryPage .kuTabs a {
  border-bottom: 0px solid #c7c8ca;
  font-size: 14px;
  background: none !important;
}

/*code to hide Caegory filter box from shopify caegory page*/
.kuCategoryPage #kuFilterBox-category {
  display: none;
}

/* CSS for Klevu color/Image Swatches */
.klevuSwatches {
  width: 100%;
  margin: 0px;
  padding: 5px 2px;
  border: none;
  min-height: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  float: none;
  box-sizing: border-box;
  position: relative;
  text-align: center;
}

.klevuSwatchItem {
  display: inline-block;
  height: 28px;
  width: 28px;
  max-height: 28px;
  max-width: 28px;
  margin: 0px;
  border: none;
  padding: 0px;
  box-sizing: border-box;
  margin-bottom: 5px;
  line-height: 28px;
  vertical-align: top;
  margin-right: 3px;
  text-align: center;
}

.klevuSwatchItem a.klevuSwatchLink {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #eee;
  margin: 0px;
  padding: 0px;
  background-size: 28px auto !important;
  background-repeat: no-repeat !important;
  background-position: 0 center !important;
  border-radius: 0px;
  transition: all 0.3s;
  background-color: transparent;
  text-decoration: none;
  color: #000;
}

.klevuSwatchItem a.klevuSwatchLink:hover,
.klevuSwatchItem a.klevuSwatchLink:focus {
  text-decoration: none;
  box-shadow: none;
}

.klevuSwatchItem.klevuSwatchMore {
  font-size: 12px;
  font-weight: bold;
  color: #000;
  background-color: transparent;
  text-align: center;
  line-height: 20px;
}

.klevuSwatchItem.klevuSwatchMore .klevuSwatchLink {
  border: 1px solid #eee !important;
}

.klevuSwatchItem.klevuDefaultSwatch a.klevuSwatchLink {
  background-color: #eee !important;
  // background-image: url(https://js.klevu.com/klevu-js-v1/img-1-1/default-swatch.jpg) !important;
}

/* ends CSS for Klevu color/Image Swatches */
.klevu-banner-ad {
  clear: both;
  text-align: center;
  width: 100%;
  padding: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.klevu-banner-ad img {
  display: inline-block;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.kuBannerAd {
  text-align: center;
  width: 100%;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.kuBannerAd img {
  display: inline-block;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

/***********************************
*** Klevu Search Styles - AW
***********************************/
.klevu-fluid *:focus {
  outline: none;
}

.klevu-fluid .klevu-searching-area:before {
  width: 200vw;
  left: -40% !important;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}

.klevu-show-opt,
.klevu-hide-opt {
  text-align: left !important;
  margin-bottom: 15px !important;
}

#klevu-pt-rs-hover {
  -webkit-transform: translateY(56%);
  -moz-transform: translateY(56%);
  -ms-transform: translateY(56%);
  -o-transform: translateY(56%);
  transform: translateY(56%);
}

.search__filters-wrapper {
  overflow-y: auto;
}

#kuLandingProductsListUl.no-results {
  display: block;
}

[data-klevu-load-more-btn] {
  margin-bottom: 1em;
}

#esc-out-of-stock-inputs {
  margin-top: 0;
  margin-bottom: 50px !important;
}
#esc-out-of-stock-inputs .esc-out-of-stock-title {
  font-size: 18px;
  line-height: 24px;
}
#esc-out-of-stock-inputs .esc-out-of-stock-subtitle {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 30px;
}
#esc-out-of-stock-inputs .esc-sms-select-country-title {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}
#esc-out-of-stock-inputs .intl-tel-input {
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 15px;
}
#esc-out-of-stock-inputs .esc-btn {
  width: 100% !important;
}
#esc-out-of-stock-inputs .esc-email-input {
  width: 100% !important;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 15px;
  padding: 0px 8px;
}

.product-card__image [data-app="eastsideco_wishlist"] button {
  width: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  background: $white-color;
}

.product-card__image [data-app="eastsideco_wishlist"] svg {
  width: 25px !important;
}

.product-price-and-wishlist-container [data-app="eastsideco_wishlist"] button,
.wishlist-placeholder button {
  width: auto;
  min-width: 0;
  width: 40px;
  height: 40px;
  background: $white-color;
}
.product-price-and-wishlist-container
  [data-app="eastsideco_wishlist"]
  button:before,
.wishlist-placeholder button:before {
  display: none !important;
}

.product-price-and-wishlist-container [data-app="eastsideco_wishlist"] svg,
.wishlist-placeholder svg {
  width: 25px !important;
}

.product-price-and-wishlist-container [data-app="eastsideco_wishlist"] {
  display: none;
}
.product-price-and-wishlist-container [data-app="eastsideco_wishlist"].active {
  display: initial;
}

.wishlist-placeholder {
  cursor: not-allowed;
}
.wishlist-placeholder button {
  cursor: not-allowed;
}
.wishlist-placeholder.hidden {
  display: none;
}

.esc-wishlist {
  padding-top: 0px;
}
.esc-wishlist .esc-wishlist-share .btn {
  margin-bottom: 15px;
}
.esc-wishlist .esc-wishlist-add {
  margin-top: 15px;
}
.esc-wishlist .esc-wishlists {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 25px;
  row-gap: 60px;
  margin-top: 50px;
  align-items: unset;
}
@media (max-width: 1000px) {
  .esc-wishlist .esc-wishlists {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .esc-wishlist .esc-wishlists {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }
}
@media (max-width: 375px) {
  .esc-wishlist .esc-wishlists {
    grid-template-columns: repeat(1, 1fr);
  }
}
.esc-wishlist .esc-wishlist-item {
  flex: unset;
  min-width: 0;
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  border-radius: 0;
}
.esc-wishlist .esc-wishlist-item img {
  height: 200px;
  object-fit: cover;
}
.esc-wishlist .esc-wishlist-item-name {
  margin-top: 10px;
}
.esc-wishlist .esc-wishlist-item-actions {
  margin-top: 15px;
}
.esc-wishlist .esc-wishlist-item-actions .btn {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
}
.esc-wishlist .form-control {
  height: 50px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  color: $theme-color;
  border-radius: 3px;
  border: 1px solid #6e6e6e;
  width: 100%;
  margin-top: 10px;
}
.esc-wishlist .form-control::-webkit-input-placeholder {
  color: #4d4d4d;
}
.esc-wishlist .form-control:-moz-placeholder {
  color: #4d4d4d;
}
.esc-wishlist .form-control::-moz-placeholder {
  color: #4d4d4d;
}
.esc-wishlist .form-control:-ms-input-placeholder {
  color: #4d4d4d;
}
.esc-wishlist .form-control::placeholder {
  color: #4d4d4d;
}
.esc-wishlist .esc-wishlist-products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 25px;
  row-gap: 60px;
  margin-top: 50px;
}
@media (max-width: 1000px) {
  .esc-wishlist .esc-wishlist-products {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .esc-wishlist .esc-wishlist-products {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }
}
@media (max-width: 375px) {
  .esc-wishlist .esc-wishlist-products {
    grid-template-columns: repeat(1, 1fr);
  }
}
.esc-wishlist .esc-wishlist-product-item {
  flex: unset;
  min-width: 0;
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  border-radius: 0;
}
.esc-wishlist .esc-wishlist-product-item-image-container {
  width: 100%;
  height: 150px;
  padding: 0;
}
.esc-wishlist .esc-wishlist-product-item-image-container a {
  width: 100%;
  height: 100%;
  padding: 0;
}
.esc-wishlist .esc-wishlist-product-item-image-container a img {
  width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}
.esc-wishlist .esc-wishlist-product-item-title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  padding: 0 !important;
  margin-top: 15px;
  height: 45px;
  overflow: hidden;
}
.esc-wishlist .esc-wishlist-product-item-title a {
  font-weight: 400 !important;
}
.esc-wishlist .esc-wishlist-product-item-price {
  padding: 0 !important;
  margin-top: 10px;
}
.esc-wishlist .esc-wishlist-product-item-actions {
  margin-top: 15px !important;
}
.esc-wishlist .esc-wishlist-product-item-actions .btn {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
}
.esc-wishlist
  .esc-wishlist-product-item-actions
  .esc-wishlist-product-item-note
  textarea {
  text-align: center;
}

[data-app="eastsideco_wishlist"] .esc-wishlist-share-email-form {
  max-width: 400px;
  margin: 0 auto;
}
[data-app="eastsideco_wishlist"]
  .esc-wishlist-share-email-form
  .esc-wishlist-share-message {
  text-align: left;
  margin-bottom: 20px;
  font-size: 12px;
}
[data-app="eastsideco_wishlist"]
  .esc-wishlist-share-email-form
  .esc-wishlist-share-message.esc-wishlist-share-message--error {
  color: #cc452a;
}
[data-app="eastsideco_wishlist"] .esc-wishlist-share-email-form h2 {
  text-align: left;
  width: 100%;
  margin-bottom: 15px;
}
[data-app="eastsideco_wishlist"]
  .esc-wishlist-share-email-form
  .esc-wishlist-share-email-form-input {
  width: 100%;
}
[data-app="eastsideco_wishlist"]
  .esc-wishlist-share-email-form
  .esc-wishlist-share-email-form-input
  label {
  text-align: left;
  width: 100%;
}
[data-app="eastsideco_wishlist"]
  .esc-wishlist-share-email-form
  .esc-wishlist-share-email-form-input
  input,
[data-app="eastsideco_wishlist"]
  .esc-wishlist-share-email-form
  .esc-wishlist-share-email-form-input
  textarea {
  text-align: left;
  width: 100%;
  height: 50px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  color: $theme-color;
  border-radius: 3px;
  border: 1px solid #6e6e6e;
}
[data-app="eastsideco_wishlist"]
  .esc-wishlist-share-email-form
  .esc-wishlist-share-email-form-input
  textarea {
  height: 100px;
  padding: 12px 14px;
}
[data-app="eastsideco_wishlist"]
  .esc-wishlist-share-email-form
  .esc-wishlist-share-email-form-input {
  margin-top: 10px;
}
[data-app="eastsideco_wishlist"]
  .esc-wishlist-share-email-form
  .esc-wishlist-share-email-form-input
  button {
  width: 100%;
}

.nosto_element {
  width: 1299px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 100% !important;
}
@media (max-width: 1024px) {
  .nosto_element {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 600px) {
  .nosto_element {
    padding-left: 30px;
    padding-right: 30px;
  }
}

#frontpage-nosto-1 .nosto-block,
#frontpage-nosto-2 .nosto-block,
#frontpage-nosto-3 .nosto-block,
#frontpage-nosto-4 .nosto-block,
#productpage-nosto-1 .nosto-block,
#productpage-nosto-2 .nosto-block,
#productpage-nosto-3 .nosto-block,
#cartpage-nosto-1 .nosto-block,
#cartpage-nosto-2 .nosto-block,
#cartpage-nosto-3 .nosto-block,
#categorypage-nosto-1 .nosto-block,
#categorypage-nosto-2 .nosto-block,
#categorypage-nosto-3 .nosto-block,
#notfound-nosto-1 .nosto-block,
#notfound-nosto-2 .nosto-block,
#notfound-nosto-3 .nosto-block,
#searchpage-nosto-1 .nosto-block,
#searchpage-nosto-2 .nosto-block,
#searchpage-nosto-3 .nosto-block {
  padding: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 50px !important;
  padding-bottom: 0px !important;
}

#frontpage-nosto-1 .nosto-block .nosto-header,
#frontpage-nosto-2 .nosto-block .nosto-header,
#frontpage-nosto-3 .nosto-block .nosto-header,
#frontpage-nosto-4 .nosto-block .nosto-header,
#productpage-nosto-1 .nosto-block .nosto-header,
#productpage-nosto-2 .nosto-block .nosto-header,
#productpage-nosto-3 .nosto-block .nosto-header,
#cartpage-nosto-1 .nosto-block .nosto-header,
#cartpage-nosto-2 .nosto-block .nosto-header,
#cartpage-nosto-3 .nosto-block .nosto-header,
#categorypage-nosto-1 .nosto-block .nosto-header,
#categorypage-nosto-2 .nosto-block .nosto-header,
#categorypage-nosto-3 .nosto-block .nosto-header,
#notfound-nosto-1 .nosto-block .nosto-header,
#notfound-nosto-2 .nosto-block .nosto-header,
#notfound-nosto-3 .nosto-block .nosto-header,
#searchpage-nosto-1 .nosto-block .nosto-header,
#searchpage-nosto-2 .nosto-block .nosto-header,
#searchpage-nosto-3 .nosto-block .nosto-header {
  text-align: left !important;
  color: #4d4d4d !important;
  font-family: var(--fmc-font--proxima-nova) !important;
  font-size: 24px !important;
  line-height: 34px !important;
  font-weight: 300 !important;
  letter-spacing: 3px !important;
  text-transform: uppercase !important;
  margin-bottom: 40px !important;
  border-top: 1px solid #b3b3b3 !important;
  padding: 0 !important;
  padding-top: 45px !important;
}
@media (max-width: 500px) {
  #frontpage-nosto-1 .nosto-block .nosto-header,
  #frontpage-nosto-2 .nosto-block .nosto-header,
  #frontpage-nosto-3 .nosto-block .nosto-header,
  #frontpage-nosto-4 .nosto-block .nosto-header,
  #productpage-nosto-1 .nosto-block .nosto-header,
  #productpage-nosto-2 .nosto-block .nosto-header,
  #productpage-nosto-3 .nosto-block .nosto-header,
  #cartpage-nosto-1 .nosto-block .nosto-header,
  #cartpage-nosto-2 .nosto-block .nosto-header,
  #cartpage-nosto-3 .nosto-block .nosto-header,
  #categorypage-nosto-1 .nosto-block .nosto-header,
  #categorypage-nosto-2 .nosto-block .nosto-header,
  #categorypage-nosto-3 .nosto-block .nosto-header,
  #notfound-nosto-1 .nosto-block .nosto-header,
  #notfound-nosto-2 .nosto-block .nosto-header,
  #notfound-nosto-3 .nosto-block .nosto-header,
  #searchpage-nosto-1 .nosto-block .nosto-header,
  #searchpage-nosto-2 .nosto-block .nosto-header,
  #searchpage-nosto-3 .nosto-block .nosto-header {
    margin-bottom: 30px !important;
  }
}

#frontpage-nosto-1 .nosto-block .nosto-list,
#frontpage-nosto-2 .nosto-block .nosto-list,
#frontpage-nosto-3 .nosto-block .nosto-list,
#frontpage-nosto-4 .nosto-block .nosto-list,
#productpage-nosto-1 .nosto-block .nosto-list,
#productpage-nosto-2 .nosto-block .nosto-list,
#productpage-nosto-3 .nosto-block .nosto-list,
#cartpage-nosto-1 .nosto-block .nosto-list,
#cartpage-nosto-2 .nosto-block .nosto-list,
#cartpage-nosto-3 .nosto-block .nosto-list,
#categorypage-nosto-1 .nosto-block .nosto-list,
#categorypage-nosto-2 .nosto-block .nosto-list,
#categorypage-nosto-3 .nosto-block .nosto-list,
#notfound-nosto-1 .nosto-block .nosto-list,
#notfound-nosto-2 .nosto-block .nosto-list,
#notfound-nosto-3 .nosto-block .nosto-list,
#searchpage-nosto-1 .nosto-block .nosto-list,
#searchpage-nosto-2 .nosto-block .nosto-list,
#searchpage-nosto-3 .nosto-block .nosto-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 60px;
  row-gap: 25px;
}
@media (max-width: 1024px) {
  #frontpage-nosto-1 .nosto-block .nosto-list,
  #frontpage-nosto-2 .nosto-block .nosto-list,
  #frontpage-nosto-3 .nosto-block .nosto-list,
  #frontpage-nosto-4 .nosto-block .nosto-list,
  #productpage-nosto-1 .nosto-block .nosto-list,
  #productpage-nosto-2 .nosto-block .nosto-list,
  #productpage-nosto-3 .nosto-block .nosto-list,
  #cartpage-nosto-1 .nosto-block .nosto-list,
  #cartpage-nosto-2 .nosto-block .nosto-list,
  #cartpage-nosto-3 .nosto-block .nosto-list,
  #categorypage-nosto-1 .nosto-block .nosto-list,
  #categorypage-nosto-2 .nosto-block .nosto-list,
  #categorypage-nosto-3 .nosto-block .nosto-list,
  #notfound-nosto-1 .nosto-block .nosto-list,
  #notfound-nosto-2 .nosto-block .nosto-list,
  #notfound-nosto-3 .nosto-block .nosto-list,
  #searchpage-nosto-1 .nosto-block .nosto-list,
  #searchpage-nosto-2 .nosto-block .nosto-list,
  #searchpage-nosto-3 .nosto-block .nosto-list {
    column-gap: 25px;
  }
}
@media (max-width: 900px) {
  #frontpage-nosto-1 .nosto-block .nosto-list,
  #frontpage-nosto-2 .nosto-block .nosto-list,
  #frontpage-nosto-3 .nosto-block .nosto-list,
  #frontpage-nosto-4 .nosto-block .nosto-list,
  #productpage-nosto-1 .nosto-block .nosto-list,
  #productpage-nosto-2 .nosto-block .nosto-list,
  #productpage-nosto-3 .nosto-block .nosto-list,
  #cartpage-nosto-1 .nosto-block .nosto-list,
  #cartpage-nosto-2 .nosto-block .nosto-list,
  #cartpage-nosto-3 .nosto-block .nosto-list,
  #categorypage-nosto-1 .nosto-block .nosto-list,
  #categorypage-nosto-2 .nosto-block .nosto-list,
  #categorypage-nosto-3 .nosto-block .nosto-list,
  #notfound-nosto-1 .nosto-block .nosto-list,
  #notfound-nosto-2 .nosto-block .nosto-list,
  #notfound-nosto-3 .nosto-block .nosto-list,
  #searchpage-nosto-1 .nosto-block .nosto-list,
  #searchpage-nosto-2 .nosto-block .nosto-list,
  #searchpage-nosto-3 .nosto-block .nosto-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 500px) {
  #frontpage-nosto-1 .nosto-block .nosto-list,
  #frontpage-nosto-2 .nosto-block .nosto-list,
  #frontpage-nosto-3 .nosto-block .nosto-list,
  #frontpage-nosto-4 .nosto-block .nosto-list,
  #productpage-nosto-1 .nosto-block .nosto-list,
  #productpage-nosto-2 .nosto-block .nosto-list,
  #productpage-nosto-3 .nosto-block .nosto-list,
  #cartpage-nosto-1 .nosto-block .nosto-list,
  #cartpage-nosto-2 .nosto-block .nosto-list,
  #cartpage-nosto-3 .nosto-block .nosto-list,
  #categorypage-nosto-1 .nosto-block .nosto-list,
  #categorypage-nosto-2 .nosto-block .nosto-list,
  #categorypage-nosto-3 .nosto-block .nosto-list,
  #notfound-nosto-1 .nosto-block .nosto-list,
  #notfound-nosto-2 .nosto-block .nosto-list,
  #notfound-nosto-3 .nosto-block .nosto-list,
  #searchpage-nosto-1 .nosto-block .nosto-list,
  #searchpage-nosto-2 .nosto-block .nosto-list,
  #searchpage-nosto-3 .nosto-block .nosto-list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 35px;
  }
}

#frontpage-nosto-1 .nosto-block .nosto-list-item,
#frontpage-nosto-2 .nosto-block .nosto-list-item,
#frontpage-nosto-3 .nosto-block .nosto-list-item,
#frontpage-nosto-4 .nosto-block .nosto-list-item,
#productpage-nosto-1 .nosto-block .nosto-list-item,
#productpage-nosto-2 .nosto-block .nosto-list-item,
#productpage-nosto-3 .nosto-block .nosto-list-item,
#cartpage-nosto-1 .nosto-block .nosto-list-item,
#cartpage-nosto-2 .nosto-block .nosto-list-item,
#cartpage-nosto-3 .nosto-block .nosto-list-item,
#categorypage-nosto-1 .nosto-block .nosto-list-item,
#categorypage-nosto-2 .nosto-block .nosto-list-item,
#categorypage-nosto-3 .nosto-block .nosto-list-item,
#notfound-nosto-1 .nosto-block .nosto-list-item,
#notfound-nosto-2 .nosto-block .nosto-list-item,
#notfound-nosto-3 .nosto-block .nosto-list-item,
#searchpage-nosto-1 .nosto-block .nosto-list-item,
#searchpage-nosto-2 .nosto-block .nosto-list-item,
#searchpage-nosto-3 .nosto-block .nosto-list-item {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 0 !important;
}
#frontpage-nosto-1 .nosto-block .nosto-list-item .nosto-image-container:before,
#frontpage-nosto-1 .nosto-block .nosto-list-item .nosto-image-container:after,
#frontpage-nosto-2 .nosto-block .nosto-list-item .nosto-image-container:before,
#frontpage-nosto-2 .nosto-block .nosto-list-item .nosto-image-container:after,
#frontpage-nosto-3 .nosto-block .nosto-list-item .nosto-image-container:before,
#frontpage-nosto-3 .nosto-block .nosto-list-item .nosto-image-container:after,
#frontpage-nosto-4 .nosto-block .nosto-list-item .nosto-image-container:before,
#frontpage-nosto-4 .nosto-block .nosto-list-item .nosto-image-container:after,
#productpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-image-container:before,
#productpage-nosto-1 .nosto-block .nosto-list-item .nosto-image-container:after,
#productpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-image-container:before,
#productpage-nosto-2 .nosto-block .nosto-list-item .nosto-image-container:after,
#productpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-image-container:before,
#productpage-nosto-3 .nosto-block .nosto-list-item .nosto-image-container:after,
#cartpage-nosto-1 .nosto-block .nosto-list-item .nosto-image-container:before,
#cartpage-nosto-1 .nosto-block .nosto-list-item .nosto-image-container:after,
#cartpage-nosto-2 .nosto-block .nosto-list-item .nosto-image-container:before,
#cartpage-nosto-2 .nosto-block .nosto-list-item .nosto-image-container:after,
#cartpage-nosto-3 .nosto-block .nosto-list-item .nosto-image-container:before,
#cartpage-nosto-3 .nosto-block .nosto-list-item .nosto-image-container:after,
#categorypage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-image-container:before,
#categorypage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-image-container:after,
#categorypage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-image-container:before,
#categorypage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-image-container:after,
#categorypage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-image-container:before,
#categorypage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-image-container:after,
#notfound-nosto-1 .nosto-block .nosto-list-item .nosto-image-container:before,
#notfound-nosto-1 .nosto-block .nosto-list-item .nosto-image-container:after,
#notfound-nosto-2 .nosto-block .nosto-list-item .nosto-image-container:before,
#notfound-nosto-2 .nosto-block .nosto-list-item .nosto-image-container:after,
#notfound-nosto-3 .nosto-block .nosto-list-item .nosto-image-container:before,
#notfound-nosto-3 .nosto-block .nosto-list-item .nosto-image-container:after,
#searchpage-nosto-1 .nosto-block .nosto-list-item .nosto-image-container:before,
#searchpage-nosto-1 .nosto-block .nosto-list-item .nosto-image-container:after,
#searchpage-nosto-2 .nosto-block .nosto-list-item .nosto-image-container:before,
#searchpage-nosto-2 .nosto-block .nosto-list-item .nosto-image-container:after,
#searchpage-nosto-3 .nosto-block .nosto-list-item .nosto-image-container:before,
#searchpage-nosto-3 .nosto-block .nosto-list-item .nosto-image-container:after {
  background-size: cover !important;
}
#frontpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#frontpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#frontpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#frontpage-nosto-4
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#productpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#productpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#productpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#cartpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#cartpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#cartpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#categorypage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#categorypage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#categorypage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#notfound-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#notfound-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#notfound-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#searchpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#searchpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand,
#searchpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-brand {
  color: #4d4d4d !important;
  font-family: var(--fmc-font--proxima-nova) !important;
  font-size: 11px !important;
  line-height: 11px !important;
  font-weight: 500 !important;
  letter-spacing: 1.1px !important;
  margin-bottom: 5px !important;
}
#frontpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#frontpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#frontpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#frontpage-nosto-4
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#productpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#productpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#productpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#cartpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#cartpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#cartpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#categorypage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#categorypage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#categorypage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#notfound-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#notfound-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#notfound-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#searchpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#searchpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name,
#searchpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-name {
  color: #4d4d4d !important;
  font-family: var(--fmc-font--proxima-nova) !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: normal !important;
  letter-spacing: 1.6px !important;
  margin-bottom: 15px !important;
  display: block !important;
  max-height: 40px !important;
  overflow: hidden !important;
}
#frontpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#frontpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#frontpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#frontpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#frontpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#frontpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#frontpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#frontpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#frontpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#frontpage-nosto-4
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#frontpage-nosto-4
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#frontpage-nosto-4
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#productpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#productpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#productpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#productpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#productpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#productpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#productpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#productpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#productpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#cartpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#cartpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#cartpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#cartpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#cartpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#cartpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#cartpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#cartpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#cartpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#categorypage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#categorypage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#categorypage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#categorypage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#categorypage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#categorypage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#categorypage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#categorypage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#categorypage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#notfound-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#notfound-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#notfound-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#notfound-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#notfound-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#notfound-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#notfound-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#notfound-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#notfound-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#searchpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#searchpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#searchpage-nosto-1
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#searchpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#searchpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#searchpage-nosto-2
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span,
#searchpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price,
#searchpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  a,
#searchpage-nosto-3
  .nosto-block
  .nosto-list-item
  .nosto-product-info
  .nosto-product-price
  span {
  color: $theme-color !important;
  font-family: var(--fmc-font--proxima-nova) !important;
  font-size: 18px !important;
  line-height: 18px !important;
  font-weight: 300 !important;
  letter-spacing: 2px !important;
  margin-top: 0px !important;
}

#productpage-nosto-1 .nosto-block .nosto-list {
  grid-template-columns: repeat(4, 1fr);
  column-gap: 25px;
  row-gap: 25px;
}
@media (max-width: 900px) {
  #productpage-nosto-1 .nosto-block .nosto-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 500px) {
  #productpage-nosto-1 .nosto-block .nosto-list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 35px;
  }
}

#cartpage-nosto-1 .nosto-block .nosto-header {
  border-top: none !important;
  padding-top: 0 !important;
}

#fitting-widgets-container {
  width: 100%;
}

.fitting-method-buttons {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.fitting-method-buttons__button {
  cursor: pointer;
  width: calc(50% - 10px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  line-height: 16px;
  padding: 20px;
  border: 1px solid #6e6e6e;
  border-radius: 3px;
  opacity: 0.7;
  background: #eeeeee;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.fitting-method-buttons__button.active {
  opacity: 1;
  background: $white-color;
}
.fitting-method-buttons__button:hover {
  opacity: 1;
  background: $white-color;
}

.pickupPagination {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.pickupPagination li {
  margin-bottom: 5px;
}

.fitting-booking #storePickupApp .checkoutMethodsContainer {
  display: none;
}

.fitting-booking #storePickupApp .shipping_note {
  display: none;
}

#sitemapper {
  width: 1299px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 1024px) {
  #sitemapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 600px) {
  #sitemapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

#sitemapper h1 {
  text-align: center;
}

#sitemapper p:first-of-type {
  text-align: center;
  margin-bottom: 100px;
}
@media (max-width: 768px) {
  #sitemapper p:first-of-type {
    margin-bottom: 50px;
  }
}

#sitemapper h2 {
  margin-bottom: 30px;
}

#sitemapper .sitemapper-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px 15px;
  margin-bottom: 100px;
  position: relative;
}
#sitemapper .sitemapper-list:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -50px;
  background: $theme-color;
}
@media (max-width: 768px) {
  #sitemapper .sitemapper-list:after {
    bottom: -25px;
  }
}
@media (max-width: 1024px) {
  #sitemapper .sitemapper-list {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 850px) {
  #sitemapper .sitemapper-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  #sitemapper .sitemapper-list {
    margin-bottom: 50px;
  }
}
@media (max-width: 650px) {
  #sitemapper .sitemapper-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

#sitemapper > .sitemapper-list:last-of-type {
  display: initial;
}
#sitemapper > .sitemapper-list:last-of-type > li {
  display: initial;
  text-align: left;
  background: transparent;
  border: none;
  border-radius: 0;
}
#sitemapper > .sitemapper-list:last-of-type > li:hover {
  background: transparent;
}
#sitemapper > .sitemapper-list:last-of-type > li:hover > a {
  color: $theme-color;
}

#sitemapper .sitemapper-list li {
  width: 100%;
  background: $white-color;
  border: 1px solid $theme-color;
  border-radius: 5px;
  min-height: 50px;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
#sitemapper .sitemapper-list li a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
  color: $theme-color;
  text-decoration: none;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 15px;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
#sitemapper .sitemapper-list li:hover {
  background: $theme-color;
  border-color: $white-color;
}
#sitemapper .sitemapper-list li:hover > a {
  color: $white-color;
}

#sitemapper p:last-of-type {
  display: none;
}

.boost-pfs-filter-collection-header-wrapper {
  background: #f6f7f7 !important;
  box-shadow: none !important;
}

.boost-pfs-filter-collection-header-wrapper h1 {
  font-size: 40px;
  letter-spacing: normal;
  font-weight: 900;
  text-transform: initial;
}
@media (max-width: 1024px) {
  .boost-pfs-filter-collection-header-wrapper h1 {
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  .boost-pfs-filter-collection-header-wrapper h1 {
    font-size: 32px;
  }
}
@media (max-width: 600px) {
  .boost-pfs-filter-collection-header-wrapper h1 {
    font-size: 24px;
  }
}

.boost-pfs-filter-collection-description {
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: normal;
}

#boost-collection-products .boost-pfs-quickview-btn {
  height: 50px;
  background-color: $white-color;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 600;
  letter-spacing: normal;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
#boost-collection-products .boost-pfs-quickview-btn span {
  display: none;
}
#boost-collection-products .boost-pfs-quickview-btn:before {
  content: "";
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln.svg?v=132685267715316169101664033355);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-left: 5px;
}
#boost-collection-products .boost-pfs-quickview-btn:hover::before {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln-white.svg?v=95280333402642282991664033348);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

#boost-collection-pagination [class*="boost-collection-pagination__item"] {
  font-size: 17px;
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 600;
}

#boost-collection-pagination
  [class*="boost-collection-pagination__arrow"]
  > span {
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 600;
}

@media (max-width: 768px) {
  #boost-collection-toolbar .boost-pfs-filter-default-toolbar-inner {
    flex-wrap: wrap;
    display: flex;
    grid-gap: 0;
  }
  #boost-collection-toolbar .boost-pfs-filter-filter-dropdown {
    width: 100%;
  }
  #boost-collection-toolbar .boost-pfs-filter-toolbar-item {
    width: 50%;
    display: inline-flex;
    height: 50px;
  }
  #boost-collection-toolbar .boost-pfs-filter-toolbar-item [class*="btn"] {
    min-width: auto;
    width: 100%;
  }
  #boost-collection-toolbar
    .boost-pfs-filter-toolbar-item.boost-pfs-filter-custom-sorting {
    background-color: #efefef;
    padding: 0 25px !important;
    border-bottom: 1px solid $theme-color;
    margin-right: 0;
    margin-left: auto;
    justify-content: center;
  }
}

.collection-filter-item__title {
  font-size: 12px;
}

.boost-collection-filter__wrapper {
  overflow: auto !important;
}

.collection-filter-open {
  overflow: hidden;
}

button.boost-collection-filter-trigger--has-active-filters[class*="btn"]:not(.boost-pfs-quickview-btn):after {
  content: attr(data-active-filters);
  width: 19px;
  height: 19px;
  background-image: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  color: $white-color;
  background: $theme-hover-color;
}

/* ---------------------------------------------
    Sorting - Trigger
--------------------------------------------- */
#boost-collection-toolbar .boost-pfs-filter-top-sorting-wrapper {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid #969ca0;
  background: #efefef;
}

/* ---------------------------------------------
    Sorting - Dropdown
--------------------------------------------- */
#boost-collection-toolbar .boost-pfs-filter-filter-dropdown {
  border: 0;
  background: #efefef;
}

/* ---------------------------------------------
    Toolbar items / buttons
--------------------------------------------- */
.boost-pfs-filter-default-toolbar__title {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 4px;
}

.boost-collection-filter-triggers__row {
  margin-top: 0 !important;
}

.boost-collection-pagination-count__option--active {
  background-color: $theme-color;
}

.boost-collection-pagination-count__option {
  border-color: $theme-color;
}

.boost-pfs-quickview-btn:hover {
  background-color: $theme-color !important;
  color: #fff !important;
  border: 0 !important;
}

#boost-collection-products .boost-pfs-quickview-btn {
  border-radius: 0;
}

/* ---------------------------------------------
    Total results
--------------------------------------------- */
.boost-pfs-filter-total-product {
  font-size: 13px;
  font-weight: 300;
  letter-spacing: normal;
  line-height: normal;
}

/* ---------------------------------------------
    Prev / Next arrows
--------------------------------------------- */
#boost-collection-pagination [class*="boost-collection-pagination__arrow"] {
  font-weight: 600;
  font-size: 16px;
  height: 50px;
  border: 1px solid $theme-color;
  border-radius: 0;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
}
@media (max-width: 1024px) {
  #boost-collection-pagination [class*="boost-collection-pagination__arrow"] {
    font-size: 0px;
    width: 42px;
    height: 42px;
    border-radius: 100px;
    background: transparent;
    border: 2px solid $theme-color;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  #boost-collection-pagination
    [class*="boost-collection-pagination__arrow"]
    svg
    * {
    fill: $theme-color;
  }
}
@media (max-width: 1024px) {
  #boost-collection-pagination
    [class*="boost-collection-pagination__arrow"]:hover {
    background: transparent !important;
    border-color: $theme-hover-color;
  }
  #boost-collection-pagination
    [class*="boost-collection-pagination__arrow"]:hover
    svg
    * {
    fill: $theme-hover-color;
  }
}
#boost-collection-pagination
  [class*="boost-collection-pagination__arrow"].boost-pfs-filter-pagination-disabled {
  opacity: 1;
}
@media (max-width: 1024px) {
  #boost-collection-pagination
    [class*="boost-collection-pagination__arrow"].boost-pfs-filter-pagination-disabled {
    background: transparent;
    border: 2px solid #6e6e6e;
  }
  #boost-collection-pagination
    [class*="boost-collection-pagination__arrow"].boost-pfs-filter-pagination-disabled
    svg
    * {
    fill: #6e6e6e;
  }
}

#boost-collection-pagination .boost-collection-pagination__arrow--prev {
  margin-right: 10px;
}
@media (max-width: 1024px) {
  #boost-collection-pagination .boost-collection-pagination__arrow--prev svg {
    transform: initial;
  }
}

#boost-collection-pagination .boost-collection-pagination__arrow--next {
  margin-left: 10px;
}
@media (max-width: 1024px) {
  #boost-collection-pagination .boost-collection-pagination__arrow--next svg {
    transform: rotate(180deg);
  }
}

.mobileArrow {
  display: none !important;
}
@media (max-width: 1024px) {
  .mobileArrow {
    display: flex !important;
  }
}

.desktopArrow {
  display: none !important;
}
@media (min-width: 1025px) {
  .desktopArrow {
    display: flex !important;
  }
}

/* ---------------------------------------------
    Number page buttons
--------------------------------------------- */
#boost-collection-pagination [class*="boost-collection-pagination__item"] {
  height: 50px;
  line-height: 26px;
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  #boost-collection-pagination [class*="boost-collection-pagination__item"] {
    height: auto;
    width: auto;
  }
}
#boost-collection-pagination
  [class*="boost-collection-pagination__item"]
  > span,
#boost-collection-pagination [class*="boost-collection-pagination__item"] > a {
  display: block;
  padding: 0;
  margin: 0;
}
#boost-collection-pagination [class*="boost-collection-pagination__item"] > a {
  color: #6e6e6e;
}
#boost-collection-pagination
  [class*="boost-collection-pagination__item"]
  > a:hover {
  text-shadow: none;
}

/* ---------------------------------------------
    Active page
--------------------------------------------- */
#boost-collection-pagination .boost-pfs-filter-pagination-active {
  color: $theme-color;
}
@media (min-width: 1025px) {
  #boost-collection-pagination .boost-pfs-filter-pagination-active {
    border: 0;
    color: #fff;
  }
}

#boost-collection-pagination .boost-collection-pagination__item--active {
  background-color: $theme-color;
  border-radius: 0;
}
@media (max-width: 1024px) {
  #boost-collection-pagination .boost-collection-pagination__item--active {
    background-color: transparent;
  }
}

.boost-collection-filter__title {
  font-size: 24px;
}
@media (max-width: 768px) {
  .boost-collection-filter__title {
    border-bottom: none;
  }
}

/* ---------------------------------------------
    Filter Group - title
--------------------------------------------- */
.boost-pfs-filter-tree-v
  .boost-pfs-filter-option
  .boost-pfs-filter-option-title
  .boost-pfs-filter-option-title-heading {
  font-size: 17px;
  line-height: 17px;
  font-weight: 300;
}

#boost-collection-filter .boost-pfs-check-box {
  border-color: $theme-color;
  border-width: 2px;
  border-radius: 0;
}

#boost-collection-filter .selected .boost-pfs-check-box {
  border-color: $theme-hover-color;
  background-color: $theme-hover-color;
}
#boost-collection-filter .selected .boost-pfs-check-box:before {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-tick-white.svg?v=9471714202977898581664033354);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.boost-pfs-filter-tree-v .boost-pfs-filter-option-title-text:after {
  content: "";
}
@media (min-width: 768px) {
  .boost-pfs-filter-tree-v .boost-pfs-filter-option-title-text:after {
    /* background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-minus.svg?v=10146345874930585381664033355);*/
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
@media (max-width: 768px) {
  .boost-pfs-filter-tree-v .boost-pfs-filter-option-title-text:after {
    /* background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-plus.svg?v=86523822998730017571664033353);*/
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    transform: scale(1.7);
  }
}

.boost-pfs-filter-tree-v
  .boost-pfs-filter-option-collapsed
  .boost-pfs-filter-option-title-text:after {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-minus.svg?v=10146345874930585381664033355);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

/* ---------------------------------------------
    Filter menu buttons
--------------------------------------------- */
[class*="boost-collection-filter__button"]:nth-child(2) {
  margin-left: 0;
  width: 100%;
}
@media (max-width: 768px) {
  [class*="boost-collection-filter__button"]:nth-child(2) {
    width: auto;
    margin-top: 25px;
  }
}

@media (max-width: 768px) {
  .boost-collection-filter__buttons {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .boost-pfs-filter-option-content {
    padding-left: 0;
  }
}

.kuGridView ul li .kuSalePrice {
  font-size: 17px;
}

.kuGridView ul li .kuName a {
  font-size: 17px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--fmc-font--proxima-nova);
}

/* ---------------------------------------------
    Components
--------------------------------------------- */
[data-preloader] [data-preload] {
  -webkit-transition: 0.2s all ease-in-out filter 750ms ease 100ms;
  -moz-transition: 0.2s all ease-in-out filter 750ms ease 100ms;
  -ms-transition: 0.2s all ease-in-out filter 750ms ease 100ms;
  -o-transition: 0.2s all ease-in-out filter 750ms ease 100ms;
  transition: 0.2s all ease-in-out filter 750ms ease 100ms;
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -o-filter: blur(15px);
  filter: blur(15px);
}
[data-preloader] [data-preload][data-preload="loaded"] {
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  filter: blur(0px);
}

.accordion-header {
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  border-bottom: 1px solid #bbbbbb;
}
.accordion-header:nth-of-type(n + 2) {
  margin-top: 37px;
}

.accordion-content {
  padding-top: 24px;
  line-height: 22px;
  font-size: 12px;
}
.accordion-content:not(.open) {
  display: none;
}

.accordion {
  margin-bottom: 20px;
}
.accordion .accordion-item.open {
  border-bottom: 1px solid #bbbbbb;
}
.accordion .accordion-item.open .accordion-header {
  border-bottom: 1px solid #fff;
  margin-bottom: 0px !important;
}
.accordion .accordion-item.open .accordion-header .minus {
  display: block;
}
.accordion .accordion-item.open .accordion-header .plus {
  display: none;
}
.accordion .accordion-item.open .accordion-content {
  margin-top: 0px !important;
}
.accordion .accordion-item.hide {
  display: none;
}
.accordion .accordion-item:not(.open) .accordion-header .minus {
  display: none;
}
.accordion .accordion-item:not(.open) .accordion-header .plus {
  display: block;
}
.accordion .accordion-item:not(.open) .accordion-content {
  height: 0px !important;
}
.accordion .accordion-item .accordion-header {
  width: 100%;
  padding: 20px 0;
  text-transform: none;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.accordion .accordion-item .accordion-header span:last-child {
  margin-right: 0;
  font-size: 17px;
  line-height: 1;
  font-weight: 700;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
.accordion .accordion-item .accordion-header span:last-child img {
  width: 100%;
  max-width: 20px;
}
.accordion .accordion-item .accordion-header span:first-child {
  font-weight: 700;
  font-size: 17px;
  letter-spacing: 2.2px;
  font-weight: 700;
}
.accordion .accordion-item .accordion-header span,
.accordion .accordion-item .accordion-header > a {
  font-size: 16px;
}
.accordion .accordion-item .accordion-header span img,
.accordion .accordion-item .accordion-header > a img {
  margin: 0;
}
.accordion .accordion-item .accordion-header.no-pseudo span:last-child:after {
  content: none;
  display: none;
}
.accordion .accordion-item.open > .accordion-header span:first-child {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.accordion .accordion-item.open .accordion-header {
  margin-bottom: 10px;
}
.accordion .accordion-item.open .accordion-content {
  overflow: visible !important;
}
.accordion .accordion-item .accordion-content {
  overflow: hidden;
  height: 0;
  padding: 0;
  text-align: left;
}
.accordion .accordion-item .accordion-content > div > *:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.accordion .accordion-item.initial .accordion-content {
  height: auto;
}
.accordion .accordion-item.open > .accordion-content {
  margin-top: 10px;
  margin-bottom: 20px;
}

.loader-overlay {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
}

.loader-overlay--active {
  opacity: 1;
  visibility: visible;
  pointer-events: unset;
}

.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  z-index: 1;
}

.loader-sector {
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 8px solid transparent;
  mix-blend-mode: overlay;
}
.loader-sector:nth-child(1) {
  animation: rotate 0.8s ease-in infinite;
  border-top: 8px solid #102b4e;
}
.loader-sector:nth-child(2) {
  animation: rotate 1.2s ease-in-out infinite;
  border-top: 8px solid #2861a4;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Components / Fitting */
.fitting-booking__ui {
  position: relative;
  margin-bottom: 30px;
  display: none;
}

.fitting-booking__label {
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 8px;
}

.fitting-booking__hidden-inputs {
  display: none;
}

.fitting-booking__no-slots-text {
  width: 100%;
  background-color: #fff6e7;
  border: 1px solid #4d4d4d;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 30px;
}

.fitting-booking__method-buttons {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.fitting-booking__method-buttons-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  background: $white-color;
  border: 1px solid $theme-color;
  border-radius: 20px;
  margin-top: 5px;
}

.fitting-booking__method-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
  padding: 0px 10px;
  width: 50%;
  height: 40px;
  background: $white-color;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  border: 1px solid transparent;
  border-radius: 20px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.fitting-booking__method-button:only-child {
  width: 100%;
}
.fitting-booking__method-button.fitting-booking__method-button--active {
  background: $theme-color;
  color: $white-color;
  border-color: #4d4d4d;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 1.35px;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}

.vdp-datepicker {
  margin-bottom: 30px;
}
.vdp-datepicker .vdp-datepicker__calendar {
  width: 100%;
}
.vdp-datepicker .cell.selected {
  color: $white-color;
  background: $theme-color !important;
}

.fitting-booking__slot-selection {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px 20px;
}
@media (max-width: 768px) {
  .fitting-booking__slot-selection {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 635px) {
  .fitting-booking__slot-selection {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 500px) {
  .fitting-booking__slot-selection {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 320px) {
  .fitting-booking__slot-selection {
    grid-template-columns: repeat(2, 1fr);
  }
}

.fitting-booking__slot-selection .fitting-booking__label {
  grid-column: 1 / span 3;
}
@media (max-width: 768px) {
  .fitting-booking__slot-selection .fitting-booking__label {
    grid-column: 1 / span 5;
  }
}
@media (max-width: 635px) {
  .fitting-booking__slot-selection .fitting-booking__label {
    grid-column: 1 / span 4;
  }
}
@media (max-width: 500px) {
  .fitting-booking__slot-selection .fitting-booking__label {
    grid-column: 1 / span 3;
  }
}
@media (max-width: 320px) {
  .fitting-booking__slot-selection .fitting-booking__label {
    grid-column: 1 / span 2;
  }
}

.fitting-booking__slot {
  width: 100%;
}

.fitting-booking__slot span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
  background: $white-color;
  color: $theme-color;
  border: 1px solid $theme-color;
  border-radius: 5px;
  padding: 10px 0;
  cursor: pointer;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}

.fitting-booking__slot input {
  display: none;
}
.fitting-booking__slot input:checked ~ span {
  background: $theme-color;
  color: $white-color;
  border-color: $white-color;
}

.fitting-booking__appointment-disclaimer {
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  line-height: 18px;
  color: $theme-color;
  grid-column: 1 / span 3;
}
@media (max-width: 768px) {
  .fitting-booking__appointment-disclaimer {
    grid-column: 1 / span 5;
  }
}
@media (max-width: 635px) {
  .fitting-booking__appointment-disclaimer {
    grid-column: 1 / span 4;
  }
}
@media (max-width: 500px) {
  .fitting-booking__appointment-disclaimer {
    grid-column: 1 / span 3;
  }
}
@media (max-width: 320px) {
  .fitting-booking__appointment-disclaimer {
    grid-column: 1 / span 2;
  }
}

/* ---------------------------------------------
    Templates
--------------------------------------------- */
/* ---------------------------------------------
    Collection
--------------------------------------------- */
.template-collection .site-footer {
  margin-top: 0px;
}

.ProductListPage,
.CategoryPage {
  .page__vin-search {
    margin: 0 auto;
    /*box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);*/
  }
  .vin-search--lincoln .vin-search .vin-search__model select,
  .vin-search--lincoln .vin-search .vin-search__vin select {
    background-color: $white-color;
  }
  .page__vin-search + div section {
    margin-top: 0;
  }
  .page__vin-search .vin-search {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-top: 1px;
  }
}

.breadcrumbs--product {
  padding-top: 25px;
  padding-bottom: 15px;
}
.breadcrumbs--product .breadcrumbs {
  padding-left: 35px;
}
@media (max-width: 950px) {
  .breadcrumbs--product {
    display: none;
  }
}

.product {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 0;
}

main#MainContent .nosto-list-item * {
  background-size: cover !important;
}

.cart-header {
  margin-top: 0;
  margin-bottom: 0;
}

.cart-header__container {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 768px) {
  .cart-header__container {
    padding-top: 25px;
    padding-bottom: 20px;
  }
}

.cart-header__heading {
  margin: 0;
}

.cart-usps {
  display: none;
  margin-top: 20px;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .cart-usps {
    display: initial;
  }
}

.cart-usps__container {
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding-top: 15px;
  padding-bottom: 15px;
}

.cart-usps__usp {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  color: #102b4e;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 11px;
  line-height: normal;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
}
.cart-usps__usp img,
.cart-usps__usp svg {
  width: 20px;
  margin-right: 15px;
}
.cart-usps__usp:not(:last-child) {
  margin-bottom: 15px;
}

.cart-main {
  margin-top: 0;
  margin-bottom: 0;
}

.cart-main__container {
  position: relative;
}

.cart-table {
  width: 100%;
}

.cart-list-headings {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  border-bottom: 1px solid;
  padding-bottom: 10px;
  margin-bottom: 25px;
}
@media (max-width: 768px) {
  .cart-list-headings {
    display: none;
  }
}

.cart-list-headings__heading {
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400 !important;
  font-style: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.cart-list-headings__heading#cart-list-heading--item {
  width: 55%;
  text-align: left;
}
.cart-list-headings__heading#cart-list-heading--price {
  width: 15%;
  text-align: left;
}
.cart-list-headings__heading#cart-list-heading--qty {
  width: 15%;
  text-align: center;
}
.cart-list-headings__heading#cart-list-heading--total {
  width: 15%;
  text-align: right;
}

.cart-list-items {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -45px;
}
.cart-list-items .cart-item {
  margin-bottom: 45px;
}
@media (max-width: 768px) {
  .cart-list-items {
    padding-top: 35px;
  }
}

.cart-footer {
  margin-top: 0;
  margin-bottom: 0;
}

.cart-footer__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 35px;
}

.cart-delivery-note {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0;
}
.cart-delivery-note.hidden {
  display: none;
}

.cart-delivery-note__checkbox {
  display: inline-block;
  width: auto;
  margin-top: 0 !important;
}

.cart-delivery-note__textarea {
  width: 100%;
  display: none;
  margin-top: 15px;
}
.cart-delivery-note__textarea textarea {
  width: 100%;
  max-width: 540px;
  height: 100px;
  border-radius: 3px;
  border: 1px solid #6e6e6e;
  background-color: $white-color;
  color: #102b4e;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  padding: 10px 15px;
}
.cart-delivery-note__textarea.active {
  display: block;
}

.cart-footer__left {
  width: calc(100% - 345px);
  padding-right: 15px;
}
@media (max-width: 768px) {
  .cart-footer__left {
    width: 100%;
  }
}

.cart-footer__usps {
  width: 100%;
}
@media (max-width: 768px) {
  .cart-footer__usps {
    display: none;
  }
}

.cart-footer__usp {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  color: #3d3d3d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: normal;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 1.6px;
}
.cart-footer__usp img,
.cart-footer__usp svg {
  width: 47px;
  margin-right: 20px;
}
.cart-footer__usp:not(:last-child) {
  margin-bottom: 15px;
}

.cart-footer__right {
  width: 345px;
}
@media (max-width: 768px) {
  .cart-footer__right {
    width: 100%;
  }
}

.cart-footer__overview {
  padding-top: 30px;
  border-top: 1px solid;
}

.cart-footer__overview-item {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.cart-footer__overview-item:not(:last-child) {
  margin-bottom: 28px;
}

.cart-footer__overview-label {
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
}

.cart-footer__overview-data {
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: normal;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.6px;
  text-align: right;
}
.cart-footer__overview-data.cart-footer__overview-data--light {
  font-weight: 400;
}

.cart-footer__checkout-cta {
  width: 100%;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1), 0 5px 5px rgba(0, 0, 0, 0.1);
}

.cart-footer__checkout-cta--disabled {
  opacity: 0.4;
  pointer-events: none;
}

.cart__checkout-error {
  width: 100%;
  max-width: 450px;
  padding: 20px;
  background: #cc452a;
  color: $white-color;
  font-family: var(--fmc-font--miller);
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  margin-top: 30px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .cart__checkout-error {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.cart-list__empty-text p {
  margin-bottom: 10px;
}

#ford-section-faq__hero {
  margin-bottom: 0px;
}
#ford-section-faq__hero .hero__content-inner {
  width: 750px;
}

#ford-section-faq__search {
  margin-top: 0px;
}

#ford-section-faq__qa {
  margin: 0 auto;
}
#ford-section-faq__topics {
  margin-top: 0px;
}

.container--faq {
  width: 1030px;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
}
@media (max-width: 1024px) {
  .container--faq {
    padding-left: 40px;
    padding-right: 40px;
  }
}

#collection-no-products {
  display: none;
}
#collection-no-products.collection-no-products--visible {
  display: block;
}

.search-breadcrumbs {
  padding-top: 30px;
}

.search-page-terms-header {
  margin-top: 0;
  margin-bottom: 0;
}

.search-page-terms-header__heading {
  text-align: center;
  color: $theme-color;
  font-family: var(--fmc-font--miller);
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.search-page-terms-header__heading .search-page-terms-header__term {
  text-transform: capitalize;
}

.search-page-terms-header__results-text {
  display: block;
  text-align: center;
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 14px;
  font-weight: 400;
}

.search-results {
  margin-bottom: 0;
}

.search-results__heading {
  display: block;
  margin-bottom: 20px;
  color: $theme-color;
  font-family: var(--fmc-font--miller);
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .search-results__heading {
    font-size: 16px;
    line-height: 20px;
  }
}

.search-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
  row-gap: 60px;
}
@media (max-width: 768px) {
  .search-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 550px) {
  .search-grid {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0px;
  }
}

.contact-breadcrumbs {
  display: none;
  padding-top: 20px;
}
@media (max-width: 768px) {
  .contact-breadcrumbs {
    display: block;
  }
}

.account:not(.register):not(.login) {
  padding-top: 40px;
  line-height: 26px;
}

.account.register,
.account.login {
  margin-top: 40px;
}

.account .form-success {
  text-align: center;
  margin-bottom: 30px;
}

/* ---------------------------------------------
    Titles
--------------------------------------------- */
.account-title {
  font-size: 48px;
  letter-spacing: 3px;
  font-weight: 300;
  text-align: center;
  line-height: 58px;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova) !important;
}
@media (max-width: 1024px) {
  .account-title {
    font-size: 45px;
    letter-spacing: 3.4px;
  }
}
@media (max-width: 768px) {
  .account-title {
    font-size: 42px;
    letter-spacing: 3.8px;
  }
}
@media (max-width: 600px) {
  .account-title {
    font-size: 36px;
    letter-spacing: 4.6px;
  }
}
@media (max-width: 600px) {
  .account-title {
    font-weight: 200;
  }
}

.account-subheading {
  margin-bottom: 40px;
  font-size: 16px;
}

@media (max-width: 1024px) {
  .account-subheading {
    margin-bottom: 33.5px;
  }
}
@media (max-width: 768px) {
  .account-subheading {
    margin-bottom: 27px;
  }
}
@media (max-width: 600px) {
  .account-subheading {
    margin-bottom: 14px;
  }
}

.account-subheading--sm {
  margin-bottom: 20px;
}

/* ---------------------------------------------
    Links
--------------------------------------------- */
.account-view-previous-orders-link {
  margin-bottom: 20px;
}

/* ---------------------------------------------
    Menu
--------------------------------------------- */
.account-menu {
  width: 900px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 50px;
  margin-top: 60px;
}
@media (max-width: 1024px) {
  .account-menu {
    margin-bottom: 82.5px;
    margin-top: 53.25px;
  }
}
@media (max-width: 768px) {
  .account-menu {
    margin-bottom: 65px;
    margin-top: 46.5px;
  }
}
@media (max-width: 600px) {
  .account-menu {
    margin-bottom: 30px;
    margin-top: 33px;
  }
}
.account-menu.wishlist-account-header {
  margin-bottom: 0;
  margin-top: 60px;
}
@media (max-width: 1024px) {
  .account-menu.wishlist-account-header {
    margin-bottom: 0;
    margin-top: 53.25px;
  }
}
@media (max-width: 768px) {
  .account-menu.wishlist-account-header {
    margin-bottom: 0;
    margin-top: 46.5px;
  }
}
@media (max-width: 600px) {
  .account-menu.wishlist-account-header {
    margin-bottom: 0;
    margin-top: 33px;
  }
}
@media (max-width: 990px) {
  .account-menu {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

[class*="account-menu__list-item"] {
  padding-bottom: 17px;
  text-align: center;
  border-bottom: 3px solid transparent;
}
@media (max-width: 990px) {
  [class*="account-menu__list-item"] {
    text-align: left;
    padding: 15px;
    border: 1px solid #6e6e6e;
    border-top: none;
    border-bottom: none;
  }
  body:not(.account-menu-open)
    [class*="account-menu__list-item"]:not(.account-menu__list-item--active) {
    display: none;
  }
  [class*="account-menu__list-item"]:last-child:not(.account-menu__list-item--active) {
    border-bottom: 1px solid #6e6e6e;
  }
}

.account-menu__list-item--active {
  border-bottom-color: $theme-color;
}
@media (max-width: 990px) {
  .account-menu__list-item--active {
    height: 50px;
    border-radius: 3px;
    border: 1px solid #6e6e6e;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -moz-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .account-menu-open .account-menu__list-item--active {
    border-radius: 0px;
  }
}

.account-menu__link {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 12px;
  text-transform: uppercase;
  font-family: var(--fmc-font--proxima-nova);
}
@media (max-width: 990px) {
  .account-menu__link {
    width: 100%;
    height: 100%;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
}

@media (max-width: 990px) {
  .account-menu__list-item--active .account-menu__link:after {
    content: "";
    background-image: url(https://storage.googleapis.com/hclcommerce2-b2c-prod-gcs-ext/B2C/lincoln/BackGround_Images/Linclon_scss/icon-chevron-down-primary.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 16px;
    height: 8px;
  }
  .account-menu-open
    .account-menu__list-item--active
    .account-menu__link:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

/* ---------------------------------------------
    Forms
--------------------------------------------- */
.account-form {
  width: 100%;
  max-width: 840px;
  margin: auto;
  margin-top: 40px;
}
.login .account-form,
.reset-password .account-form {
  max-width: 607px;
}

.account-form__grid {
  grid-gap: 40px 40px;
}
@media (max-width: 768px) {
  .account-form__grid {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .account-form__grid .input-field {
    grid-column: 1 / span 2;
  }
}

.account-form__grid select {
  width: 100%;
}

.account-form__submit {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
  margin-top: 35px;
  width: 182px;
}
@media (max-width: 500px) {
  .account-form__submit {
    width: 100%;
    margin-left: 0;
  }
}

.account-form__help-link {
  margin-top: 44px;
}
.account-form__help-link:nth-of-type(n + 2) {
  margin-top: 20px;
}
.account-form__help-link a {
  margin-left: 20px;
  color: $theme-color;
}
@media (max-width: 600px) {
  .account-form__help-link a {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
}

.guest-login {
  text-align: center;
  margin-top: 50px;
}
.guest-login form {
  margin-top: 20px;
}
.guest-login [class*="btn"] {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
  width: 182px;
}

.account-cta {
  color: $theme-color;
  text-decoration: underline !important;
  letter-spacing: 1px;
  display: inline-block;
}

/* ---------------------------------------------
    General
--------------------------------------------- */
.account-dashboard__cta {
  width: 245px;
}
.account-dashboard__cta:before,
.account-dashboard__cta:after {
  right: 41px;
}

.account-dashboard__section {
  padding-bottom: 80px;
}
@media (max-width: 1024px) {
  .account-dashboard__section {
    padding-bottom: 70px;
  }
}
@media (max-width: 768px) {
  .account-dashboard__section {
    padding-bottom: 60px;
  }
}
@media (max-width: 600px) {
  .account-dashboard__section {
    padding-bottom: 40px;
  }
}
.account-dashboard__section:nth-of-type(n + 2) {
  border-top: 1px solid #bbbbbb;
  padding-top: 80px;
  padding-top: 10px;
}
@media (max-width: 1024px) {
  .account-dashboard__section:nth-of-type(n + 2) {
    padding-top: 70px;
  }
}
@media (max-width: 768px) {
  .account-dashboard__section:nth-of-type(n + 2) {
    padding-top: 60px;
  }
}
@media (max-width: 600px) {
  .account-dashboard__section:nth-of-type(n + 2) {
    padding-top: 40px;
  }
}

.ccpa-disclaimer-text {
  margin-top: 30px;
  font-size: 14px;
  line-height: 20px;
}

/* ---------------------------------------------
    Addresses
--------------------------------------------- */
@media (max-width: 768px) {
  .account-dashboard__details {
    grid-template-columns: 1fr;
    grid-gap: 44px;
  }
}

.account-dashboard__addresses-cta {
  width: 271px;
  margin-top: 50px;
}
.account-dashboard__addresses-cta:before,
.account-dashboard__addresses-cta:after {
  right: 41px;
}

/* ---------------------------------------------
    Orders
--------------------------------------------- */
.account-dashboard__orders {
  margin-top: 44px;
}
@media (max-width: 1024px) {
  .account-dashboard__orders {
    margin-top: 39px;
  }
}
@media (max-width: 768px) {
  .account-dashboard__orders {
    margin-top: 34px;
  }
}
@media (max-width: 600px) {
  .account-dashboard__orders {
    margin-top: 24px;
  }
}

.account-dashboard__orders-cta {
  margin-top: 47px;
}

@media (max-width: 768px) {
  .account.addresses .text-align-center {
    text-align: left !important;
  }
}

.addresses--add-address .addresses-main {
  display: none;
}

.addresses-grid {
  display: grid;
  grid-template-columns: 1fr 536px;
  grid-gap: 40px;
}
@media (max-width: 768px) {
  .addresses-grid {
    grid-template-columns: 1fr;
    grid-gap: 44px;
  }
}
.addresses--edit-address .addresses-grid {
  display: block;
}

/* ---------------------------------------------
    Add address
--------------------------------------------- */
.addresses-add-form {
  width: 840px;
  max-width: 100%;
  margin: auto;
}
.addresses:not(.addresses--add-address) .addresses-add-form {
  display: none;
}
.addresses-add-form .checkbox {
  margin-left: 12px;
}
@media (max-width: 768px) {
  .addresses-add-form .checkbox {
    margin-top: 10px;
  }
}

.addresses-add-form__submit-cta {
  margin-left: 12px;
  width: 182px;
  margin-top: 45px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1024px) {
  .addresses-add-form__submit-cta {
    margin-top: 40px;
  }
}
@media (max-width: 768px) {
  .addresses-add-form__submit-cta {
    margin-top: 35px;
  }
}
@media (max-width: 600px) {
  .addresses-add-form__submit-cta {
    margin-top: 25px;
  }
}

.addresses-add-form__cancel-cta {
  margin-left: 12px;
  color: $theme-color;
  text-decoration: underline;
  letter-spacing: 1px;
  margin-top: 45px;
}
@media (max-width: 1024px) {
  .addresses-add-form__cancel-cta {
    margin-top: 42.25px;
  }
}
@media (max-width: 768px) {
  .addresses-add-form__cancel-cta {
    margin-top: 39.5px;
  }
}
@media (max-width: 600px) {
  .addresses-add-form__cancel-cta {
    margin-top: 34px;
  }
}

/* ---------------------------------------------
    Address list
--------------------------------------------- */
.addresses__list-item:nth-of-type(n + 2) {
  margin-top: 40px;
}

.addresses__list-item-cta {
  color: $theme-color;
  text-decoration: underline;
  letter-spacing: 1px;
  margin-top: 24px;
}

.addresses-delete-form {
  display: inline-block;
  margin-left: 20px;
}
.addresses-delete-form button {
  color: $theme-color;
  text-decoration: underline;
  letter-spacing: 1px;
}

/* ---------------------------------------------
    Edit address
--------------------------------------------- */
.addresses--edit-address .addresses-main > *:not(div),
.addresses--edit-address
  .addresses__default.addresses__list-item--edit
  > *:not(.edit-address-container),
.addresses--edit-address .addresses__default:not(.addresses__list-item--edit),
.addresses--edit-address .addresses__list-item > *:not(.edit-address-container),
.addresses--edit-address .addresses__list-item:not(.addresses__list-item--edit),
.addresses--edit-address .addresses__list-title {
  display: none;
}

.addresses__default:not(.addresses__list-item--edit) .edit-address-container,
.addresses__list-item:not(.addresses__list-item--edit) .edit-address-container {
  display: none;
}

.edit-address-container {
  width: 840px;
  max-width: 100%;
  margin: auto;
}

@media (max-width: 600px) {
  .addresses-edit-form .account-form__grid {
    grid-gap: 30px;
  }
  .addresses-edit-form .checkbox {
    margin-bottom: 0;
  }
}

.address-edit-form__update-cta-wrapper [class*="btn"] {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .address-edit-form__update-cta-wrapper {
    margin-top: 35px;
  }
}

@media (max-width: 350px) {
  .address-edit-form__update-cta-wrapper .addresses-delete-form {
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
}

.account-form__info-message {
  color: #4d4d4d;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  margin-top: 10px;
}

.order-item:nth-of-type(n + 2) {
  margin-top: 40px;
}
@media (max-width: 1024px) {
  .order-item:nth-of-type(n + 2) {
    margin-top: 37.5px;
  }
}
@media (max-width: 768px) {
  .order-item:nth-of-type(n + 2) {
    margin-top: 35px;
  }
}
@media (max-width: 600px) {
  .order-item:nth-of-type(n + 2) {
    margin-top: 30px;
  }
}

.order-item__details {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.order-item__detail {
  font-size: 12px;
}
.order-item__detail.bold {
  font-weight: 600;
}
.order-item__detail strong {
  font-weight: 500;
}
.order-item__detail:nth-last-of-type(n + 2):after {
  content: " / ";
  display: inline-block;
  padding: 0 10px;
}
@media (max-width: 500px) {
  .order-item__detail {
    width: 100%;
  }
  .order-item__detail:after {
    display: none !important;
  }
}

.order-item__products {
  margin: 20px 0;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .order-item__products {
    margin: 10px 0 30px;
  }
}

.order-item__product {
  border: 2px solid $theme-color;
  margin-top: 20px;
}
.order-item__product:nth-last-of-type(n + 2) {
  margin-right: 20px;
}
@media (max-width: 1024px) {
  .order-item__product:nth-last-of-type(n + 2) {
    margin-right: 17.5px;
  }
}
@media (max-width: 768px) {
  .order-item__product:nth-last-of-type(n + 2) {
    margin-right: 15px;
  }
}
@media (max-width: 600px) {
  .order-item__product:nth-last-of-type(n + 2) {
    margin-right: 10px;
  }
}
@media (max-width: 480px) {
  .order-item__product:nth-of-type(4n + 5) {
    margin-left: 0;
  }
}
.order-item__product a {
  display: block;
  width: 120px;
  height: 120px;
}
@media (max-width: 1024px) {
  .order-item__product a {
    width: 107px;
    height: 107px;
  }
}
@media (max-width: 768px) {
  .order-item__product a {
    width: 94px;
    height: 94px;
  }
}
@media (max-width: 600px) {
  .order-item__product a {
    width: 68px;
    height: 68px;
  }
}
.order-item__product a img {
  width: 100%;
  height: 100%;
}

.order-item__cta {
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: underline !important;
  color: $theme-color;
}
@media (max-width: 600px) {
  .order-item__cta {
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .account.order .account-subheading {
    font-size: 30px;
    font-weight: 200;
    letter-spacing: 1.5px;
  }
  .account.order .account-subheading--sm.m-t-l {
    margin-top: 0px;
    font-size: 16px;
    text-transform: none;
    font-family: var(--fmc-font--miller);
    letter-spacing: 1px;
    line-height: 26px;
    font-weight: 400;
  }
  .account.order .account-subheading--sm.m-t-l span {
    display: block;
    font-size: 0;
    height: 0;
  }
}

.account.order .return__animated-background {
  animation-duration: 2s !important;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite !important;
  animation-name: Shimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 60px;
  width: 60%;
  position: relative;
}
@media (max-width: 1024px) {
  .account.order .return__animated-background {
    width: 100%;
  }
}

@keyframes Shimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.account.order .order__return {
  margin-left: 1em;
  color: #d62d0a;
  text-decoration: underline;
}

.account.order .account-return-status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: 60%;
  height: 4em;
  border: #d62d0a 0px solid;
  border-top-color: #d62d0a;
  border-top-width: 1em;
  margin-bottom: 2em;
  -moz-box-shadow: 0px 3px 8px rgba(100, 100, 100, 0.5);
  -webkit-box-shadow: 0px 3px 8px rgba(100, 100, 100, 0.5);
  box-shadow: 0px 3px 8px rgba(100, 100, 100, 0.5);
}
@media (max-width: 1024px) {
  .account.order .account-return-status {
    width: 100%;
    height: fit-content;
  }
}
.account.order .account-return-status p {
  padding: 0.5em;
}
.account.order .account-return-status > .warn {
  font-size: 24px;
  color: transparent;
  position: relative;
  padding: 0;
  margin: 0;
}
.account.order .account-return-status > .warn::before {
  position: relative;
  padding: 0;
  margin: 0;
}
.account.order .account-return-status > .warn::after {
  position: relative;
  padding: 0;
  margin: 0;
}
.account.order .account-return-status > .warn.warning {
  display: inline-block;
  top: -0.5em;
  width: 1.65em;
  border: none;
  background-color: transparent;
}
@media (max-width: 480px) {
  .account.order .account-return-status > .warn.warning {
    width: 2.5em;
  }
}
.account.order .account-return-status > .warn.warning::before {
  content: "";
  display: block;
  top: -0.08em;
  left: 0.25em;
  position: absolute;
  border: transparent 0.6em solid;
  border-bottom-color: #d62d0a;
  border-bottom-width: 1em;
  border-top-width: 0;
}
.account.order .account-return-status > .warn.warning::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0.05em;
  width: 100%;
  text-align: center;
  content: "!";
  font-size: 0.5em;
  color: #fff;
}
@media (max-width: 480px) {
  .account.order .account-return-status > .warn.warning::after {
    left: 0.35em;
  }
}

.order-address__title {
  color: #4d4d4d;
  font-family: var(--fmc-font--miller);
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 3px;
  line-height: 30px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .order-address__title {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .order-address p {
    color: #4d4d4d;
    font-family: var(--fmc-font--miller);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
  }
}

.order-table {
  margin-top: 60px;
}

.order-table__head {
  border-bottom: 1px solid #bbbbbb;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.order-table__head .grid--4 {
  grid-column-start: 2;
}

.order-table__title {
  text-transform: uppercase;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 500;
}

.order-table__main-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 768px) {
  .order-table__main-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .order-table__main-item:nth-child(n + 2) {
    margin-top: 20px;
  }
}
.order-table__main-item > div:not(.grid--4),
.order-table__main-item .grid--4 > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  padding: 20px 0;
}
@media (max-width: 768px) {
  .order-table__main-item > div:not(.grid--4),
  .order-table__main-item .grid--4 > div {
    padding: 0;
  }
}
.order-table__main-item > div:not(.grid--4):not(.order__details),
.order-table__main-item .grid--4 > div:not(.order__details) {
  font-family: var(--fmc-font--proxima-nova);
  letter-spacing: 4px;
  color: $theme-color;
  font-weight: 300;
}
@media (max-width: 768px) {
  .order-table__main-item > div:not(.grid--4):not(.order__details),
  .order-table__main-item .grid--4 > div:not(.order__details) {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 3px;
    line-height: 14px;
  }
  .order-table__main-item
    > div:not(.grid--4):not(.order__details):nth-last-child(2),
  .order-table__main-item
    .grid--4
    > div:not(.order__details):nth-last-child(2) {
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  .order-table__main-item > .grid--4 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    grid-gap: 0px;
  }
}

.order__details {
  width: 390px;
  max-width: 100%;
}
@media (max-width: 768px) {
  .order__details {
    width: 102px;
    margin-right: 20px;
  }
}
.order__details img {
  border: 2px solid $theme-color;
}
@media (max-width: 768px) {
  .order__details img {
    min-width: 100%;
    height: 102px;
    margin: 0;
  }
}

.order__details-text {
  width: 100%;
}

.order__product-title {
  font-weight: 400;
}
@media (max-width: 768px) {
  .order__product-title {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 13px;
  }
}

.order__product-type {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .order__product-type {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 12px;
  }
}

.order-totals {
  width: 373px;
  max-width: 100%;
  border-top: 1px solid #bbbbbb;
  padding-top: 20px;
  margin-left: auto;
  text-transform: uppercase;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .order-totals {
    margin-top: 40px;
  }
}
.order-totals > li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.order-totals > li:nth-of-type(2) {
  padding-top: 24px;
  padding-bottom: 21px;
}
.order-totals > li:last-child {
  font-weight: 700;
  padding-top: 20px;
  border-top: 1px solid #bbbbbb;
}

@media (max-width: 768px) {
  .order-footer {
    padding-top: 10px !important;
  }
}

.order-footer p {
  margin-top: 10px;
  border-bottom: 1px solid #bbbbbb;
  padding-bottom: 20px;
  margin-bottom: 40px;
}
.order-footer p a {
  color: $theme-color;
}

.account-order__title {
  padding-top: 20px;
  border-top: 1px solid #bbbbbb;
}

.order-footer__cta {
  color: $theme-color;
  text-decoration: underline !important;
}

.order__buy-again {
  color: $theme-color;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 16px;
}
@media (max-width: 1024px) {
  .order__buy-again {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .order__buy-again {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .order__buy-again {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .order__buy-again {
    margin-top: 10px;
  }
}

.order__buy-all-btn {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}

a.order__buy-again--oos {
  display: block;
  font-size: 11px;
  line-height: 21px;
  text-decoration: underline;
  color: #4d4d4d;
}

.order-tracking {
  width: 100%;
  margin-top: 40px;
  p {
    font-weight: 500;
  }
}

.order-tracking__title {
  color: #4d4d4d;
  font-family: var(--fmc-font--miller);
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .order-tracking__title {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
}

a.order-tracking__link {
  color: #4d4d4d;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  line-height: 26px;
  font-weight: normal;
  letter-spacing: 1px;
  text-decoration: underline;
}
@media (max-width: 768px) {
  a.order-tracking__link {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
}

.my-garage__add-vehicle {
  position: relative;
  width: 840px;
  max-width: 100%;
  margin: auto;
}

.my-garage__edit-vehicle {
  position: relative;
  width: 840px;
  max-width: 100%;
  margin: auto;
}

.my-garage__heading-and-text-area {
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .my-garage__heading-and-text-area {
    text-align: left;
  }
}

.my-garage__top-message {
  font-weight: 500;
}

.add-vehicle-cta {
  margin-top: 52px;
  width: 245px;
  max-width: 100%;
}

.my-garage-vehicle__default-cta {
  color: inherit;
  text-align: left;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 26px;
  text-transform: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.my-garage-vehicle__default-cta svg {
  margin-right: 10px;
}

button.my-garage-vehicle__default-cta {
  color: inherit;
}
button.my-garage-vehicle__default-cta:before {
  content: "";
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 40px;
  border: 2px solid #6e6e6e;
  display: inline-block;
  margin-right: 10px;
}

.my-garage-vehicle__edit-cta {
  color: inherit;
  margin-top: 20px;
  text-decoration: underline;
  letter-spacing: 1px;
}

.edit-vehicle-cta-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.edit-vehicle-delete-cta {
  margin-left: 62px;
  text-align: left;
}
@media (max-width: 500px) {
  .edit-vehicle-delete-cta {
    margin-left: 0;
    margin-top: 15px;
    width: 100%;
  }
}

.my-garage__form-submit-btn {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}

.my-garage__add-vehicle-back-cta,
.my-garage__edit-vehicle-back-cta {
  margin-top: 44px;
}

.my-garage__view-garage-cta {
  margin-top: 45px;
}
@media (max-width: 768px) {
  .my-garage__view-garage-cta {
    margin-top: 25px;
  }
}
.my-garage__view-garage-cta [class*="btn"] {
  width: 245px;
  max-width: 100%;
}
.template-full-mygarage .my-garage__view-garage-cta {
  display: none;
}

.my-garage-vehicles {
  position: relative;
  grid-gap: 20px 122px;
  margin-top: 44px;
}
@media (max-width: 768px) {
  .my-garage-vehicles {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}

.my-garage-vehicle {
  padding: 20px 20px;
}

.my-garage-vehicle--active {
  background: $theme-color;
  color: $white-color;
}
.my-garage-vehicle--active .my-garage-vehicle__edit-cta {
  color: $white-color;
}
.my-garage-vehicle--active .my-garage-vehicle__icon svg path {
  fill: $white-color;
}
.my-garage-vehicle--active .my-garage-vehicle__icon {
  filter: brightness(0) invert(1);
}

.my-garage-vehicle--active__text {
  margin-top: 10px;
}

.my-garage-vehicle__identifier {
  margin-top: 22px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.my-garage-vehicle__icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.my-garage-vehicle__icon span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.my-garage-vehicle__icon svg,
.my-garage-vehicle__icon .my-garage-vehicle__icon-image {
  width: 40px;
}

.my-garage-vehicle__nickname {
  margin-left: 18px;
}

.my-garage-vehicle__details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 22px;
}

.my-garage-vehicle__detail:nth-last-child(n + 2):after {
  content: " - ";
  padding: 0 20px;
  display: inline-block;
}
@media (max-width: 850px) {
  .my-garage-vehicle__detail:nth-last-child(n + 2):after {
    padding: 0 10px;
  }
}
@media (max-width: 350px) {
  .my-garage-vehicle__detail:nth-last-child(n + 2):after {
    padding: 0 5px;
  }
}

.my-garage-vehicle__vin {
  margin-top: 10px;
}

.my-garage__form {
  margin-top: 40px;
}

.my-garage__form .input-field input:not([type="checkbox"]):focus,
.my-garage__form .input-field select:focus {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}

.my-garage__form-vin {
  text-transform: uppercase;
}

.my-garage__form .input-field select {
  width: 100%;
}
.my-garage__form .input-field select:disabled {
  color: $white-color;
  background-color: #6e6e6e;
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-down-white.svg?v=155950126711001990771664033354);*/
}

.input-field--vin-disabled {
  position: relative;
}
.input-field--vin-disabled:after {
  content: "Remove VIN number to enter data manually";
  width: 100%;
  position: absolute;
  font-size: 11px;
  line-height: normal;
  display: none;
  z-index: 1;
  left: 0;
  top: calc(100% + 5px);
}
@media (min-width: 769px) {
  .input-field--vin-disabled:hover:after {
    display: block;
  }
}

@media (max-width: 768px) {
  .my-garage__form .grid-item--full {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
}

.my-garage__global-error {
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  color: #cc452a;
  width: 100%;
  grid-column: 1 / -1;
}

@media (max-width: 767px) {
  .vin-search {
    box-shadow: none;
  }
  .vin-search__toggle.vin-search__toggle {
    border: solid 0.5px;
    margin: 0 5px;
    padding: 6px;
  }
}

.cart-header__container {
  padding-top: 5px;
  padding-bottom: 5px;
}

.cart-footer__overview-item:not(:last-child) {
  margin-bottom: 10px;
}

.cart-footer__checkout-cta {
  margin-top: 30px;
}

.cart-footer__usp {
  font-size: 13px;
}

/* ---------------------------------------------
    Sections
--------------------------------------------- */
/* ------------------------------------------
    Site header
------------------------------------------ */
.site-header {
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  background: $white-color;
  width: 100%;
  will-change: transform;
}

.site-header-main {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 0px 30px;
}

.site-header-main__inner {
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

/* ------------------------------------------
    Eyebrow header / pre navigation
------------------------------------------ */
.site-header-eyebrow__nav-list-item:nth-of-type(n + 2) {
  margin-left: 50px;
}

.site-header-eyebrow__nav-link {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.33px;
  text-transform: uppercase;
  color: $white-color !important;
  font-family: var(--fmc-font--proxima-nova);
}
.site-header-eyebrow__nav-list-item:last-child .site-header-eyebrow__nav-link {
  margin-right: 40px !important;
}
/* ------------------------------------------
    Logo
------------------------------------------ */
.site-header__logo {
  margin-left: 13px;
  margin-right: 40px;
}
@media (max-width: 500px) {
  .site-header__logo,
  .site-header__logo svg {
    margin-right: 15px;
    width: 75px !important;
  }
}

/* ------------------------------------------
    Navigation
------------------------------------------ */
.site-header__nav {
  margin-right: auto;
  height: 100%;
}

@media (min-width: 1025px) {
  .site-header__nav-list {
    height: 100%;
  }
}

.site-header__nav-dropdown-link {
  display: flex;
}

[class*="site-header__nav-list-item"] {
  padding-top: 6px;
  padding-bottom: 9px;
  border-bottom: 3px solid transparent;
  display: flex;
  align-items: center;
}
[class*="site-header__nav-list-item"]:nth-child(n + 2) {
  margin-left: 40px;
}
@media (max-width: 1439px) {
  [class*="site-header__nav-list-item"]:nth-child(n + 2) {
    margin-left: 25px;
  }
}

.site-header__nav-list-item--parent {
  height: 100%;
}
@media (min-width: 769px) {
  .site-header__nav-list-item--parent:hover {
    border-color: $theme-hover-color;
  }
}
.site-header__nav-list-item--parent .site-header__nav-link-toggle {
  height: 8px;
  margin-top: 5px;
  margin-left: 11px;
  position: relative;
  width: 15px;
}
.site-header__nav-list-item--parent .site-header__nav-link-toggle::after {
  content: "";
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-down-secondary.svg?v=83094230789985669231664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  height: 8px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}
@media (max-width: 1024px) {
  .site-header__nav-list-item--parent .site-header__nav-link-toggle::after {
    transform: rotate(-90deg);
  }
}
.site-header__nav-list-item--parent > .site-header__nav-link {
  height: 100%;
}

.site-header__nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: normal;
  color: $theme-hover-color;
  white-space: nowrap;
  cursor: pointer;
}
.site-header__nav-link svg {
  margin-right: 10px;
}
.site-header__nav-link a {
  text-decoration: none;
}

/* ---------------------------------------------
    User menu
--------------------------------------------- */
@media (max-width: 1024px) {
  .site-header__user-menu {
    margin-left: auto;
  }
}

.site-header__user-menu .user-menu__link svg {
  pointer-events: none;
}

@media (max-width: 1200px) {
  .site-header__user-menu .user-menu__link {
    font-size: 0px;
  }
  .site-header__user-menu .user-menu__link svg {
    margin-right: 0px;
  }
}

@media (max-width: 1024px) {
  /* ------------------------------------------
        Site header
    ------------------------------------------ */
  #ford-section-site-header,
  #ford-section-lincoln__site-header {
    z-index: 10;
    position: relative;
  }
  /* ------------------------------------------
        Eyebrow header / pre navigation
    ------------------------------------------ */
  .site-header-eyebrow__nav {
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 30px;
  }
  .site-header-eyebrow__nav-list-item:nth-of-type(n + 2) {
    margin-left: 0px;
    margin-top: 16px;
  }
  .site-header-eyebrow__nav-link {
    font-size: 14px;
    color: #747474;
  }
  /* ------------------------------------------
        Logo
    ------------------------------------------ */
  .site-header__logo {
    width: 71px;
    margin-left: 0px;
  }
  /* ------------------------------------------
        Navigation
    ------------------------------------------ */
  .site-header__nav {
    height: auto;
    bottom: 0;
    left: 0;
    width: 500px;
    max-width: 100%;
    padding-top: 40px;
    background: $white-color;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: 0.2s all ease-in-out;
    -moz-transition: 0.2s all ease-in-out;
    -ms-transition: 0.2s all ease-in-out;
    -o-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
    -webkit-box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  }
  .mobile-menu-open:not(.megamenu-open) .site-header__nav {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    overflow-y: scroll;
  }
  .megamenu-sub-open .site-header__nav .megamenu {
    pointer-events: none;
  }
  .mobile-menu-open.megamenu-open .site-header__nav {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    pointer-events: none;
  }
  .site-header__nav-list {
    display: block;
    padding-left: 28px;
    padding-right: 28px;
  }
  [class*="site-header__nav-list-item"] {
    padding: 0;
    border-bottom: 0px;
  }
  [class*="site-header__nav-list-item"]:nth-child(n + 2) {
    margin-left: 0;
    margin-top: 36px;
  }
  [class*="site-header__nav-list-item"]:last-child {
    padding-bottom: 31px;
    border-bottom: 1px solid #bbbbbb;
  }
  .site-header__nav-list-item--parent .site-header__nav-link:after {
    margin-left: auto;
  }
  .site-header__nav-link {
    font-size: 21px;
    font-weight: 300;
    letter-spacing: normal;
    width: 100%;
  }
  /* ---------------------------------------------
        User menu
    --------------------------------------------- */
  .site-header__user-menu {
    margin-left: auto;
  }
  .site-header__user-menu [class*="user-menu__item"] svg {
    height: 27px;
    width: 27px;
  }
  .site-header__user-menu [class*="user-menu__item"]:nth-of-type(n + 2) {
    margin-left: 10px;
  }
  .site-header__user-menu .user-menu__item--account {
    /*display: none;*/
  }
  /* ---------------------------------------------
        Burger
    --------------------------------------------- */
}

@media (max-width: 1024px) and (max-width: 1024px) {
  .site-header__burger {
    margin-left: 27px;
  }
}

@media (max-width: 1024px) {
  .user-logged-in .mobile-menu__user-menu {
    margin-top: 30px;
    width: 100%;
  }
  .user-not-logged-in .mobile-menu__user-menu {
    margin-top: 27px;
  }
  .mobile-menu__user-menu [class*="user-menu__item"] {
    height: 80px;
    background: #f2f2f2;
    width: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  .mobile-menu__user-menu [class*="user-menu__item"]:nth-of-type(2) {
    border-left: 1px solid #bbbbbb;
    margin-left: 0px;
  }
}

@media (max-width: 1024px) {
  body.mobile-menu-open {
    overflow: hidden;
  }
}

.site-footer {
  margin-top: 73px;
}
@media (max-width: 1024px) {
  .site-footer {
    margin-top: 63.75px;
  }
}
@media (max-width: 768px) {
  .site-footer {
    margin-top: 54.5px;
  }
}
@media (max-width: 600px) {
  .site-footer {
    margin-top: 36px;
  }
}

/* ------------------------------------------
    Text block
------------------------------------------ */
.footer-text-block {
  padding-top: 18px;
  padding-bottom: 17px;
}
@media (max-width: 1024px) {
  .footer-text-block {
    padding-top: 19.25px;
    padding-bottom: 19px;
  }
}
@media (max-width: 768px) {
  .footer-text-block {
    padding-top: 20.5px;
    padding-bottom: 21px;
  }
}
@media (max-width: 600px) {
  .footer-text-block {
    padding-top: 23px;
    padding-bottom: 25px;
  }
}

@media (max-width: 600px) {
  .footer-text-block__inner {
    padding-left: 27px;
    padding-right: 27px;
  }
}

.footer-text-block__title {
  font-size: 36px;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: none;
  font-family: var(--fmc-font--miller);
}
@media (max-width: 1024px) {
  .footer-text-block__title {
    font-size: 31.5px;
  }
}
@media (max-width: 768px) {
  .footer-text-block__title {
    font-size: 27px;
  }
}
@media (max-width: 600px) {
  .footer-text-block__title {
    font-size: 18px;
  }
}

.footer-text-block__subtitle {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 24px;
}
@media (max-width: 1024px) {
  .footer-text-block__subtitle {
    font-size: 17px;
    margin-top: 14.25px;
  }
}
@media (max-width: 768px) {
  .footer-text-block__subtitle {
    font-size: 16px;
    margin-top: 18.5px;
  }
}
@media (max-width: 600px) {
  .footer-text-block__subtitle {
    font-size: 14px;
    margin-top: 27px;
  }
}
@media (max-width: 600px) {
  .footer-text-block__subtitle {
    line-height: normal;
    width: 278px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer-text-block__subtitle a {
  color: $white-color !important;
  text-decoration: underline;
}

.footer-text-block__cta {
  font-size: 16px;
  margin-top: 10px;
  font-weight: 300;
  letter-spacing: normal;
  font-family: var(--fmc-font--miller);
  height: 51px;
  padding-left: 35px;
  padding-right: 35px;
}
@media (max-width: 1024px) {
  .footer-text-block__cta {
    font-size: 15.25px;
    margin-top: 13.25px;
  }
}
@media (max-width: 768px) {
  .footer-text-block__cta {
    font-size: 14.5px;
    margin-top: 16.5px;
  }
}
@media (max-width: 600px) {
  .footer-text-block__cta {
    font-size: 13px;
    margin-top: 23px;
  }
}

/* ------------------------------------------
    Nav
------------------------------------------ */
@media (min-width: 601px) {
  .footer-nav {
    padding-top: 60px;
    padding-bottom: 91px;
  }
}

.footer-nav__link-separator {
  display: block;
  height: 18px;
}

@media (max-width: 1024px) {
  .footer-nav__list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {
  .footer-nav__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .footer-nav__list {
    display: block;
    padding: 0;
  }
}

@media (max-width: 600px) {
  .footer-nav__column-list {
    padding-left: 46px;
    display: none;
  }
}

.footer-nav__column-title {
  font-size: 18px;
  margin-bottom: 21px;
}
@media (max-width: 600px) {
  .footer-nav__column-title {
    padding: 26px;
    font-size: 16px;
    border: 1px solid #3b3b3b;
    margin-bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
  .footer-nav__column-title:after {
    content: "";
    width: 17px;
    height: 9px;
    /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-down-white.svg?v=155950126711001990771664033354);*/
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
.footer-nav__column-title.active:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.footer-nav__list-item {
  margin-top: 20px;
  color: #a8a8a8;
  line-height: 18px;
}
@media (max-width: 600px) {
  .footer-nav__list-item:last-child {
    padding-bottom: 60px;
  }
}

.footer-nav__link {
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
}
@media (min-width: 769px) {
  .footer-nav__link:hover {
    color: $theme-hover-color;
  }
}

/* ------------------------------------------
    Legal
------------------------------------------ */
.footer-legal {
  height: 120px;
  letter-spacing: normal;
}
@media (max-width: 1200px) {
  .footer-legal {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    padding-top: 5px;
    padding-bottom: 40px;
  }
}
@media (max-width: 768px) {
  .footer-legal.flex.f-vertical-center {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start;
  }
}
.footer-legal > svg {
  width: 89px;
  margin-left: auto;
}
@media (max-width: 1024px) {
  .footer-legal > svg {
    width: 84.5px;
  }
}
@media (max-width: 768px) {
  .footer-legal > svg {
    width: 80px;
  }
}
@media (max-width: 600px) {
  .footer-legal > svg {
    width: 71px;
  }
}
@media (max-width: 1200px) {
  .footer-legal > svg {
    margin-left: 0px;
    margin-top: 23px;
  }
}

.footer-legal__copy {
  font-size: 14px;
  font-family: var(--fmc-font--proxima-nova);
}
@media (max-width: 1024px) {
  .footer-legal__copy {
    font-size: 13.5px;
  }
}
@media (max-width: 768px) {
  .footer-legal__copy {
    font-size: 13px;
  }
}
@media (max-width: 600px) {
  .footer-legal__copy {
    font-size: 12px;
  }
}

.footer-legal__nav {
  padding-right: 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .footer-legal__nav {
    padding-right: 0px;
    margin-top: 15px;
  }
}
@media (max-width: 1024px) {
  .footer-legal__nav {
    padding-right: 30px;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .footer-legal__nav {
    display: block;
  }
}

.footer-legal__nav-list-item {
  margin-left: 27px;
}
@media (max-width: 768px) {
  .footer-legal__nav-list-item {
    margin-left: 0;
  }
  .footer-legal__nav-list-item:nth-of-type(n + 2) {
    margin-top: 19px;
  }
}

.footer-legal__nav-link {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--fmc-font--proxima-nova);
  white-space: nowrap;
}

.search-dealers {
  margin-top: 0;
  margin-bottom: 0;
}

.search-dealers__inner {
  background: $theme-color;
  padding: 40px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.search-dealers__heading {
  text-align: center;
  color: $white-color;
  margin-bottom: 40px;
}

.search-dealers__mobile-label {
  text-align: center;
  color: $white-color;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  margin-bottom: 30px;
  display: none;
}
@media (max-width: 768px) {
  .search-dealers__mobile-label {
    display: block;
  }
}

.search-dealers__form {
  position: relative;
  width: 100%;
  max-width: 485px;
  margin: 0 auto;
}

.search-dealers__input {
  width: 100%;
  border: 1px solid $white-color;
  padding-right: 65px;
  height: 50px;
  padding: 0 14px;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1px;
  color: $white-color;
  border-radius: 3px;
}
.search-dealers__input::-webkit-input-placeholder {
  color: $white-color;
}
.search-dealers__input:-moz-placeholder {
  color: $white-color;
}
.search-dealers__input::-moz-placeholder {
  color: $white-color;
}
.search-dealers__input:-ms-input-placeholder {
  color: $white-color;
}
.search-dealers__input::placeholder {
  color: $white-color;
}
@media (max-width: 768px) {
  .search-dealers__input {
    padding-right: 15px;
  }
}

.search-dealers__submit {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  background: $white-color;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 3px 3px 0px;
}
@media (max-width: 768px) {
  .search-dealers__submit {
    position: initial;
    width: 165px;
    height: 40px;
    background: $white-color;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin: 0 auto;
    margin-top: 30px;
  }
}

.search-dealers__form img,
.search-dealers__form svg {
  width: 16px;
}
@media (max-width: 768px) {
  .search-dealers__form img,
  .search-dealers__form svg {
    display: none;
  }
}

.search-dealers__submit-mobile-text {
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 18px;
  line-height: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  text-align: center;
  display: none;
}
@media (max-width: 768px) {
  .search-dealers__submit-mobile-text {
    display: block;
  }
}

.contact-boxes {
  margin-top: 0;
  margin-bottom: 0;
}

.contact-boxes__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 55px;
}
@media (max-width: 768px) {
  .contact-boxes__container {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

.contact-box {
  width: calc(50% - 30px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (max-width: 768px) {
  .contact-box {
    width: 100%;
  }
  .contact-box:not(:last-child) {
    margin-bottom: 50px;
  }
}

.contact-box__heading {
  margin-bottom: 30px;
  color: $theme-color;
}
@media (max-width: 1312px) {
  .contact-box__heading {
    min-height: 96px;
  }
}
@media (max-width: 768px) {
  .contact-box__heading {
    min-height: 0px;
  }
}

.contact-box__inner {
  background: $white-color;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 20px 40px;
  padding-bottom: 45px;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (max-width: 768px) {
  .contact-box__inner {
    padding: 20px 20px;
    padding-bottom: 35px;
  }
}

.contact-box__inner-heading {
  color: #4d4d4d;
  margin-bottom: 20px;
}

.contact-box__content {
  width: 100%;
}

.contact-box__content-box {
  width: 100%;
  color: #4d4d4d;
  font-family: var(--fmc-font--miller) !important;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 25px;
}
.contact-box__content-box:last-child {
  margin-bottom: 40px;
}

.contact-box__content-footer {
  width: 100%;
  margin-top: auto;
  border-top: 1px solid #4d4d4d;
  padding-top: 40px;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.contact-box__content-footer img,
.contact-box__content-footer svg {
  margin-right: 20px;
  width: 22px;
}
.contact-box__content-footer img:last-child,
.contact-box__content-footer svg:last-child {
  margin-right: 0;
  margin-left: 20px;
  width: 16px;
}

.contact-box__footer-link-text {
  position: relative;
}
.contact-box__footer-link-text.contact-box__footer-link-text--underlined:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: $theme-color;
  position: absolute;
  bottom: 0;
  left: 0;
}

.state-popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.37);
  z-index: 10;
}

.state-popup__inner {
  background: $white-color;
  max-height: calc(100% - 60px);
  max-width: calc(100% - 60px);
  width: 640px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}

.state-popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  cursor: pointer;
}

.state-popup__text-close {
  cursor: pointer;
}

.state-popup__text-content {
  padding-top: 40px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
}
@media (max-width: 1024px) {
  .state-popup__text-content {
    padding-top: 35px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 56.25px;
  }
}
@media (max-width: 768px) {
  .state-popup__text-content {
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 52.5px;
  }
}
@media (max-width: 600px) {
  .state-popup__text-content {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 45px;
  }
}

.state-popup__footnote {
  font-size: 12px;
  line-height: 24px;
}

.state-popup__buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 35px;
  margin-top: 35px;
  margin-bottom: 35px;
}
@media (max-width: 1024px) {
  .state-popup__buttons {
    margin-top: 32.5px;
    margin-bottom: 32.5px;
  }
}
@media (max-width: 768px) {
  .state-popup__buttons {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 600px) {
  .state-popup__buttons {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
@media (max-width: 768px) {
  .state-popup__buttons {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

.state-popup__trigger svg {
  max-height: 9px;
  margin-left: 7px;
}
@media (max-width: 1024px) {
  .state-popup__trigger svg {
    max-height: 16px;
  }
  .state-popup__trigger svg path {
    fill: $theme-color;
  }
}

@media (max-width: 1024px) {
  .state-popup__trigger {
    font-size: 11px;
    letter-spacing: 0.5px;
  }
}

.state-popup__trigger-mobile-container {
  display: none;
  height: 41px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
  background: #f6f7f7;
}

.collection__loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white-color;
  z-index: 10;
}

.collection__loading-text {
  letter-spacing: 0.25em;
}
.collection__loading-text.animated {
  -webkit-animation: typing 5s steps(100, end);
  -moz-animation: typing 5s steps(100, end);
  -o-animation: typing 5s steps(100, end);
  animation: typing 5s steps(100, end);
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  white-space: nowrap;
}

@keyframes typing {
  from {
    width: 0;
  }
  30% {
    width: 100%;
  }
  60% {
    width: 100%;
  }
  90% {
    width: 0;
  }
  to {
    width: 0;
  }
}

@-webkit-keyframes typing {
  from {
    width: 0;
  }
  30% {
    width: 100%;
  }
  60% {
    width: 100%;
  }
  90% {
    width: 0;
  }
  to {
    width: 0;
  }
}

@-moz-keyframes typing {
  from {
    width: 0;
  }
  30% {
    width: 100%;
  }
  60% {
    width: 100%;
  }
  90% {
    width: 0;
  }
  to {
    width: 0;
  }
}

@-o-keyframes typing {
  from {
    width: 0;
  }
  30% {
    width: 100%;
  }
  60% {
    width: 100%;
  }
  90% {
    width: 0;
  }
  to {
    width: 0;
  }
}

.disclosures {
  width: 100%;
}

.disclosures__trigger-area {
  width: 100%;
  background: $white-color;
}

.disclosures__trigger-text {
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  cursor: pointer;
  color: $theme-hover-color;
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 300;
  text-transform: uppercase;
}
.disclosures__trigger-text-icon {
  font-size: 26px;
}
.disclosures__trigger-text img,
.disclosures__trigger-text svg {
  margin-left: 15px;
}

.disclosures__trigger-text--active img,
.disclosures__trigger-text--active svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.disclosures__content {
  width: 100%;
  height: 0;
  background: #f7f7f7;
  color: #4d4d4d;
  font-size: 12px;
  line-height: 16px;
  overflow-y: auto;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.disclosures__content p:not(:last-child) {
  margin-bottom: 5px;
}

.disclosures__content .rte a {
  color: #4d4d4d !important;
}

.disclosures__content .container,
.disclosures__content .container--sm,
.disclosures__content .container--no-padding,
.disclosures__content .container--mb-2,
.disclosures__content .kuContainer {
  padding-top: 30px;
  padding-bottom: 30px;
}

[data-disclosures-trigger] {
  cursor: pointer;
}

.video__heading {
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .video__heading {
    margin-bottom: 27.5px;
  }
}
@media (max-width: 768px) {
  .video__heading {
    margin-bottom: 25px;
  }
}
@media (max-width: 600px) {
  .video__heading {
    margin-bottom: 20px;
  }
}
@media (max-width: 600px) {
  .video__heading {
    text-align: center;
  }
}

.video__inner {
  position: relative;
}

.video__overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
.video__overlay.hidden {
  opacity: 0;
  visibility: hidden;
}
.video__overlay img,
.video__overlay svg {
  cursor: pointer;
}

.video video {
  width: 100%;
}

.cart-add-vehicle-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  padding: 30px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.cart-add-vehicle-modal.active {
  opacity: 1;
  visibility: visible;
  pointer-events: unset;
}

.cart-add-vehicle-modal__inner {
  width: 100%;
  max-width: 1010px;
  background: $white-color;
  border-radius: 3px;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  max-height: 100%;
  overflow-y: scroll;
}

.cart-add-vehicle-modal__header {
  padding: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.17);
}

.cart-add-vehicle-modal__heading {
  text-align: center;
  text-transform: uppercase;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
  letter-spacing: 3px;
  margin-bottom: 11px;
}

.cart-add-vehicle-modal__text {
  text-align: center;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: 1px;
  max-width: 560px;
  margin: 0 auto;
}

.cart-add-vehicle-modal__main .vin-search {
  width: 100%;
  margin-top: 0;
}

.cart-add-vehicle-modal__main .vin-search__title,
.cart-add-vehicle-modal__main .vin-search__subtitle {
  display: none;
}

.cart-add-vehicle-modal__footer {
  padding: 30px;
  text-align: center;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  line-height: 26px;
  font-weight: normal;
  letter-spacing: 1px;
}
.cart-add-vehicle-modal__footer [data-remove-all-installation-products] {
  color: #2861a4;
  text-decoration: underline;
  cursor: pointer;
}

.cta-banner {
  position: relative;
  width: 100%;
  height: 100%;
  background: #324047; /* Update background color for better contrast */
  color: #ffffff; /* Update text color for better contrast */
  min-height: 100px;
  padding-top: 15px;
  padding-bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.cta-banner--no-margin {
  margin: 0;
}

.cta-banner__container {
  padding-bottom: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: inherit;
}
.cta-banner__container .slick-track {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.cta-banner__container .slick-arrow {
  filter: invert(1);
  width: 8px;
  height: 14px;
}
@media (max-width: 480px) {
  .cta-banner__container .slick-list {
    padding: 0 !important;
    margin: 0 20px;
  }
}
@media (max-width: 1239px) {
  .cta-banner__container {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 480px) {
  .cta-banner__container {
    padding-left: 0;
    padding-right: 0;
  }
}

.cta-banner .slick--left-and-right-arrows {
  padding-bottom: 42px;
}
.cta-banner .slick--left-and-right-arrows .slick-arrow {
  bottom: 0;
  -webkit-transform: translateY(-90%);
  -moz-transform: translateY(-90%);
  -ms-transform: translateY(-90%);
  -o-transform: translateY(-90%);
  transform: translateY(-90%);
}
.cta-banner .slick--left-and-right-arrows .slick-arrow.slick-prev {
  left: 0;
  left: calc(50% - 45px);
  -webkit-transform: translateY(calc(100% + 16px)) rotate(180deg);
  -moz-transform: translateY(calc(100% + 16px)) rotate(180deg);
  -ms-transform: translateY(calc(100% + 16px)) rotate(180deg);
  -o-transform: translateY(calc(100% + 16px)) rotate(180deg);
  transform: translateY(calc(100% + 16px)) rotate(180deg);
}
@media (max-width: 1024px) {
  .cta-banner .slick--left-and-right-arrows .slick-arrow.slick-prev {
    -webkit-transform: translateY(calc(100% + 13px)) rotate(180deg);
    -moz-transform: translateY(calc(100% + 13px)) rotate(180deg);
    -ms-transform: translateY(calc(100% + 13px)) rotate(180deg);
    -o-transform: translateY(calc(100% + 13px)) rotate(180deg);
    transform: translateY(calc(100% + 13px)) rotate(180deg);
  }
}
@media (max-width: 600px) {
  .cta-banner .slick--left-and-right-arrows .slick-arrow.slick-prev {
    -webkit-transform: translateY(calc(100% + 30px)) rotate(180deg);
    -moz-transform: translateY(calc(100% + 30px)) rotate(180deg);
    -ms-transform: translateY(calc(100% + 30px)) rotate(180deg);
    -o-transform: translateY(calc(100% + 30px)) rotate(180deg);
    transform: translateY(calc(100% + 30px)) rotate(180deg);
  }
}
.cta-banner .slick--left-and-right-arrows .slick-arrow.slick-next {
  right: 0;
  right: calc(50% - 45px);
  -webkit-transform: translateY(calc(100% + 16px));
  -moz-transform: translateY(calc(100% + 16px));
  -ms-transform: translateY(calc(100% + 16px));
  -o-transform: translateY(calc(100% + 16px));
  transform: translateY(calc(100% + 16px));
}
@media (max-width: 1024px) {
  .cta-banner .slick--left-and-right-arrows .slick-arrow.slick-next {
    -webkit-transform: translateY(calc(100% + 13px));
    -moz-transform: translateY(calc(100% + 13px));
    -ms-transform: translateY(calc(100% + 13px));
    -o-transform: translateY(calc(100% + 13px));
    transform: translateY(calc(100% + 13px));
  }
}
@media (max-width: 600px) {
  .cta-banner .slick--left-and-right-arrows .slick-arrow.slick-next {
    -webkit-transform: translateY(calc(100% + 30px));
    -moz-transform: translateY(calc(100% + 30px));
    -ms-transform: translateY(calc(100% + 30px));
    -o-transform: translateY(calc(100% + 30px));
    transform: translateY(calc(100% + 30px));
  }
}
.cta-banner .slick--left-and-right-arrows .slick-arrow.slick-disabled {
  opacity: 0;
  cursor: default;
}

.cta-banner-item {
  width: 100%;
  box-shadow: none !important;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cta-banner-item__left {
  width: 150px;
  padding-left: 5px;
}
@media (max-width: 1024px) {
  .cta-banner-item__left {
    width: 100%;
    padding-left: 0;
  }
}

.cta-banner-item__overview-text {
  font-family: var(--fmc-font--miller);
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .cta-banner-item__overview-text {
    text-align: center;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
}

.cta-banner-item__right {
  width: calc(100% - 150px);
}
@media (max-width: 1024px) {
  .cta-banner-item__right {
    width: 100%;
    padding-left: 0px;
  }
}
.cta-banner-item__right.cta-banner-item__right--full-width {
  width: 100%;
  padding-right: 0;
  text-align: center;
  box-shadow: none !important;
}

.cta-banner-item__main-text {
  font-family: var(--fmc-font--miller);
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  color: $white-color;
}
.cta-banner-item__main-text span {
  color: #3caeee;
}
@media (max-width: 1024px) {
  .cta-banner-item__main-text {
    text-align: center;
    font-size: 14px;
    line-height: normal;
  }
}
@media (max-width: 480px) {
  .cta-banner-item__main-text {
    font-size: 12px;
  }
}
.cta-banner-item__supportive-text {
  margin-top: 2px;
  color: $white-color;
  font-family: var(--fmc-font--miller);
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
}
.cta-banner-item__supportive-text span,
.cta-banner-item__supportive-text span a {
  color: #63beff;
  font-size: 11px;
}
.cta-banner-item__supportive-text span a {
  text-decoration: underline;
  text-decoration-color: #63beff;
  text-decoration-thickness: 2px;
}
.cta-banner-item__supportive-text [data-disclosures-trigger] {
  cursor: pointer;
}
@media (max-width: 1024px) {
  .cta-banner-item__supportive-text {
    text-align: center;
    font-size: 10px;
    line-height: normal;
  }
}

.cta-banner-item__disclaimer-text {
  margin-top: 10px;
  font-family: var(--fmc-font--miller);
  font-size: 7px;
  line-height: 8px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
}
@media (max-width: 1024px) {
  .cta-banner-item__disclaimer-text {
    text-align: center;
  }
}

.cta-banner__toggle-play-container {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
}
.cta-banner__toggle-play-container.active {
  display: flex;
}

.cta-banner__toggle-play-button {
  height: 18px;
  width: 18px;
  background-position: center;
  background-size: cover;
  margin-bottom: 24px;
}
.cta-banner__toggle-play-button.play {
  background-image: url("data:image/svg+xml,%3Csvg height='512px' id='Layer_1' style='enable-background:new 0 0 512 512;' version='1.1' viewBox='0 0 512 512' width='512px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%23fff' d='M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20 c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z'/%3E%3C/svg%3E");
}
.cta-banner__toggle-play-button.pause {
  background-image: url("data:image/svg+xml,%3Csvg height='512px' id='Layer_1' style='enable-background:new 0 0 512 512;' version='1.1' viewBox='0 0 512 512' width='512px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath fill='%23fff' d='M224,435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6 C218.6,448,224,442.6,224,435.8z'/%3E%3Cpath fill='%23fff' d='M371.8,64h-71.6c-6.7,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6c6.7,0,12.2-5.4,12.2-12.2V76.1 C384,69.4,378.6,64,371.8,64z'/%3E%3C/g%3E%3C/svg%3E");
}

.cta-banner--hidden {
  content-visibility: hidden;
  /* when hidden, we want the element to size as if it had one child of 0x0 size */
  contain-intrinsic-size: 0 0;
}

.cta-banner--visible {
  content-visibility: visible;
  /* this is here to avoid layout shift when switching between .hidden and .visible */
  contain: style layout paint;
}

.featured-content__title {
  margin-bottom: 30px;
  margin-top: 0;
  letter-spacing: 4px;
}

.cta__feature {
  position: relative;
  width: 100%;
  height: 333px;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media (max-width: 480px) {
  .cta__feature {
    height: fit-content;
  }
}
.cta__feature .banner-datetime-check {
  width: 100%;
  min-height: 100%;
}

.cta__feature__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  object-fit: cover;
  filter: brightness(60%);
}

.cta__feature__content {
  position: relative;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
  margin: 2.5em auto;
}
.cta__feature__content .small--heading {
  font-weight: 500;
  font-size: 18px;
  margin-top: 2em;
}
@media (max-width: 480px) {
  .cta__feature__content .small--heading {
    font-size: 10px;
  }
}
.cta__feature__content .large--heading {
  font-weight: 700;
  font-size: 68px;
}
@media (max-width: 480px) {
  .cta__feature__content .large--heading {
    font-size: 24px;
  }
}
.cta__feature__content .offer--text {
  font-weight: 400;
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  .cta__feature__content .offer--text {
    font-size: 10px;
  }
}
.cta__feature__content .promo--text {
  font-weight: 600;
}
@media (max-width: 480px) {
  .cta__feature__content .promo--text {
    font-size: 10px;
  }
}
.cta__feature__content .disclaimer--text {
  font-weight: 400;
  font-size: 7px;
  margin-top: auto;
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  .cta__feature__content .disclaimer--text {
    font-size: 6px;
  }
}
.cta__feature__content span {
  font-weight: 700;
  font-size: 22px;
  color: #3caeee;
}
@media (max-width: 480px) {
  .cta__feature__content span {
    font-size: 12px;
  }
}
@media screen and (min-width: 712px) {
  .cta__feature__content .spacer {
    margin: 1em;
  }
}

.store-locator {
  padding-top: 40px;
}
@media (max-width: 768px) {
  .store-locator {
    padding-top: 35px;
    margin-bottom: 0;
  }
}

.store-locator__heading {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .store-locator__heading {
    text-align: left !important;
    margin-bottom: 35px;
  }
}

.store-locator__subtitle {
  max-width: 850px;
  margin: 0 auto;
  margin-bottom: 40px;
  font-size: 16px;
}
.store-locator__subtitle p {
  text-align: center;
}
@media (max-width: 768px) {
  .store-locator__subtitle p {
    text-align: left;
  }
}
@media (max-width: 768px) {
  .store-locator__subtitle {
    text-align: left;
    margin-bottom: 76px;
  }
}

@media (max-width: 768px) {
  #stockist-widget {
    height: auto;
    width: calc(100% + 30px + 30px) !important;
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media (max-width: 600px) {
  #stockist-widget {
    width: calc(100% + 30px + 30px) !important;
    margin-left: -30px;
    margin-right: -30px;
  }
}

#stockist-widget .stockist-horizontal {
  background-color: #f4f4f3;
  height: 878px;
}
@media (max-width: 768px) {
  #stockist-widget .stockist-horizontal {
    overflow-y: scroll !important;
  }
}

#stockist-widget .stockist-horizontal .stockist-side-panel {
  width: 442px !important;
  height: 878px;
}
@media (max-width: 768px) {
  #stockist-widget .stockist-horizontal .stockist-side-panel {
    width: 100% !important;
    height: auto;
  }
}

#stockist-widget .stockist-query-entry {
  padding: 30px;
}
@media (max-width: 768px) {
  #stockist-widget .stockist-query-entry {
    width: 100%;
    position: relative;
    padding-bottom: 65px;
  }
  #stockist-widget .stockist-query-entry .stockist-search-button {
    width: 100% !important;
    margin-left: -30px;
    margin-right: -30px;
    position: absolute;
    left: 30px;
    bottom: -15px;
  }
  #stockist-widget .stockist-query-entry .stockist-search-button button {
    width: 100% !important;
  }
  #stockist-widget .stockist-query-entry .stockist-search-button button:after {
    content: "Search Now";
    color: $white-color;
    font-family: var(--fmc-font--proxima-nova);
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
  }
  #stockist-widget
    .stockist-query-entry
    .stockist-search-button
    .stockist-icon {
    display: none;
  }
}

#stockist-widget .stockist-query-entry::before {
  position: relative;
  display: block;
  text-transform: uppercase;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 1.5px;
  margin-bottom: 23px;
}
@media (max-width: 768px) {
  #stockist-widget .stockist-query-entry::before {
    text-align: center;
    color: #575757;
    font-weight: 200;
    letter-spacing: 1.5px;
    border-bottom: 1px solid #eeeeee;
  }
}

#stockist-widget .stockist-query-entry .stockist-search-field {
  background-color: $white-color;
  height: 51px !important;
}

#stockist-widget .stockist-query-entry .stockist-search-button button {
  height: 51px !important;
  width: 51px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  background-color: $theme-color !important;
}
@media (max-width: 768px) {
  #stockist-widget .stockist-query-entry .stockist-search-button button {
    width: 100% !important;
  }
}

#stockist-widget .stockist-query-entry .stockist-search-button {
  vertical-align: bottom !important;
}

#stockist-widget .stockist-map {
  left: 442px !important;
  height: 878px;
}
@media (max-width: 768px) {
  #stockist-widget .stockist-map {
    height: 400px !important;
    margin-bottom: 0 !important;
  }
}

#stockist-widget .stockist-map .stockist-map-inner {
  height: 878px;
}
@media (max-width: 768px) {
  #stockist-widget .stockist-map .stockist-map-inner {
    height: 400px !important;
  }
}

#stockist-widget .stockist-horizontal .stockist-result-list {
  height: calc(100% - 176px) !important;
  padding: 0 30px !important;
}
#stockist-widget .stockist-horizontal .stockist-result-list ul {
  padding-bottom: 60px;
}
@media (max-width: 768px) {
  #stockist-widget .stockist-horizontal .stockist-result-list ul {
    padding-top: 67px;
    padding-bottom: 15px;
  }
}

#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result:before {
  display: none;
  content: "";
  width: 40px;
  height: 100%;
  background-color: $theme-color;
  position: absolute;
  top: 0;
  right: 0;
  left: -40px;
}

#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result:after {
  display: none;
  content: "";
  width: 40px;
  height: 100%;
  background-color: $theme-color;
  position: absolute;
  top: 0;
  right: -40px;
}

#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result.stockist-selected {
  background-color: $theme-color;
}
#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result.stockist-selected
  * {
  filter: brightness(0) invert(1);
}
#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result.stockist-selected:before,
#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result.stockist-selected:after {
  display: block;
}

#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result-name {
  margin-bottom: 15px;
  color: $theme-color;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
  line-height: 26px;
  text-align: left;
  text-transform: uppercase;
}

#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result-address {
  margin-bottom: 15px;
  color: #4d4d4d;
  font-size: 14px;
  line-height: 24px;
}

#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result-details {
  margin-top: 0;
}

#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result-details
  .stockist-result-phone {
  margin-bottom: 15px;
  color: #4d4d4d;
  font-size: 14px;
  line-height: 24px;
}

#stockist-widget
  .stockist-horizontal
  .stockist-result-list
  .stockist-result-details
  .stockist-result-email
  a {
  color: $theme-color;
  text-decoration: underline;
  font-size: 14px;
  line-height: 24px;
}

#stockist-widget .gm-style-iw.gm-style-iw-c {
  border-radius: 0 !important;
  padding: 25px 20px !important;
}
#stockist-widget .gm-style-iw.gm-style-iw-c .gm-style-iw-d {
  padding: 0 !important;
}
#stockist-widget .gm-style-iw.gm-style-iw-c .stockist-map-result {
  padding: 0 !important;
}
#stockist-widget .gm-style-iw.gm-style-iw-c .stockist-map-result * {
  white-space: pre-wrap;
}
#stockist-widget .gm-style-iw.gm-style-iw-c button {
  width: auto !important;
  height: auto !important;
  top: 21px !important;
  right: 17px !important;
}
#stockist-widget .gm-style-iw.gm-style-iw-c button img {
  margin: 0 !important;
}
#stockist-widget .gm-style-iw.gm-style-iw-c .stockist-result-name {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
  line-height: 26px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 5px;
}
#stockist-widget .gm-style-iw.gm-style-iw-c .stockist-result-address,
#stockist-widget .gm-style-iw.gm-style-iw-c .stockist-result-details {
  color: $theme-color;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: left;
}
#stockist-widget .gm-style-iw.gm-style-iw-c .stockist-result-address {
  margin-bottom: 15px;
}
#stockist-widget .gm-style-iw.gm-style-iw-c .stockist-result-details {
  margin-top: 0;
}
#stockist-widget .gm-style-iw.gm-style-iw-c .stockist-result-phone {
  margin-bottom: 2px;
}
#stockist-widget .gm-style-iw.gm-style-iw-c .stockist-result-email {
  text-decoration: underline;
}
#stockist-widget .gm-style-iw.gm-style-iw-c .stockist-result-directions-link {
  display: none;
}

@media (max-width: 768px) {
  #stockist-widget {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  #stockist-widget .stockist-search-form {
    order: 1;
  }
  #stockist-widget .stockist-map {
    order: 2;
  }
  #stockist-widget .stockist-horizontal {
    order: 3;
  }
}

.page__content h1 {
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 40px;
}

.page__content h2 {
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 34px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 4px;
  line-height: 44px;
  text-align: left;
  text-transform: uppercase;
}

.page__content h3 {
  color: #4d4d4d !important;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  line-height: 34px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 40px;
}

.page__content h4 {
  margin-bottom: 0px;
  margin-top: 20px;
}

.page__content h5 {
  margin-bottom: 0px;
  margin-top: 20px;
}

.page__content h6 {
  margin-bottom: 0px;
  margin-top: 20px;
}

.page__content p,
.page__content span {
  color: #4d4d4d !important;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: left;
  margin-top: 20px;
}

.page__content b {
  font-weight: 600;
}

.page__content ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-top: 40px;
}
.page__content ul li {
  font-size: 14px;
}

.page__content img {
  margin-top: 60px;
}

/* Sections / Home */
/* ------------------------------------------
    Hero
------------------------------------------ */
.hero {
  height: 431px;
  position: relative;
  margin: 0;
}

.hero__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero__overlay:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
}

.hero__overlay--light-gradient:after {
  background: linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0) 100%);
}

.hero__overlay--dark-gradient:after {
  background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

.hero__overlay--light-overlay:after {
  background: rgba(255, 255, 255, 0.7);
}

.hero__overlay--dark-overlay:after {
  background: rgba(0, 0, 0, 0.4);
}

.hero__breadcrumbs {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
}
.hero__breadcrumbs span {
  color: #fff !important;
}

.hero__content {
  position: relative;
  height: 100%;
}

.hero__content-inner {
  max-width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  position: absolute;
}

.hero__subtitle {
  margin-top: 30px;
}

.hero__textarea {
  margin-top: 25px;
}

.hero__cta {
  margin-top: 40px;
  margin-bottom: 30px;
}

.image-cta-grid__title {
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .image-cta-grid__title {
    margin-bottom: 29.25px;
  }
}
@media (max-width: 768px) {
  .image-cta-grid__title {
    margin-bottom: 28.5px;
  }
}
@media (max-width: 600px) {
  .image-cta-grid__title {
    margin-bottom: 27px;
  }
}
@media (max-width: 600px) {
  .image-cta-grid__title {
    text-align: center;
  }
}

.image-cta-grid__grid {
  grid-gap: 14px;
}
@media (max-width: 1024px) {
  .image-cta-grid__grid {
    grid-template-columns: 1fr;
  }
}

.image-cta-grid__grid-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
}
.image-cta-grid__grid-column:first-child .image-cta:first-child,
.image-cta-grid__grid-column:nth-child(2) .image-cta:nth-child(3) {
  grid-column: 1 / -1;
  height: 512px;
}
@media (max-width: 1024px) {
  .image-cta-grid__grid-column:first-child .image-cta:first-child,
  .image-cta-grid__grid-column:nth-child(2) .image-cta:nth-child(3) {
    height: 472.5px;
  }
}
@media (max-width: 768px) {
  .image-cta-grid__grid-column:first-child .image-cta:first-child,
  .image-cta-grid__grid-column:nth-child(2) .image-cta:nth-child(3) {
    height: 433px;
  }
}
@media (max-width: 600px) {
  .image-cta-grid__grid-column:first-child .image-cta:first-child,
  .image-cta-grid__grid-column:nth-child(2) .image-cta:nth-child(3) {
    height: 354px;
  }
}
.image-cta-grid__grid-column:first-child
  .image-cta:first-child
  .image-cta__text,
.image-cta-grid__grid-column:nth-child(2)
  .image-cta:nth-child(3)
  .image-cta__text {
  bottom: 64px;
}
@media (max-width: 1024px) {
  .image-cta-grid__grid-column:first-child
    .image-cta:first-child
    .image-cta__text,
  .image-cta-grid__grid-column:nth-child(2)
    .image-cta:nth-child(3)
    .image-cta__text {
    bottom: 56.75px;
  }
}
@media (max-width: 768px) {
  .image-cta-grid__grid-column:first-child
    .image-cta:first-child
    .image-cta__text,
  .image-cta-grid__grid-column:nth-child(2)
    .image-cta:nth-child(3)
    .image-cta__text {
    bottom: 49.5px;
  }
}
@media (max-width: 600px) {
  .image-cta-grid__grid-column:first-child
    .image-cta:first-child
    .image-cta__text,
  .image-cta-grid__grid-column:nth-child(2)
    .image-cta:nth-child(3)
    .image-cta__text {
    bottom: 35px;
  }
}
@media (max-width: 1024px) {
  .image-cta-grid__grid-column:nth-child(2) .image-cta:nth-child(3) {
    grid-row: 1 / 2;
  }
}

.image-cta {
  height: 251px;
  display: block;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1024px) {
  .image-cta {
    height: 230.75px;
  }
}
@media (max-width: 768px) {
  .image-cta {
    height: 210.5px;
  }
}
@media (max-width: 600px) {
  .image-cta {
    height: 170px;
  }
}
.image-cta > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.image-cta.object-cover__top img {
  object-position: top;
}
.image-cta:after {
  content: "";
  display: block;
  width: 100%;
  height: 150px;
  background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.image-cta.image-cta--tint-disabled:after {
  display: none;
}
@media (min-width: 769px) {
  .image-cta:hover img {
    -webkit-transform: scale(1.025);
    -moz-transform: scale(1.025);
    -ms-transform: scale(1.025);
    -o-transform: scale(1.025);
    transform: scale(1.025);
  }
}
.image-cta img {
  -webkit-transition: 0.4s all ease-in-out;
  -moz-transition: 0.4s all ease-in-out;
  -ms-transition: 0.4s all ease-in-out;
  -o-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
  will-change: transform;
}

.image-cta__text {
  position: absolute;
  bottom: 33px;
  left: 42px;
  z-index: 2;
}
@media (max-width: 1024px) {
  .image-cta__text {
    bottom: 27.25px;
    left: 34px;
  }
}
@media (max-width: 768px) {
  .image-cta__text {
    bottom: 21.5px;
    left: 26px;
  }
}
@media (max-width: 600px) {
  .image-cta__text {
    bottom: 10px;
    left: 10px;
  }
}

.offer-banners__title {
  margin-bottom: 30px;
  margin-top: 0;
  letter-spacing: 4px;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 1024px) {
  .offer-banners__title {
    margin-bottom: 29.25px;
  }
}
@media (max-width: 768px) {
  .offer-banners__title {
    margin-bottom: 28.5px;
  }
}
@media (max-width: 600px) {
  .offer-banners__title {
    margin-bottom: 27px;
  }
}
@media (max-width: 600px) {
  .offer-banners__title {
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .offer-banners__title {
    margin-bottom: 27.5px;
  }
}
@media (max-width: 768px) {
  .offer-banners__title {
    margin-bottom: 25px;
  }
}
@media (max-width: 600px) {
  .offer-banners__title {
    margin-bottom: 20px;
  }
}
@media (max-width: 1024px) {
  .offer-banners__title {
    margin-top: 2.5px;
  }
}
@media (max-width: 768px) {
  .offer-banners__title {
    margin-top: 5px;
  }
}
@media (max-width: 600px) {
  .offer-banners__title {
    margin-top: 10px;
  }
}

.offer-banners__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 23px;
}
@media (max-width: 1024px) {
  .offer-banners__grid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (max-width: 768px) {
  .offer-banners__grid {
    grid-template-columns: 1fr;
  }
}

[class*="offer-banner--"] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  padding-left: 53px;
  padding-right: 53px;
  padding-top: 19px;
  padding-bottom: 27px;
  height: 200px;
}
@media (max-width: 1024px) {
  [class*="offer-banner--"] {
    padding-left: 44.25px;
    padding-right: 44.25px;
    padding-top: 19.75px;
    padding-bottom: 29.25px;
  }
}
@media (max-width: 768px) {
  [class*="offer-banner--"] {
    padding-left: 35.5px;
    padding-right: 35.5px;
    padding-top: 20.5px;
    padding-bottom: 31.5px;
  }
}
@media (max-width: 600px) {
  [class*="offer-banner--"] {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 22px;
    padding-bottom: 36px;
  }
}

@media (min-width: 1025px) {
  .offer-banner--long {
    grid-column: auto / span 2;
  }
}

@media (min-width: 769px) {
  .offer-banner--long .offer-banner__title {
    width: 498px;
    max-width: 100%;
  }
}

.offer-banner--long .offer-banner__cta {
  max-width: 100%;
}
@media (min-width: 1025px) {
  .offer-banner--long .offer-banner__cta {
    width: 264px;
  }
}

.offer-banner__title,
.offer-banner__cta {
  position: relative;
}

@media (max-width: 600px) {
  .offer-banner__title {
    line-height: 30px;
  }
}

.offer-banner__cta {
  width: 191px;
}

/* ---------------------------------------------
    Icon banner
--------------------------------------------- */
.icon-banner {
  background: #f5f5f5;
  padding: 17px 0;
  margin-top: 30px;
}
@media (min-width: 1025px) {
  .icon-banner {
    -webkit-box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
  }
}
@media (max-width: 1024px) {
  .icon-banner {
    background: none;
    margin-top: 0px;
    padding: 0;
    -webkit-transition: 0.2s all ease-in-out;
    -moz-transition: 0.2s all ease-in-out;
    -ms-transition: 0.2s all ease-in-out;
    -o-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
  }
}

/* ---------------------------------------------
    Icon banner segment
--------------------------------------------- */
.icon-banner__segment {
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 1024px) {
  .icon-banner__segment {
    padding-left: 36.25px;
    padding-right: 36.25px;
  }
}
@media (max-width: 768px) {
  .icon-banner__segment {
    padding-left: 32.5px;
    padding-right: 32.5px;
  }
}
@media (max-width: 600px) {
  .icon-banner__segment {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.icon-banner__segment > a,
.icon-banner__segment > div {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  text-decoration: none;
}
.icon-banner__segment > a:after,
.icon-banner__segment > div:after {
  content: "";
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-primary-thin.svg?v=119719154482964749141664033355);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  width: 13px;
  height: 22px;
  display: block;
  margin-left: 15px;
  will-change: transform;
}
@media (max-width: 1024px) {
  .icon-banner__segment > a:after,
  .icon-banner__segment > div:after {
    /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-white.svg?v=32409767268468141941664033353);*/
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 8px;
    height: 16px;
  }
}
@media (min-width: 769px) {
  .icon-banner__segment > a:hover:after,
  .icon-banner__segment > div:hover:after {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
  }
}
@media (min-width: 1025px) {
  .icon-banner__segment:nth-of-type(n + 2) {
    border-left: 1px solid #979797;
  }
}
.icon-banner__segment img,
.icon-banner__segment svg {
  width: 38px;
  margin-right: 22px;
}
@media (max-width: 1024px) {
  .icon-banner__segment img,
  .icon-banner__segment svg {
    width: 20px;
    margin-right: 15px;
  }
}
.icon-banner__segment svg {
  height: 38px;
}
@media (max-width: 1024px) {
  .icon-banner__segment svg {
    height: 20px;
  }
}

.icon-banner__segment-text {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media (max-width: 1024px) {
  .icon-banner__segment-title,
  .icon-banner__segment-title span {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: $white-color;
    white-space: normal;
    line-height: normal;
  }
}

.reviews {
  position: relative;
  overflow: hidden;
}
@media (max-width: 600px) {
  .reviews {
    padding-left: 28px;
    padding-right: 28px;
  }
}

@media (min-width: 769px) {
  .reviews__text,
  .reviews__title {
    width: 607px;
    max-width: 50%;
  }
}

.reviews__title {
  letter-spacing: 4px;
}
@media (max-width: 600px) {
  .reviews__title {
    text-align: center;
  }
}

.reviews__text {
  margin-top: 10px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1024px) {
  .reviews__text {
    margin-top: 12.75px;
  }
}
@media (max-width: 768px) {
  .reviews__text {
    margin-top: 15.5px;
  }
}
@media (max-width: 600px) {
  .reviews__text {
    margin-top: 21px;
  }
}
@media (max-width: 600px) {
  .reviews__text {
    position: relative;
  }
  .reviews__text:after {
    content: "";
    width: 100vw;
    height: calc(100% + 8px);
    position: absolute;
    background: $white-color;
    left: -28px;
    top: 0;
    z-index: -1;
  }
}

.reviews__image {
  position: absolute;
  width: 720px;
  top: 0;
  left: 50%;
  background: $theme-color;
}
@media (max-width: 768px) {
  .reviews__image {
    top: auto;
    bottom: -50px;
    width: auto;
    height: 350px;
    left: 65%;
  }
}
@media (max-width: 480px) {
  .reviews__image {
    bottom: 0;
  }
}

.reviews__slider {
  margin-top: 54px;
}
@media (max-width: 768px) {
  .reviews__slider {
    margin-top: 26px;
  }
}
.reviews__slider .slick-list {
  width: calc(100% + 23px);
  position: relative;
  left: -11.5px;
}
@media (max-width: 768px) {
  .reviews__slider .slick-list {
    width: calc(100% + 17px);
    position: relative;
    left: -8.5px;
  }
}
@media (max-width: 480px) {
  .reviews__slider .slick-list {
    width: calc(100% + 17px + 28px);
  }
}
@media (max-width: 480px) {
  .reviews__slider .slick-slide {
    width: 295px;
  }
}

.review__inner {
  border: 2px solid $theme-color;
  background: $white-color;
  padding: 30px;
  margin: 0 11.5px;
}
@media (max-width: 768px) {
  .review__inner {
    padding-left: 24px;
    padding-right: 17px;
    margin: 0 8.5px;
  }
}

.review__text {
  font-size: 14px;
  line-height: 26px;
  letter-spacing: normal;
}

.review__author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
}
.review__author img {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  margin-right: 12px;
}

.review__author-name {
  text-transform: uppercase;
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 300;
  letter-spacing: 4px;
  color: $theme-color;
}

@media (max-width: 600px) {
  .featured-articles {
    padding-left: 52px;
    padding-right: 52px;
    margin-top: 57px;
  }
  .featured-articles .featured-articles__container {
    padding-left: 0;
    padding-right: 0;
  }
}

.featured-articles__title {
  letter-spacing: 4px;
}
@media (max-width: 600px) {
  .featured-articles__title {
    text-align: center;
  }
}

.featured-articles__text {
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .featured-articles__text {
    margin-top: 12.75px;
  }
}
@media (max-width: 768px) {
  .featured-articles__text {
    margin-top: 15.5px;
  }
}
@media (max-width: 600px) {
  .featured-articles__text {
    margin-top: 21px;
  }
}
@media (min-width: 769px) {
  .featured-articles__text {
    width: 607px;
    max-width: 50%;
  }
}

.featured-articles__grid {
  margin-top: 34px;
}
@media (max-width: 600px) {
  .featured-articles__grid .slick-list {
    width: calc(100% + 14px + 52px);
    margin-left: -7px;
  }
}
@media (max-width: 600px) {
  .featured-articles__grid .slick-slide {
    width: 270px;
    padding: 0 7px;
  }
}

@media (max-width: 600px) {
  .featured-articles__article-item .article-item__image {
    height: 270px;
  }
}

.featured-articles__article-item .article-item__title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.featured-articles--alt {
  margin-top: 40px;
  margin-bottom: 40px;
}
.featured-articles--alt .featured-articles__container {
  position: relative;
  padding-top: 25px;
}
.featured-articles--alt .featured-articles__container:before {
  content: "";
  display: block;
  width: calc(100% - (30px * 2));
  margin-left: 30px;
  margin-right: 30px;
  height: 300px;
  background-color: #f4f4f4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
@media (max-width: 1024px) {
  .featured-articles--alt .featured-articles__container:before {
    width: calc(100% - (30px * 2));
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media (max-width: 768px) {
  .featured-articles--alt .featured-articles__container:before {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 600px) {
  .featured-articles--alt .featured-articles__container:before {
    width: calc(100% + (52px * 2));
    margin-left: -52px;
    margin-right: -52px;
  }
}
.featured-articles--alt .featured-articles__title {
  position: relative;
  text-align: center;
  z-index: 1;
}
.featured-articles--alt .featured-articles__text {
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.27px;
}
.featured-articles--alt .featured-articles__grid {
  padding-left: 50px;
  padding-right: 50px;
  grid-gap: 92px;
}
@media (max-width: 768px) {
  .featured-articles--alt .featured-articles__grid {
    grid-gap: 30px;
  }
}
@media (max-width: 600px) {
  .featured-articles--alt .featured-articles__grid {
    padding-left: 0;
    padding-right: 0;
  }
}
.featured-articles--alt .article-item__image {
  height: 344px;
}
@media (max-width: 768px) {
  .featured-articles--alt .article-item__image {
    height: 270px;
  }
}

.banner-section__title {
  margin-bottom: 30px;
  letter-spacing: 4px;
}
@media (max-width: 1024px) {
  .banner-section__title {
    margin-bottom: 29.25px;
  }
}
@media (max-width: 768px) {
  .banner-section__title {
    margin-bottom: 28.5px;
  }
}
@media (max-width: 600px) {
  .banner-section__title {
    margin-bottom: 27px;
  }
}
@media (max-width: 600px) {
  .banner-section__title {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .banner-section {
    padding: 0;
  }
}

/* Sections / FAQ */
.search-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 45px;
  margin: 0 auto;
}

.faq-search-form {
  text-align: center;
}
.faq-search-form svg {
  position: absolute;
  z-index: 9;
  left: 15px;
  top: -3px;
}

.faq-search-form__close {
  padding: 0;
  width: 20px;
  height: 20px;
  margin-right: 0;
  position: absolute;
  z-index: 9;
  right: 17px;
  top: -7px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.faq-search-form__close.active {
  opacity: 1;
  visibility: visible;
}

.faq-search-form__input {
  color: $theme-color;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: left;
  border: 1px solid #6e6e6e;
  outline: none;
  border-radius: 3px 3px 3px 3px;
  width: 100%;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  padding: 11px 45px;
  margin-bottom: 0px;
  background: $white-color;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.faq-search-form__input::-webkit-input-placeholder {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  color: $theme-color;
  opacity: 1;
}
.faq-search-form__input:-moz-placeholder {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  color: $theme-color;
  opacity: 1;
}
.faq-search-form__input::-moz-placeholder {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  color: $theme-color;
  opacity: 1;
}
.faq-search-form__input:-ms-input-placeholder {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  color: $theme-color;
  opacity: 1;
}
.faq-search-form__input::placeholder {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  color: $theme-color;
  opacity: 1;
}
.faq-search-form__input:focus {
  outline: none;
}
.faq-search-form__input.faq-search-form__input--no-bottom-border-radius {
  border-radius: 3px 3px 0px 0px;
}

.sort {
  padding: 8px 30px;
  border-radius: 6px;
  border: none;
  display: inline-block;
  color: $white-color;
  text-decoration: none;
  background-color: #28a8e0;
  height: 30px;
}
.sort:hover {
  text-decoration: none;
  background-color: #1b8aba;
}
.sort:focus {
  outline: none;
}
.sort:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: relative;
  top: -10px;
  right: -5px;
}
.sort.asc:after {
  content: "";
  border-top: 5px solid $white-color;
  top: 13px;
}
.sort.desc:after {
  border-bottom: 5px solid $white-color;
}

.faq-suggested-results {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateY(29px);
  -moz-transform: translateY(29px);
  -ms-transform: translateY(29px);
  -o-transform: translateY(29px);
  transform: translateY(29px);
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  background-color: $white-color;
  border: 1px solid #6e6e6e;
  border-top: none;
  border-radius: 0px 0px 3px 3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}
.faq-suggested-results.active {
  opacity: 1;
  visibility: visible;
}

.faq-suggested-results__item {
  width: 100%;
  text-align: left;
  color: $theme-color;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  padding: 12px 15px;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.faq-suggested-results__item:hover {
  background: #6e6e6e;
  color: $white-color;
}

.faq-intro__title {
  color: #4d4d4d !important;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  line-height: 34px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.faq-intro__content p {
  color: #4d4d4d !important;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: left;
}
.faq-intro__content p a {
  color: $theme-color;
  font-weight: 500;
  text-decoration: none;
}

.faq-qa__item.hidden {
  display: none;
}

.faq-qa__item.open .faq-qa__question {
  color: $theme-color;
}

.faq-qa__question {
  color: #4d4d4d;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.faq-qa__answer {
  color: #4d4d4d;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: left;
}
.faq-qa__answer.open {
  height: auto !important;
  padding-top: 20px !important;
}
.faq-qa__answer a {
  color: $theme-color;
  text-decoration: none;
}
.faq-qa__answer .btn {
  margin-top: 30px;
  color: $white-color;
}

.faq-qa__image {
  width: 100%;
  height: auto;
  margin-top: 35px;
}

.faq-topics__category-select {
  display: none;
  width: 100%;
  padding: 25px;
  border: 1px solid #6e6e6e;
  cursor: pointer;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .faq-topics__category-select {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px;
  }
}
.faq-topics__category-select svg {
  margin-top: 5px;
  transform: rotateX(0deg);
  -webkit-transition: 0.35s;
  -moz-transition: 0.35s;
  -ms-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
}
.faq-topics__category-select.open {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.faq-topics__category-select.open svg {
  transform: rotateX(180deg);
}

.faq-top-filter-area {
  padding: 0 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 1024px) {
  .faq-top-filter-area {
    display: none;
    padding: 0px;
    border-bottom: 1px solid #6e6e6e;
    border-left: 1px solid #6e6e6e;
    border-right: 1px solid #6e6e6e;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .faq-top-filter-area.open {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.faq-topics__topic.active .faq-topics__topic-link {
  border-bottom: 3px solid $theme-color;
  padding: 15px;
}
@media (max-width: 1024px) {
  .faq-topics__topic.active .faq-topics__topic-link {
    border-bottom: 0px;
    font-weight: 900;
    color: $theme-color !important;
  }
}

.faq-topics__topic-link {
  cursor: pointer;
  color: #4d4d4d !important;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 2px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px;
}
@media (max-width: 1024px) {
  .faq-topics__topic-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.faq-topic-headings__heading {
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  line-height: 34px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 60px;
  margin-bottom: 10px;
  display: none;
}
@media (max-width: 1024px) {
  .faq-topic-headings__heading {
    margin-top: 40px;
  }
}
.faq-topic-headings__heading.active {
  display: block;
}

@media (max-width: 1024px) {
  .faq-contact {
    padding-left: 0;
    padding-right: 0;
  }
}

.faq-contact__title {
  color: #4d4d4d !important;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  line-height: 34px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.faq-contact__content {
  background: #f4f4f4 !important;
  padding: 20px 30px;
}
.faq-contact__content p {
  color: #4d4d4d !important;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: left;
}
.faq-contact__content p a {
  color: $theme-color;
  text-decoration: underline;
}

/* Sections / Account */
.account-hero {
  position: relative;
  height: 325px;
  overflow: hidden;
}

.account-hero__background {
  position: absolute;
  height: 325px;
  width: 100%;
}

.account-hero__overlay:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
}

.account-hero__overlay--light-gradient:after {
  background: linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0) 100%);
}

.account-hero__overlay--dark-gradient:after {
  background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

.account-hero__overlay--light-overlay:after {
  background: rgba(255, 255, 255, 0.7);
}

.account-hero__overlay--dark-overlay:after {
  background: rgba(0, 0, 0, 0.4);
}

.account-hero__title {
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .account-hero__title {
    text-align: center;
  }
}

.site-header__nav-link {
  color: $theme-color;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.5px;
}

.site-header-eyebrow__nav-link {
  font-size: 12px;
}

.site-header-eyebrow__message {
  font-size: 10px;
  padding: 0 30px;
  width: 100%;
}
@media (min-width: 1025px) {
  .site-header-eyebrow__message {
    display: none;
  }
}

.site-header__nav-list-item--parent .site-header__nav-link:after {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln.svg?v=132685267715316169101664033355);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  height: 10px;
  width: 13px;
}

[class*="site-header__nav-list-item"] {
  padding-top: 13px;
}

@media (max-width: 1024px) {
  .site-header__logo {
    width: 110px;
  }
}

.footer-text-block__title {
  letter-spacing: 2.5px;
}
@media (max-width: 1024px) {
  .footer-text-block__title {
    letter-spacing: 2.4025px;
  }
}
@media (max-width: 768px) {
  .footer-text-block__title {
    letter-spacing: 2.305px;
  }
}
@media (max-width: 600px) {
  .footer-text-block__title {
    letter-spacing: 2.11px;
  }
}

.footer-text-block__subtitle {
  font-size: 16px;
}
@media (max-width: 1024px) {
  .footer-text-block__subtitle {
    font-size: 15.25px;
  }
}
@media (max-width: 768px) {
  .footer-text-block__subtitle {
    font-size: 14.5px;
  }
}
@media (max-width: 600px) {
  .footer-text-block__subtitle {
    font-size: 13px;
    width: 100%;
    margin-top: 5px;
  }
}

.footer-nav__column-title {
  font-size: 17px;
  letter-spacing: 2px;
  margin-bottom: 40px;
  color: $white-color;
}
@media (max-width: 1024px) {
  .footer-nav__column-title {
    font-size: 16.5px;
  }
}
@media (max-width: 768px) {
  .footer-nav__column-title {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .footer-nav__column-title,
  .footer-nav__column-title span {
    font-size: 15px;
  }
}

.footer-legal__nav-link {
  font-size: 13px;
}

@media (min-width: 601px) {
  .footer-nav__column {
    margin-bottom: 25px;
  }
}

@media (max-width: 600px) {
  .footer-text-block__cta {
    width: 100%;
  }
  .footer-text-block__inner {
    text-align: left;
  }
  .footer-text-block__subtitle {
    margin-left: 0;
  }
  .footer-nav__list {
    padding: 35px 20px;
    background: #231f20;
  }
  .footer-nav__column-title {
    border: none;
    margin-bottom: 0;
  }
  .footer-nav__column-title:after {
    background: none;
    content: "+";
    color: #f26147;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
  }
  .footer-nav__column-title.active:after {
    content: "-";
  }
  .footer-nav__column {
    border-top: 1px solid #f3f3f3;
  }
  .footer-nav__column:first-of-type {
    border: none;
  }
  .footer-nav__column-list {
    padding-top: 14px;
    padding-right: 26px;
    padding-bottom: 40px;
    padding-left: 26px;
  }
  .footer-nav__link {
    font-size: 15px !important;
  }
}

.disclosures__trigger-text {
  color: #3d3d3d;
}

.disclosures__content .rte a {
  color: #3d3d3d;
}

.cta-banner-item__text span {
  color: $theme-hover-color;
}

/* ---------------------------------------------
    Snippets
--------------------------------------------- */
.article-item__image {
  height: 279px;
  width: 100%;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.24);
  display: block;
  overflow: hidden;
}
.article-item__image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.article-item__image.object-cover__top img {
  object-position: top;
}

.article-item__title {
  margin-top: 18px;
  margin-bottom: 0px;
}
.article-item__title a {
  text-decoration: none;
}

.article-item__subtitle {
  margin-top: 16px;
}

.social-icons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.social-icons__share-title {
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-right: 35px;
}

.social-icons__list-item {
  cursor: pointer;
}
.social-icons__list-item:not(:last-child) {
  margin-right: 20px;
}

.cart-item {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  order: 1;
}

.cart-item--side-cart {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
}

.cart-item--labour {
  order: 2;
  pointer-events: none;
}
.cart-item--labour .cart-item__price--line-item,
.cart-item--labour .cart-item__quantity {
  opacity: 0;
  visibility: hidden;
}

.cart-item__information {
  width: 55%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media (max-width: 768px) {
  .cart-item__information {
    width: 100%;
  }
}

.cart-item__image .cart-item__image-link {
  display: block;
  height: 115px;
}
.cart-item__image .cart-item__image-link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cart-item__image .cart-item__image-remove {
  display: none;
  width: 100%;
  text-align: center;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 6px;
}
@media (max-width: 768px) {
  .cart-item__image {
    width: 123px;
  }
  .cart-item__image .cart-item__image-link {
    height: auto;
  }
  .cart-item__image .cart-item__image-link img {
    height: 123px;
  }
  .cart-item__image .cart-item__image-remove {
    display: initial;
  }
}

.cart-item__details {
  padding-left: 30px;
  padding-right: 10px;
  width: calc(100% - 200px);
  a:hover {
    font-weight: 500 !important;
  }
}
.cart-item__part-number a:hover {
  font-size: 11px;
  line-height: normal;
  font-weight: 400 !important;
  font-style: normal;
  letter-spacing: 1.1px;
}
.cart-item__title a:hover {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1.6px;
}
@media (max-width: 768px) {
  .cart-item__details {
    width: calc(100% - 123px);
  }
}

.cart-item__part-number {
  display: block;
  color: #6e6e6e;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 11px;
  line-height: normal;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.1px;
  margin-bottom: 5px;
}

.cart-item__title {
  color: #3d3d3d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1.6px;
  text-transform: capitalize;
  margin-bottom: 3px;
}
@media (max-width: 768px) {
  .cart-item__title {
    color: #4d4d4d;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
}

.cart-item__variant-value {
  display: block;
  color: #3d3d3d;
  font-size: 11px;
  line-height: normal;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.1px;
  margin-bottom: 9px;
}
@media (max-width: 768px) {
  .cart-item__variant-value {
    color: #4d4d4d;
  }
}

.cart-item__informational-text {
  display: block;
  font-size: 8px;
  line-height: 12px;
  color: #805300;
}

.cart-item__remove,
.cart-item__remove button {
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 4px;
}
/* @media (max-width: 768px) {
  .cart-item__remove {
    display: none;
  }
}*/

.cart-item__price {
  width: 15%;
  p {
    color: $theme-color;
    font-family: var(--fmc-font--proxima-nova);
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2px;
  }
}
.cart-item__price.cart-item__price--line-item {
  text-align: left;
}
.cart-item__price.cart-item__price--total {
  text-align: right;
  font-weight: 400;
}
@media (max-width: 768px) {
  .cart-item__price {
    display: none;
  }
}

.cart-item__quantity {
  width: 15%;
  text-align: center;
}
.cart-item__quantity.cart-item__quantity--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.cart-item__quantity .product-quantity {
  width: 80px;
  margin: 0 auto;
}
.cart-item__quantity .product-quantity__input {
  font-weight: 300;
}
@media (max-width: 768px) {
  .cart-item__quantity {
    display: none;
  }
}

.cart-item__mobile-quantity-and-price {
  display: none;
  margin-top: 9px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.cart-item__mobile-quantity-and-price .cart-item__price {
  display: initial;
  font-weight: 400;
  letter-spacing: 1px;
  width: auto;
}
.cart-item__mobile-quantity-and-price .cart-item__quantity {
  display: initial;
  margin-right: 30px;
  width: auto;
}
.cart-item__mobile-quantity-and-price .cart-item__quantity .product-quantity {
  width: 68px;
}
.cart-item__mobile-quantity-and-price
  .cart-item__quantity
  .product-quantity__input {
  width: 48px;
}
@media (max-width: 768px) {
  .cart-item__mobile-quantity-and-price {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 350px) {
  .cart-item__mobile-quantity-and-price {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cart-item__mobile-quantity-and-price .cart-item__price {
    margin-top: 10px;
    width: 100%;
    text-align: left;
  }
  .cart-item__mobile-quantity-and-price .cart-item__quantity {
    margin-right: 0px;
  }
}

.cart-item.cart-item--side-cart .cart-item__information {
  width: 100%;
}

.cart-item.cart-item--side-cart .cart-item__image {
  width: 201px;
}
.cart-item.cart-item--side-cart .cart-item__image .cart-item__image-link {
  height: auto;
}
.cart-item.cart-item--side-cart .cart-item__image .cart-item__image-link img {
  height: 135px;
}
.cart-item.cart-item--side-cart .cart-item__image .cart-item__image-remove {
  display: initial;
}
@media (max-width: 490px) {
  .cart-item.cart-item--side-cart .cart-item__image {
    width: 123px;
  }
  .cart-item.cart-item--side-cart .cart-item__image .cart-item__image-link img {
    height: 123px;
  }
}

.cart-item.cart-item--side-cart .cart-item__details {
  width: calc(100% - 201px);
}
@media (max-width: 490px) {
  .cart-item.cart-item--side-cart .cart-item__details {
    width: calc(100% - 123px);
    padding-left: 15px;
  }
}

.cart-item.cart-item--side-cart .cart-item__title {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1.6px;
  margin-bottom: 6px;
}
@media (max-width: 490px) {
  .cart-item.cart-item--side-cart .cart-item__title {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 11px;
  }
}

.cart-item.cart-item--side-cart .cart-item__variant-value {
  color: #4d4d4d;
}

.cart-item.cart-item--side-cart .cart-item__remove {
  display: none;
}

.cart-item.cart-item--side-cart .cart-item__price {
  display: none;
}

.cart-item.cart-item--side-cart .cart-item__quantity {
  display: none;
}

.cart-item.cart-item--side-cart .cart-item__mobile-quantity-and-price {
  margin-top: 9px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.cart-item.cart-item--side-cart
  .cart-item__mobile-quantity-and-price
  .cart-item__price {
  display: initial;
  font-weight: 400;
  letter-spacing: 1px;
  width: auto;
}
.cart-item.cart-item--side-cart
  .cart-item__mobile-quantity-and-price
  .cart-item__quantity {
  display: initial;
  margin-right: 30px;
  width: auto;
}
.cart-item.cart-item--side-cart
  .cart-item__mobile-quantity-and-price
  .cart-item__quantity
  .product-quantity {
  width: 63px;
}
.cart-item.cart-item--side-cart
  .cart-item__mobile-quantity-and-price
  .cart-item__quantity
  .product-quantity__input {
  font-weight: 300;
  width: 47px;
  height: 25px;
  margin-right: 6px;
}
@media (max-width: 490px) {
  .cart-item.cart-item--side-cart
    .cart-item__mobile-quantity-and-price
    .cart-item__quantity
    .product-quantity {
    width: 66px;
  }
  .cart-item.cart-item--side-cart
    .cart-item__mobile-quantity-and-price
    .cart-item__quantity
    .product-quantity__input {
    width: 48px;
    height: 40px;
    margin-right: 8px;
  }
}
@media (max-width: 350px) {
  .cart-item.cart-item--side-cart .cart-item__mobile-quantity-and-price {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cart-item.cart-item--side-cart
    .cart-item__mobile-quantity-and-price
    .cart-item__quantity {
    margin-right: 0;
  }
  .cart-item.cart-item--side-cart
    .cart-item__mobile-quantity-and-price
    .cart-item__price {
    width: 100%;
    margin-top: 10px;
  }
}

#storePickupApp .success {
  display: none;
}

#storePickupApp .locations {
  display: none;
}

/* ---------------------------------------------
    List item
--------------------------------------------- */
[class*="user-menu__item"]:nth-of-type(n + 2) {
  margin-left: 40px;
}
@media (max-width: 1439px) {
  [class*="user-menu__item"]:nth-of-type(n + 2) {
    margin-left: 25px;
  }
}

.user-menu__item--cart {
  position: relative;
}

.user-menu__item--account svg path {
  fill: $theme-color;
}

.user-menu__cart-item-count {
  pointer-events: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
  background: $theme-color;
  color: $white-color;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: 0;
  font-size: 10px;
  line-height: 10px;
  padding-left: 2px;
}
.user-menu__cart-item-count.hidden {
  display: none;
}
@media (max-width: 1200px) {
  .user-menu__cart-item-count {
    right: -10px;
  }
}

.user-menu__item--garage.user-menu__item--garageUnauthed
  .user-menu__link:after {
  content: "";
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-down-primary.svg?v=44734096722713386291664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 15px;
  height: 8px;
  margin-left: 10px;
}
@media (max-width: 500px) {
  .user-menu__item--garage.user-menu__item--garageUnauthed
    .user-menu__link:after {
    display: none;
  }
}

.user-menu__item--garage.user-menu__item--garage-no-chevron
  .user-menu__link:after {
  display: none;
}

/* ---------------------------------------------
    Link
--------------------------------------------- */
.user-menu__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  color: $theme-color;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  white-space: nowrap;
}
.user-menu__link svg {
  margin-right: 8px;
  color: $theme-color;
}

.fmc-type--heading1,
.fmc-type--h1,
.fmc-type--heading2,
.fmc-type--h2,
.fmc-type--heading3,
.fmc-type--h3,
.fmc-type--heading4,
.fmc-type--h4,
.fmc-type--heading5,
.fmc-type--h5,
.fmc-type--heading6,
.fmc-type--h6,
.fmc-type--subtitle1,
.fmc-type--subtitle2,
.fmc-type--body1,
.fmc-type--body2,
.fmc-type--content2,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
a:link,
a:active,
.fmc-type--content1,
.fmc-type--emphasize {
  color: $theme-color;
}
/* ---------------------------------------------
    My garage
--------------------------------------------- */
.user-menu__item--garage.has-vehicles {
  position: relative;
}
.user-menu__item--garage.has-vehicles:before {
  content: attr(data-vehicle-count);
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  background: #a8ce38;
  color: $theme-color;
  position: absolute;
}
.user-menu__item--garage.has-vehicles:after {
  content: "";
  height: 16px;
  display: block;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.user-menu__item--garage.has-vehicles:before {
  left: 12px;
  top: -7px;
}
@media (max-width: 1024px) {
  .user-menu__item--garage.has-vehicles:before {
    top: 27%;
    left: 34%;
  }
}
@media (max-width: 450px) {
  .user-menu__item--garage.has-vehicles:before {
    left: 27%;
  }
}
@media (max-width: 330px) {
  .user-menu__item--garage.has-vehicles:before {
    left: 22%;
  }
}

@media (max-width: 1024px) {
  body.user-not-logged-in .user-menu__item--garage.has-vehicles:before {
    left: 28%;
  }
}

@media (max-width: 400px) {
  body.user-not-logged-in .user-menu__item--garage.has-vehicles:before {
    left: 22%;
  }
}

@media (max-width: 340px) {
  body.user-not-logged-in .user-menu__item--garage.has-vehicles:before {
    left: 16%;
  }
}

@media (max-width: 1024px) {
  body.user-not-logged-in
    .site-header__user-menu
    .user-menu__item--garage.has-vehicles:before,
  .site-header__user-menu .user-menu__item--garage.has-vehicles:before {
    top: -12%;
    left: 64%;
  }
}

.active-vehicle-subtext {
  width: 100%;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .active-vehicle-subtext {
    top: 66%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
  }
}
.active-vehicle-subtext:hover .active-vehicle-subtext__vehicle-text {
  color: $theme-hover-color;
}
.active-vehicle-subtext:hover .active-vehicle-subtext__remove svg path {
  fill: $theme-hover-color;
}

.active-vehicle-subtext--authed {
  cursor: initial;
}
.active-vehicle-subtext--authed .active-vehicle-subtext__vehicle-text {
  width: 100%;
}
.active-vehicle-subtext--authed .active-vehicle-subtext__remove {
  display: none;
}

.active-vehicle-subtext__vehicle-text {
  display: block;
  width: calc(100% - 10px - 5px);
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: normal;
  text-transform: uppercase;
  color: #4d4d4d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.active-vehicle-subtext__remove {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: 10px;
  height: 10px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.active-vehicle-subtext__remove svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.active-vehicle-subtext__remove svg path {
  fill: #4d4d4d;
}

@media (max-width: 1024px) {
  .active-vehicle-subtext {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
  .site-header__user-menu .active-vehicle-subtext {
    display: none;
  }
  .active-vehicle-subtext__vehicle-text {
    width: auto;
  }
  .active-vehicle-subtext__remove {
    position: initial;
    margin-left: 5px;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

/* 

  Show vehicle and close button in all screen resolutions

*/
@media only screen and (max-width: 1024px) {
  .user-menu__item--garage {
    width: 50px;
    margin-right: 0px !important;
  }
  .user-menu__item--garage button {
    margin: 11px 0;
    display: flex;
  }
  .active-vehicle-subtext__vehicle-text {
    font-size: 8px;
    width: calc(100% - 10px - 5px);
  }
  .active-vehicle-subtext__remove {
    height: 6px;
    width: 8px;
  }
  .active-vehicle-subtext__remove svg {
    height: 100% !important;
    width: 100% !important;
  }
  body.user-not-logged-in
    .site-header__user-menu
    .user-menu__item--garage.has-vehicles:before,
  .site-header__user-menu .user-menu__item--garage.has-vehicles:before {
    top: -2%;
    left: 42%;
  }
}

/* ---------------------------------------------
    List item
--------------------------------------------- */
[class*="user-menu__item"]:nth-of-type(n + 2) {
  margin-left: 40px;
}

@media (max-width: 1439px) {
  [class*="user-menu__item"]:nth-of-type(n + 2) {
    margin-left: 15px;
  }
}

.user-menu__item--cart {
  position: relative;
}

.user-menu__item--search {
  margin-left: 10px;
}

.user-menu__item--account.is-customer {
  display: flex;
  gap: 8px;
  margin-left: 10px !important;
}

.user-menu__item--account.is-customer .user-menu__icon {
  align-self: center;
}

@media (max-width: 1360px) {
  .user-menu__item--account.is-customer .user-menu__icon {
    width: 20px;
    height: 20px;
  }
  .user-menu__item--account.is-customer .user-menu__icon svg {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 1182px) {
  .user-menu__link-wrapper {
    display: none !important;
  }
}

.active-vehicle-subtext__vehicle-text:empty + .active-vehicle-subtext__remove {
  display: none;
}

.breadcrumbs {
  font-family: var(--fmc-font--proxima-nova);
}
.breadcrumbs ul li a {
  text-decoration: none;
}
.breadcrumbs ul li span {
  color: #6e6e6e;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}

.breadcrumbs-separator {
  margin: 0 5px;
}

.recently-viewed-products {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 50px;
  padding-bottom: 40px;
}

.recently-viewed-products__container {
  position: relative;
}

.recently-viewed-products__heading {
  margin-bottom: 40px;
}
@media (max-width: 500px) {
  .recently-viewed-products__heading {
    margin-bottom: 30px;
  }
}

.recently-viewed-products__products {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 60px;
  row-gap: 25px;
}
@media (max-width: 1024px) {
  .recently-viewed-products__products {
    column-gap: 25px;
  }
}
@media (max-width: 900px) {
  .recently-viewed-products__products {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .recently-viewed-products__products {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 35px;
  }
}

.recently-viewed-products--top-border {
  padding-top: 0;
}
.recently-viewed-products--top-border .recently-viewed-products__heading {
  border-top: 1px solid #b3b3b3;
  padding-top: 45px;
}

/* ------------------------------------------
    Affected elements
------------------------------------------ */
@media (min-width: 1025px) {
  .megamenu-open main:after {
    pointer-events: unset;
    opacity: 0.37;
    visibility: visible;
  }
}

/* ------------------------------------------
    Megamenu
------------------------------------------ */
.megamenu,
.megamenu__sub-menu {
  width: calc(1440px / 2);
  background: $white-color;
  overflow-y: auto;
  border: 1px solid #bbbbbb;
  height: auto;
}
@media (max-width: 1440px) {
  .megamenu,
  .megamenu__sub-menu {
    width: 50%;
  }
}
@media (max-width: 1024px) {
  .megamenu,
  .megamenu__sub-menu {
    top: 0;
    display: none;
    width: 500px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    bottom: 0;
  }
}

.megamenu {
  left: calc((100vw - 1440px) / 2);
  display: block;
  opacity: 0;
  visibility: hidden;
  padding: 35px 25px;
  pointer-events: none;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
@media (max-width: 1440px) {
  .megamenu {
    left: 0;
  }
}
@media (min-width: 1025px) {
  .megamenu {
    transition-delay: 250ms;
  }
  .megamenu:before {
    content: "";
    width: 100%;
    height: 42px;
    top: -42px;
    left: 0;
    position: absolute;
  }
}
.megamenu.megamenu--open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

/* ------------------------------------------
    Title
------------------------------------------ */
.megamenu__title,
.megamenu__title p {
  font-size: 20px !important;
  font-weight: 400 !important;
  letter-spacing: normal;
  line-height: normal;
  font-family: var(--fmc-font--miller);
  padding-bottom: 0px;
  text-transform: none;
}
@media (max-width: 1024px) {
  .megamenu__title,
  .megamenu__title p {
    font-size: 18px;
    font-weight: 300;
    color: $theme-hover-color;
    text-align: center;
    width: 100%;
    padding-bottom: 18px;
    position: relative;
    cursor: pointer;
  }
  .megamenu__title:before {
    content: "";
    /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-primary.svg?v=156992684832692582051664033349);*/
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    width: 13px;
    height: 24px;
    position: absolute;
    left: 5px;
    top: 1px;
  }
}

/* ---------------------------------------------
    List
--------------------------------------------- */
.megamenu__list {
  border-top: 1px solid #bbbbbb;
  padding-top: 0px;
}

.megamenu__divider {
  height: 1px;
  width: 100%;
  background: #bbbbbb;
}

/* ---------------------------------------------
    List item
--------------------------------------------- */
[class*="megamenu__list-item"] {
  margin: 7px 0;
  padding-left: 5px;
  position: relative;
  font-size: 18px;
  width: 50%;
  display: inline-flex;
  font-weight: 300;
}
@media (max-width: 1024px) {
  [class*="megamenu__list-item"] {
    margin: 33px 0;
    padding-left: 0px;
    width: 100%;
    display: block;
  }
}
@media (min-width: 769px) {
  [class*="megamenu__list-item"] {
    -webkit-transition: 0.2s all ease-in-out;
    -moz-transition: 0.2s all ease-in-out;
    -ms-transition: 0.2s all ease-in-out;
    -o-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
  }
}

/* ---------------------------------------------
    Parent List item
--------------------------------------------- */
.megamenu__list-item--parent .megamenu__link:after,
.megamenu__list-item--parent .megamenu__link:before {
  content: "";
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-black.svg?v=37895313717466944871664033354);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 8px;
  height: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 38px;
  margin: auto;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
@media (max-width: 1024px) {
  .megamenu__list-item--parent .megamenu__link:after,
  .megamenu__list-item--parent .megamenu__link:before {
    right: 0;
  }
}

.megamenu__list-item--parent .megamenu__link:before {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-tertiary.svg?v=13105066322230701531664033353);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
}

.megamenu__link {
  width: 100%;
  text-align: left;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .megamenu__link {
    font-size: 18px;
    font-weight: 300;
  }
}
@media (min-width: 769px) {
  .megamenu__link {
    -webkit-transition: 0.2s all ease-in-out;
    -moz-transition: 0.2s all ease-in-out;
    -ms-transition: 0.2s all ease-in-out;
    -o-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
  }
  .megamenu__link:hover {
    color: $theme-hover-color;
  }
  .megamenu__link:hover:before {
    opacity: 1;
  }
  .megamenu__link:hover:after {
    opacity: 0;
  }
}
@media (min-width: 769px) {
  .megamenu__link.active {
    color: $theme-hover-color;
  }
  .megamenu__link.active:before {
    opacity: 1;
  }
  .megamenu__link.active:after {
    opacity: 0;
  }
}

/* ---------------------------------------------
    Link
--------------------------------------------- */
.megamenu__link {
  padding-right: 0px;
}
@media (max-width: 1024px) {
  .megamenu__link {
    padding-right: 0px;
  }
}

/* ---------------------------------------------
    Sub menu
--------------------------------------------- */
.megamenu__sub-menu {
  left: calc((100vw - 1440px) / 2);
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  padding: 130px 25px 35px 60px;
  border-left: none;
  left: calc(((100vw - 1440px) / 2) + (1440px / 2));
}
@media (max-width: 1440px) {
  .megamenu__sub-menu {
    left: 50%;
  }
}
@media (min-width: 1025px) {
  .megamenu__sub-menu {
    padding-top: 80px;
    padding-bottom: calc(35px - 28px);
  }
  .megamenu__sub-menu .megamenu__sub-list {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (max-width: 1024px) {
  .megamenu__sub-menu {
    left: 0;
    z-index: 1;
    padding: 35px 28px;
  }
}
.megamenu__sub-menu.megamenu__sub-menu--open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

/* ---------------------------------------------
    Sub menu close
--------------------------------------------- */
.megamenu__sub-menu-close {
  position: absolute;
  right: 24px;
  top: 35px;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .megamenu__sub-menu-close {
    /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-right-primary.svg?v=156992684832692582051664033349);*/
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    width: 13px;
    height: 24px;
    padding: 0;
    left: 28px;
    top: 37px;
  }
  .megamenu__sub-menu-close:before,
  .megamenu__sub-menu-close:after {
    content: none;
  }
}

/* ---------------------------------------------
    Sub menu title
--------------------------------------------- */
.megamenu__sub-menu-title {
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
  letter-spacing: 1.8px;
  text-align: center;
  font-family: var(--fmc-font--proxima-nova);
  color: #4d4d4d;
  width: 100%;
  text-transform: uppercase;
  padding-bottom: 18px;
  border-bottom: 1px solid #bbbbbb;
  margin-bottom: 37px;
  cursor: pointer;
}

/* ---------------------------------------------
    Sub menu list item
--------------------------------------------- */
.megamenu__sub-list-item {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (min-width: 1025px) {
  .megamenu__sub-list-item {
    width: 33.33%;
    padding-right: 15px;
  }
}
@media (max-width: 1024px) {
  .megamenu__sub-list-item {
    margin-bottom: 33px;
  }
}

.megamenu__sub-link {
  display: block;
  font-size: 14px;
}
@media (min-width: 769px) {
  .megamenu__sub-link {
    -webkit-transition: 0.2s all ease-in-out;
    -moz-transition: 0.2s all ease-in-out;
    -ms-transition: 0.2s all ease-in-out;
    -o-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
  }
  .megamenu__sub-link:hover {
    color: $theme-hover-color;
  }
}
@media (max-width: 1024px) {
  .megamenu__sub-link {
    font-size: 16px;
    font-weight: 400;
  }
}

.collection-group {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.collection-group__container {
  padding-top: 60px;
  padding-bottom: 55px;
}
@media (max-width: 768px) {
  .collection-group__container {
    padding-top: 30px;
    padding-bottom: 45px;
  }
}

.collection-group__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .collection-group__header {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 35px;
  }
}

.collection-group__heading {
  padding-right: 30px;
  margin-right: 30px;
  border-right: 1px solid #e0e0e0;
}
@media (max-width: 768px) {
  .collection-group__heading {
    width: 100%;
    padding-right: 0;
    margin-right: 0;
    border-right: none;
    margin-bottom: 20px;
  }
}

.collection-group__view-all {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  color: $theme-color;
  font-size: 18px;
  line-height: 13px;
}
.collection-group__view-all img,
.collection-group__view-all svg {
  margin-left: 15px;
  width: 16px;
}
@media (max-width: 768px) {
  .collection-group__view-all {
    padding-left: 20px;
  }
}

.collection-group__grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 35px;
}
@media (max-width: 900px) {
  .collection-group__grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .collection-group__grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .collection-group__grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 30px;
  }
}

.collection-group-item {
  display: block;
  width: 100%;
}

.collection-group-item__image {
  width: 100%;
  max-width: 150px;
  height: 137px;
  object-fit: cover;
  margin: 0 auto;
  margin-bottom: 15px;
}

.collection-group-item__title {
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02px;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.collection-group-item__title img,
.collection-group-item__title svg {
  width: 10px;
  margin-left: 10px;
}
.collection-group-item__title img path,
.collection-group-item__title svg path {
  fill: #4d4d4d;
}

.partners-group {
  margin-top: 0;
  margin-bottom: 0;
  background-color: #f4f4f4;
}

.partners-group__container {
  padding-top: 60px;
  padding-bottom: 55px;
}
@media (max-width: 768px) {
  .partners-group__container {
    padding-top: 30px;
    padding-bottom: 45px;
  }
}

.partners-group__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .partners-group__header {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 35px;
  }
}

.partners-group__heading {
  text-align: center;
}

.partners-group__grid {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -30px;
}
.partners-group__grid .collection-group-item {
  width: 225px;
  margin-bottom: 30px;
}
@media (max-width: 960px) {
  .partners-group__grid .collection-group-item {
    width: 180px;
  }
}
@media (max-width: 450px) {
  .partners-group__grid {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    margin-bottom: -15px;
  }
  .partners-group__grid .collection-group-item {
    width: calc(50% - 10px);
    margin-bottom: 15px;
  }
}

.centered-heading-and-text {
  margin-top: 0;
  margin-bottom: 0;
}

.centered-heading-and-text__container {
  padding-top: 40px;
  padding-bottom: 50px;
}

.centered-heading-and-text__heading {
  margin-bottom: 10px;
}

.centered-heading-and-text__text {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1.27px;
  max-width: 940px;
  margin: 0 auto;
}

.cta-grid {
  margin-top: 0;
  margin-bottom: 0;
}
.cta-grid.cta-grid--has-top-padding {
  padding-top: 40px;
}
.cta-grid.cta-grid--has-bottom-padding {
  padding-bottom: 40px;
}
.cta-grid.cta-grid--move-up {
  margin-top: -20px;
}
@media (max-width: 1024px) {
  .cta-grid.cta-grid--move-up {
    margin-top: -7.5px;
  }
}
@media (max-width: 768px) {
  .cta-grid.cta-grid--move-up {
    margin-top: -5px;
  }
}
@media (max-width: 600px) {
  .cta-grid.cta-grid--move-up {
    margin-top: 0;
  }
}

.cta-grid__title {
  margin-bottom: 30px;
  margin-bottom: 30px;
  margin-top: 0;
  letter-spacing: 4px;
}
@media (max-width: 1024px) {
  .cta-grid__title {
    margin-bottom: 29.25px;
  }
}
@media (max-width: 768px) {
  .cta-grid__title {
    margin-bottom: 28.5px;
  }
}
@media (max-width: 600px) {
  .cta-grid__title {
    margin-bottom: 27px;
  }
}
@media (max-width: 600px) {
  .cta-grid__title {
    text-align: center;
  }
}
@media (max-width: 1024px) {
  .cta-grid__title {
    margin-bottom: 27.5px;
  }
}
@media (max-width: 768px) {
  .cta-grid__title {
    margin-bottom: 25px;
  }
}
@media (max-width: 600px) {
  .cta-grid__title {
    margin-bottom: 20px;
  }
}
@media (max-width: 1024px) {
  .cta-grid__title {
    margin-top: 2.5px;
  }
}
@media (max-width: 768px) {
  .cta-grid__title {
    margin-top: 5px;
  }
}
@media (max-width: 600px) {
  .cta-grid__title {
    margin-top: 10px;
  }
}

.cta-grid__grid {
  grid-gap: 14px;
}

.cta-grid__grid.grid--style-1 {
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 512px 200px;
}
.cta-grid__grid.grid--style-1 .cta-grid-item:nth-child(1) {
  grid-area: 1 / 1 / 2 / 4;
}
.cta-grid__grid.grid--style-1 .cta-grid-item:nth-child(2) {
  grid-area: 1 / 4 / 2 / 7;
}
.cta-grid__grid.grid--style-1 .cta-grid-item:nth-child(3) {
  grid-area: 2 / 1 / 3 / 5;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.24);
}
.cta-grid__grid.grid--style-1 .cta-grid-item:nth-child(4) {
  grid-area: 2 / 5 / 3 / 7;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.24);
}
@media (max-width: 768px) {
  .cta-grid__grid.grid--style-1 {
    grid-template-columns: 1fr;
    grid-template-rows: 354px 354px 200px 200px;
  }
  .cta-grid__grid.grid--style-1 .cta-grid-item:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  .cta-grid__grid.grid--style-1 .cta-grid-item:nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
  }
  .cta-grid__grid.grid--style-1 .cta-grid-item:nth-child(3) {
    grid-area: 3 / 1 / 4 / 2;
    box-shadow: none;
  }
  .cta-grid__grid.grid--style-1 .cta-grid-item:nth-child(4) {
    grid-area: 4 / 1 / 5 / 2;
    box-shadow: none;
  }
}

.cta-grid__grid.grid--style-2 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 250px);
}
.cta-grid__grid.grid--style-2 .cta-grid-item:nth-child(1) {
  grid-area: 1 / 1 / 3 / 3;
}
.cta-grid__grid.grid--style-2 .cta-grid-item:nth-child(2) {
  grid-area: 1 / 3 / 2 / 4;
}
.cta-grid__grid.grid--style-2 .cta-grid-item:nth-child(3) {
  grid-area: 1 / 4 / 2 / 5;
}
.cta-grid__grid.grid--style-2 .cta-grid-item:nth-child(4) {
  grid-area: 3 / 1 / 4 / 3;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.24);
}
.cta-grid__grid.grid--style-2 .cta-grid-item:nth-child(5) {
  grid-area: 2 / 3 / 4 / 5;
}
@media (max-width: 768px) {
  .cta-grid__grid.grid--style-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 354px 170px 200px 354px;
  }
  .cta-grid__grid.grid--style-2 .cta-grid-item:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
  }
  .cta-grid__grid.grid--style-2 .cta-grid-item:nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
  }
  .cta-grid__grid.grid--style-2 .cta-grid-item:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
  }
  .cta-grid__grid.grid--style-2 .cta-grid-item:nth-child(4) {
    grid-area: 3 / 1 / 4 / 3;
    box-shadow: none;
  }
  .cta-grid__grid.grid--style-2 .cta-grid-item:nth-child(5) {
    grid-area: 4 / 1 / 5 / 3;
  }
}

.cta-grid__grid.grid--style-3 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 250px);
}
.cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(1) {
  grid-area: 1 / 1 / 3 / 3;
}
.cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(2) {
  grid-area: 1 / 3 / 2 / 4;
}
.cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(3) {
  grid-area: 1 / 4 / 2 / 5;
}
.cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(4) {
  grid-area: 3 / 1 / 4 / 2;
}
.cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(5) {
  grid-area: 3 / 2 / 4 / 3;
}
.cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(6) {
  grid-area: 2 / 3 / 4 / 5;
}
@media (max-width: 768px) {
  .cta-grid__grid.grid--style-3 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 354px 170px 354px 170px;
  }
  .cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
  }
  .cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
  }
  .cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
  }
  .cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(4) {
    grid-area: 3 / 1 / 4 / 3;
  }
  .cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(5) {
    grid-area: 4 / 1 / 5 / 2;
  }
  .cta-grid__grid.grid--style-3 .cta-grid-item:nth-child(6) {
    grid-area: 4 / 2 / 5 / 3;
  }
}

.cta-grid__grid.grid--style-4 {
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 299px, 253px;
}
.cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(1) {
  grid-area: 1 / 1 / 2 / 9;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.24);
}
.cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(2) {
  grid-area: 1 / 9 / 2 / 13;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.24);
}
.cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(3) {
  grid-area: 2 / 1 / 3 / 4;
}
.cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(4) {
  grid-area: 2 / 4 / 3 / 7;
}
.cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(5) {
  grid-area: 2 / 7 / 3 / 10;
}
.cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(6) {
  grid-area: 2 / 10 / 3 / 13;
}
@media (max-width: 768px) {
  .cta-grid__grid.grid--style-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 354px 354px 170px 170px;
  }
  .cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
    box-shadow: none;
  }
  .cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(2) {
    grid-area: 2 / 1 / 3 / 3;
    box-shadow: none;
  }
  .cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(3) {
    grid-area: 3 / 1 / 4 / 2;
  }
  .cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(4) {
    grid-area: 3 / 2 / 4 / 3;
  }
  .cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(5) {
    grid-area: 4 / 1 / 5 / 2;
  }
  .cta-grid__grid.grid--style-4 .cta-grid-item:nth-child(6) {
    grid-area: 4 / 2 / 5 / 3;
  }
}

.cta-grid__grid.grid--style-5 {
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 300px 512px;
}
.cta-grid__grid.grid--style-5 .cta-grid-item:nth-child(1) {
  grid-area: 1 / 1 / 2 / 3;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.24);
}
.cta-grid__grid.grid--style-5 .cta-grid-item:nth-child(2) {
  grid-area: 1 / 3 / 2 / 7;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.24);
}
.cta-grid__grid.grid--style-5 .cta-grid-item:nth-child(3) {
  grid-area: 2 / 1 / 3 / 4;
}
.cta-grid__grid.grid--style-5 .cta-grid-item:nth-child(4) {
  grid-area: 2 / 4 / 3 / 7;
}
@media (max-width: 768px) {
  .cta-grid__grid.grid--style-5 {
    grid-template-columns: 1fr;
    grid-template-rows: 255px 255px 354px 354px;
  }
  .cta-grid__grid.grid--style-5 .cta-grid-item:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
    box-shadow: none;
  }
  .cta-grid__grid.grid--style-5 .cta-grid-item:nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
    box-shadow: none;
  }
  .cta-grid__grid.grid--style-5 .cta-grid-item:nth-child(3) {
    grid-area: 3 / 1 / 4 / 2;
  }
  .cta-grid__grid.grid--style-5 .cta-grid-item:nth-child(4) {
    grid-area: 4 / 1 / 5 / 2;
  }
}
.cta-grid__grid.grid--style-5.cta-grid-item__block-limit {
  padding-top: 6rem;
  grid-template-columns: 1fr;
  grid-template-rows: none;
}
.cta-grid__grid.grid--style-5.cta-grid-item__block-limit
  .cta-grid-item:nth-child(1) {
  grid-area: 1 / 1;
  box-shadow: none;
}
.cta-grid__grid.grid--style-5.cta-grid-item__block-limit
  .cta-grid-item:nth-child(2) {
  grid-area: 1 / 2;
  box-shadow: none;
}
@media (max-width: 768px) {
  .cta-grid__grid.grid--style-5.cta-grid-item__block-limit {
    grid-template-columns: 1fr;
    grid-template-rows: 354px 354px;
  }
  .cta-grid__grid.grid--style-5.cta-grid-item__block-limit
    .cta-grid-item:nth-child(1) {
    grid-area: 1 / 1;
    box-shadow: none;
  }
  .cta-grid__grid.grid--style-5.cta-grid-item__block-limit
    .cta-grid-item:nth-child(2) {
    grid-area: 2 / 1;
    box-shadow: none;
  }
}

.cta-grid-item {
  width: 100%;
  height: 100%;
}

.cta-grid-item__link {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}
.cta-grid-item__link > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.cta-grid-item__link.object-cover__top img {
  object-position: top;
}
.cta-grid-item__link:after {
  content: "";
  display: block;
  width: 100%;
  height: 150px;
  background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.cta-grid-item__link.cta-grid-item__link--tint-disabled:after {
  display: none;
}
.cta-grid-item__link img {
  -webkit-transition: 0.4s all ease-in-out;
  -moz-transition: 0.4s all ease-in-out;
  -ms-transition: 0.4s all ease-in-out;
  -o-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
  will-change: transform;
}
.cta-grid-item__link:hover img {
  -webkit-transform: scale(1.025);
  -moz-transform: scale(1.025);
  -ms-transform: scale(1.025);
  -o-transform: scale(1.025);
  transform: scale(1.025);
}

.cta-grid-item__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 30px 42px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .cta-grid-item__content {
    padding: 20px;
  }
}

.cta-grid-item__heading {
  font-family: var(--fmc-font--proxima-nova);
  display: block;
  color: $white-color;
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: auto;
}
.cta-grid-item__heading.cta-grid-item__heading--alt {
  font-size: 30px;
  line-height: normal;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .cta-grid-item__heading.cta-grid-item__heading--alt {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.8px;
  }
}
@media (max-width: 1024px) {
  .cta-grid-item__heading {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 768px) {
  .cta-grid-item__heading {
    font-size: 20px;
    line-height: 30px;
  }
}

.cta-grid-item__subtitle {
  display: block;
  color: $white-color;
  font-size: 11px;
  line-height: 21px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
}

.cta-grid-item__cta-text {
  margin-left: 0;
  margin-right: auto;
  text-transform: uppercase;
}
.cta-grid-item__cta-text.btn--centered {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 400px) {
  .cta-grid-item__cta-text {
    font-size: 14px;
    line-height: 18px;
  }
}

body:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}

body.side-cart-open:after {
  opacity: 1;
  visibility: visible;
}

.side-cart {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 490px;
  height: 100%;
  background-color: $white-color;
  z-index: 11;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.side-cart.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.side-cart-header {
  width: 100%;
  padding: 30px 25px;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
@media (max-width: 490px) {
  .side-cart-header {
    padding-top: 55px;
    padding-bottom: 15px;
    margin-bottom: 0;
    padding-right: 50px;
  }
}
@media (max-height: 670px) {
  .side-cart-header {
    padding-bottom: 10px;
  }
}

.side-cart-header__heading {
  margin: 0;
}
@media (max-width: 490px) {
  .side-cart-header__heading {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    letter-spacing: 3px;
  }
}
@media (max-height: 670px) {
  .side-cart-header__heading {
    font-size: 22px;
    line-height: 28px;
  }
}

.side-cart-header__item-count {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
}

.side-cart-header__close {
  position: relative;
  margin-right: 0;
  padding: 0;
  cursor: pointer;
}
@media (max-width: 490px) {
  .side-cart-header__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.side-cart-usps {
  margin: 0 25px;
  padding: 15px;
  border-top: 1px solid;
  border-bottom: 1px solid;
}
@media (max-width: 490px) {
  .side-cart-usps {
    padding: 10px;
  }
}
@media (max-height: 670px) {
  .side-cart-usps {
    display: none;
  }
}

.side-cart-usps__usp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: normal;
  font-weight: 300;
  letter-spacing: 1.6px;
}
.side-cart-usps__usp img,
.side-cart-usps__usp svg {
  width: 36px;
  margin-right: 15px;
}
.side-cart-usps__usp:not(:last-child) {
  margin-bottom: 10px;
}
@media (max-width: 490px) {
  .side-cart-usps__usp {
    font-size: 11px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  .side-cart-usps__usp img,
  .side-cart-usps__usp svg {
    width: 20px;
    margin-right: 10px;
  }
}

.side-cart-items {
  height: 100%;
  overflow-y: auto;
  padding: 30px 25px;
  padding-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.side-cart-items .cart-item {
  margin-bottom: 30px;
}
@media (max-height: 670px) {
  .side-cart-items {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.side-cart__empty-text {
  padding: 50px 25px;
  height: 100%;
}
.side-cart__empty-text .btn {
  margin-top: 10px;
}

.side-cart-overview {
  border-top: 1px solid;
  margin: 0px 25px;
  padding: 25px 0px;
  text-align: center;
}
@media (max-width: 490px) {
  .side-cart-overview {
    padding: 20px 0px;
  }
}
@media (max-height: 670px) {
  .side-cart-overview {
    padding-top: 10px;
  }
}

.side-cart-overview__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.side-cart-overview__item:not(:last-child) {
  margin-bottom: 18px;
}
@media (max-width: 490px) {
  .side-cart-overview__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.side-cart-overview__label {
  color: #4d4d4d;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
}
@media (max-width: 490px) {
  .side-cart-overview__label {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.88px;
  }
}
@media (max-height: 670px) {
  .side-cart-overview__label {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.88px;
  }
}

.side-cart-overview__data {
  color: $theme-color;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  text-align: right;
}
.side-cart-overview__data.side-cart-overview__data--light {
  font-weight: 400;
}
@media (max-width: 490px) {
  .side-cart-overview__data {
    font-size: 14px;
    letter-spacing: 0.88px;
  }
  .side-cart-overview__data.side-cart-overview__data--light {
    letter-spacing: 1px;
  }
}
@media (max-height: 670px) {
  .side-cart-overview__data {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.88px;
  }
}

.side-cart-overview__checkout-cta {
  width: 100%;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
}
.side-cart-overview__checkout-cta:hover {
  border: 1px solid $theme-color;
}
@media (max-width: 490px) {
  .side-cart-overview__checkout-cta {
    margin-top: 10px;
  }
}
@media (max-height: 670px) {
  .side-cart-overview__checkout-cta {
    width: calc(50% - 10px);
    min-width: 0;
    margin-top: 0;
    font-size: 14px !important;
    padding: 0px 10px;
  }
}

.side-cart-overview__cart-cta {
  width: 100%;
  margin-top: 25px;
}
@media (max-width: 490px) {
  .side-cart-overview__cart-cta {
    margin-top: 20px;
  }
}
@media (max-height: 670px) {
  .side-cart-overview__cart-cta {
    width: 100%;
    min-width: 0;
    margin-top: 0;
    font-size: 14px !important;
    padding: 0px 10px;
  }
}

.side-cart-overview__clear {
  width: 100%;
  text-align: center;
  margin-top: 17px;
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (max-width: 490px) {
  .side-cart-overview__clear {
    margin-top: 30px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.49);
  z-index: 12;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.modal.active,
.modal.modal--active {
  opacity: 1;
  visibility: visible;
}

.modal__inner {
  width: 100%;
  max-width: 768px;
  background-color: $white-color;
  position: relative;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  background-color: #6e6e6e;
  z-index: 1;
  cursor: pointer;
}
.modal__close .close-cross {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  filter: brightness(0) invert(1);
}
@media (max-width: 500px) {
  .modal__close {
    width: 40px;
    height: 40px;
  }
}

.modal__heading.modal__heading--spaced-around-close {
  padding-right: 40px;
}
@media (max-width: 500px) {
  .modal__heading.modal__heading--spaced-around-close {
    padding-right: 10px;
  }
}

.modal__actions {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.modal__actions .modal__action * {
  cursor: pointer;
}
.modal__actions .modal__action:not(:last-child) {
  margin-right: 15px;
}
@media (max-width: 500px) {
  .modal__actions .modal__action {
    width: 100%;
  }
  .modal__actions .modal__action:not(:last-child) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.modal-image-slider {
  width: 100%;
}
.modal-image-slider .slick-list.draggable {
  padding: 0 !important;
}

.modal-image-slider__item {
  width: 100%;
  height: 100%;
  padding-top: 56.25%;
  position: relative;
}

.modal-image-slider__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 481px) {
  .modal__inner .modal-image-slider + .modal__content {
    margin-top: -3em;
  }
}

.modal__content {
  padding: 30px;
}
.modal__content .modal-content-slider > .slick-list {
  padding: 0 !important;
}

.modal-image-slider .modal__content {
  margin-top: -46px;
}

.modal__heading {
  text-transform: uppercase;
  margin-bottom: 30px;
}

.modal__text {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
}

.blog-filter-and-sort {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 95px;
}
@media (max-width: 768px) {
  .blog-filter-and-sort {
    padding-top: 60px;
  }
}
@media (max-width: 768px) {
  .blog-filter-and-sort {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
}

.blog-filter-and-sort__filter {
  width: 100%;
}

.blog-filter-and-sort__filter-list {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media (min-width: 769px) {
  .blog-filter-and-sort__filter-list.active {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.blog-filter-and-sort__filter-list-item {
  min-width: 160px;
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
}
.blog-filter-and-sort__filter-list-item a {
  text-decoration: none;
}
.blog-filter-and-sort__filter-list-item:not(:last-child) {
  margin-right: 50px;
}
.blog-filter-and-sort__filter-list-item:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -17px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 160px;
  height: 3px;
  background: $theme-color;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
.blog-filter-and-sort__filter-list-item:hover:after {
  opacity: 1;
  visibility: visible;
}
.blog-filter-and-sort__filter-list-item.active:after {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1280px) {
  .blog-filter-and-sort__filter-list-item {
    min-width: 0;
  }
  .blog-filter-and-sort__filter-list-item:not(:last-child) {
    margin-right: 30px;
  }
  .blog-filter-and-sort__filter-list-item:after {
    width: 100%;
  }
}

.blog-article-filter-dropdown {
  display: none;
}
.blog-article-filter-dropdown.active {
  display: block;
}
@media (max-width: 768px) {
  .blog-article-filter-dropdown {
    display: block;
  }
}

.blog-filter-and-sort__sort {
  width: 240px;
}
@media (max-width: 768px) {
  .blog-filter-and-sort__sort {
    width: calc(50% - 5px);
  }
}

.blog-filter-and-sort__sort .choices {
  width: 100%;
}
.blog-filter-and-sort__sort .choices .choices__inner {
  border-radius: 3px;
  border: 1px solid #6e6e6e;
  background: $white-color;
  font-size: 16px;
  line-height: 26px;
}

.blog-article-grid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
  row-gap: 60px;
  margin-bottom: 80px;
}
@media (max-width: 940px) {
  .blog-article-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .blog-article-grid {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 40px;
  }
}

/* ---------------------------------------------
    Pagination - non-boost
--------------------------------------------- */
.pagination {
  width: 100%;
  background: #f4f4f4;
}
.template-full-orders .pagination {
  margin-top: 30px;
}

.pagination__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

.pagination__item-count {
  left: 0;
  top: 50%;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.39px;
}
@media (max-width: 768px) {
  .pagination__item-count {
    display: none;
  }
}

.pagination__page-select {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  text-align: center;
}
.pagination__page-select .prev,
.pagination__page-select .next {
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.39px;
}
.pagination__page-select .prev a,
.pagination__page-select .next a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  text-decoration: none;
}
.pagination__page-select .prev img,
.pagination__page-select .prev svg,
.pagination__page-select .next img,
.pagination__page-select .next svg {
  width: 8px;
  height: 16px;
}
.pagination__page-select .prev img path,
.pagination__page-select .prev svg path,
.pagination__page-select .next img path,
.pagination__page-select .next svg path {
  fill: #4d4d4d;
}
.pagination__page-select .prev {
  margin-right: 45px;
}
.pagination__page-select .prev img,
.pagination__page-select .prev svg {
  margin-right: 14px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.pagination__page-select .next {
  margin-left: 45px;
}
.pagination__page-select .next img,
.pagination__page-select .next svg {
  margin-left: 14px;
}
.pagination__page-select .page {
  color: #6e6e6e;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.23px;
  text-align: center;
  position: relative;
}
.pagination__page-select .page:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -11px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 32px;
  height: 2px;
  background: $theme-color;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
.pagination__page-select .page a {
  text-decoration: none;
}
.pagination__page-select .page:not(:last-child) {
  margin-right: 30px;
}
.pagination__page-select .page.current:after,
.pagination__page-select .page:hover:after {
  opacity: 1;
  visibility: visible;
}
.pagination__page-select .deco {
  margin-right: 30px;
}
.pagination__page-select.pagination__page-select--mobile {
  display: none;
}
@media (max-width: 768px) {
  .pagination__page-select {
    display: none;
  }
  .pagination__page-select.pagination__page-select--mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.article__main {
  width: 1135px;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 1024px) {
  .article__main {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 600px) {
  .article__main {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.article__main .breadcrumbs {
  display: none;
  padding-top: 25px;
  padding-bottom: 25px;
}
@media (max-width: 768px) {
  .article__main .breadcrumbs {
    display: block;
  }
}

.article-hero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white-color;
}

.article-hero__container {
  padding-top: 115px;
  padding-bottom: 120px;
}
@media (max-width: 768px) {
  .article-hero__container {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

.article-hero__category {
  text-align: center;
  text-transform: uppercase;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
  letter-spacing: 3px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .article-hero__category {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

.article-hero__heading {
  text-align: center;
  text-transform: uppercase;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .article-hero__heading {
    text-align: left;
    margin-bottom: 30px;
  }
}

.article-hero__publish-date {
  text-align: center;
}
@media (max-width: 768px) {
  .article-hero__publish-date {
    text-align: left;
  }
}

.article-actions {
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 45px;
}
@media (max-width: 768px) {
  .article-actions {
    padding-top: 0;
    padding-bottom: 0;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.article-actions__return {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  color: $theme-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 18px;
  line-height: 13px;
}
.article-actions__return img,
.article-actions__return svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-right: 15px;
  width: 15px;
  height: 15px;
}
@media (max-width: 768px) {
  .article-actions__return {
    width: 100%;
    border-top: 1px solid #4d4d4d;
    border-bottom: 1px solid #4d4d4d;
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .article-actions .social-icons {
    width: 100%;
    border-bottom: 1px solid #4d4d4d;
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
}

.rte.article-content {
  margin-bottom: 40px;
  font-weight: 400;
}
.rte.article-content img {
  width: 100%;
  margin-top: 65px;
  margin-bottom: 50px;
}
.rte.article-content h1,
.rte.article-content h2,
.rte.article-content h3,
.rte.article-content h4,
.rte.article-content h5,
.rte.article-content h6 {
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-top: 0;
}
.rte.article-content p {
  margin-top: 20px;
}
.rte.article-content a {
  color: $theme-color;
  text-decoration: underline;
}
@media (max-width: 768px) {
  .rte.article-content img {
    margin-top: 45px;
    margin-bottom: 40px;
  }
}

.article-image-and-text-cols {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin: 60px 0px;
}
@media (max-width: 768px) {
  .article-image-and-text-cols {
    margin: 40px 0px;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.article-image-and-text-cols__image {
  width: 50%;
}
.article-image-and-text-cols__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.article-image-and-text-cols__image.article-image-and-text-cols__image--left {
  order: 1;
}
.article-image-and-text-cols__image.article-image-and-text-cols__image--right {
  order: 2;
}
@media (max-width: 768px) {
  .article-image-and-text-cols__image {
    width: 100%;
  }
  .article-image-and-text-cols__image.article-image-and-text-cols__image--left {
    margin-bottom: 40px;
  }
  .article-image-and-text-cols__image.article-image-and-text-cols__image--right {
    margin-top: 40px;
  }
}

.article-image-and-text-cols__text-container {
  width: 50%;
  padding-top: 70px;
  padding-bottom: 70px;
}
.article-image-and-text-cols__text-container.article-image-and-text-cols__text-container--image-left {
  order: 2;
  padding-left: 80px;
}
.article-image-and-text-cols__text-container.article-image-and-text-cols__text-container--image-right {
  order: 1;
  padding-right: 80px;
}
@media (max-width: 768px) {
  .article-image-and-text-cols__text-container {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
  .article-image-and-text-cols__text-container.article-image-and-text-cols__text-container--image-left {
    padding-left: 0px;
  }
  .article-image-and-text-cols__text-container.article-image-and-text-cols__text-container--image-right {
    padding-right: 0px;
  }
}

.article-image-and-text-cols__heading {
  width: 100%;
}

.article-image-and-text-cols__text {
  width: 100%;
}

.article-quote {
  margin: 80px 0px;
  text-align: center;
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 34px;
  line-height: 44px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .article-quote {
    margin: 40px 0px;
  }
}
@media (max-width: 500px) {
  .article-quote {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 2px;
  }
}

.article-video {
  margin-top: 90px;
  margin-bottom: 60px;
  position: relative;
}
@media (max-width: 768px) {
  .article-video {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.article-video__overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}
.article-video__overlay.hidden {
  opacity: 0;
  visibility: hidden;
}
.article-video__overlay img,
.article-video__overlay svg {
  cursor: pointer;
}

.article-video video {
  width: 100%;
}

.article-share {
  width: 100%;
  background: #f4f4f4;
  text-align: center;
  padding: 15px 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .article-share {
    background: transparent;
    border-top: 1px solid #4d4d4d;
    border-bottom: 1px solid #4d4d4d;
  }
}

.article-pagination {
  width: 100%;
  box-shadow: 0 30px 20px rgba(0, 0, 0, 0.1);
  background: $theme-color;
  margin-top: 40px;
}
.article-pagination.article-pagination--desktop {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.article-pagination.article-pagination--mobile {
  display: none;
  box-shadow: none;
  background: transparent;
  border-bottom: 1px solid #4d4d4d;
  padding-top: 30px;
  padding-bottom: 30px;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}
@media (max-width: 768px) {
  .article-pagination {
    margin-top: 0;
  }
  .article-pagination.article-pagination--desktop {
    display: none;
  }
  .article-pagination.article-pagination--mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.article-pagination__item {
  width: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 35px;
  padding-bottom: 50px;
  padding-left: 45px;
  padding-right: 45px;
  position: relative;
}
.article-pagination__item:after {
  content: "";
  display: block;
  width: 1px;
  height: calc(100% - 40px);
  background: $white-color;
  opacity: 0.4;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 768px) {
  .article-pagination__item {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    color: $theme-color;
    font-family: var(--fmc-font--proxima-nova);
    font-size: 18px;
    line-height: 13px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
  }
  .article-pagination__item:after {
    display: none;
  }
  .article-pagination__item img,
  .article-pagination__item svg {
    filter: brightness(100) invert(1);
  }
}

.article-pagination__item--previous {
  margin-right: auto;
}
.article-pagination__item--previous .article-pagination__arrow img,
.article-pagination__item--previous .article-pagination__arrow svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.article-pagination__item--previous .article-pagination__data {
  padding-left: 30px;
}
@media (max-width: 768px) {
  .article-pagination__item--previous {
    margin-right: 0;
  }
  .article-pagination__item--previous img,
  .article-pagination__item--previous svg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-right: 15px;
  }
}

.article-pagination__item--next {
  margin-left: auto;
}
.article-pagination__item--next .article-pagination__label,
.article-pagination__item--next .article-pagination__title {
  text-align: right;
  margin-left: auto;
}
.article-pagination__item--next .article-pagination__data {
  padding-right: 30px;
}
.article-pagination__item--next:after {
  right: auto;
  left: 0;
}
@media (max-width: 768px) {
  .article-pagination__item--next {
    margin-left: 0;
  }
  .article-pagination__item--next img,
  .article-pagination__item--next svg {
    margin-left: 15px;
  }
}

.article-pagination__arrow {
  width: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.article-pagination__arrow img,
.article-pagination__arrow svg {
  width: 30px;
  height: 60px;
}
.article-pagination__arrow img path,
.article-pagination__arrow svg path {
  fill: $white-color;
}

.article-pagination__data {
  width: calc(100% - 30px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.article-pagination__label {
  width: 100%;
  color: $white-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.article-pagination__title {
  color: $white-color;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
  max-width: 320px;
}

.suggested-articles {
  margin-top: 80px;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .suggested-articles {
    margin-top: 55px;
  }
}

.suggested-articles__heading {
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .suggested-articles__heading {
    margin-bottom: 30px;
  }
}

.suggested-articles__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
  row-gap: 40px;
}
@media (max-width: 768px) {
  .suggested-articles__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.article-featured-products {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom: 55px;
}
@media (max-width: 768px) {
  .article-featured-products {
    padding-top: 0px;
    padding-bottom: 40px;
  }
}

.article-featured-products__heading {
  margin-bottom: 40px;
}
@media (max-width: 500px) {
  .article-featured-products__heading {
    margin-bottom: 30px;
  }
}

.article-featured-products__grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 30px;
  row-gap: 25px;
}
@media (max-width: 1024px) {
  .article-featured-products__grid {
    column-gap: 25px;
  }
}
@media (max-width: 900px) {
  .article-featured-products__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 500px) {
  .article-featured-products__grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 35px;
  }
}
@media (max-width: 959px) {
  .quick-search {
    width: 100%;
    background: $white-color;
    padding: 40px 0px 35px 0px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.2s all ease-in-out;
    -moz-transition: 0.2s all ease-in-out;
    -ms-transition: 0.2s all ease-in-out;
    -o-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
  }
}

@media (max-width: 959px) {
  .quick-search {
    padding: 20px 0px 50px 0px;
  }
  .quick-search__form {
    margin-bottom: 10px;
    background: #fff;
    padding-left: 15px;
    padding-right: 5px;
  }
}
@media (max-width: 959px) {
  .quick-search-open .quick-search {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 959px) {
  body:not(.quick-search-open) .quick-search {
    top: -50px;
  }
}
@media (min-width: 960px) {
  .searchbar-global {
    max-width: 210px;
  }
  .quick-search__form {
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .quick-search__inner {
    padding-right: 0px !important;
  }
}

.quick-search__inner {
  width: 100%;
  position: relative;
  padding-right: 40px;
  @media (max-width: 959px) {
    padding-right: 15px;
  }
}
.quick-search__inner .close-cross:before,
.quick-search__inner .close-cross:after {
  height: 1px;
}

.quick-search__button {
  margin-right: 20px;
}

.quick-search__input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 26px;
  color: $theme-color;
  width: 100%;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.quick-search__close {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
}
.quick-search__close .close-cross {
  position: initial;
}

@media (min-width: 769px) {
  .klevu-search__sidebar,
  .klevu-search__product-suggestions {
    max-height: 100%;
    overflow: auto;
  }
  .klevu-search__product-suggestions {
    /*padding-top: 10px;*/
  }
  .klevu-search__sidebar {
    /*padding: 10px 15px;*/
  }
}

@media (max-width: 768px) {
  .klevu-search__sidebar,
  .klevu-search__product-suggestions {
    height: 100%;
  }
}

.ford-section--header-vin-search {
  visibility: hidden;
  pointer-events: none;
}
body:not(.user-has-vehicle) .ford-section--header-vin-search {
  margin-top: -60px;
  margin-bottom: -90px;
  -webkit-transform: translateY(-70px);
  -moz-transform: translateY(-70px);
  -ms-transform: translateY(-70px);
  -o-transform: translateY(-70px);
  transform: translateY(-70px);
}
@media (max-width: 1024px) {
  body:not(.user-has-vehicle) .ford-section--header-vin-search {
    margin-top: -52.5px;
    margin-bottom: -82.5px;
  }
}
@media (max-width: 944px) {
  body:not(.user-has-vehicle) .ford-section--header-vin-search {
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    transform: translateY(0%);
    margin-bottom: 22.5px;
  }
}
.user-has-vehicle.user-logged-i .ford-section--header-vin-search {
  display: none;
}
.user-not-logged-in.user-has-vehicle .ford-section--header-vin-search,
.show-non-logged-in-vin-search .ford-section--header-vin-search {
  width: 100%;
  background: white;
  margin: 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
  visibility: visible;
  pointer-events: unset;
  -webkit-transition: top 0.2s ease-in-out;
  -moz-transition: top 0.2s ease-in-out;
  -ms-transition: top 0.2s ease-in-out;
  -o-transition: top 0.2s ease-in-out;
  transition: top 0.2s ease-in-out;
}
.user-not-logged-in.user-has-vehicle
  .ford-section--header-vin-search
  .vin-search,
.show-non-logged-in-vin-search .ford-section--header-vin-search .vin-search {
  margin-top: 0;
  box-shadow: none;
}
@media (max-width: 1175px) {
  .ford-section--header-vin-search {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    max-width: 944px;
  }
}

@media (max-width: 768px) {
  .ford-section--home-vin-search {
    padding-left: 0;
    padding-right: 0;
  }
}

.vin-search {
  width: 1176px;
  max-width: 100%;
  margin: auto;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: $white-color;
  padding: 20px 50px;
  position: relative;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  margin-top: -80px;
  min-height: 20px;
}
@media (max-width: 1175px) {
  .vin-search {
    height: auto;
  }
}
@media (max-width: 875px) {
  .vin-search {
    padding-bottom: 0;
  }
}
@media (max-width: 500px) {
  .vin-search {
    padding: 20px 10px;
    padding-bottom: 0;
  }
}
.vin-search.vin-search--has-message {
  padding-bottom: 45px;
}
@media (max-width: 875px) {
  .vin-search.vin-search--has-message {
    padding-bottom: 0;
  }
}
.vin-search input,
.vin-search select {
  font-family: var(--fmc-font--proxima-nova);
}

.vin-search__title {
  margin-bottom: 5px;
  color: #4d4d4d;
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 300;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
}

.vin-search__subtitle {
  margin-bottom: 20px;
  color: #4d4d4d;
  font-family: var(--fmc-font--miller);
  font-size: 13px;
  line-height: normal;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  text-align: center;
}
@media (max-width: 1175px) {
  .vin-search__subtitle {
    border-bottom: 1px solid #bbbbbb;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
}

.ford-section--header-vin-search .vin-search__title,
.ford-section--header-vin-search .vin-search__subtitle {
  display: none;
}

@media (max-width: 1175px) {
  .vin-search__content {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.vin-search__toggles {
  margin-right: 30px;
}
@media (max-width: 1175px) {
  .vin-search__toggles {
    width: 100%;
    margin-right: 0;
    margin-bottom: 35px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
}

.vin-search__toggle {
  position: relative;
  width: 115px;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 6px;
  color: #4d4d4d;
}
.vin-search__toggle.active {
  color: $theme-color;
}
.vin-search__toggle.active:after {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background: $theme-color;
}

.vin-search__inputs {
  flex-grow: 1;
}
.vin_search-default-make {
  text-transform: capitalize;
  box-sizing: border-box;
  font: inherit;
  border: 1px solid rgb(110, 110, 110);
  text-overflow: ellipsis;
  color: rgb(77, 77, 77);
  line-height: 26px;
  letter-spacing: 1.23px;
  font-family: var(--fmc-font--proxima-nova);
  width: 100%;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  height: 50px;
  border-radius: 0px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-image: initial;
  border-bottom: 2px solid rgb(149, 156, 160);
  /*background-color: rgb(239, 239, 239);*/
  outline: none !important;
}
@media (max-width: 1175px) {
  .vin_search-default-make {
    font-size: 16px;
    padding-left: 15px;
    padding-right: 35px;
    background-position: calc(100% - 15px) 50%;
    background-position-x: calc(100% - 15px);
    background-position-y: 50%;
  }
}
.vin-search__model,
.vin-search__vin {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.vin-search__model .input-field,
.vin-search__vin .input-field {
  height: 50px;
  text-transform: uppercase;
}
.vin-search__model select,
.vin-search__vin select {
  font-family: var(--fmc-font--proxima-nova);
  width: 100%;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  background-position: calc(100% - 5px) 50%;
  height: 50px;
}
@media (max-width: 1175px) {
  .vin-search__model select,
  .vin-search__vin select {
    font-size: 16px;
    padding-left: 15px;
    padding-right: 35px;
    background-position: calc(100% - 15px) 50%;
  }
}
@media (max-width: 875px) {
  .vin-search__model,
  .vin-search__vin {
    width: 100%;
  }
}

.vin-search__model {
  height: 100%;
  column-gap: 5px;
}
@media (max-width: 1175px) {
  .vin-search__model {
    column-gap: 25px;
  }
}
@media (max-width: 768px) {
  .vin-search__model {
    grid-template-columns: repeat(1, 1fr);
  }
}

.vin-search__vin,
.vin-search__model {
  position: relative;
}
.vin-search__vin > div,
.vin-search__model > div {
  width: 100%;
}

.vin-search__cta {
  margin-left: 30px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1),
    0px 10px 10px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 875px) {
  .vin-search__cta {
    width: calc(100% + 50px + 50px);
    margin-left: -50px;
    margin-right: -50px;
    margin-top: 30px;
    border-radius: 0;
    color: $white-color;
    font-family: var(--fmc-font--proxima-nova);
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 4px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
  }
}
@media (max-width: 500px) {
  .vin-search__cta {
    width: calc(100% + 10px + 10px);
    margin-left: -10px;
    margin-right: -10px;
  }
}

/* ---------------------------------------------
    States / Messages
--------------------------------------------- */
.vin-search-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  position: absolute;
  margin-top: 10px;
}
@media (max-width: 875px) {
  .vin-search-message {
    position: initial;
  }
}
.vin-search-message span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.vin-search-message svg {
  margin-right: 10px;
}

.vin-search-error {
  color: #d62d0a;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
}

.vin-search-loading {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 21px;
  color: $theme-color;
}

.vin-search--focused {
  animation: vinSearchFocus 0.4s linear 0.3s 2;
}

@keyframes vinSearchFocus {
  50% {
    box-shadow: 0 10px 10px $theme-color, 0 20px 20px $theme-color;
  }
}

.garage-active-vehicle-notification {
  width: 100%;
  background-color: $white-color;
  border-bottom: 8px solid #008200;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 25px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}

.garage-active-vehicle-notification--active {
  opacity: 1;
  visibility: visible;
  pointer-events: unset;
}

.garage-active-vehicle-notification__checkmark-icon {
  width: 23px;
  height: 22px;
}

.garage-active-vehicle-notification__text {
  width: 100%;
  margin: 0 15px;
  color: #4d4d4d;
  font-family: var(--fmc-font--miller);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
}
@media (max-width: 1024px) {
  .garage-active-vehicle-notification__text {
    font-size: 12px;
    line-height: 16px;
  }
}

.garage-active-vehicle-notification__close-icon {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.header-mobile-usp-slider {
  display: none;
  width: 100%;
  padding: 0px 10px;
}
@media (max-width: 1024px) {
  .header-mobile-usp-slider {
    display: initial;
  }
}

.header-mobile-usp-slider .slick-slide > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.header-mobile-usp-slider__item {
  height: 100%;
}

.header-mobile-usp-slider__item a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-decoration: none;
}

.header-mobile-usp-slider__item svg {
  width: 30px;
  height: auto;
  transform: translateY(3px);
}

.header-mobile-usp-slider__item img {
  width: 30px;
  height: auto;
}

.header-mobile-usp-slider__text {
  color: $white-color;
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;
  margin-left: 15px;
  max-width: calc(100% - 30px - 15px - 6px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-mobile-usp-slider__chevron {
  margin-left: 15px;
}
.header-mobile-usp-slider__chevron svg {
  width: 6px;
  transform: translateY(0px);
}

.text-and-image-links {
  margin-top: 0;
  margin-bottom: 0;
}

.text-and-image-links__container {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}
@media (max-width: 768px) {
  .text-and-image-links__container {
    padding-top: 50px;
    padding-bottom: 70px;
  }
}

.text-and-image-links__heading {
  text-align: center;
  margin-bottom: 5px;
  color: $theme-color;
}

.text-and-image-links__text {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.text-and-image-links__cta {
  min-width: 233px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1),
    0px 10px 10px 0px rgba(0, 0, 0, 0.1);
}

.text-and-image-links-image-ctas {
  margin-top: 0;
  margin-bottom: 0;
}

.text-and-image-links-image-ctas__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 70px;
}
@media (max-width: 768px) {
  .text-and-image-links-image-ctas__container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    padding-bottom: 20px;
  }
}

.text-and-image-links-image-cta {
  width: 150px;
  margin-bottom: 30px;
}
@media (max-width: 915px) {
  .text-and-image-links-image-cta {
    width: 200px;
  }
}
@media (max-width: 768px) {
  .text-and-image-links-image-cta {
    width: calc(50% - 7.5px);
  }
  .text-and-image-links-image-cta:last-child {
    width: 100%;
  }
}

.text-and-image-links-image-cta__image {
  width: 100%;
  max-width: 150px;
  height: 140px;
  object-fit: cover;
  margin: 0 auto;
  margin-bottom: 10px;
}
.text-and-image-links-image-cta__image.text-and-image-links-image-cta__image--mobile {
  display: none;
}
@media (max-width: 768px) {
  .text-and-image-links-image-cta__image {
    max-width: 100%;
  }
  .text-and-image-links-image-cta__image.text-and-image-links-image-cta__image--desktop {
    display: none;
  }
  .text-and-image-links-image-cta__image.text-and-image-links-image-cta__image--mobile {
    display: block;
  }
}

.text-and-image-links-image-cta__title {
  font-family: var(--fmc-font--proxima-nova);
  font-size: 18px;
  line-height: 13px;
  color: $theme-color;
  text-align: center;
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.text-and-image-links-image-cta__title img,
.text-and-image-links-image-cta__title svg {
  margin-left: 10px;
}

.page-hero {
  height: 320px;
  position: relative;
  margin-top: 0;
}

.page-hero__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.page-hero__overlay:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
}

.page-hero__overlay--light-gradient:after {
  background: linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0) 100%);
}

.page-hero__overlay--dark-gradient:after {
  background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

.page-hero__overlay--light-overlay:after {
  background: rgba(255, 255, 255, 0.7);
}

.page-hero__overlay--dark-overlay:after {
  background: rgba(0, 0, 0, 0.4);
}

.page-hero__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
.page-hero__image img {
  min-width: 100%;
  max-width: unset;
  height: 100%;
}

.page-hero__content {
  position: relative;
  height: 100%;
}

.page-hero__content-inner {
  width: 100%;
  padding: 10px 30px;
  position: absolute;
  left: 0;
}
.page-hero__content-inner.page-hero__content-inner--top {
  top: 0;
}
.page-hero__content-inner.page-hero__content-inner--middle {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.page-hero__content-inner.page-hero__content-inner--bottom {
  bottom: 0;
}

.page-hero__heading {
  color: $white-color;
}

.page-hero__subtitle {
  margin-top: 30px;
  color: $white-color;
}

.page-hero__textarea {
  margin-top: 25px;
  color: $white-color;
  font-size: 12px;
  line-height: 24px;
}

.page-hero__cta {
  margin-top: 15px;
}

.page-hero-search {
  width: calc(100% - 30px - 30px);
  max-width: 600px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #6e6e6e;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  -o-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

input.page-hero-search__input {
  width: 100%;
  height: 100%;
  background: $white-color;
  padding: 0px 50px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  border: none;
  border-radius: 5px;
}

.page-hero-search__submit {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.page-hero-search__submit img,
.page-hero-search__submit svg {
  width: 14px;
  height: 14px;
}

.cart-fitting-scroll-message {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 25px 0px;
  text-align: center;
  background-color: #fff6e7;
  border: 1px solid #4d4d4d;
  font-size: 12px;
  line-height: 16px;
  opacity: 1;
  visibility: visible;
  z-index: 3;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.cart-fitting-scroll-message.cart-fitting-scroll-message--hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.cta-grid-item__heading.cta-grid-item__heading--alt {
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 2px;
}

.cta-grid-item__subtitle {
  letter-spacing: 1.5px;
  font-size: 12px;
  font-family: var(--fmc-font--proxima-nova);
}

.cta-grid-item {
  box-shadow: none !important;
}

.cta-grid-item__cta-text {
  text-transform: initial;
}

@media (max-width: 768px) {
  .cta-grid-item__content,
  .cta-grid-item__subtitle {
    text-align: center;
  }
  .cta-grid-item__heading.cta-grid-item__heading--alt {
    margin-bottom: 10px;
  }
  .cta-grid-item__content {
    padding: 10px 15px;
  }
  .cta-grid-item__cta-text {
    margin-top: 25px;
  }
}
@media (max-width: 768px) and (min-width: 769px) {
  .cta-grid-item__cta-text:hover::after {
    background-image: initial;
  }
}

@media (max-width: 768px) {
  .cta-grid-item__cta-text.btn--centered {
    margin-bottom: 25px;
  }
}

@media (max-width: 500px) {
  .featured-articles__article-item .article-item__title {
    letter-spacing: 1px;
    line-height: 1.5;
  }
}

.article-item__image {
  box-shadow: none;
}

.breadcrumbs span {
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.breadcrumbs ul li span {
  color: $theme-color;
}

.user-menu__item--garage .user-menu__link:after {
  /*background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln.svg?v=132685267715316169101664033355);*/
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 13px;
  height: 10px;
}

.cart-item.cart-item--side-cart .cart-item__mobile-quantity-and-price {
  margin-top: 15px;
}
.cart-item.cart-item--side-cart
  .cart-item__mobile-quantity-and-price
  .cart-item__quantity
  .product-quantity__input {
  height: 40px;
  margin-right: 10px;
}

.cart-item.cart-item--side-cart .cart-item__title {
  margin-bottom: 10px;
  font-weight: 600;
}

.cart-item__title {
  margin-bottom: 10px;
  font-weight: 600;
}

.cart-item__variant-value {
  margin-bottom: 6px;
}

.cart-item__image .cart-item__image-remove {
  margin-top: 10px;
}

.cart-delivery-note .form-checkbox__label {
  font-weight: 600 !important;
}

.side-cart-overview__checkout-cta {
  box-shadow: none;
}

.side-cart-usps__usp {
  font-size: 13px;
}

.side-cart-header__heading {
  font-size: 34px;
  letter-spacing: 2px;
}
@media (max-width: 1024px) {
  .side-cart-header__heading {
    font-size: 30.5px;
  }
}
@media (max-width: 768px) {
  .side-cart-header__heading {
    font-size: 27px;
  }
}
@media (max-width: 600px) {
  .side-cart-header__heading {
    font-size: 20px;
  }
}
@media (max-height: 670px) {
  .side-cart-header__heading {
    font-size: 22px !important;
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .megamenu__title {
    color: $theme-color;
  }
}

.vin-search--lincoln .vin-search {
  background: #f1f2f2;
}
.ProductListPage,
.CategoryPage {
  .vin-search--lincoln .vin-search {
    background: $white-color;
  }
}
.vin-search--lincoln .vin-search .vin-search__title {
  font-family: var(--fmc-font--miller);
}
.vin-search--lincoln .vin-search .vin-search__subtitle {
  font-family: var(--fmc-font--proxima-nova);
}
.vin-search--lincoln .vin-search .vin-search__toggle:after {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -2px;
  left: 0;
  background: #ececec;
}
.vin-search--lincoln .vin-search .vin-search__toggle.active:after {
  background: #ff553b;
}
.vin-search--lincoln .vin-search .vin-search__model select,
.vin-search--lincoln .vin-search .vin-search__vin select {
  background-color: #efefef;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #959ca0;
}
.vin-search--lincoln .vin-search .vin-search__cta {
  border-radius: 0;
}

.product-card {
  width: 100%;
}

.product-card__image {
  position: relative;
  display: block;
  width: 100%;
  height: 290px;
  margin-bottom: 12px;
}
@media (max-width: 1024px) {
  .product-card__image {
    margin-bottom: 11.5px;
  }
}
@media (max-width: 768px) {
  .product-card__image {
    margin-bottom: 11px;
  }
}
@media (max-width: 600px) {
  .product-card__image {
    margin-bottom: 10px;
  }
}
.product-card__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card__category {
  font-family: var(--fmc-font--proxima-nova);
  color: #3d3d3d;
  text-transform: capitalize;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  letter-spacing: 1.3px;
  margin-bottom: 5px;
}

.product-card__title {
  color: #575757;
  line-height: normal;
  letter-spacing: 0.02px;
  font-weight: 400;
  font-size: 18px;
}
@media (max-width: 1024px) {
  .product-card__title {
    font-size: 17.5px;
  }
}
@media (max-width: 768px) {
  .product-card__title {
    font-size: 17px;
  }
}
@media (max-width: 600px) {
  .product-card__title {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .product-card__title {
    letter-spacing: normal;
  }
}

.product-card__reviews {
  font-size: 12px;
  margin-top: 12px;
}
@media (max-width: 1024px) {
  .product-card__reviews {
    font-size: 11.5px;
    margin-top: 11.5px;
  }
}
@media (max-width: 768px) {
  .product-card__reviews {
    font-size: 11px;
    margin-top: 11px;
  }
}
@media (max-width: 600px) {
  .product-card__reviews {
    font-size: 10px;
    margin-top: 10px;
  }
}

.product-card__price {
  color: $theme-color;
  font-size: 24px;
  line-height: normal;
  font-weight: 300;
  letter-spacing: 2.4px;
  font-family: var(--fmc-font--proxima-nova);
  margin-top: 20px;
}
@media (max-width: 1024px) {
  .product-card__price {
    margin-top: 17.75px;
  }
}
@media (max-width: 768px) {
  .product-card__price {
    margin-top: 15.5px;
  }
}
@media (max-width: 600px) {
  .product-card__price {
    margin-top: 11px;
  }
}
@media (max-width: 768px) {
  .product-card__price {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 2px;
  }
}

.product-card--small .product-card__image {
  height: 115px;
}

.product-card--small .product-card__category {
  font-size: 11px;
  letter-spacing: 1.1px;
}

.product-card--small .product-card__title {
  font-size: 16px;
  letter-spacing: 1.6px;
}

.product-card--small .product-card__title-link {
  font-size: 16px;
  letter-spacing: 1.6px;
}

.product-card--small .product-card__price {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 2px;
  margin-top: 15px;
}

.product__form {
  position: relative;
}

.product-variant-selectors {
  width: 100%;
}

.product-variant-selectors__group {
  width: 100%;
}
.product-variant-selectors__group:not(:last-child) {
  margin-bottom: 20px;
}
.product-variant-selectors__group .choices__inner {
  background-color: $white-color;
}

.form-checkbox .form-checkbox__label {
  color: #4d4d4d;
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 0.88px;
}

.product-variant-selectors__label {
  display: block;
  color: $theme-color;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 15px;
}

.product-variant-selectors__dropdown {
  font-family: var(--fmc-font--proxima-nova);
  width: 100%;
}

/* Continue selling message in product page */
.continue-selling__message {
  margin: 2rem 0;
  border: 1px red solid;
  text-align: center;
  padding: 1.5rem 1rem;
  color: red;
  width: 100%;
  display: none;
}

.product-price--original {
  opacity: 0.7;
  text-decoration: line-through;
  margin-left: 5px;
  font-weight: 200;
}

.product-images {
  width: 820px;
}
@media (max-width: 1440px) {
  .product-images {
    width: calc(100% - 355px);
  }
}
@media (max-width: 950px) {
  .product-images {
    width: calc(100% + 30px + 30px);
    margin-left: -30px;
    margin-right: -30px;
  }
}
@media (max-width: 600px) {
  .product-images {
    width: calc(100% + 30px + 30px);
    margin-left: -30px;
    margin-right: -30px;
  }
}

.product-images__main {
  position: relative;
}
.product-images__main .slick-list {
  margin-left: 35px;
  margin-right: 35px;
  padding: 0 !important;
}
.product-images__main img {
  width: 100%;
  height: 508px;
  object-fit: cover;
  cursor: grab;
}
.product-images__main .slick-prev {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.product-images__main .slick-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 950px) {
  .product-images__main .slick-list {
    margin-left: 0;
    margin-right: 0;
  }
  .product-images__main img {
    height: 400px;
  }
  .product-images__main .slick-prev {
    left: 30px !important;
    filter: brightness(0) invert(1);
  }
  .product-images__main .slick-next {
    right: 30px !important;
    filter: brightness(0) invert(1);
  }
}
@media (max-width: 768px) {
  .product-images__main img {
    height: 350px;
  }
}
@media (max-width: 500px) {
  .product-images__main img {
    height: 255px;
  }
}

.product-images__thumbnails {
  margin-top: 43px;
}
.product-images__thumbnails img {
  height: 71px;
  object-fit: cover;
  cursor: pointer;
}
.product-images__thumbnails .slick-slide {
  margin: 0 12.5px;
}
@media (max-width: 950px) {
  .product-images__thumbnails {
    margin-top: 20px;
  }
  .product-images__thumbnails .slick-slide {
    margin: 0 10px;
  }
}
@media (max-width: 768px) {
  .product-images__thumbnails {
    display: none;
  }
}

@media (max-width: 950px) {
  .product-usps.product-images__usps {
    display: none;
  }
}

.product-main {
  width: calc(100% - 820px);
  padding-left: 35px;
}
@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .product-main {
    max-width: 355px;
  }
}
@media (max-width: 950px) {
  .product-main {
    width: 100%;
    padding-left: 0;
    margin-top: 30px;
  }
}
.product-main__garage-warning {
  max-width: fit-content;
  padding: 12px;
  color: #fff;
  border-radius: 8px;
  background-color: #cc452a;
  margin-bottom: 2em;
}

.product-main__sku {
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 5px;
}

.product__title {
  margin-bottom: 25px;
}

.product-main__reviews {
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 15px;
}

.product-price-and-wishlist-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 20px;
}

.product-main__price {
  font-family: var(--fmc-font--proxima-nova);
  color: $theme-color;
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.product-main__quick-links {
  margin-bottom: 0px;
}

.product-main__variant-selectors {
  margin-bottom: 35px;
}

.product__actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.product-main .product-main__quantity {
  width: 80px;
  margin-right: 25px;
}

.product-main__cta {
  width: calc(100% - 80px);
  max-width: 293px;
}

.product-usps.product-main__usps {
  display: none;
}
@media (max-width: 950px) {
  .product-usps.product-main__usps {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #999999;
    border-bottom: 1px solid #999999;
    margin-top: 40px;
  }
}

.product-main__tabs {
  display: none;
}
.product-main__tabs .container,
.product-main__tabs .container--sm,
.product-main__tabs .container--no-padding,
.product-main__tabs .container--mb-2,
.product-main__tabs .kuContainer {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1180px) {
  .product-main__tabs {
    display: initial;
  }
}
@media (max-width: 950px) {
  .product-main__tabs {
    display: none;
  }
}

.related-products .title--sm,
.recommended-products .title--sm,
.recently-viewed-products .title--sm {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 2px;
}

.product-quick-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-quick-links__link {
  font-family: var(--fmc-font--proxima-nova);
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  color: $theme-color;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 25px;
  padding-right: 25px;
}
.product-quick-links__link img,
.product-quick-links__link svg {
  width: 17px;
  min-width: 17px;
  height: auto;
  margin-left: 10px;
}
@media (max-width: 950px) {
  .product-quick-links__link {
    max-width: 50%;
  }
}

.product-usps {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  margin-top: 40px;
}
@media (max-width: 768px) {
  .product-usps {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}

.product-usps__item {
  width: 265px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
}
.product-usps__item img,
.product-usps__item svg {
  margin-right: 15px;
  min-width: 38px;
  min-height: 38px;
  width: 38px;
  height: 38px;
}
.product-usps__item:not(:last-child) {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #eaeaea;
}
@media (max-width: 768px) {
  .product-usps__item {
    width: 100%;
  }
  .product-usps__item:not(:last-child) {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
    margin-bottom: 20px;
  }
}

.product-quantity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.product-quantity__buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

.product-quantity__button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}
.product-quantity__button.product-quantity__button--increase {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
  padding-bottom: 3px;
}
.product-quantity__button.product-quantity__button--decrease {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
  padding-top: 3px;
}
.product-quantity__button.product-quantity__button--hidden {
  cursor: none;
  display: none;
}

.product-quantity__input {
  width: 60px;
  height: 41px;
  border-radius: 3px;
  background-color: $white-color;
  border: 1px solid #6e6e6e;
  text-align: center;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1.23px;
  padding: 0;
  margin-right: 10px;
}

@media (max-width: 1180px) {
  .product-tabs {
    margin-top: 0;
  }
}

@media (max-width: 1180px) {
  .product-tabs.product-tabs--main {
    /*display: none; */
  }
}

@media (max-width: 950px) {
  .product-tabs.product-tabs--main {
    display: initial;
  }
}

.product-tabs__tabs {
  width: 100%;
  background-color: #eeeeee;
  padding-top: 25px;
  padding-bottom: 30px;
}
.product-tabs__tabs .container,
.product-tabs__tabs .container--sm,
.product-tabs__tabs .container--no-padding,
.product-tabs__tabs .container--mb-2,
.product-tabs__tabs .kuContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
@media (max-width: 1180px) {
  .product-tabs__tabs .container,
  .product-tabs__tabs .container--sm,
  .product-tabs__tabs .container--no-padding,
  .product-tabs__tabs .container--mb-2,
  .product-tabs__tabs .kuContainer {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (max-width: 1180px) {
  .product-tabs__tabs {
    background-color: $white-color;
    padding-top: 30px;
    padding-bottom: 0;
  }
}

.product-tabs__tab {
  font-family: var(--fmc-font--proxima-nova);
  cursor: pointer;
  color: #4d4d4d;
  font-size: 16px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  padding: 5px;
}
.product-tabs__tab > svg {
  display: none;
}
.product-tabs__tab:after {
  content: "";
  display: block;
  width: 138px;
  height: 3px;
  border-radius: 3px;
  background-color: $theme-color;
  position: absolute;
  left: 50%;
  bottom: -17px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s all ease-in-out;
  -moz-transition: 0.2s all ease-in-out;
  -ms-transition: 0.2s all ease-in-out;
  -o-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
}
.product-tabs__tab.active:after {
  opacity: 1;
  visibility: visible;
}
.product-tabs__tab:hover:after {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1250px) {
  .product-tabs__tab {
    font-size: 14px;
    line-height: 10px;
    font-weight: 400;
    letter-spacing: 2px;
  }
}
@media (max-width: 1180px) {
  .product-tabs__tab {
    width: 100%;
    background-color: $theme-color;
    color: $white-color;
    padding: 20px 15px;
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
    text-transform: capitalize;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .product-tabs__tab:not(:last-child) {
    margin-bottom: 10px;
  }
  .product-tabs__tab > svg {
    display: initial;
    filter: brightness(0) invert(1);
  }
  .product-tabs__tab.active {
    background-color: $theme-hover-color;
  }
  .product-tabs__tab.active > svg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .product-tabs__tab.active .product-tabs__accordian-content {
    display: initial;
  }
  .product-tabs__tab:after {
    display: none !important;
  }
}

.product-tabs__accordian-content {
  display: none;
  width: calc(100% + 15px + 15px);
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 20px;
  margin-bottom: -20px;
  background-color: $white-color;
  color: #4d4d4d;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: none;
  font-weight: 400;
  cursor: default;
}
.product-tabs__accordian-content .product-tabs__content {
  columns: 1;
}

.product-tabs__content-container {
  background-color: #f4f4f4;
  padding: 50px 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
@media (max-width: 1180px) {
  .product-tabs__content-container {
    /*display: none; */
  }
}

.product-tabs__content-item {
  display: none;
}
.product-tabs__content-item.active {
  display: initial;
}

.product-tabs__content-title {
  font-family: var(--fmc-font--proxima-nova);
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 15px;
}

.product-tabs__content {
  column-count: 2;
  column-gap: 100px;
}
.product-tabs__content.product-tabs__content--full-column {
  column-count: 1;
  column-gap: 0;
}
.product-tabs__content * {
  color: #4d4d4d;
  font-size: 12px;
  line-height: 24px;
}
.product-tabs__content ul {
  margin-top: 8px;
  list-style: disc;
  padding-left: 27px;
}

.product-tabs__read-more {
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 30px;
  font-size: 12px;
  line-height: 24px;
  text-decoration: underline;
}

@media (max-width: 1180px) {
  .product-tabs__content iframe {
    width: -webkit-fill-available;
  }
}

.related-products {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 50px;
  padding-bottom: 40px;
}

.related-products__heading {
  margin-bottom: 40px;
}

.related-products__products {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 25px;
  row-gap: 25px;
}
@media (max-width: 1024px) {
  .related-products__products {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1024px) {
  .related-products .product-card .product-card__image {
    height: 220px;
  }
}

@media (max-width: 500px) {
  .related-products .product-card .product-card__image {
    height: 150px;
  }
}

.recommended-products {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 50px;
  padding-bottom: 40px;
}

.recommended-products__heading {
  margin-bottom: 40px;
}
@media (max-width: 500px) {
  .recommended-products__heading {
    margin-bottom: 30px;
  }
}

.recommended-products__products {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 60px;
  row-gap: 25px;
}
@media (max-width: 1024px) {
  .recommended-products__products {
    column-gap: 25px;
  }
}
@media (max-width: 900px) {
  .recommended-products__products {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .recommended-products__products {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 35px;
  }
}

.featured-articles--alt .featured-articles__text {
  letter-spacing: normal;
}

.article-item__subtitle {
  margin-top: 0;
}

.featured-articles--alt .featured-articles__text {
  font-size: 13px;
}

.product__title {
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
}
@media (max-width: 1024px) {
  .product__title {
    font-size: 35.5px;
  }
}
@media (max-width: 768px) {
  .product__title {
    font-size: 35px;
  }
}
@media (max-width: 600px) {
  .product__title {
    font-size: 34px;
  }
}
@media (max-width: 1024px) {
  .product__title {
    line-height: 43px;
  }
}
@media (max-width: 768px) {
  .product__title {
    line-height: 42px;
  }
}
@media (max-width: 600px) {
  .product__title {
    line-height: 40px;
  }
}

.product-main__sku {
  font-size: 12px;
  letter-spacing: 1.5px;
}
@media (max-width: 1024px) {
  .product-main__sku {
    font-size: 11.5px;
  }
}
@media (max-width: 768px) {
  .product-main__sku {
    font-size: 11px;
  }
}
@media (max-width: 600px) {
  .product-main__sku {
    font-size: 10px;
  }
}
@media (max-width: 1024px) {
  .product-main__sku {
    letter-spacing: 1.4375px;
  }
}
@media (max-width: 768px) {
  .product-main__sku {
    letter-spacing: 1.375px;
  }
}
@media (max-width: 600px) {
  .product-main__sku {
    letter-spacing: 1.25px;
  }
}

.product-main__price {
  color: $theme-color;
}

.recently-viewed-products {
  padding-bottom: 100px;
}

@media (max-width: 950px) {
  .product-usps.product-main__usps {
    border: none;
  }
}

@media (max-width: 600px) {
  .product-images {
    width: calc(100% + 25px + 25px);
    margin-left: -25px;
    margin-right: -25px;
  }
  .container,
  .container--sm,
  .container--no-padding,
  .container--mb-2,
  .kuContainer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.product-card__title-link {
  color: $theme-color;
  letter-spacing: 2px;
  font-size: 17px;
}
@media (max-width: 1024px) {
  .product-card__title-link {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .product-card__title-link {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  .product-card__title-link {
    font-size: 13px;
  }
}

.product-card__price {
  font-size: 17px;
  letter-spacing: 2px;
}

.product-card--small .product-card__title-link {
  font-size: 12px;
  letter-spacing: 1.5px;
}

.product-card--small .product-card__category {
  font-size: 12px;
  text-transform: uppercase;
}

.product-card--small .product-card__price {
  font-size: 17px;
}

.product-quick-links__link {
  font-size: 16px;
  position: relative;
}
.product-quick-links__link svg {
  width: 9px;
  min-width: 9px;
}
@media (max-width: 1024px) {
  .product-quick-links__link svg {
    transform: rotate(90deg);
  }
}
@media (min-width: 769px) {
  .product-quick-links__link:hover:before {
    -webkit-transition: 0.2s all ease-in-out;
    -moz-transition: 0.2s all ease-in-out;
    -ms-transition: 0.2s all ease-in-out;
    -o-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
    content: " ";
    display: block;
    width: calc(100% - 20px);
    position: absolute;
    border-bottom: 2px solid $theme-hover-color;
    bottom: -5px;
    left: 0;
    opacity: 1;
  }
}

.product-tabs__tabs {
  padding: 30px 0;
  background-color: #f6f7f7;
}
@media screen and (min-width: 950px) and (max-width: 1180px) {
  .product-tabs__tabs {
    padding: 0;
    margin: 30px 0;
  }
}

.product-tabs__tab {
  font-size: 17px;
  color: $theme-color;
}
@media screen and (min-width: 950px) and (max-width: 1180px) {
  .product-tabs__tab {
    background: none;
    color: $theme-color;
  }
  .product-tabs__tab.active {
    background: none;
  }
}
.product-tabs__tab i {
  display: flex;
  align-items: center;
  height: 100%;
}
@media (min-width: 1025px) {
  .product-tabs__tab i {
    display: none !important;
  }
}
.product-tabs__tab i.minus {
  display: none;
}
.product-tabs__tab.active i.plus {
  display: none;
}
.product-tabs__tab.active i.minus {
  display: flex;
}
.product-tabs__tab svg {
  filter: none;
}
.product-tabs__tab svg path {
  fill: $theme-hover-color !important;
}
@media (max-width: 1024px) {
  .product-tabs__tab {
    background: none;
    color: $theme-color;
    text-transform: uppercase;
    border-top: 1px solid #e3e3e3;
    margin-bottom: 0;
    font-size: 15px;
    letter-spacing: 2px;
  }
  .product-tabs__tab.active {
    background: none;
  }
}
@media (max-width: 1180px) {
  .product-tabs__tab:not(:last-child) {
    margin-bottom: 0;
  }
}

.product-tabs__tab:after {
  background-color: $theme-hover-color;
  bottom: -29px;
  width: 100%;
}

.product-tabs__content * {
  font-size: 16px;
  letter-spacing: normal;
  line-height: 20px;
  color: $theme-color;
}

@media (max-width: 1024px) {
  .product-tabs__accordian-content {
    background: none;
  }
}

.product-tabs__content-title.subtitle {
  font-size: 17px;
}

.product-quantity__button.product-quantity__button--increase {
  transform: rotate(-90deg);
  margin-bottom: 1px;
}

.product-quantity__button.product-quantity__button--decrease {
  transform: rotate(90deg);
  margin-top: 1px;
}

.product-main .product-main__quantity {
  margin-right: 45px;
}

.product-quantity__input {
  border: 2px solid #99a0a3;
  border-radius: 0;
}

.form-checkbox .form-checkbox__label {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: normal;
  color: $theme-color;
}

.form-checkbox .checkmark {
  border-radius: 0;
  border-color: #99a0a3;
}

/* ---------------------------------------------
    Layout
--------------------------------------------- */
.site-header {
  height: 123px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.fixed-header {
  position: sticky;
  top: 0;
  z-index: 101;
}
.site-header-eyebrow {
  height: 35px;
  position: relative;
  z-index: 9999;
}

.site-header-main {
  height: 100px;
}

main {
  margin-top: 135px;
}

.page-scrolled-down .site-header {
  -webkit-transform: translateY(-35px);
  -moz-transform: translateY(-35px);
  -ms-transform: translateY(-35px);
  -o-transform: translateY(-35px);
  transform: translateY(-35px);
}

@media (max-width: 1024px) {
  .icon-banner {
    position: fixed;
    top: 0;
    left: 0;
    height: 43px;
    width: 100%;
    z-index: 2;
  }
  .icon-banner .icon-banner__segment > a,
  .icon-banner .icon-banner__segment > div {
    height: 43px;
  }
  .site-header {
    height: 70px;
  }
  .site-header-eyebrow {
    display: none;
  }
  .site-header-main {
    height: 70px;
  }
  .site-header__nav {
    position: fixed;
    top: 70px;
  }
  main {
    margin-top: 70px;
  }
}
@media (max-width: 1024px) and (max-width: 500px) {
  main {
    margin-top: 70px;
  }
}

@media (max-width: 1024px) {
  .page-scrolled-down .icon-banner {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .page-scrolled-down .site-header {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .page-scrolled-down .site-header__nav {
    top: 70px;
  }
}
@media (max-width: 959px) {
  .quick-search {
    position: fixed !important;
    top: 123px;
    left: 0;
    z-index: 9999;
  }
}

.quick-search .klevu-search__inner {
  max-height: calc(100vh - 100px - 35px - 30px);
}

.megamenu,
.megamenu__sub-menu {
  position: fixed;
  z-index: 5;
  top: 135px;
  max-height: calc(100vh - 100px - 35px);
}
@media (max-width: 480px) {
  .megamenu,
  .megamenu__sub-menu {
    top: 0;
  }
}

.garage-active-vehicle-notification {
  position: fixed;
  top: 135px;
  left: 0;
  z-index: 5;
}

.ford-section--header-vin-search {
  position: fixed;
  left: 0;
  z-index: 5;
  top: 135px;
}

.boost-collection-filter__outer-wrapper {
  /* position: fixed;
  left: 0;
  height: calc(100vh - 100px - 35px);
  top: 135px;
  z-index: 2;*/
}
.kuContainer #ku-search-filter-tags {
  position: fixed;
  left: 0;
  height: calc(100vh - 100px - 35px);
  top: 135px;
  z-index: 2;
}

#klevu-pt-rs-hover {
  position: fixed !important;
  top: 190px !important;
}

.klevu-fluid .klevu-searching-area {
  position: fixed;
  top: calc(100px + 35px + 101px) px !important;
}

.page-scrolled-down .quick-search {
  top: 100px;
}
.page-scrolled-down .quick-search .klevu-search__inner {
  max-height: calc(100vh - 100px - 30px);
}
.page-scrolled-down .quick-search .klevu-search {
  top: calc(41px + 100px + 66px);
}

.page-scrolled-down .megamenu,
.page-scrolled-down .megamenu__sub-menu {
  max-height: calc(100vh - 100px);
}

.page-scrolled-down .garage-active-vehicle-notification {
  top: 100px;
}

.page-scrolled-down .ford-section--header-vin-search {
  top: 100px;
}

.page-scrolled-down .boost-collection-filter__outer-wrapper,
.page-scrolled-down .kuContainer #ku-search-filter-tags {
  height: calc(100vh - 100px);
  top: 100px;
}

.page-scrolled-down #klevu-pt-rs-hover {
  top: 155px !important;
}

.page-scrolled-down .klevu-fluid .klevu-searching-area {
  top: calc(100px + 101px) px !important;
}

@media (max-width: 959px) {
  .quick-search {
    bottom: 0;
    top: 70px;
  }
  .quick-search .klevu-search__inner {
    max-height: calc(100vh - 140px - 30px);
    padding-bottom: 10px;
    padding-top: 5px;
  }
  .garage-active-vehicle-notification {
    top: 70px;
  }
  .ford-section--header-vin-search {
    top: 70px;
  }
  .boost-collection-filter__outer-wrapper,
  .kuContainer #ku-search-filter-tags {
    height: calc(100vh - 70px);
    top: 70px;
  }
  #klevu-pt-rs-hover {
    top: 162px !important;
  }
  .klevu-fluid .klevu-searching-area {
    top: 171px !important;
  }
}

@media (max-width: 1024px) {
  .page-scrolled-down .quick-search {
    top: 70px !important;
  }
  .page-scrolled-down .quick-search .klevu-search__inner {
    max-height: calc(100vh - 70px - 100px);
    padding-bottom: 50px;
  }
  .page-scrolled-down .quick-search .klevu-search {
    top: 70px;
  }
  .page-scrolled-down .garage-active-vehicle-notification {
    top: 70px;
  }
  .page-scrolled-down .ford-section--header-vin-search {
    top: 70px;
  }
  .page-scrolled-down .boost-collection-filter__outer-wrapper,
  .page-scrolled-down .kuContainer #ku-search-filter-tags {
    height: calc(100vh - 70px);
    top: 70px;
  }
  .page-scrolled-down #klevu-pt-rs-hover {
    top: 119px !important;
  }
  .page-scrolled-down .klevu-fluid .klevu-searching-area {
    top: 171px !important;
  }
}

/* Typography Overrides for Lincoln */
/* ---------------------------------------------
    General
--------------------------------------------- */
body {
  font-family: var(--fmc-font--proxima-nova) !important;
  color: $theme-color;
  font-size: 16px;
}
@media (max-width: 1024px) {
  body {
    font-size: 15px;
    padding-right: 0px !important;
  }
}
@media (max-width: 768px) {
  body {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  body {
    font-size: 14px;
  }
}

a {
  text-decoration-color: $theme-hover-color;
  text-decoration-thickness: 2px;
}

/* ---------------------------------------------
    Titles
--------------------------------------------- */
h1 {
  font-family: var(--fmc-font--miller) !important;
}

.fnt-pri {
  font-family: var(--fmc-font--proxima-nova) !important;
}

.fnt-semi {
  font-family: var(--fmc-font--proxima-nova) !important;
  font-weight: 600;
}

.fnt-sec {
  font-family: var(--fmc-font--miller) !important;
}

.ic {
  text-transform: initial !important;
}

.uc {
  text-transform: uppercase !important;
}

.lc {
  text-transform: lowercase !important;
}

.ul {
  border-bottom: 2px solid $theme-hover-color;
  text-decoration: none;
}
.ul:hover {
  border-color: $theme-color;
}

.ls-xs {
  letter-spacing: 1px;
}

.ls-md {
  letter-spacing: 1.5px;
}

.ls-xl {
  letter-spacing: 2px;
}

.title--xl {
  font-size: 96px;
}
@media (max-width: 1024px) {
  .title--xl {
    font-size: 82px;
  }
}
@media (max-width: 768px) {
  .title--xl {
    font-size: 68px;
  }
}
@media (max-width: 600px) {
  .title--xl {
    font-size: 40px;
  }
}

.title--lg {
  font-size: 66px;
}
@media (max-width: 1024px) {
  .title--lg {
    font-size: 58px;
  }
}
@media (max-width: 768px) {
  .title--lg {
    font-size: 50px;
  }
}
@media (max-width: 600px) {
  .title--lg {
    font-size: 34px;
  }
}
.title--lg.bold {
  line-height: 70px;
  letter-spacing: 2px;
}
@media (max-width: 1024px) {
  .title--lg.bold {
    line-height: 67px;
    letter-spacing: 1.9px;
  }
}
@media (max-width: 768px) {
  .title--lg.bold {
    line-height: 64px;
    letter-spacing: 1.8px;
  }
}
@media (max-width: 600px) {
  .title--lg.bold {
    line-height: 58px;
    letter-spacing: 1.6px;
  }
}

.title--md {
  font-size: 40px;
  line-height: 48px;
}
@media (max-width: 1024px) {
  .title--md {
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  .title--md {
    font-size: 32px;
  }
}
@media (max-width: 600px) {
  .title--md {
    font-size: 24px;
  }
}
@media (max-width: 1024px) {
  .title--md {
    line-height: 43.5px;
  }
}
@media (max-width: 768px) {
  .title--md {
    line-height: 39px;
  }
}
@media (max-width: 600px) {
  .title--md {
    line-height: 30px;
  }
}

.title--sm,
h2 {
  font-size: 24px;
}
.product-listing-container.productListingWidget {
  .MuiCardMedia-root {
    height: 230px !important;
    width: 100% !important;
    background-size: cover;
  }
  .product-card .full-height .vertical-padding-2 {
    padding-left: 12px !important;
    padding-right: 12px !important;
    text-align: left;
  }
  .product-card p.wrapText.MuiTypography-alignCenter {
    font-family: var(--fmc-font--proxima-nova);
    color: $theme-color !important;
    letter-spacing: 1px !important;
    font-size: 17px !important;
    text-transform: uppercase !important;
    width: auto !important;
    font-weight: 400;
    text-align: left;
    line-height: normal;
  }
  .product-card p.MuiTypography-alignCenter {
    font-family: var(--fmc-font--proxima-nova);
    margin-top: auto;
    font-size: 17px !important;
    font-weight: 400;
    padding-top: 10px;
    text-align: left;
  }

  @media (min-width: 1280px) {
    .MuiGrid-grid-lg-3 {
      flex-grow: 0;
      max-width: 33.333333%;
      flex-basis: 33.333333%;
    }
  }
  .product-card .full-height {
    border: none;
    box-shadow: none;
  }
  .container-width {
    width: 1280px;
    max-width: 100%;
    margin: auto !important;
    padding: 0 24px;
  }

  .inner-container-width {
    width: 900px;
    max-width: 100%;
    margin: auto !important;
    padding: 0 24px;
    overflow: hidden;
  }
}
.boost-collection-filter__outer-wrapper
  .product-filter
  .MuiAccordionSummary-root:focus {
  outline: inherit !important;
  outline-offset: inherit;
  box-shadow: none;
  cursor: default;
  user-select: none;
  border: inherit;
}

.boost-collection-filter__outer-wrapper .product-filter {
  border-radius: inherit;
  box-shadow: none;
  overflow: initial;
  .MuiCollapse-wrapper {
    border-bottom: 1px solid #eaeaea;
  }
  label {
    padding-left: 20px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  label {
    span.MuiCheckbox-colorSecondary {
      display: none;
    }
    span.MuiFormControlLabel-label {
      font-family: var(--fmc-font--proxima-nova);
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 26px;
    }
  }
  h6 {
    font-size: 24px;
    font-family: var(--fmc-font--proxima-nova);
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding-left: 10px;
  }
  .MuiAccordion-root.MuiAccordion-root.Mui-expanded::before {
    background-color: #eaeaea;
  }
  .MuiAccordionDetails-root {
    padding: 8px 16px 0px;
  }
  .MuiAccordionSummary-root {
    padding-top: 20px;
    cursor: default;
    outline: none;
    user-select: none;
  }

  .price-filter .MuiGrid-container.MuiGrid-item.MuiGrid-grid-xs-12 {
    width: 50%;
    /* float: left; */
    max-width: 70%;
    display: block;
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true:first-child {
      float: right;
      right: 5px;
      position: relative;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true {
      margin-right: 20px;
      max-width: 45%;
    }
    p.MuiTypography-root.StyledNumberInput {
      max-width: 6rem;
    }
  }
  .price-filter .MuiGrid-item.MuiGrid-grid-xs-12 {
    width: 30%;
    max-width: 30%;
    float: right;
  }
  .MuiButton-containedPrimary {
    margin-top: 3px;
    span.MuiButton-label {
      font-size: 10px;
      font-family: var(--fmc-font--proxima-nova);
      font-weight: 300;
    }
  }
  .MuiButton-containedPrimary.Mui-disabled {
    background: #959ca0;
    border: 2px solid #efefef;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    padding-left: 10px;
    font-size: 17px;
    line-height: 17px;
    font-family: var(--fmc-font--proxima-nova);
    font-weight: 400;
    text-transform: uppercase;
  }
}
@media (max-width: 1024px) {
  .title--sm,
  h2 {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .title--sm,
  h2 {
    font-size: 21px;
  }
}
@media (max-width: 600px) {
  .title--sm,
  h2 {
    font-size: 19px;
  }
}

.title--xs {
  font-size: 17px;
  line-height: 34px;
}
@media (max-width: 1024px) {
  .title--xs {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .title--xs {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .title--xs {
    font-size: 15px;
  }
}
@media (max-width: 1024px) {
  .title--xs {
    line-height: 32px;
  }
}
@media (max-width: 768px) {
  .title--xs {
    line-height: 30px;
  }
}
@media (max-width: 600px) {
  .title--xs {
    line-height: 26px;
  }
}

.title--xxs {
  font-size: 12px;
}
@media (max-width: 1024px) {
  .title--xxs {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .title--xxs {
    font-size: 11px;
  }
}
@media (max-width: 600px) {
  .title--xxs {
    font-size: 10px;
  }
}

.subtitle {
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 300;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 26px;
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 400;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .subtitle {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .subtitle {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .subtitle {
    font-size: 12px;
  }
}

/* ---------------------------------------------
    Font sizes
--------------------------------------------- */
.error {
  font-size: 12px;
  letter-spacing: 1.5px;
  color: #b00020;
}
@media (max-width: 1024px) {
  .error {
    font-size: 9px;
  }
}
@media (max-width: 768px) {
  .error {
    font-size: 6px;
  }
}
@media (max-width: 600px) {
  .error {
    font-size: px;
  }
}

.caption {
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 21px;
  font-family: var(--fmc-font--proxima-nova);
  text-transform: uppercase;
  font-weight: 600;
}
@media (max-width: 1024px) {
  .caption {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .caption {
    font-size: 11px;
  }
}
@media (max-width: 600px) {
  .caption {
    font-size: 10px;
  }
}

.sm {
  font-size: 14px;
}
@media (max-width: 1024px) {
  .sm {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .sm {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .sm {
    font-size: 10px;
  }
}

.md {
  font-size: 16px;
}
@media (max-width: 1024px) {
  .md {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .md {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  .md {
    font-size: 14px;
  }
}

.lg {
  font-size: 18px;
}
@media (max-width: 1024px) {
  .lg {
    font-size: 17px;
  }
}
@media (max-width: 768px) {
  .lg {
    font-size: 17px;
  }
}
@media (max-width: 600px) {
  .lg {
    font-size: 16px;
  }
}

/* ---------------------------------------------
    Font weights
--------------------------------------------- */
.bolder {
  font-weight: 900;
}

.bold {
  font-weight: 600;
}

.medium {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

/* ---------------------------------------------
    Hide on specific views
--------------------------------------------- */
@media (min-width: 769px) {
  .hd {
    display: none;
  }
}

.expanded-menu-three-tier-submenu a:hover p {
  color: $theme-hover-color !important;
}

footer {
  color: $white-color !important;
  margin-top: 0px !important;
}
.page {
  padding-left: 0;
  padding-right: 0;
}

.user-menu__item--garage-no-chevron {
  margin-left: 10px;
  list-style-type: none;
  margin-right: 12px;
}

#ford-lincoln-section-160992556677381260 {
  min-height: 320px;
  height: 320px;
  position: relative;
  margin-bottom: 60px;
  margin-top: 0px;
}
@media (max-width: 1024px) {
  #ford-lincoln-section-160992556677381260 {
    min-height: 300px;
  }
}
@media (max-width: 768px) {
  #ford-lincoln-section-160992556677381260 {
    min-height: 280px;
  }
}
@media (max-width: 600px) {
  #ford-lincoln-section-160992556677381260 {
    min-height: 240px;
  }
}

#ford-lincoln-section-160992556677381260 .hero__subtitle {
  line-height: 34px;
}
@media (max-width: 768px) {
  #ford-lincoln-section-160992556677381260 .hero__subtitle {
    line-height: 26px;
  }
}

#shopify-section-disclosures .disclosures__content--active {
  height: 240px;
}

@media (max-width: 768px) {
  #shopify-section-disclosures .disclosures__content--active {
    height: 240px;
  }
}

.site-footer {
  margin-top: 0;
}
#global-search:focus {
  outline: none !important;
  outline-offset: initial;
}

div[data-testid="app-wrapper"] {
  perspective-origin: 50% 50%;
  overflow-y: initial !important;
  perspective: initial !important;
}

.MuiAlert-message {
  line-height: 18px;
}
