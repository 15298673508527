/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
* CSS Reset
*/
$fds-assets: "~@ford/ford-design-system/dist/assets";
@import "~@ford/ford-design-system/src/scss/fds.scss";
/*
@import 'node_modules/@ford/ford-design-system/src/scss/fonts/brand/lincoln/';
@import 'node_modules/@ford/ford-design-system/src/scss/brand';
*/
$hover-color: #f26148;

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:-webkit-any-link {
  text-decoration: none;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

$images-dir: "^images/";

.accordion-title:focus {
  background-color: transparent;
}

/**
* Call out
*/
// call out additional styles to fix close button overlaying text
.callout {
  & > *:first-child:not(.close-button) {
    display: inline-flex;
    margin-right: 1rem;
  }
}

.bundle-qty {
  vertical-align: middle;
  @media not screen and (max-width: 40em) {
    max-width: 4rem;
  }
}

@media screen and (max-width: 58.875em) {
  .hideOnMobile {
    display: none;
  }
}

.promo-large {
  position: relative;
  overflow: hidden;
  height: 85vh;
  background-position: 34% 0;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 640px) {
    height: 0;
    padding-top: 41.6667%;
  }

  img.promo-img {
    position: absolute;
    top: 0%;
    height: 100%;

    @media (max-width: 640px) {
      height: 100%;
      max-width: none;
    }
  }

  .promo-border {
    outline: 3px solid #fff;
    outline-offset: -20px;
  }
}

.promo-large-message {
  position: absolute;
  top: 10%;
  left: 10%;
  margin-right: 10%;

  .promo-large-text {
    font-size: 1.625rem; /*24px*/
    line-height: 1.4;
    font-weight: 600;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  }
}

/* Medium promo. Full browser width. Medium promo is meant to be placed in a
3-column-wide area. Hidden on small screens. */
.promo-medium {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6c6c6c;
  height: 40px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;

  img {
    position: absolute;
    top: 0%;
    left: 0;

    height: 100%;
  }

  @media (min-width: 640px) {
    display: static;
    position: relative;
    height: 0;
    padding-top: 16.6667%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 640px) {
    position: relative;
  }
}

.promo-medium-message {
  color: #fff;

  @media (min-width: 39.9375em) {
    width: 100%;
    position: absolute;
    top: 40%;
    left: 0;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  }

  @media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: landscape) {
    top: 35%;
  }

  @media (min-width: 63.9375em) {
    font-size: 1.875rem; /*30px*/
  }

  > p {
    margin: 0;
  }
}

/* Medium promo. Full browser width. Medium promo is meant to be placed in a
3-column-wide area. Hidden on small screens. */
.promo-small-container {
  position: relative;
  width: 100%;

  //In promotion image profile, small rendition has dimension ( 285px by 297px)
  max-width: 285px;
  max-height: 297px;

  .promo-small {
    position: relative;
    height: 0;
    padding-top: 75.1%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      top: 0%;
      display: block;
      height: auto;
      overflow: hidden;
    }

    .promo-border {
      outline: 3px solid black;
      outline-offset: -27px;
    }

    .promo-small-message {
      position: absolute;
      padding: 6px 10px;
      width: 100%;
      font-size: 1.625rem;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      > p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .promo-text-small {
    font-size: 1.375rem; /*22px*/
    font-weight: 700;
    line-height: 1.2;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    color: black;

    @media (min-width: 900px) {
      font-size: 1.625rem; /*26px*/
    }
  }
}

.wch-hero-image {
  overflow: hidden;
  position: relative;
  text-align: center;

  @media (max-width: 639px) {
    position: relative;
    height: 85vh;
    overflow: hidden;
  }

  .section {
    /*Heroes touch the header, remove top margin.*/
    padding-top: 0;
    display: flex;
  }

  &.wch-hero-image-message-right .hero-message > div {
    text-align: right;
  }

  &.wch-hero-image-message-left .hero-message > div {
    text-align: left;
  }

  &.wch-hero-image-message-center .hero-message > div {
    text-align: center;
  }

  img {
    max-width: 100%;
    transition: transform 0.3s ease-in;

    position: relative;
    z-index: 1;

    @media (max-width: 639px) {
      transform: translate3d(-18%, 0, 0);
      height: 100%;
      max-width: none;
    }

    @media (max-width: 39.9375em) {
      width: auto;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .foreground-image {
    position: absolute;
    z-index: 2;
  }
}

.hero-message {
  top: 0;
  right: auto;
  left: auto; /*centering overlaid message*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 1200px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5),
    0 -1px 0 rgba(255, 255, 255, 0.5), 1px 0 0 rgba(255, 255, 255, 0.5),
    -1px 0 0 rgba(255, 255, 255, 0.5);

  position: absolute;
  z-index: 2;
  margin-left: 1rem;
  margin-right: 1rem;

  @media (max-width: 39.9375em) {
    justify-content: flex-start;
    padding-top: 22vh;
  }

  > div {
    padding: 0 1rem;

    @media (min-width: 780px) {
      padding: 0 2rem;
    }
    @media (min-width: 1024px) {
      padding: 0 3rem;
    }
  }

  .text-hero-left {
    text-align: left;
  }

  .text-hero-right {
    text-align: right;
  }

  .text-hero-center {
    text-align: center;
  }
}

h1.text-hero {
  font-size: 2rem;
  line-height: 1;
  color: #000;
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 600;
  @media (min-width: 640px) {
    font-size: 2.5rem;
    line-height: 1.26;
  }

  @media (min-width: 1024px) {
    font-size: 4rem;

    h1 {
      max-width: 1200px;
    }
    > .button {
      margin-top: 64px;
    }
  }
}

.text-left {
  text-align: left;
}

/**
* Global mobile fixes
*/

// fix image overlaying border on safari and mobile
img {
  vertical-align: inherit;
}

.swatch-image {
  vertical-align: middle;
}

/* eSpot styling */
.increaseTextContrast {
  .MuiTypography-root {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 30px white;
    display: inline-block;
    border-radius: 6px;
    color: black;
  }
}

.textShadow {
  .MuiTypography-root {
    text-shadow: 0 0 0.3em white;
    color: black;
  }
}
.marketing-heroImage {
  overflow: hidden;
  height: 400px;
  background-size: cover;

  @media (min-width: 800px) {
    height: 500px;
  }

  @media (min-width: 1100px) {
    height: 600px;
  }

  @media (min-width: 1900px) {
    height: 700px;
  }

  .MuiTypography-h2 {
    font-weight: 400;
  }

  .marketing-text {
    left: 20px;

    @media (min-width: 600px) {
      left: 40px;
    }

    @media (min-width: 1000px) {
      left: 60px;
    }
  }

  img {
    opacity: 0.75;

    @media (min-width: 640px) {
      opacity: 0.8;
    }

    @media (min-width: 780px) {
      opacity: 0.85;
    }

    @media (min-width: 900px) {
      opacity: 0.9;
    }
  }
}

.marketing-fullscreen-banner {
  img {
    position: absolute;
  }
}

.HomeHeroContent {
  .marketing-text {
    background: rgba(255, 255, 255, 0.6);
    border: 2px solid white;
    border-radius: 6px;

    @media (max-width: 799px) {
      width: 100%;
      max-width: 100%;
      left: 0;
    }
  }

  .MuiTypography-root {
    display: inline;
  }

  .MuiTypography-h2 {
    font-weight: 700;
  }

  .marketing-text {
    padding: 20px 24px;
    left: 0;

    @media (min-width: 960px) {
      padding: 24px 30px;
      left: 40px;
    }

    @media (min-width: 1320px) {
      left: 0;
    }
  }
}

.FreeDeliveryContent {
  height: 200px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1),
    0px 2px 1px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: 600px) {
    height: 240px;

    .marketing-text {
      padding: 16px 32px;
    }
  }
  @media (max-width: 599px) {
    .marketing-text {
      padding: 8px 16px;
    }
  }

  img {
    opacity: 0.4;
  }

  .marketing-text {
    width: 600px;
    max-width: 100%;
    text-align: center;
    padding: 8px 16px;
    font-weight: 500;
    color: black;

    @media (min-width: 600px) {
      padding: 16px 32px;
    }
  }

  .MuiTypography-h4 {
    font-weight: 500;
  }
}

.PercentOffContent {
  border-radius: 6px;
  box-shadow: 2px 3px 3px #ddd;
  margin-top: 16px;
  background: white;

  .MuiGrid-item:last-child {
    min-height: 120px;
    position: relative;
    margin-top: 8px;
  }

  .MuiTypography-h4 {
    font-weight: 500;
  }
}

.SapphirePercentOffContent {
  color: white;
  height: 200px;
  border-radius: 6px;
  font-weight: 500;

  @media (min-width: 600px) {
    height: 400px;
  }

  .marketing-text {
    max-width: 350px;
  }
}

.SapphireOrderManagement {
  height: 200px;
  border-radius: 6px;

  @media (min-width: 600px) {
    height: 240px;

    .marketing-text {
      padding: 16px 32px;
    }
  }
  @media (max-width: 599px) {
    .marketing-text {
      padding: 8px 16px;
    }
  }

  img {
    opacity: 0.9;
  }

  .marketing-text {
    width: 600px;
    max-width: 90%;
    padding: 0 16px 8px;
    font-weight: 500;
    color: black;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 6px;

    @media (min-width: 600px) {
      padding: 8px 20px 16px;
    }
  }

  .MuiTypography-h4 {
    font-weight: 400;
  }
}

.marketing-backgroundImage {
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    vertical-align: middle;
  }
}

.marketing-content {
  position: relative;
  overflow: hidden;
}

.marketing-text {
  &.marketing-text-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &.marketing-text-horizontal-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &.marketing-text-horizontal-center.marketing-text-vertical-center {
    transform: translateX(-50%) translateY(-50%);
  }
}

.category-recommendation {
  img {
    display: block;
  }
}

.MuiPopover-root {
  .StyledTableFilterMenu {
    top: 50% !important;
    left: 50% !important;
  }
}

.table-filter {
  .MuiTypography-body2 {
    font-weight: 600;
    font-size: 1.05rem;
  }
}

.bundle-error {
  .MuiTypography-body2.error {
    font-weight: 600;
    font-size: 0.8rem;
  }
}

li.MuiBreadcrumbs-li:last-child {
  overflow: hidden;
}

.table-tablet {
  @media screen and (min-width: 40em) and (max-width: 64em) {
    > thead {
      > tr {
        > th {
          padding: 0.2rem;
        }
      }
    }
    > tbody {
      > tr {
        > td {
          padding: 0.2rem;
        }
      }
    }
  }
}

.cprof-cr8-acrdn {
  > .MuiAccordionSummary-content {
    > .MuiBox-root {
      flex-wrap: nowrap;
    }
  }
}

.shipment-group-summary {
  // panel-text
  > .MuiAccordionSummary-content {
    > .shipment-group-summary-text {
      margin-top: 0.25rem;
      p {
        word-break: break-word;
        white-space: normal;
      }
    }
  }
  // panel-expander (icon+text)
  > .MuiAccordionSummary-expandIcon.MuiButtonBase-root.MuiIconButton-root {
    margin-top: 0.25rem;

    > .MuiIconButton-label {
      > .accordion-show-summary,
      .accordion-show-expanded {
        @media screen and (max-width: 40em) {
          display: none;
        }
      }
    }
  }
}

p.MuiTypography-root.StyledNumberInput {
  @media screen and (min-width: 40em) {
    max-width: 10rem;
  }
}

.break-word {
  word-break: break-word;
}

.text-right {
  text-align: right;
}

.cta-banner {
  max-height: 170px;
  overflow: hidden;
}
.mini-cart-container,
.search-results-page,
.ProductListPage,
.product-filter {
  .react-numeric-input {
    input {
      width: 60px !important;
      height: 41px !important;
      border-radius: 3px !important;
      background-color: #ffffff;
      border: 1px solid #6e6e6e !important;
      text-align: center;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 1.23px;
      padding: 0;
      margin-right: 10px;
    }
    b {
      width: inherit !important;
      border: none !important;
      right: -14px;
      bottom: -10px;
      i {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='12' viewBox='0 0 9 12'%3E%3Cg%3E%3Cg transform='rotate(90 4.5 6.5)'%3E%3Cpath fill='%23324047' d='M-.53 10.28l4.59-4.945 4.59 4.945 1.41-1.522-6-6.478-6 6.478z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        width: 0;
        height: 0;
        background: transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #102b4e;
        &:nth-child(2) {
          display: none !important;
        }
      }
      &:hover {
        i {
          border-color: #102b4e transparent transparent !important;
        }
      }
    }
    b:last-child {
      left: auto;
      right: -14px;
      bottom: 22px;
      i {
        width: 0;
        height: 0;
        background: transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 10px solid #102b4e;
        border-top: none;
      }
      &:hover {
        i {
          border-color: transparent transparent #102b4e !important;
        }
      }
    }
  }
  .StyledNumberInput {
    .react-numeric-input {
      input {
        padding: 0 8px !important;
        font-weight: 400;
      }
      b {
        right: -14px !important;
        bottom: auto !important;
        top: -2px !important;
        i {
          background: transparent !important;
          border-top: none;
          border-bottom: 10px solid #102b4e;
          &:hover {
            border-color: transparent transparent #102b4e !important;
          }
        }
      }
      b:last-child {
        left: auto;
        top: 18px !important;
        right: -14px !important;
        bottom: auto !important;
        i {
          border-bottom: none;
          border-top: 10px solid #102b4e;
          &:hover {
            border-color: #102b4e transparent transparent !important;
          }
        }
      }
    }
  }
}
.mini-cart-popper {
  position: fixed !important;
  transform: initial !important;
  left: auto !important;
  margin: 0 !important;
  z-index: 10000 !important;
  right: 0;
  height: 100vh;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1),
    0px 2px 1px 0px rgba(0, 0, 0, 0.1);
  &::before {
    content: "";
    width: 100vw;
    height: 100vh;
    display: initial;
    position: fixed;
    top: 0;
    left: -100vw;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .mini-cart-container {
    width: 100% !important;
    max-height: 100% !important;
    height: 100%;
    border-radius: 0;
    padding: 5px 45px 20px 25px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1;
    position: relative;

    .side-cart-overview__cart-cta.btn.btn--border:after {
      position: relative;
      right: -2px;
    }
    .btn.cartbutton:after {
      position: relative;
      right: 0px;
    }
    .side-cart-header {
      padding: 0;
      margin-bottom: 15px;
      border-bottom: 1px solid #eeeeee;
      .side-cart-header__heading {
        font-size: 30px !important;
        padding-bottom: 15px;
      }
      .side-cart-header__item-count {
        font-size: 18px;
        font-weight: 300;
      }
      .side-cart-header__close.close-cross {
        &:hover:before {
          transform: rotate(45deg) !important;
        }
        &:hover:after {
          transform: rotate(-45deg) !important;
        }
      }
    }
    .side-cart-overview__cart-cta:hover {
      @media (max-width: 999px) {
        color: #324047;
      }
    }
    .side-cart-items {
      padding: 0;
      padding-top: 15px;
      height: calc(100vh - 400px);

      .cart-item {
        .cart-item__information {
          text-align: left;
          align-items: flex-start;
          .cart-item__details {
            width: 235px;

            .cart-item__mobile-quantity-and-price {
              .product-quantity {
                .product-quantity__input {
                }
              }
            }
          }
        }
        .cart-item__image .cart-item__image-remove {
          margin-top: 5px;
          padding: 5px;
        }
      }
    }
    h6 {
      padding: 0;
      font-size: 34px;
      font-weight: 300;
      letter-spacing: 3px;
      padding-top: 17px;
      padding-bottom: 15px;
      color: #4d4d4d;
    }
    p {
      color: #4d4d4d;
      font-size: 20px;
      line-height: 26px;
      font-weight: 400 !important;
      font-style: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    p.StyledNumberInput {
      border: none;
    }

    .horizontal-padding-12,
    .horizontal-padding-2.vertical-padding-1 {
      padding: 0 !important;
    }
    button {
      width: 100%;
      margin-top: 25px;
      border: 1px solid #324047;
      background: none;
      color: #324047;
      border-radius: 20px;
      font-size: 18px;
      letter-spacing: 1px;
      max-height: 40px;
      font-weight: 400;
    }
    .heading {
      height: 1px;
      background-color: #eeeeee !important;
    }
  }
}

@media (max-width: 490px) {
  .mini-cart-popper {
    left: 0 !important;
    .mini-cart-container {
      padding: 5px 25px 20px 25px !important;
      .side-cart-header {
        padding-top: 55px;
        padding-bottom: 15px;
        padding-right: 50px;
        align-items: baseline;
        .side-cart-header__heading {
          font-size: 20px !important;
        }
      }
      .side-cart-items {
        .cart-item {
          .cart-item__information {
            .cart-item__details {
              width: calc(100% - 123px);
            }
          }
        }
      }
    }
  }
}

a.klevu-search__product-link p {
  /*overflow: hidden;*/
  white-space: normal;
  /*text-overflow: ellipsis;*/
  display: inline-block;
  vertical-align: top;
  max-width: 88%;
  padding-left: 10px;
  font-size: 14px;
  line-height: 16px;
  font-family: var(--fmc-font--proxima-nova);
}
.klevu-search.results-display {
  visibility: visible;
  background: #ffffff;
  position: absolute;
  width: 100%;
  min-width: 380px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
  @media (min-width: 960px) {
    width: 150%;
  }
}

.quick-search__form fieldset {
  border-width: 1.5px !important;
}

.my-garage-vehicle--active h3.my-garage-vehicle__nickname {
  color: #ffffff;
}

.verizona-popup {
  .state-popup__buttons {
    .linbutton {
      color: #ffffff;
    }
    .linbutton:after {
      right: 2px;
      position: relative;
    }
    .accbutton:after {
      background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln.svg?v=132685267715316169101664033355);
      color: #324047;
      position: relative;
      right: 2px;
    }
    .accbutton:hover:after {
      background-image: url(//cdn.shopify.com/s/files/1/0518/9563/2063/t/689/assets/icon-chevron-lincoln-white.svg?v=95280333402642282991664033348);
      color: #ffffff;
    }
  }
}

.order__return {
  cursor: pointer;
  text-decoration: underline;
}
.order__return:hover {
  color: $hover-color !important;
}

.redeem-points-border {
  a {
    border-bottom: 1px dotted #324047;
  }
  .hover-none,
  .hover-none:hover,
  .hover-none:visited {
    border-bottom: 1px dotted #324047;
    color: #324047;
  }
}

.fpr-hover-color:hover {
  color: #324047;
}

 

.lincoln-recommended-products {
  h4 {
    font-size: 2.4rem;
    font-weight: 400;
    letter-spacing: 3px;
    color: #4d4d4d;
    @media (max-width: 767px) {
      font-size: 18px;
    }
    span {
      padding: 20px 80px 5px 80px;
      @media (max-width: 767px) {
        padding: 20px 20px 5px 20px;
      }
    }
  }
  .carousel__back-button,
  .carousel__next-button {
    svg {
      font-size: 24px;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
  .product-card {
    .full-height {
      border: none;
      box-shadow: none;
      height: auto !important;
      max-height: none !important;
      .vertical-padding-2 {
        padding-left: 12px !important;
        padding-right: 12px !important;
        text-align: left;
      }
    }
    &--card-img {
      width: 100% !important;
      height: 185px !important;
      background-size: cover !important;

      @media (min-width: 1440px) {
        height: 210px !important;
      }
      @media (min-width: 1680px) {
        height: 240px !important;
      }
    }
    &__title {
      width: 100% !important;
      text-align: left;
      text-transform: uppercase;
    }
    &__price {
      text-align: left;
    }
    .product-card__rewardsP {
      a {
        font-weight: initial;
        text-decoration: underline;
        text-decoration-style: dotted;
      }
    }
    .MuiTypography-body1.MuiTypography-alignCenter {
      color: #324047;
      font-family: var(--fmc-font--proxima-nova);
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 2px;
      line-height: 2px;
      margin-top: 20px;
      text-align: left;
    }
    .MuiTypography-body2.MuiTypography-alignCenter {
      color: #324047;
      font-size: 16px !important;
      line-height: 22px !important;
      font-weight: 400 !important;
      letter-spacing: 1.6px !important;
      text-align: left;
      text-transform: capitalize;
      width: 100% !important;
    }
    .MuiCardMedia-root {
      background-size: cover !important;
      height: 185px !important;
      width: 100% !important;
    }
  }
}

.arizonapop .state-popup__inner.flex.f-dir-column {
  padding-top: initial;
}
.site-header-eyebrow__nav.container {
  @media (min-width: 1020px) and (max-width: 1300px) {
    width: 100%;
  }
}

.returnitem-check input {
  width: 20px;
  height: 20px;
}

/*----- sitemap --*/

#sitemapper h2 {
  margin-bottom: 50px;
  font-size: 34px;
}

#sitemapper .sitemapper-list {
  /* display: flex !important;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 50px; */
  .subtitle {
    font-size: 20px;
    font-weight: 400;
    align-items: baseline;
    font-stretch: normal;
    text-align: left;
    text-decoration: underline;
    justify-content: flex-start;
    &:hover {
      color: $hover-color !important;
    }
  }
  li {
    /* margin-top: 10px;
    border: none !important;
    min-height: initial !important;
    &:hover {
      background: transparent !important;
      color: #102b4e !important;
      a {
        color: #102b4e !important;
      }
    }
    a {
      width: 100%;
      text-transform: uppercase;
      padding-left: 0 !important;
      padding-right: 0 !important;
    } */
    .sitemapper-list__category {
      margin-bottom: 20px;
      width: 33%;
      display: block;
      float: left;
      @media screen and (max-width: 40em) {
        width: 50%;
      }
      li {
        a {
          line-height: 110%;
          font-size: 18px;
          text-decoration: none !important;
        }
        li a {
          font-weight: 300;
          text-transform: initial;
        }
      }
    }
  }
}

.myaccount_section {
  margin-bottom: 40px;
  .pagination {
    background-color: #fff;
    margin-top: 50px;
    @media (max-width: 767px) {
      margin-top: 30px;
    }
    .pagination__container {
      .pagination__page-select {
        li {
          width: 40px;
          height: 40px;
          line-height: 40px;
          margin: 0 3px;
          border: 2px solid rgb(140, 140, 140);
          a {
            cursor: pointer;
            padding: 10px 15px;
            &:hover {
              color: $hover-color;
            }
          }
          &:first-child,
          &:last-child {
            width: auto;
            border: none;
            color: rgb(16, 43, 78);
            background-color: initial;
          }
          &.paginationActive {
            border: 0px;
            background: #324047;
            color: white;
            a {
              color: white;
              &:hover {
                color: $hover-color;
              }
            }
          }
          &.paginationDisabled {
            a {
              cursor: default;
              pointer-events: none;
              color: #aaaaaa;
            }
          }
        }
      }
    }
  }
  .title {
    font-size: 34px !important;
  }
  .subtitle {
    text-transform: initial !important;
    font-size: 24px !important;
    margin-bottom: 20px !important;
  }
  .content {
    font-size: 16px !important;
  }
  .addaddressDetail {
    @media (max-width: 767px) {
      margin-top: 30px;
      .account-subheading {
        margin-bottom: 0 !important;
      }
    }
  }
  .editaddressDetail {
    @media (max-width: 767px) {
      margin-top: 50px;
    }
  }
  .account-dashboard__orders {
    padding: 20px;
    border: 1px solid #dddddd;
    border-radius: 6px;
    ol li {
      .order-item__detail {
        font-size: 16px;
      }
    }
    &_thumbnail {
      display: flex;
      flex-direction: row;
      a {
        margin: 10px;
        border: 1px solid #102b4e;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .order-address {
    .order-address__title {
      font-weight: 500;
    }
    .OrderHistory_addresCard {
      display: flex;
      flex-direction: column;
      .address_label {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 2px;
      }
    }
  }
  .order-tracking {
    a {
      text-decoration: underline;
    }
    .order-tracking__title {
      font-weight: 500;
    }
  }
  .order-table {
    .order__details {
      width: 100%;
      a {
        display: flex;
        flex-direction: row;
        img {
          border: none;
          max-width: 120px;
          max-height: 120px;
          margin-right: 25px;
        }
        .order__details-text {
          .order__product-title {
            margin-bottom: 10px;
          }
          .order__product-text {
            color: #102b4e;
          }
        }
      }
      .order-table__main-item {
        .order__buy-again {
          text-decoration: underline;
        }
      }
    }
  }
  .add-address {
    .deleteAddress {
      padding: 10px;
      margin-left: 60px;
      text-align: center;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      .grid-padding {
        padding: 0;
        width: 100%;
        margin: auto;
        .MuiGrid-item {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .addresses__list-item-cta {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .input-field {
    @media (max-width: 767px) {
    }
    .label-address-form {
      font-size: 16px;
      font-weight: 300;
    }
    .input-address-form {
      margin-top: 10px;
      input {
        font-size: 16px;
        font-weight: 300;
        height: 100%;
        border-radius: 5px;
        padding: 16px 15px 17px;
        border: 1px solid #d9d9d9;
      }
      fieldset {
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        border-width: 1px;
      }
      &:hover {
        fieldset {
          border: 1px solid #d9d9d9 !important;
        }
      }
    }
  }
  .state-my-account,
  .input-address-form {
    margin-top: 10px;
    max-height: 54px;
    input {
      font-size: 16px;
      font-weight: 300;
      height: 100%;
      border-radius: 5px;
      padding: 16px 15px 17px;
      border: 1px solid #d9d9d9;
    }
    fieldset {
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      border-width: 1px;
    }
    &:hover {
      fieldset {
        border: 1px solid #d9d9d9 !important;
      }
    }
  }
  .state-my-account input {
    border: none;
  }
  .addressformCheckbox {
    input {
      position: absolute;
    }
  }
  > .addresses-add-form__cancel-cta {
    margin-left: 45px;
    display: flex;
    margin-bottom: 60px;
    margin-top: 0;
    @media (max-width: 767px) {
      margin-left: 0 !important;
    }
  }
  .addresses-add-form__cancel-cta {
    margin-left: 45px;
    display: flex;
    margin-bottom: 60px;
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-left: 0 !important;
    }
  }
  .addresses-add-form__submit-cta {
    margin-left: 45px;
    width: auto;
    margin-top: 0px;
    @media (max-width: 767px) {
      margin-left: 0 !important;
    }
  }
  .address-title-container {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    .account-subheading {
      margin-bottom: 20px;
    }
  }
}

.site-header-eyebrow,
.footer-legal {
  a:hover {
    color: $hover-color !important;
  }
}
.offerText:hover {
  color: $hover-color !important;
}

a.cartbutton {
  color: #ffffff;
}
a.cartbutton:after {
  position: relative;
  right: 0px;
}
a.btn.cartbutton:hover {
  color: #f26148;
}
.cart-list__empty-text p {
  font-family: var(--fmc-font--proxima-nova);
  font-weight: 500;
}
.sorrynotfoundpage {
  text-align: center;
  .text-and-image-links__container {
    padding-top: 50px;
  }
  h1 {
    font-size: 25px;
  }
  p {
    font-size: 26px;
    font-weight: 400;
    font-family: var(--fmc-font--proxima-nova);
    padding-top: 20px;
  }
  .backtohome {
    color: #ffffff;
  }
  .backtohome:after {
    position: relative !important;
    right: 2px !important;
  }
}

.orderDetail-mail-confirmation {
  .return-details {
    margin: 0;
    padding-top: 25px;
    padding-bottom: 25px;

    .return-modal-content-close {
      font-size: 24px;
      top: -12px;
      right: -12px;
      background: #102b4e;
      width: 23px;
      height: 23px;
      color: #fff;
      border-radius: 2px;
      text-align: center;
      line-height: 100%;
    }

    .input-field {
      width: 95%;
      margin: 0 auto 1em;
    }
    .return-details__form-actions {
      .return-details__form-actions-cta {
        margin-bottom: 1.5em;
      }
    }
  }
}

.blur {
  filter: blur(8px);
}

.error-tag {
  color: #d62d0a;
}
.error-border {
  border-color: #d62d0a !important;
  box-shadow: 0 3px 8px rgb(255 118 118 / 50%);
}

@media screen and (max-width: 555px) {
  .order-history-cart-footer {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .order-history-cart-footer {
    width: 28%;
    float: right;
  }
}

.rewardslink-cart {
  margin-top: 20px;
  text-align: center;
  a {
    letter-spacing: 0.5px;
    font-family: var(--fmc-font--proxima-nova);
    text-align: center;
    margin: 0 auto;
    display: block;
    color: #2861a4 !important;
    text-decoration: underline;
    line-height: 22px;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }

  div {
    font-weight: 600;
    font-family: var(--fmc-font--proxima-nova);
  }
}
.rewardslink-info {
  @media (min-width: 599px) {
    display: inline-block;
    float: right;
  }
  span {
    font-size: 13px;
    display: inline-block;
    line-height: 1.5em;
    @media (max-width: 599px) {
      font-size: 13px;
    }
    @media (max-width: 399px) {
      font-size: 11px;
    }
  }
  a {
    color: #2861a4 !important;
    display: inline-block;
    margin-left: 5px;
    text-decoration: underline !important;
    font-size: 13px;
    @media (max-width: 599px) {
      font-size: 13px;
    }
    @media (max-width: 399px) {
      font-size: 11px;
    }
  }
}

@media (max-width: 480px) {
  .cta-banner__container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.cta-banner {
  .slick--left-and-right-arrows.carousel-enabled {
    padding-bottom: 42px;
  }
  .slick--left-and-right-arrows.no-carousel {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .cta-banner-redeemtxt {
    font-size: 13px !important;
    font-weight: 500;
    line-height: normal;
  }
}
.state-dropdown-lincoln {
  svg {
    font-size: 24px;
  }
}

.breacrumb-title {
  font-size: 12px;
  font-weight: 400;
  font-family: var(--fmc-font--proxima-nova) !important;
}
.content-box-earn {
  margin-top: 18px;
}
.section-earn {
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: block;
  }
}
.image-container-earn {
  flex: 1;
}
.text-container-earn {
  flex: 1;
  margin-left: 10px;
  margin-bottom: 25px;
  line-height: 1.5;
  font-family: var(--fmc-font--proxima-nova);
  @media (max-width: 768px) {
    text-align: left;
    margin-top: 15px;
    font-size: 12px;
  }
}
.Earn-Content-1 {
  font-size: 17px !important;
  font-weight: 400;
  line-height: 1.5;
  @media (max-width: 768px) {
    font-size: 24px !important;
  }
}
.text-container-earn a {
  text-decoration: underline !important;
}
.Earn-Content-2 {
  margin-top: 20px !important;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
}

.fordreward-message {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  letter-spacing: 1px;
  margin-top: 24px;
  max-width: 373px;
  word-wrap: break-word;
}
.fordrewards-sitelink {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-decoration-line: underline !important;
}
.addresses-main .fordrewards-sitelink {
  font-size: 23px;
  color: #000 !important;
}

.fp-disclosure .fordrewards-sitelink {
  font-size: 12px;
}

.addresses-main a.fordrewards-sitelink:hover {
  font-weight: 400 !important;
}

.input-field--has-tooltip.checkoutFPR-tooltip {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  color: #fff;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
.checkoutFPR-tooltip {
  .tooltip-rewards {
    position: relative;
    display: inline-block;
  }
  .tooltip-rewards .tooltip-rewards-text {
    visibility: hidden;
    width: 120px;
    background-color: #fff;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;
    width: 300px;
    position: absolute;
    z-index: 1;
    top: 150%;
    text-align: left;
    left: -6%;
    padding: 10px 10px;
    border: 1px solid #6e6e6e;
    margin-left: -60px;
    color: var(--brand-primary-ford-blue, #00095b);
    font-family: var(--fmc-font--proxima-nova);
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;

    a {
      text-decoration: underline !important;
    }
  }

  .tooltip-rewards .tooltip-rewards-text:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;

    &:hover .tooltip-rewards-text {
      visibility: visible;
    }
  }

  .tooltip-rewards:hover .tooltip-rewards-text {
    visibility: visible;
  }

  .tooltip-rewards-text:hover {
    visibility: visible;
  }

  .questionttxt {
    font-weight: 700;
    border-bottom: 1px dotted #fff;
    cursor: pointer;
  }

  .supportingtext {
    margin-right: 5px;
  }

  .hiddnespan {
    display: inline-block;
    width: 100%;
    height: 20px;
    position: absolute;
    background: transparent;
    background: transparent;
    top: -13px;
    left: 0px;
  }
}

.CheckOutPage { 
.breabrums-list {
  display: flex;
  flex-direction: row;

  .full-height {
      svg {
          margin: 0 7px;
          width: 0.8em;
          height: 0.8em;
      }

      .labelClass-Shipping {
          label {
              &.MuiInputLabel-outlined {
                  width: 100%;

                  .field__label1 {
                      margin: 0;
                      top: 28px;
                      left: 15px;
                      font-weight: 300;
                      font-size: 17px;
                      position: absolute;
                      -webkit-transition: all 0.2s ease-out;
                      transition: all 0.2s ease-out;
                  }
              }

              &.MuiInputLabel-outlined.MuiInputLabel-shrink {
                  .field__label1 {
                      top: 18px;
                      z-index: 1;
                      font-size: 14px;
                      color: #737373;
                      font-weight: 300;
                  }
              }
          }

          .MuiOutlinedInput-root {
              padding: 0;

              input {
                  height: 100%;
                  font-size: 16px;
                  font-weight: 300;
                  padding: 25px 15px 10px;
              }

              fieldset {
                  font-size: 16px;
                  font-weight: 300;
                  border: 1px #d9d9d9 solid;
              }
          }
      }
  }

  .payment-card,
  .address-card {
      border: 1px solid #ddd !important;
  }

  .address-card {
      position: relative;
      box-shadow: none !important;

      &::after {
          content: '';
          width: 90%;
          margin: 0 10px;
          position: absolute;
          bottom: 0;
          left: 10px;
          height: 1px;
          background: #d9d9d9;
      }

      &:first-child {
          border-bottom: none !important;
      }

      &:last-child() {
          border-top: none !important;
      }
  }

  .shipping-method-review {
      .address-card::after {
          display: none;
      }
  }

  a {
      line-height: 140%;
  }
}

.review-container {
  @media screen and (min-width: 1390px) {
     /* max-width: 480px !important;*/
      // display: flex !important;
      justify-content: flex-start;
      padding: 0 20px !important;
      margin: 0 !important;
  }

  @media screen and (min-width: 1440px) {
      .sidebar__content {
          /*width: 470px;*/
      }
  }
}

.Order-review-container {
  padding: 0 !important;
  width: 100% !important;

  .order-review-checkout {
      padding-top: 2em !important;
      padding-left: 0 !important;
  }
}

.order-summary__section {
  .field--show-floating-label {
      .field__input-promo-wrapper {

          @media screen and (max-width: 768px) {
              flex-direction: column;

              .field__input-wrapper {
                  width: 100%;
              }

              .field__input-btn {
                  width: 100%;
                  margin-left: 0;
                  margin-top: 15px;
                  display: block;
                  padding-left: 0;
                  padding-right: 0;
                  background-color: #fff;
                  border-radius: 5px;

                  .btn__content {
                      width: 100%;
                      color: #102b4e;
                      background-color: #fff;
                  }
              }
          }
      }
  }
}

.checkout-action-button-container {
  margin: 0.75em 0;
  padding: 0 15px;
  padding-right: 0;
  max-width: 680px;
  float: right;

  @media screen and (max-width: 959px) {
      padding: 0;
      max-width: 100%;
      margin: 0.75em auto;
  }

  @media screen and (max-width: 768px) {
      button {
          margin-top: 20px;
      }
  }
}

.checkout-billing-container {
  /*margin: 0.75em 0;
  padding: 0 15px;
  padding-right: 0;
  max-width: 680px;
  float: right;*/

  @media (max-width: 959px) {
      padding: 0;
      max-width: 100%;
      margin: 0.75em auto;
  }

  @media (max-width: 767px) {
      button {
          margin-top: 20px;
      }
      .billing-state-dropdown button{
          margin-top: 0px;
      }
  }
}

.checkoutBreadrums-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 300px;

  @media screen and (max-width: 959px) {
      justify-content: center;
  }

  @media screen and (max-width: 810px) {
      padding-left: 8px;
  }

  .checkoutBreadrums {
      padding: 0;
      padding-top: 2.8rem;
      font-weight: 300;
      width: 680px;
      padding-left: 20px;

      @media screen and (max-width: 1440px) {
          padding-left: 0;
      }

      @media screen and (max-width: 959px) {
          padding-left: 8px;
          display: flex;
          width: 100%;
      }

      @media screen and (max-width: 810px) {
          padding-left: 0;
          padding-top: 1rem;
      }
  }
}

.checkout-shipping-container-header {
  display: flex;
  width: 100%;
  padding-top: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.checkout-shipping-container {
  text-align: left;
  // padding: 1em 1em !important;
  display: flex;
  flex-direction: column;
  align-items: end;
  top: 64px;
  border-bottom: 1px solid #B2B2B2;
  left: 56px;

  // padding: 20px 40px 20px var(--space-None, 0px) !important;
  .main-content-input {
      width: 85%;
      padding: 1em !important;

      @media screen and (max-width: 1024px) {
          width: 100%;
          padding: 0em !important;
      }

      .section__header {
          margin-top: 0.5em;
          margin-bottom: 0;
      }
      .section__header.billing-container {
          margin-top: 1.5em;
      }
  }

  @media (max-width: 959px) {
      padding: 1em 1em !important;
  }

  .shipping-addressform {
      display: flex;
      flex-direction: column;
      width: 90%;
      @media (max-width : 767px){
          width: 100%;
      }
      .editAddress-shipping {
          // align-self: flex-end;
          font-weight: 300;
          width: 100%;
          @media screen and (max-width: 959px) {
              align-self: center;
          }

          @media screen and (max-width: 959px) {
              max-width: 100%;
          }

          .section__header {
              margin-top: 0.5em;
              margin-bottom: 0;
          } 
          .formlabel-container {
              width: 100%;
              padding: 3.5px 7px;

              select {
                  border: 1px #d9d9d9 solid;
                  border-radius: 6px;
                  font-weight: 300;

                  option {
                      font-weight: 300;
                  }
              }

              .labelClass-Shipping {
                  label {
                      &.MuiInputLabel-outlined {
                          width: 100%;

                          .field__label1 {
                              margin: 0;
                              top: 28px;
                              left: 15px;
                              font-size: 17px;
                              position: absolute;
                              -webkit-transition: all 0.2s ease-out;
                              transition: all 0.2s ease-out;
                              font-weight: 300;
                              color: rgb(77, 77, 77);
                          }
                      }

                      &.MuiInputLabel-outlined.MuiInputLabel-shrink {
                          .field__label1 {
                              top: 18px;
                              z-index: 1;
                              font-size: 14px;
                              color: #737373;
                              font-weight: 300;
                          }
                      }
                  }

                  .MuiOutlinedInput-root {
                      padding: 0;

                      input {
                          height: 100%;
                          font-size: 16px;
                          font-weight: 300;
                          padding: 25px 15px 10px;
                      }

                      fieldset {
                          font-size: 16px;
                          font-weight: 300;
                          border: 1px #d9d9d9 solid;
                      }
                  }
              }
          }

          .payment-card,
          .address-card {
              // border: 1px #d9d9d9 solid;
              border: none !important;
              box-shadow: none !important;
          }

          .address-card {
              position: relative;
              box-shadow: none !important;

              &::after {
                  // content: '';
                  width: 90%;
                  margin: 0 10px;
                  // position: absolute;
                  bottom: 0;
                  left: 10px;
                  height: 1px;
                  background: #d9d9d9;
              }

              &:first-child {
                  border-bottom: none !important;
              }

              &:last-child() {
                  border-top: none !important;
              }
          }

          .shipping-method-review {
              .address-card::after {
                  display: none;
              }
          }
      }
  }

  .shipping-method-review {
      margin-top: 2.5em;

      @media screen and (max-width: 768px) {
          margin-top: 1em;
      }
  }

  .shipping_method-container {
      // margin-bottom: 1.5em;

      .section__header {
          margin-top: 1.75em;
          margin-bottom: 0.5em;
          text-transform: none;
          text-align: start;
          font-size: 25px;
          padding-left: 10px;
          width: 100%;
      }

      .checkout-terms-checkbox__section {
          .section__header {
              padding-left: 0;
              margin-top: 0.5em;
              margin-bottom: 0.5em;

              .section__title {
                  font-size: 25px;
                  text-transform: none;
              }

              .section__text {
                  font-size: 17px;
              }
          }

          .section__content {
              .content-box {
                  border-color: #d9d9d9;

                  label {
                      margin: 0;
                      padding: 8px 5px;

                      &.radio-control {
                          .MuiIconButton-label {
                              input {
                                  top: 15px;
                                  left: 15px;
                                  position: absolute;
                              }

                              .MuiSvgIcon-fontSizeLarge {
                                  font-size: 1.35rem;
                              }
                          }
                      }
                  }

                  .MuiDivider-root {
                      background-color: #d9d9d9;
                  }
              }

              .checkout-terms-checkbox__label {
                  .checkbox_input {
                      .MuiIconButton-label {
                          svg {
                              font-size: 1.5rem;

                              .MuiIconButton-colorSecondary {
                                  color: #d9d9d9;
                              }
                          }
                      }
                  }
              }
          }
      }
  }

  .payment-action-container {
      justify-content: end;
  }

  .address-card,
  .content-box-shipping {
      // border: 1px #d9d9d9 solid !important;
      //border: none !important;
      // box-shadow: none !important;

      &:first-child {
          // border-top-left-radius: 6px;
          // border-top-right-radius: 6px;
          border: none;
      }

      &:last-child {
          // border-bottom: 1px #d9d9d9 solid !important;
          // border-bottom-left-radius: 6px;
          // border-bottom-right-radius: 6px;
          border: none;
      }

      .review-block__label,
      .review-block__content {
          font-size: 17px;
          text-align: left;
          overflow-wrap: break-word;
      }

      .review-block__label-contact {
          font-size: 24px;
          text-align: left;
          overflow-wrap: break-word;
          color: #000000;
          font-weight: 400;
          // width: 300px;
          @media (max-width : 360px) {
              font-size: 19px;
          }
      }

      .shipping-method{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 17px;
          text-align: left;
          overflow-wrap: break-word;
          margin-bottom: 3px;
          padding-left: 30px;
      }
      
      .shipping-method-container {
          // padding: 5px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          width: 100%;
          @media (max-width: 767px) {
              flex-direction: column;
          }
      }
  }

  .content-box-shipping {
      padding: 5px 0;

      label {
          margin-bottom: 0;
      }
  }
}

//SessionErrorPopUp style
.fma_mo {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 100;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.fma_mo_content {
  margin: 15% auto;
  /* centered */
  width: 600px;
  height: 300px;
  position: relative;
}

@media screen and (max-width: 945px) {
  .fma_mo_content {
      width: 90%;
      margin-top: 33%;
  }
}

@media screen and (max-width: 710px) {
  .fma_mo_content {
      height: 350px;
  }
}

@media screen and (max-width: 555px) {
  .fma_mo_content {
      height: 460px;
  }
}

@media screen and (max-width: 472px) {
  .fma_mo_content {
      height: 480px;
  }
}

@media screen and (max-width: 302px) {
  .fma_mo_content {
      height: 540px;
  }
}

.fma_description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 26px;
  margin-bottom: 1em;
}

///

/* The Modal (background) */

.fma-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 100;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}


/* Modal Content/Box */

.fma-modal-content {
  margin: 15% auto;
  /* centered */
  width: 867px;
  height: 515px;
  position: relative;
}

@media screen and (max-width: 945px) {
  .fma-modal-content {
      width: 90%;
      margin-top: 33%;
  }
}

@media screen and (max-width: 710px) {
  .fma-modal-content {
      height: 350px;
  }
}

@media screen and (max-width: 555px) {
  .fma-modal-content {
      height: 460px;
  }
}

@media screen and (max-width: 472px) {
  .fma-modal-content {
      height: 480px;
  }
}

@media screen and (max-width: 302px) {
  .fma-modal-content {
      height: 540px;
  }
}


/* The Close Button */

.fma-close {
  font-weight: 200;
  font-size: 48px;
  position: absolute;
  top: 8px;
  right: 24px;
  color: #fff;
  z-index: 1;
}

@media screen and (max-width: 945px) {
  .fma-close {
      font-size: 24px;
      right: 12px;
  }
}

.fma-close:hover,
.fma-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.fma-popup_image {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  object-fit: cover;
}

.fma-popup-content {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  padding: 4em;
  text-align: center;
  color: #fff;
  background-color: rgba(16, 43, 78, 0.8);
}

@media screen and (max-width: 945px) {
  .fma-popup-content {
      padding: 2em 1em;
  }
}

.fma-header {
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 3px;
  line-height: 34px;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.fma-header span {
  font-weight: 500;
}

.fma-description {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 26px;
  margin-bottom: 1em;
}

@media screen and (max-width: 710px) {
  .fma-description {
      display: none;
  }
}

.fma-description~.fma-description {
  display: none;
  font-size: 14px;
  letter-spacing: .88px;
}

.fma-description~.fma-description br {
  margin: 1em;
}

@media screen and (max-width: 710px) {
  .fma-description~.fma-description {
      display: block;
  }
}

.fma-info {
  font-size: 14px;
  font-weight: 200;
  font-style: normal;
  letter-spacing: .88px;
  line-height: 26px;
  margin-bottom: 4em;
}

.fma-info span {
  text-decoration: underline;
}

@media screen and (max-width: 710px) {
  .fma-info {
      display: none;
      margin-bottom: 1em;
  }
}

.fma-login {
  background-color: #fff;
  color: #102b4e;
}

.fma-login:hover {
  background-color: #102b4e !important;
  color: #fff !important;
}

@media screen and (max-width: 710px) {
  .fma-login {
      display: none;
  }
}

.fma-login-mobile {
  display: none;
  margin-bottom: 1em;
}

@media screen and (max-width: 710px) {
  .fma-login-mobile {
      display: inline;
  }
}

.fma-read-more {
  display: none;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 24px;
  color: #fff;
}

@media screen and (max-width: 710px) {
  .fma-read-more {
      display: inline;
  }
}

.fma-open {
  height: 692px;
}

@media screen and (max-width: 400px) {
  .fma-open {
      height: 790px;
  }
}

@media screen and (max-width: 374px) {
  .fma-open {
      height: 970px;
  }
}

@media screen and (max-width: 296px) {
  .fma-open {
      height: 90vh;
  }
}

.fma-hide {
  display: none;
}

.fedex-validate-modal {
  padding: 25px;
  width: 550px;
  margin: 0 auto;
  background: #fff;
  font-family: var(--fmc-font--proxima-nova);
  position: relative;
  top:2%;
  @media (max-width: 768px) {
      width : 100%;  
      display: flex;
      flex-direction: column; 
  }

  .modal-title {
      margin: 0 0 15px 0;
      font-family: var(--fmc-font--proxima-nova);
      font-size: 2rem;
  }

  .fedex-error-msg {
      color: red;
      font-size: 1.3rem;
      margin: 0 0 15px 0;
  }

  .fedex-address-wrap {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;

      .addressbox {
          // flex-basis: 50%;
          // max-width:50%;
          // flex-grow: 0;
          min-width: 50%;
          margin-bottom: 15px;

          p {
            font-size: 1.5rem;
            font-weight: 400;
            margin: 5px 0;
          }

          .MuiButton-containedPrimary {
              margin-top: 15px;
          }
      }

      .enteredaddress {
          padding-right: 15px;
      }
  }
}


/* Order History start */

.DashboardMyAccount {
  .MuiDivider-middle {
      margin-top: 50px;
      margin-bottom: 50px;
  }
}

.addresses-main {
  padding-left: 14px;
  padding-right: 14px;

  .account-dashboard__addresses-cta {
      margin-top: 20px;
  }
}

.orderHistory_container {
  box-shadow: none !important;
  margin-bottom: 50px;

  .order-table__head {
      grid-template-columns: 1fr 1.2fr;

      .grid--5 {
          grid-column-start: 2;
      }
  }

  .order-table__main-item {
      grid-template-columns: 1fr 1.2fr;

      @media (max-width: 768px) {
          flex-direction: column;
          padding-bottom: 30px;
          margin-bottom: 15px;
          border-bottom: 1px solid #eee;

          &:last-child {
              border-bottom: none;
          }
      }

      .grid--5 {
          grid-template-columns: repeat(5, 1fr);
          display: grid !important;
          text-align: center;

          @media (max-width: 768px) {
              margin-top: 20px;
          }
      }

      .order__details {
          img {
              border: none;
              max-width: 120px;
              max-height: 120px;
              margin-right: 25px;

              @media (max-width: 768px) {
                  min-width: auto;
                  height: auto;
                  align-self: baseline;
              }
          }
      }
  }
}

.myaccount_section {
  margin-bottom: 40px;

  .pagination {
      background-color: #fff;
      margin-top: 50px;

      @media (max-width: 767px) {
          margin-top: 30px;
      }

      .pagination__container {
          .pagination__page-select {
              li {
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                  margin: 0 3px;
                  border: 2px solid rgb(140, 140, 140);

                  a {
                      text-align: center;
                      cursor: pointer;
                      padding: 10px 5px;
                  }

                  &:first-child,
                  &:last-child {
                      width: auto;
                      border: none;
                      color: rgb(16, 43, 78);
                      background-color: initial;
                  }

                  &.paginationActive {
                      border: 0px;
                      background: rgb(16, 43, 78);
                      color: white;
                  }
              }
          }
      }

  }

  .title {
      font-size: 34px !important;
  }

  .subtitle {
      text-transform: initial !important;
      font-size: 24px !important;
      margin-bottom: 20px !important;
  }

  .content {
      font-size: 16px !important;
  }

  .addaddressDetail {
      @media (max-width: 767px) {
          margin-top: 30px;

          .account-subheading {
              margin-bottom: 0 !important;
          }
      }
  }

  .editaddressDetail {
      @media (max-width: 767px) {
          margin-top: 50px;
      }
  }

  .account-dashboard__orders {
      padding: 20px;
      border: 1px solid #dddddd;
      border-radius: 6px;

      ol li {
          .order-item__detail {
              font-size: 16px;
          }
      }

      &_thumbnail {
          display: flex;
          flex-direction: row;

          a {
              margin: 10px;
              border: 1px solid #102b4e;

              &:first-child {
                  margin-left: 0;
              }

              &:last-child {
                  margin-right: 0;
              }
          }
      }
  }

  .order-address {
      .order-address__title {
          font-weight: 500;
      }

      .OrderHistory_addresCard {
          display: flex;
          flex-direction: column;

          .address_label {
              font-size: 16px;
              font-weight: 300;
              margin-bottom: 2px;
          }
      }
  }

  .order-tracking {
      a {
          text-decoration: underline;
      }

      .order-tracking__title {
          font-weight: 500;
      }
  }

  .order-table {
      .order__details {
          width: 100%;

          @media (max-width: 768px) {
              display: flex;
              flex-direction: column;
          }

          a {
              display: flex;
              flex-direction: row;

              @media (max-width: 768px) {
                  flex-direction: column;
              }

              img {
                  border: none;
                  max-width: 120px;
                  max-height: 120px;
                  margin-right: 25px;

                  @media (max-width: 768px) {
                      min-width: auto;
                      height: auto;
                  }
              }

              .order__details-text {
                  .order__product-title {
                      margin-bottom: 10px;

                      @media (max-width: 768px) {
                          margin-top: 20px;
                      }
                  }

                  .order__product-text {
                      color: #102b4e;
                      line-height: 140%;
                  }
              }
          }

          .order-table__main-item {
              .order__buy-again {
                  text-decoration: underline;
              }
          }
      }
  }

  .add-address {
      .deleteAddress {
          padding: 10px;
          margin-left: 60px;
          text-align: center;

          @media (max-width: 767px) {
              margin-left: 0;
          }
      }

      @media (max-width: 767px) {
          padding-left: 0;
          padding-right: 0;

          .grid-padding {
              padding: 0;
              width: 100%;
              margin: auto;

              .MuiGrid-item {
                  padding-left: 0;
                  padding-right: 0;
              }
          }
      }
  }

  .addresses__list-item-cta {
      margin-top: 8px;
      margin-bottom: 20px;
  }

  .input-field {
      @media (max-width: 767px) {}

      .label-address-form {
          font-size: 16px;
          font-weight: 300;
      }

      .input-address-form {
          margin-top: 10px;

          input {
              font-size: 16px;
              font-weight: 300;
              height: 100%;
              border-radius: 5px;
              padding: 16px 15px 17px;
              border: 1px solid #d9d9d9;
          }

          fieldset {
              border-radius: 5px;
              border: 1px solid #d9d9d9;
              border-width: 1px;
          }

          &:hover {
              fieldset {
                  border: 1px solid #d9d9d9 !important;
              }
          }
      }
  }

  .state-my-account,
  .input-address-form {
      margin-top: 10px;
      max-height: 54px;

      input {
          font-size: 16px;
          font-weight: 300;
          height: 100%;
          border-radius: 5px;
          padding: 16px 15px 17px;
          border: 1px solid #d9d9d9;
      }

      fieldset {
          border-radius: 5px;
          border: 1px solid #d9d9d9;
          border-width: 1px;
      }

      &:hover {
          fieldset {
              border: 1px solid #d9d9d9 !important;
          }
      }
  }

  .state-my-account input {
      border: none;
  }

  .addressformCheckbox {
      input {
          position: absolute;
      }
  }

  >.addresses-add-form__cancel-cta {
      margin-left: 45px;
      display: flex;
      margin-bottom: 60px;
      margin-top: 0;

      @media (max-width: 767px) {
          margin-left: 0 !important;
      }
  }

  .addresses-add-form__cancel-cta {
      margin-left: 45px;
      display: flex;
      margin-bottom: 60px;
      margin-top: 40px;

      @media (max-width: 767px) {
          margin-left: 0 !important;
      }
  }

  .addresses-add-form__submit-cta {
      margin-left: 45px;
      width: auto;
      margin-top: 0px;

      @media (max-width: 767px) {
          margin-left: 0 !important;
      }
  }

  .address-title-container {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;

      .account-subheading {
          margin-bottom: 20px;
      }
  }
}


/* Order History end */

#sitemapper h2 {
  margin-bottom: 50px;
}

#sitemapper .sitemapper-list {

  .subtitle {
      font-size: 20px;
      font-weight: 400;
      align-items: baseline;
      font-stretch: normal;
      text-align: left;
      text-decoration: underline;
      justify-content: flex-start;
  }

  .sitemapper-list:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: -50px;
      background: #102b4e;
  }

  li {
      width: 100%;
      background: #ffffff;
      border: 1px solid #102b4e;
      border-radius: 5px;
      min-height: 50px;
      -webkit-transition: .2s all ease-in-out;
      -moz-transition: .2s all ease-in-out;
      -ms-transition: .2s all ease-in-out;
      -o-transition: .2s all ease-in-out;
      transition: .2s all ease-in-out;

      &:hover {
          background: #102b4e;
          border-color: #fff;

          a {
              color: #fff;
          }
      }

      a {
          width: 100%;
          font-stretch: normal;
          padding-left: 0 !important;
          padding-right: 0 !important;
      }

      .sitemapper-list__category {
          margin-top: 20px;
          width: 33%;
          display: block;
          float: left;

          li {
              a {
                  line-height: 110%;
                  font-size: 18px;
                  text-decoration: none !important;
              }

              li a {
                  font-weight: 300;
                  text-transform: initial;
              }
          }
      }
  }
}

.return-details,
.return-details-items {
  margin-top: 60px;
  margin-bottom: 60px;

  .return-details__title {
      font-weight: 300;
  }

  p a {
      text-decoration: underline;
  }

  .return-details__form,
  p {
      font-stretch: normal;
  }

  .return-details-items__content {
      .return-details-items__content-product {
          .return-details-items__content-product-info {
              position: relative;

              .MuiIconButton-label {
                  svg {
                      position: absolute;
                  }

                  input[disabled] {
                      opacity: 0;
                  }
              }

              .order__details {
                  width: 100%;

                  a {
                      display: flex;
                      flex-direction: row;

                      img {
                          border: none;
                          margin-right: 20px;
                      }

                      .order__details-text {
                          .order__product-title {
                              margin-bottom: 10px;
                          }
                      }
                  }

                  .disableTag {
                      pointer-events: none;
                      opacity: 0.7;
                  }
              }

              .return-details__form-error {
                  color: #6e6e6e;
                  background: #e3e3e3;
                  position: absolute;
                  top: 4rem;
                  left: 13.5rem;
                  width: 75%;
                  height: auto;
                  line-height: 80%;
                  padding: 3px 10px 10px;
                  border: none;
                  box-shadow: 0 2px 4px rgba(100, 100, 100, 0.4019607);

                  &>.warn.warning {
                      top: 0.25em !important;
                      height: 1em !important;

                      &::before {
                          border-bottom-color: #6e6e6e;
                      }
                  }

                  a {
                      text-decoration: underline;
                  }
              }
          }
      }
  }

  .return-details-items__info {
      .return-details-items__info-form {
          .return-details-items__info-form-actions {
              select {
                  margin-top: 10px;
                  margin-bottom: 10px;
              }
          }
      }
  }

  .state-popup {
      .return-modal-content-container {
          .order__details {
              width: 80%;

              a {
                  display: flex;
                  flex-direction: row;

                  img {
                      margin-right: 20px;
                  }
              }
          }
      }
  }
}

.order__return {
  cursor: pointer;
  text-decoration: underline;
}

#orderLabel {
  .state-popup {
      .state-popup__inner {
          overflow-y: scroll;
          top: 55% !important;
          max-height: calc(100% - 200px) !important;
      }
  }
}

.return-details-items {
  &__info-form {}
}

.category-recommendation-hidden {
  display: none;
}

.disclaimer_box {
  margin-top: 2rem;
  background: #FFFFFF;
  padding: 30px;
  color: #323232;
  border-radius: 7px;
  text-decoration: none solid rgb(50, 50, 50);
  word-spacing: 0px;
  // height: 206.188px;
  // width: 417.703px;
  margin: 32px 0 0 0;
  padding: 30px 15px 30px 15px;
  min-width: auto;
  min-height: auto;
  transition: all 0s ease 0s;

  h2 {
      margin-bottom: 0.5rem;
      font-size: 15px;
      color: #323232;
      font-family: var(--fmc-font--proxima-nova);
      letter-spacing: 1.6px;
      line-height: 18.2px;
      // text-decoration: none solid rgb(50, 50, 50);
      word-spacing: 0px;
      background: #FFFFFF;
      margin: 0 0 8px 0;
      // transition: all 0s ease 0s;   
      text-align: start;
      text-transform: none;
  }

  p {
      text-align: start;
      font-size: 12px;
      letter-spacing: 0.08em;
      line-height: 1.5em;
      font-weight: 300;
      letter-spacing: 0.96px;
      line-height: 18px;
      text-decoration: none solid rgb(50, 50, 50);
      word-spacing: 0px;
      color: #323232;
      background: #FFFFFF;
  }
}

/*.cta-banner .slick--left-and-right-arrows {
  padding-bottom: 25px !important;
}*/

.cta-banner .slick--left-and-right-arrows .slick-arrow.slick-next {
  transform: translateY(calc(100% + 13px)) !important;
}

.cta-banner .slick--left-and-right-arrows .slick-arrow.slick-prev {
  transform: translateY(calc(100% + 13px)) rotate(180deg) !important;
}


/* SHOP ACCESSORIES homepage start*/

.cta-grid__container {
  .cta-grid__title {
      text-align: left;
      margin-top: 60px !important;

      @media (max-width: 767px) {
          text-align: center;
      }
  }
}


/* SHOP ACCESSORIES homepage end */

@media (max-width: 768px) {
  #shopify-section-disclosures .disclosures__content--active {
      height: 100% !important;
  }
}

.cta-links {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 20px;
}

.cta-link {
  margin-right: 10px;
  margin-top: 14px;
  color: #2861a4;
  text-align: left;
  font-size: 20px;
}

.text-primary {
  font-size: 40px;
  text-align: left;
  font-weight: 200;
}

.top-padding-5 {
  font-size: 40px;
  text-align: left;
  font-weight: 200;
  padding-top: 3px;
}

.top-padding-3 {
  font-size: 20px;
  text-align: left;
  font-weight: 200;
  padding-top: 3px;
}


/* Mobile Styles */

@media only screen and (max-width: 767px) {
  .text-primary {
      font-size: 26px;
  }

  .top-padding-3 {
      font-size: 30px;
  }

  .top-padding-3.recommendation-text {
      font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .text-primary {
      font-size: 20px;
  }

  .top-padding-3 {
      font-size: 30px;
  }

  .top-padding-3.recommendation-text {
      font-size: 14px;
  }
}

.pagination__page-select a {
  padding-right: 40px;
}

.nextBttn {
  padding-left: 20px;
}

.returnitem-check input {
  width: 20px;
  height: 20px;
}



.MuiContainer-root {
  .MuiGrid-container {
      .MuiGrid-item {
          h4 {
              span {
                  padding: 30px;
              }
          }
      }
  }
}

.recommendationWidget {
  h4.MuiTypography-root {
      display: contents;
      margin-top: 20px;

      span {
          padding: 30px;
      }
  }

  .product-card {
      max-height: 460px !important;
  }
}

.my-garage__add-vehicle,
.my-garage__edit-vehicle {
  @media (max-width: 1024px) {
      padding-left: 30px;
      padding-right: 30px;
  }
}

.checkout-actions {
  // margin: 30px 0;

  .paymentBtn-primary {
      display: flex;
      flex-direction: column;
  }
}

.slick-dots {
  display: flex !important;
}

.slick-dots li {
  margin: 0 10px !important;
}

.bronco-image {
  margin-right: 10px;
}

.orderDetail-mail-confirmation {
  .return-details {
      margin: 0;
      padding-top: 25px;
      padding-bottom: 25px;

      .return-modal-content-close {
          font-size: 24px;
          top: -12px;
          right: -12px;
          background: #102b4e;
          width: 23px;
          height: 23px;
          color: #fff;
          border-radius: 2px;
          text-align: center;
          line-height: 100%;
      }

      .input-field {
          width: 95%;
          margin: 0 auto 1em;
      }

      .return-details__form-actions {
          .return-details__form-actions-cta {
              margin-bottom: 1.5em;
          }
      }
  }
}

.blur {
  filter: blur(8px);
}

.error-tag {
  color: #d62d0a;
}

.error-border {
  border-color: #d62d0a !important;
  box-shadow: 0 3px 8px rgb(255 118 118 / 50%);
}

@media screen and (max-width: 555px) {
  .order-history-cart-footer {
      width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .order-history-cart-footer {
      width: 30%;
      float: right;
  }
}

.rewardslink-cart {
  margin-top: 20px;

  a {
      text-align: center;
      margin: 0 auto;
      display: block;
      color: #2861a4 !important;
      text-decoration: underline;
      line-height: 22px;

      &:hover {
          text-decoration: underline;
      }
  }

}

.rewardslink-info {

  @media (min-width:599px) {
      margin-right: 15px;
      display: inline-block;
      float: right;
  }

  span {
      font-size: 14px;
      line-height: 1.5em;
      display: inline-block;

      @media (max-width:599px) {
          font-size: 13px;
      }

      @media (max-width:399px) {
          font-size: 11px;
      }
  }

  a {
      color: #2861a4 !important;
      display: inline-block;
      margin-left: 5px;
      text-decoration: underline !important;

      @media (max-width:599px) {
          font-size: 13px;
      }

      @media (max-width:399px) {
          font-size: 12px;
      }
  }
}

.fma-modal.outfitter-gallery {
  .fma_mo_content {
      margin: 15% auto;
      width: 800px;
      height: 600px;
      display: block;
      background-color: #fff;

      @media (max-width:599px) {
          font-size: 13px;
          margin: 35% auto;
          width: 400px;
          height: 400px;
      }

      @media (max-width:399px) {
          font-size: 12px;
          margin: 35% auto;
          width: 400px;
          height: 400px;
      }
  }

  .modal__content {
      padding: 20px;
  }
}

.return-details-items .return-details__form-actions .return-details__form-actions-cta {
  margin-bottom: 1.5em;
}

.offer-details-title {
  color: "#ffffff";
  font-size: 38px;
  letter-spacing: 3px;
  line-height: 58px;
  padding-left: 60px;
  padding-right: 60px;
  text-align: left;

  @media (max-width:768px) {
      font-size: 28px !important;
      padding-left: 10px;
      line-height: 38px !important;
      padding-right: 10px;
  }
}

.offer-details-section {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 100px;
  padding-right: 100px;

  @media (max-width:768px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
      margin-top: 20px !important;
      margin-bottom: 20px !important;

  }
}

.available-offers {
  font-weight: 400;
  letter-spacing: 1.5px;
  font-size: 16px;
  padding-left: 60px;
  padding-right: 60px;

  @media (max-width:768px) {
      font-size: 16px;
      padding-right: 10px;
      padding-left: 10px;
  }
}

.page-hero__heading title--md {
  text-align: left;
}

.warranty-title {
  @media (max-width:768px) {
      padding-left: 0px !important;
  }
}

.breacrumb-title {
  font-size: 12px;
  font-weight: 400;
}

.not-found-section p {
  text-align: center;
}

.Fitment {
  display: inline-block;
  cursor: pointer;
  position: relative;

  svg {
      position: absolute;
  }
}

.footersection-title-mobile {
  font-size: 0.8312499999999999rem;
  font-weight: 400;
  line-height: 1.5;
}

.content-box-earn {
  margin-top: 18px;
}

.section-earn {
  display: flex;
  align-items: center;

  @media (max-width:768px) {
      display: block;
  }
}

.image-container-earn {
  flex: 1;
}

.text-container-earn {
  flex: 1;
  color: #112b4e;
  margin-left: 10px;
  margin-bottom: 20px;
  line-height: 1.5;
  padding: 0.5em;
  font-family: var(--fmc-font--proxima-nova);

  @media (max-width:768px) {
      text-align: left;
      margin-top: 15px;
  }

  @media (max-width:768px) {
      font-size: 12px;
  }
}

.Earn-Content-1 {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.5;
  font-family: var(--fmc-font--proxima-nova);

  @media (max-width:768px) {
      font-size: 25px;
  }
}

.Earn-Content-2 {
  font-family: var(--fmc-font--proxima-nova);
  margin-top: 20px;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;

  @media (max-width:768px) {
      font-size: 16px;
  }
}

.text-container-earn a {
  text-decoration: underline;
}

.user-menu__item--garage {
  position: relative;
}

.fordreward-message {
  margin-top: 24px;
  color: #000;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 1px;
  max-width: 426px;
  word-wrap: break-word;
}

.fordrewards-sitelink {
  color: #000;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-decoration-line: underline !important;
}

.fp-disclosure .fordrewards-sitelink {
  font-size: 12px;
}

.addresses-main a.fordrewards-sitelink:hover {
  font-weight: 400 !important;
}

.input-field--has-tooltip.checkoutFPR-tooltip {
  /*display: flex;*/
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  color: #000;
  font-family: var(--fmc-font--proxima-nova);
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1.2px;
}

.checkoutFPR-tooltip {
  .tooltip-rewards {
      position: relative;
      display: inline-block;
  }

  .tooltip-rewards .tooltip-rewards-text {
      visibility: hidden;
      width: 120px;
      background-color: #fff;
      color: #000;
      text-align: left;
      border-radius: 6px;
      padding: 5px 0;
      width: 300px;
      position: absolute;
      z-index: 1;
      top: 150%;
      text-align: left;
      left: 9%;
      padding: 10px 10px;
      border: 1px solid #6e6e6e;
      margin-left: -60px;
      color: var(--brand-primary-ford-blue, #00095B);
      font-family: var(--fmc-font--proxima-nova);
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 18px;

      a {
          text-decoration: underline;
      }
  }

  .tooltip-rewards .tooltip-rewards-text:after {
      position: absolute;
      width: 1em;
      height: 1em;
      top: calc(-0.7em + 2px);
      left: 50%;
      transform: rotate(-45deg);
      border-top: 1px solid #6e6e6e;
      border-right: 1px solid #6e6e6e;
      background-color: #fff;
      content: '';

      &:hover .tooltip-rewards-text {
          visibility: visible;
      }
  }

  .tooltip-rewards:hover .tooltip-rewards-text {
      visibility: visible;
  }

  .tooltip-rewards-text:hover {
      visibility: visible;
  }

  .questionttxt {
      font-weight: 700;
      border-bottom: 1px dotted #000;
      cursor: pointer;
  }

  .supportingtext {
     /* margin-right: 5px;*/
  }

  .hiddnespan {
      display: inline-block;
      width: 100%;
      height: 20px;
      position: absolute;
      background: transparent;
      background: transparent;
      top: -13px;
      left: 0px;
  }
} 
  
#CheckOutPage {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
  .MuiContainer-maxWidthLg {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.checkout-actions button {
  width: auto;
  margin-left: 0.8571428571em;
  white-space: nowrap;
  padding: 20px;
  /* padding-bottom: 0; */
  font-family: var(--fmc-font--proxima-nova);
  font-size: 15px;
  height: 50px;
  font-weight: 500;
  position: relative;
  transition: 0.2s all ease-in-out;
  @media (max-width: 999px) {
    font-size: 13px;
    padding: 10px;
  }
}
.checkout-actions button:hover {
  background-color: #324047;
}
.checkout-actions button:hover::before {
  transition: 0.2s all ease-in-out;
  content: " ";
  display: block;
  width: 100%;
  position: absolute;
  border-bottom: 3px solid #f26148;
  bottom: 0;
  left: 0;
  opacity: 1;
}


}
#OrderConfirmationPage {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
  .MuiContainer-maxWidthLg {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}
#province {
  font-size: 16px;
}